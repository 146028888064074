import React, { FunctionComponent, useState, memo } from 'react';
import Select from 'react-select';

import { SelectItem } from './select-item';
import { wrapField, cssClasses, BaseInputProps } from './field-helpers';
import { ErrorMessages } from '../errors';

import './SelectInput.scss';

export interface MultiSelectInputProps extends BaseInputProps<StoredType> {
  label: string;
  items: SelectItem[];
  icon?: string;
  defaultValue?: StoredType;
}

type InputType = SelectItem[];
type StoredType = any[];

const getDefaultValue = (): StoredType => [];

const getInitialValue = (props: MultiSelectInputProps): StoredType => {
  if (props.defaultValue !== undefined) {
    return props.defaultValue;
  } else {
    return getDefaultValue();
  }
};

const toInputValue = ({items}: MultiSelectInputProps, values: StoredType): InputType => {

  const selectedItems = [];

  for (const value of values) {

    const selectedItemArray = items.filter((x) => x.value === value);

    if (!selectedItemArray.length) {
      throw new Error(`Value '${value}' does not exist in the items array. `);
    }

    selectedItems.push(selectedItemArray[0]);
  }

  return selectedItems;
};

const fromInputValue = (inputValue: InputType): StoredType => {

  let newValue: StoredType;

  if (inputValue && inputValue.length) {
    newValue = inputValue.map((x) => x.value);
  } else {
    newValue = [];
  }

  return newValue;
};

export const MultiSelectInput: FunctionComponent<MultiSelectInputProps> = memo((props: MultiSelectInputProps) => {

  const {label, icon, readonly, items, style, errorMessages, onChange, setTouched, className, customRef} = props;

  if (customRef) {
    customRef(props);
  }

  let value = props.value;

  // Initialize
  if (value === undefined) {
    value = getInitialValue(props);
    setTimeout(() => onChange(value), 0);
  }

  const [focused, setFocused] = useState(false);

  const active = (value && value.length) || (!readonly && focused);

  const cssStates = cssClasses({
    readonly,
    invalid: !!errorMessages && !focused,
    active,
    focused,
  });

  return (
    <div className={`select-input custom-form-input form-group ${className} ${cssStates}`} style={style}>

      {icon && <i className={`fa fa-${icon} prefix`}/>}

      <div className="select-wrapper">
        <Select
          options={items}
          isClearable={true}
          isSearchable={true}
          closeMenuOnSelect={false}
          noOptionsMessage={() => '-'}
          placeholder={''}
          onChange={(inputValue: any) => {
            if (!readonly) {
              // to prevent flickering - when you clear the input
              // for a moment there are validation errors but the input is not yet focused
              // and they are shown an then hidden very quickly.
              setFocused(true);

              const newValue = fromInputValue(inputValue);
              onChange(newValue);
            }
          }}
          value={toInputValue(props, value)}
          onBlur={(_) => {
            if (setTouched) {
              setTouched();
            }
            setFocused(false);
          }}
          onFocus={() => setFocused(true)}
          isMulti={true}
        />
      </div>

      <label>{label}</label>

      {errorMessages && !focused && <ErrorMessages errors={errorMessages}/>}
    </div>
  );
});

export const MultiSelectField = wrapField(MultiSelectInput);
