import React, { Fragment } from 'react';

import { BaseComponent } from '../infrastructure/components/BaseComponent';
import { AppContextProps } from '../infrastructure/react-context';
import { CustomForm } from '../infrastructure/components/CustomForm';
import { TextField } from '../infrastructure/fields/TextInput';
import LoadingIndicator from '../infrastructure/components/LoadingIndicator';
import { ErrorMessages } from '../infrastructure/errors';
import { DocumentTitle } from '../infrastructure/DocumentTitle';
import { serverValidations, DoctorChangePasswordResponse, DoctorChangePasswordRequest } from '../dto';

interface Props extends AppContextProps {
}

interface State {
  loading: boolean;
  response: DoctorChangePasswordResponse | undefined;
  errorMessages: string[] | undefined;
}

const initialState: State = {
  loading: false,
  response: undefined,
  errorMessages: undefined,
};

export class ChangeDoctorPassword extends BaseComponent<Props, State> {

  state: State = initialState;

  onSubmit = async (data: DoctorChangePasswordRequest) => {

    const {server} = this.props.context;

    await this.setStateAsync({loading: true});

    const response = await server.doctorChangePassword(data);

    if (!response.success) {
      await this.setStateAsync({
        loading: false,
        errorMessages: response.errorMessages,
      });

      return;
    }

    await this.setStateAsync({loading: false, response: response.payload});
  };

  render() {

    const validations = serverValidations.changeUsernameAndPasswordRequest;

    const {loading, response, errorMessages} = this.state;

    return (
      <div className="change-password-page">

        <DocumentTitle title="Промяна на парола"/>

        {!response && <CustomForm onSubmit={this.onSubmit} render={(_) => (
          <div className="card">
            <div className="card-header blue white-text">
              Промяна на парола
            </div>
            <div className="card-body">

              <TextField
                label="Текуща парола"
                name="currentPassword"
                password
                icon="lock"
                validation={validations.currentPassword}
                inputProps={{autoComplete: 'current-password'}}
              />

              <TextField
                label="Нова парола"
                name="newPassword"
                icon="lock"
                password
                validation={validations.newPassword}
                inputProps={{autoComplete: 'new-password'}}
              />

              <TextField
                label="Потвърждаване на паролата"
                name="newPasswordConfirm"
                icon="lock"
                password
                validation={validations.newPasswordConfirm}
                inputProps={{autoComplete: 'new-password'}}
              />

              {loading && <LoadingIndicator/>}

              {errorMessages && <ErrorMessages errors={errorMessages}/>}

              <div className="text-center">
                <button type="submit" className="btn btn-md btn-default">
                  Промени
                </button>
              </div>
            </div>
          </div>
        )}/>}

        {response && <Fragment>
          <div className="card">
            <div className="card-header green white-text">Паролата Ви е сменена успешно.</div>
            <div className="card-body">
              <div className="text-center">Паролата Ви е сменена успешно!</div>
              <div className="text-center">
                <button
                  className="btn btn-md btn-default"
                  onClick={() => this.setState(initialState)}>
                  Промени отново
                </button>
              </div>
            </div>
          </div>
        </Fragment>}
      </div>
    );
  }
}
