import React from 'react';

import {AppContextProps} from '../infrastructure/react-context';
import {BaseComponent} from '../infrastructure/components/BaseComponent';
import {DocumentTitle} from '../infrastructure/DocumentTitle';
import {CustomForm} from '../infrastructure/components/CustomForm';
import {CustomModal} from '../infrastructure/components/CustomModal';
import {TextField} from '../infrastructure/fields/TextInput';

interface Props extends AppContextProps {
  value1: string;
  my_submit: (t: string) => void;
  my_submit2?: (t: string) => number;
  onCancel: () => void;
}

interface PassReset {
  email: string;
  mobilile: string;
}

interface State {
  user_obj: PassReset;
  mynum: number;
}

// до тук е демо
export class SmailDemoResetPassword extends BaseComponent<Props, State> {
  state: State = {
    mynum: 0,
    user_obj: {email: 'new', mobilile: 'new_pass'},
  };

  iWantOpenForm = () => {

    return 'ssss';
  };

  submit = (data: any) => {
      this.props.my_submit(data.text1);
  };

  render() {
    return (
      <div>

        <DocumentTitle title="Ресетване на парола"/>
        <h5>Сега ще видим какво става</h5>
        {<CustomModal title="Възтановяване на парола"
                     onClose={this.props.onCancel}>
            <CustomForm
                values={this.state}
                onSubmit={this.submit}
                render = {() => (
                  <div className="card">
                    <div className="card-header">{this.state.mynum}</div>
                    <div className="row">
                      <div className="col-sm-6">
                        <TextField label="Имейл:" name="email"></TextField>
                      </div>
                      <div className="col-sm-7">
                        <TextField label="Телефон:" name="mobilile"></TextField>
                      </div>
                    </div>
                    <div className="col">
                      <div className="float-right">
                        <button className="btn bg-success" type="submit">ПОТВЪРДИ</button>
                        <button className="btn bg-info" type="button"
                                onClick={this.props.onCancel}>
                          ОТКАЖИ</button>
                        <button className="btn bg-default" type="button"
                                onClick={() => {
                                  if (this.props.my_submit2 !== undefined) {
                                    const zzz = this.props.my_submit2('sss');
                                    this.setState({mynum: zzz});
                                  }}}>
                          ОТКАЖИ</button>
                      </div>
                    </div>
                  </div>
                )}>
            </CustomForm>
        </CustomModal>
        }

      </div>
    );
  }
}
