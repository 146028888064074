import {RouteTable} from '../infrastructure/components/RenderRouteTable';

import {ViewLabResults} from './ViewLabResults';
import {ViewBillingInformation} from './ViewBillingInformation';
import {ViewVisits} from './ViewVisits';
import {MakeAppointment} from '../shared/MakeAppointment';
import {InvalidateAccount} from './InvalidateAccount';
import ChangeUsernameAndPassword from './ChangeUsernameAndPassword';
import {AppointmentDelay} from './AppointmentDelay';
import {ViewObligations} from './ViewObligations';
import {ViewObligationsError} from './ViewObligationsError';
import {DisplayAppointment} from './DisplayAppointment';
import {ViewPrescriptions} from './ViewPrescriptions';
import {ViberSubscription} from './ViberSubscription';
import {ViewPublicLabResults} from './ViewPublicLabResult';
import {ConsultationForm} from './online-consultations/ConsultationForm';
import {ConsultationList} from './online-consultations/ConsultationList';
import {PasswordRecoveryForm} from './password-recovery/PasswordRecoveryForm';
import {RsaCertificate} from '../RSA/RsaCertificate';
import {RsaDocuments} from '../RSA/RsaDocuments';
import {RsaDocumentPreview} from '../RSA/RsaDocumentPreview';

export const patientRoutes: RouteTable = [
  {
    location: '/patient/results/:uniqueRecordPrefix?',
    component: ViewLabResults,
    role: 'patient',
    feature: 'ResultsPage',
  },
  {location: '/patient/billing', component: ViewBillingInformation, role: 'patient', feature: 'BillingPage'},
  {location: '/patient/visits', component: ViewVisits, role: 'patient', feature: 'VisitsPage', frame: 'narrow'},
  {
    location: '/patient/prescriptions', component: ViewPrescriptions, role: 'patient', feature: 'PrescriptionPage',
    frame: 'narrow',
  },
  {
    location: '/patient/appointment',
    component: MakeAppointment,
    role: 'patient',
    feature: 'PatientAppointmentPage',
    frame: 'narrow',
  },
  {
    location: '/patient/change-username-and-password',
    component: ChangeUsernameAndPassword,
    role: 'patient',
    feature: 'ChangePasswordPage',
    frame: 'narrow',
  },
  {
    location: '/patient/invalidate-account',
    component: InvalidateAccount,
    role: 'patient',
    feature: 'InvalidateAccountPage',
  },
  {
    location: '/patient/appointment-delay',
    component: AppointmentDelay,
    role: 'patient',
    feature: 'AppointmentDelayPage',
  },
  {
    location: '/patient/displays',
    component: DisplayAppointment,
    requireLogin: false,
    feature: 'DisplayAppointmentPage',
  },
  {
    location: '/patient/obligations/error',
    component: ViewObligationsError,
    role: 'patient',
    feature: 'VirtualPOSPage',
  },
  {
    location: '/patient/obligations/:orderID?',
    component: ViewObligations,
    role: 'patient',
    feature: 'VirtualPOSPage',
  },
  {
    location: '/patient/viber-subscription',
    component: ViberSubscription,
    role: 'patient',
    feature: 'ViberSubscriptionPage',
    frame: 'narrow',
  },
  {
    location: '/qr/:qrID',
    component: ViewPublicLabResults,
    feature: 'ResultsPage',
    requireLogin: false,
  },
  {
    location: '/patient/online-consultations',
    component: ConsultationList,
    feature: 'OnlineConsultationPage',
    role: 'patient',
    requireLogin: true,
  },
  {
    location: '/patient/online-consultations/new',
    component: ConsultationForm,
    feature: 'OnlineConsultationPage',
    role: 'patient',
    requireLogin: true,
  },
  {
    location: '/patient/password-recovery',
    component: PasswordRecoveryForm,
    feature: 'PasswordRecoveryPage',
    requireLogin: false,
    frame: 'narrow',
  },
  {
    location: '/patient/rsa/certificate',
    component: RsaCertificate,
    role: 'patient',
    feature: 'RSACertificate',
  },
  {
    location: '/patient/rsa/documents',
    component: RsaDocuments,
    role: 'patient',
    feature: 'RSACertificate',
  },
  {
    location: '/patient/rsa/:signDocID',
    component: RsaDocumentPreview,
    role: 'patient',
    feature: 'RSACertificate',
    frame: 'standard',
  },
];
