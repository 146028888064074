import React from 'react';
import {RouteComponentProps} from 'react-router-dom';

import {AppContextProps} from '../infrastructure/react-context';
import {BaseComponent} from '../infrastructure/components/BaseComponent';
import {CustomForm} from '../infrastructure/components/CustomForm';

import './demo.scss';

import {DebugValue} from '../infrastructure/components/DebugValue';
import {NumberField} from '../infrastructure/fields/NumberInput';
import { required } from '../infrastructure/fields/validations';

interface Props extends RouteComponentProps, AppContextProps {
}

interface State {
}

export class NumberFieldDemo extends BaseComponent<Props, State> {

  state: State = {};

  submit = async (values: any) => {

    console.info(values);
  };

  render() {

    return (
      <CustomForm onSubmit={this.submit} render={(form) => (
        <div className="card demo-form">
          <div className="card-body">

            <div>

              <NumberField
                name="default"
                label="Default"
              />

              <NumberField
                name="defaultWithIcon"
                icon="user"
                label="Default"
              />

              <NumberField
                name="defaultWithIconDefaultValue"
                icon="user"
                label="Default"
                defaultValue={123.23}
                decimalPlaces={2}
              />

              <NumberField
                name="readonly"
                label="Readonly"
                readonly
              />

              <NumberField
                name="readonlyWithIcon"
                icon="user"
                label="Readonly"
                readonly
              />

              <NumberField
                name="readonlyWithIconDefaultValue"
                icon="user"
                label="Readonly"
                readonly
                defaultValue={123}
              />

              <NumberField
                name="readonlyWithIconNullable"
                icon="user"
                label="Nullable"
                nullable
              />

              <NumberField
                name="readonlyWithIconNullableDefaultValue"
                icon="user"
                label="Nullable"
                nullable
                defaultValue={123}
              />

              <NumberField
                name="defaultValidate"
                icon="user"
                nullable
                defaultValue={undefined}
                label="Default Validate"
                validation={[required('The field is required.')]}
              />

            </div>

            <div className="text-center py-3 mt-3">
              <button className="btn btn-warning btn-block">Submit</button>
            </div>

            <DebugValue value={form} />

          </div>
        </div>
      )}/>
    );
  }
}
