import { ActionCreatorsMapObject, Action, Reducer } from 'redux';

import { Result } from './api-result';
import { put } from 'redux-saga/effects';
import { toastActionCreators } from './global.state';
import { VersionedResourcesResponse, PublicSettingsModel } from '../dto';
import { sessionActionCreators } from './session.state';
import { SagaMap } from './redux/store';

export interface CacheActionCreators extends ActionCreatorsMapObject {
  updateCache: (forceUpdate?: boolean) => Action;
  setGlobalVersion: (globalVersion: number) => Action;
  setUserPrivilegesVersion: (userPrivilegesVersion: number) => Action;
  setACodeResources: (
    textResources: { [key: number]: string },
    settings: PublicSettingsModel,
    aCodesVersion: number,
  ) => Action;
}

const cacheActions = {
  UPDATE_CACHE: 'CACHE/UPDATE_CACHE',
  SET_ACODE_RESOURCES: 'CACHE/SET_ACODE_RESOURCES',
  SET_GLOBAL_VERSION: 'CACHE/SET_GLOBAL_VERSION',
  SET_USER_PRIVILEGE_VERSION: 'CACHE/SET_USER_PRIVILEGE_VERSION',
};

export const cacheActionCreators: CacheActionCreators = {
  updateCache: (forceUpdate) => ({type: cacheActions.UPDATE_CACHE, forceUpdate: !!forceUpdate}),
  setGlobalVersion: (globalVersion) => ({type: cacheActions.SET_GLOBAL_VERSION, globalVersion}),
  setUserPrivilegesVersion: (userPrivilegesVersion) => ({
    type: cacheActions.SET_USER_PRIVILEGE_VERSION,
    userPrivilegesVersion,
  }),
  setACodeResources: (textResources, settings, aCodesVersion) => ({
    type: cacheActions.SET_ACODE_RESOURCES,
    textResources,
    settings,
    aCodesVersion,
  }),
};

export const cacheSagas: SagaMap = {
  [`cache/update-cache`]: {
    actionType: cacheActions.UPDATE_CACHE,
    * saga({forceUpdate}, {store, server}) {

      const {cache} = store.getState();

      const result: Result<VersionedResourcesResponse> = (yield server.versionedResources({
        aCodesVersion: cache.aCodesVersion,
        userPrivilegesVersion: cache.userPrivilegesVersion,
        forceUpdate,
      })) as any;

      if (!result.success) {

        yield put(toastActionCreators.error(result.errorMessages.join(', ')));
        return;
      }

      const response = result.payload;

      if (response.aCodesUpdated) {
        yield put(cacheActionCreators.setACodeResources(
          response.textResources || {},
          response.settings,
          response.aCodesVersion,
        ));
      }

      if (response.userPrivilegesUpdated) {
        yield put(sessionActionCreators.setUserPrivileges(response.userPrivileges || []));
        yield put(cacheActionCreators.setUserPrivilegesVersion(response.userPrivilegesVersion));
      }

      yield put(cacheActionCreators.setGlobalVersion(response.globalVersion));
    },
  },
};

export interface CacheState {
  globalVersion: number;

  aCodesVersion: number;
  textResources: { [key: number]: string };
  settings: PublicSettingsModel | undefined;

  userPrivilegesVersion: number;
}

const initialState: CacheState = {
  globalVersion: -1,

  aCodesVersion: -1,
  textResources: {},

  userPrivilegesVersion: -1,

  settings: undefined,
};

export const cacheReducer: Reducer<CacheState> = (state = initialState, action) => {
  switch (action.type) {
    case cacheActions.SET_ACODE_RESOURCES: {
      return {
        ...state,
        textResources: action.textResources,
        settings: action.settings,
        aCodesVersion: action.aCodesVersion,
      };
    }
    case cacheActions.SET_GLOBAL_VERSION: {
      return {
        ...state,
        globalVersion: action.globalVersion,
      };
    }

    case cacheActions.SET_USER_PRIVILEGE_VERSION: {
      return {
        ...state,
        userPrivilegesVersion: action.userPrivilegesVersion,
      };
    }

    default: {
      return state;
    }
  }
};

declare module './redux/store' {

  interface ReduxState {
    cache: CacheState;
  }

  interface AllActionCreators {
    cache: CacheActionCreators;
  }
}
