import React from 'react';

import {AppContextProps} from '../infrastructure/react-context';
import {BaseComponent} from '../infrastructure/components/BaseComponent';
import LoadingIndicator from '../infrastructure/components/LoadingIndicator';
import {format} from '../infrastructure/util';
import {DocumentTitle} from '../infrastructure/DocumentTitle';
import {AppointmentDelayDto} from '../dto';

interface Props extends AppContextProps {
}

interface State {
  loading: boolean;
  item: AppointmentDelayDto | undefined;
  secondsSinceRefresh: number;
  secondsTimerHandle: NodeJS.Timeout | undefined;
  dataTimerHandle: NodeJS.Timeout | undefined;
}

export class AppointmentDelay extends BaseComponent<Props, State> {

  state: State = {
    loading: false,
    item: undefined,
    secondsSinceRefresh: 0,
    secondsTimerHandle: undefined,
    dataTimerHandle: undefined,
  };

  componentDidMountAsync = async () => {

    await this.fetchData();

    await this.setStateAsync({
      secondsTimerHandle: setInterval(this.unwrapPromise(async () => {
        await this.setStateAsync(({secondsSinceRefresh}) => {
          return ({secondsSinceRefresh: secondsSinceRefresh + 1});
        });
      }), 1000),
      dataTimerHandle: setInterval(this.unwrapPromise(async () => {
        await this.fetchData();
      }), 1000 * 30),
    });
  };

  componentWillUnmountAsync = async () => {
    if (this.state.secondsTimerHandle) {
      clearInterval(this.state.secondsTimerHandle);
    }

    if (this.state.dataTimerHandle) {
      clearInterval(this.state.dataTimerHandle);
    }
  };

  fetchData = async () => {
    const {server, actions} = this.props.context;

    await this.setStateAsync({loading: true});

    const response = await server.patientAppointmentDelay({});

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loading: false, item: undefined});
      return;
    }

    await this.setStateAsync({loading: false, item: response.payload.item, secondsSinceRefresh: 0});
  };

  render() {

    const {loading, item} = this.state;

    return (
      <div className="appointment-delay-page">

        <DocumentTitle title="Чакане пред кабинет"/>

        <div className="card" style={{marginTop: '5rem'}}>
          <div className="card-body text-center padded">
            <h4>РЕД ЗА ПРЕГЛЕД</h4>
          </div>
        </div>

        {loading && <LoadingIndicator/>}

        {item && item.prevPatients < 2 && <div className="card" style={{marginTop: '1.5rem'}}>
            <div className="card-body text-center padded">
                МОЛЯ, ИЗЧАКАЙТЕ ПРЕД КАБИНЕТА.
            </div>
            <div className="card-body text-center padded">
                (СЛЕДВА ВАШИЯТ РЕД)
            </div>
        </div>}

        {item && item.prevPatients >= 2 && <ul className="list-group" style={{marginTop: '1rem'}}>
            <li className="list-group-item">ВАШИЯТ НОМЕР: <span style={{fontWeight: 'bold'}}>
              {item.calculatednum}</span></li>
            <li className="list-group-item">ЧАКАЩИ ПРЕДИ ВАС ПАЦИЕНТИ: <span style={{fontWeight: 'bold'}}>
              {item.prevPatients}</span></li>
            <li className="list-group-item">ВАШИЯТ ЧАС Е ЗА: <span style={{fontWeight: 'bold'}}>
              {format(item.wAppointmentFromDateTime, 'dd.MM.yyyy г. HH:mm ч.')} при {item.doctorname}
            </span></li>
            <li className="list-group-item">ЗАКЪСНЕНИЕ: <span style={{fontWeight: 'bold'}}>
              {item.lateMinutes < 10 && <span> НЯМА </span>}
              {item.lateMinutes >= 10 && <span> {item.lateMinutes} минути</span>}</span>
            </li>
            <li className="list-group-item">Автоматично опресняване на информацията: <span style={{fontWeight: 'bold'}}>
              {this.state.secondsSinceRefresh} секунди</span></li>
        </ul>}

        {!item && <div className="card" style={{marginTop: '1.5rem'}}>
            <div className="card-body text-center padded">
                НЯМА ИНФОРМАЦИЯ, КОЯТО ДА ВИ ПОКАЖЕМ.
            </div>
        </div>}

        {/*<button*/}
        {/*  className="btn btn-md btn-default center-block"*/}
        {/*  style={{marginTop: '1.5rem'}}*/}
        {/*  type="button"*/}
        {/*  onClick={this.fetchData}>*/}
        {/*  Опресни*/}
        {/*</button>*/}
      </div>
    );
  }
}
