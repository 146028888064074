import React, {memo} from 'react';
import {useParams} from 'react-router-dom';

import {RouteTable} from '../infrastructure/components/RenderRouteTable';

import {MakeAppointment} from '../shared/MakeAppointment';
import {DoctorSchedule} from './schedules/DoctorSchedule';
import {OperationPlanningList} from './operations/OperationPlanningList';
import {SendNotifications} from './notifications/SendNotifications';

import {AttachedResultList} from './attached-results/AttachedResultList';
import {AttachedResultNew} from './attached-results/AttachedResultNew';
import {AttachedResultEdit} from './attached-results/AttachedResultEdit';
import {AttachedResultPrint} from './attached-results/AttachedResultPrint';
import {AttachedResultDelete} from './attached-results/AttachedResultDelete';

import {MicrobiologyResultList} from './microbiology-results/MicrobiologyResultList';
import {MicrobiologyResultForm} from './microbiology-results/MicrobiologyResultForm';
import {MicrobiologyResultPrint} from './microbiology-results/MicrobiologyResultPrint';
import {MicrobiologyResultDelete} from './microbiology-results/MicrobiologyResultDelete';

import {SenderDoctorResultsList} from './sender-doctor-results/SenderDoctorResultsList';
import {SenderDoctorResultsView} from './sender-doctor-results/SenderDoctorResultsView';
import {CytologyResultList} from './cytology-results/CytologyResultList';
import {CytologyResultForm} from './cytology-results/CytologyResultForm';
import {CytologyResultDelete} from './cytology-results/CytologyResultDelete';
import {CytologyResultPrint} from './cytology-results/CytologyResultPrint';
import {LabResultsList} from './lab-results/LabResultsList';
import {AppContext} from '../infrastructure/context';
import {LabResultsForm} from './lab-results/LabResultsForm';
import {LabResultsPrint} from './lab-results/LabResultsPrint';
import {LabResultsDelete} from './lab-results/LabResultsDelete';
import {ChangeDoctorPassword} from './ChangeDoctorPassword';
import {JRequestsList} from './jrequests/JRequestsList';
import {JRequestsForm} from './jrequests/JRequestsForm';
import {JRequestsPrint} from './jrequests/JRequestsPrint';
import {DocumentSigningList} from './document-signing/DocumentSigningList';
import {SignDocument} from './document-signing/SignDocument';
import {ViberSubscription} from '../patient/ViberSubscription';
import {DoctorAgentMeetingsCatalog} from './medical-agent/DoctorAgentMeetingsCatalog';

export const doctorRoutes: RouteTable = [
  {
    location: '/doctor/appointment',
    component: MakeAppointment,
    role: 'doctor',
    feature: 'DoctorAppointmentPage',
    frame: 'narrow',
  },
  {
    location: '/doctor/sender-doctor-results',
    component: SenderDoctorResultsList,
    role: 'doctor',
    feature: 'DoctorResultsPage',
    frame: 'wide',
  },
  {
    location: '/doctor/sender-doctor-results/:patientRecID',
    component: memo(({context}: { context: AppContext }) => {

      const params = useParams<{ patientRecID: string }>();
      const patientRecordID = Number.parseInt(params.patientRecID, 10);

      return (
        <SenderDoctorResultsView
          patientRecID={patientRecordID}
          context={context}
          onSelectedResult={(selectedPatientRecID) => {
            context.actions.router.routerPush(`/doctor/sender-doctor-results/${selectedPatientRecID}`);
          }}
        />
      );
    }),
    role: 'doctor',
    feature: 'DoctorResultsPage',
  },
  {
    location: '/doctor/doctor-results/:patientID/:patientRecID?',
    component: memo(({context}: { context: AppContext }) => {

      const params = useParams<{ patientID: string, patientRecID: string }>();

      const patientID = Number.parseInt(params.patientID, 10) || undefined;
      const patientRecordID = Number.parseInt(params.patientRecID, 10) || undefined;

      return (
        <SenderDoctorResultsView
          patientID={patientID}
          patientRecID={patientRecordID}
          context={context}
          onSelectedResult={(selectedPatientRecID) => {
            context.actions.router.routerPush(`/doctor/doctor-results/${patientID}/${selectedPatientRecID}`);
          }}
        />
      );
    }),
    role: 'doctor',
    feature: 'DoctorResultsPage',
  },
  {
    location: '/doctor/schedule',
    component: DoctorSchedule,
    role: 'doctor',
    feature: 'DoctorSchedulePage',
  },
  {
    location: '/doctor/operation-planing',
    component: OperationPlanningList,
    role: 'doctor',
    feature: 'OperationPlanningPage',
  },
  {
    location: '/doctor/attached-results',
    component: AttachedResultList,
    role: 'doctor',
    feature: 'ExternalResultsPage',
    userPrivilege: 'ExternalResults',
  },
  {
    location: '/doctor/attached-results/new',
    component: AttachedResultNew,
    role: 'doctor',
    feature: 'ExternalResultsPage',
    userPrivilege: 'ExternalResults',
  },
  {
    location: '/doctor/attached-results/:patientRecID/edit',
    component: AttachedResultEdit,
    role: 'doctor',
    feature: 'ExternalResultsPage',
    userPrivilege: 'ExternalResults',
  },
  {
    location: '/doctor/attached-results/:patientRecID/print',
    component: AttachedResultPrint,
    role: 'doctor',
    feature: 'ExternalResultsPage',
    userPrivilege: 'ExternalResults',
  },
  {
    location: '/doctor/attached-results/:patientRecID/delete',
    component: AttachedResultDelete,
    role: 'doctor',
    feature: 'ExternalResultsPage',
    userPrivilege: 'ExternalResults',
  },
  {
    location: '/doctor/microbiology-results',
    component: MicrobiologyResultList,
    role: 'doctor',
    feature: 'MicrobiologyPage',
    userPrivilege: 'Microbiology',
  },
  {
    location: '/doctor/microbiology-results/new',
    component: MicrobiologyResultForm,
    role: 'doctor',
    feature: 'MicrobiologyPage',
    userPrivilege: 'Microbiology',
  },
  {
    location: '/doctor/microbiology-results/:patientRecID/edit',
    component: MicrobiologyResultForm,
    role: 'doctor',
    feature: 'MicrobiologyPage',
    userPrivilege: 'Microbiology',
  },
  {
    location: '/doctor/microbiology-results/:patientRecID/print',
    component: MicrobiologyResultPrint,
    role: 'doctor',
    feature: 'MicrobiologyPage',
    userPrivilege: 'Microbiology',
  },
  {
    location: '/doctor/microbiology-results/:patientRecID/delete',
    component: MicrobiologyResultDelete,
    role: 'doctor',
    feature: 'MicrobiologyPage',
    userPrivilege: 'Microbiology',
  },
  {
    location: '/doctor/send-notifications',
    component: SendNotifications,
    role: 'doctor',
    feature: 'NotificationsPage',
    userPrivilege: 'MobileNotification',
    frame: 'narrow',
  },
  {
    location: '/doctor/cytology-results',
    component: CytologyResultList,
    role: 'doctor',
    feature: 'CytologyResultsPage',
    userPrivilege: 'EditHistology',
  },
  {
    location: '/doctor/cytology-results/new',
    component: memo(({context}: { context: AppContext }) => (

      <CytologyResultForm
        patientRecordID={0}
        context={context}
        onDelete={() => {
        }}
      />
    )),
    role: 'doctor',
    feature: 'CytologyResultsPage',
    userPrivilege: 'EditHistology',
  },
  {
    location: '/doctor/cytology-results/:patientRecordID/edit',
    component: memo(({context}: { context: AppContext }) => {

      const params = useParams<{ patientRecordID: string }>();
      const patientRecordID = Number.parseInt(params.patientRecordID, 10);

      return (
        <CytologyResultForm
          patientRecordID={patientRecordID}
          context={context}
          onDelete={() => {
            context.actions.router.routerPush(`/doctor/cytology-results/${patientRecordID}/delete`);
          }}
        />
      );
    }),
    role: 'doctor',
    feature: 'CytologyResultsPage',
    userPrivilege: 'EditHistology',
  },
  {
    location: '/doctor/cytology-results/:patientRecordID/delete',
    component: memo(({context}: { context: AppContext }) => {

      const params = useParams<{ patientRecordID: string }>();
      const patientRecordID = Number.parseInt(params.patientRecordID, 10);

      return (
        <CytologyResultDelete
          patientRecordID={patientRecordID}
          context={context}
        />
      );
    }),
    role: 'doctor',
    feature: 'CytologyResultsPage',
    userPrivilege: 'EditHistology',
  },
  {
    location: '/doctor/cytology-results/:patientRecordID/print',
    component: memo(({context}: { context: AppContext }) => {

      const params = useParams<{ patientRecordID: string }>();
      const patientRecordID = Number.parseInt(params.patientRecordID, 10);

      return (
        <CytologyResultPrint
          patientRecordID={patientRecordID}
          context={context}
        />
      );
    }),
    role: 'doctor',
    feature: 'CytologyResultsPage',
    userPrivilege: 'EditHistology',
  },
  {
    location: '/doctor/lab-results',
    component: LabResultsList,
    role: 'doctor',
    feature: 'CytologyResultsPage',
    userPrivilege: 'EditHistology',
  },
  {
    location: '/doctor/lab-results/:jRequestID',
    component: LabResultsForm,
    role: 'doctor',
    feature: 'CytologyResultsPage',
    userPrivilege: 'EditHistology',
  },
  {
    location: '/doctor/lab-results/:jRequestID/print',
    component: LabResultsPrint,
    role: 'doctor',
    feature: 'CytologyResultsPage',
    userPrivilege: 'EditHistology',
  },
  {
    location: '/doctor/lab-results/:jRequestID/delete',
    component: LabResultsDelete,
    role: 'doctor',
    feature: 'CytologyResultsPage',
    userPrivilege: 'EditHistology',
  },
  {
    location: '/doctor/change-password',
    component: ChangeDoctorPassword,
    role: 'doctor',
    frame: 'narrow',
  },
  {
    location: '/doctor/jrequests',
    component: JRequestsList,
    role: 'doctor',
    userPrivilege: 'OnLineJRequests',
  },
  {
    location: '/doctor/jrequests/new',
    component: JRequestsForm,
    role: 'doctor',
    userPrivilege: 'OnLineJRequests',
  },
  {
    location: '/doctor/jrequests/:jRequestID/print',
    component: memo(({context}: { context: AppContext }) => {

      const params = useParams<{ jRequestID: string }>();
      const jRequestID = Number.parseInt(params.jRequestID, 10);

      return (<JRequestsPrint jRequestID={jRequestID} context={context}/>);
    }),
    role: 'doctor',
    userPrivilege: 'OnLineJRequests',
  },
  {
    location: '/doctor/document-signing',
    component: DocumentSigningList,
    role: 'doctor',
    frame: 'standard',
    feature: 'DocumentSigningPage',
    userPrivilege: 'DigitalSigning',
  },
  {
    location: '/doctor/document-signing/:documentID/sign',
    component: SignDocument,
    role: 'doctor',
    frame: 'standard',
    feature: 'DocumentSigningPage',
    userPrivilege: 'DigitalSigning',
  },
  {
    location: '/doctor/viber-subscription',
    component: ViberSubscription,
    role: 'doctor',
    feature: 'ViberSubscriptionPage',
    frame: 'narrow',
  },
  {
    location: '/doctor/medical-agent',
    component: DoctorAgentMeetingsCatalog,
    role: 'doctor',
    feature: 'MedicalAgent',
    frame: 'standard',
    userPrivilege: 'MedicalAgent',
  },
];
