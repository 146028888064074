/* tslint:disable:max-classes-per-file */
import React, { Fragment } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import { FieldArray } from 'formik';

import { BaseComponent } from '../../infrastructure/components/BaseComponent';
import { AppContextProps, AppContextFactoryProps, withAppContext } from '../../infrastructure/react-context';
import { DocumentTitle } from '../../infrastructure/DocumentTitle';
import { SelectItem } from '../../infrastructure/fields/select-item';
import { CustomModal } from '../../infrastructure/components/CustomModal';
import { CustomForm } from '../../infrastructure/components/CustomForm';
import { TextField } from '../../infrastructure/fields/TextInput';
import { SelectField } from '../../infrastructure/fields/SelectInput';
import { ErrorMessages } from '../../infrastructure/errors';
import { DateField } from '../../infrastructure/fields/DateInput';
import { LegacyDoctorSelectField } from '../LegacyDoctorSelectField';
import { TemplatesButton } from '../TemplatesButton';
import { TextAreaField } from '../../infrastructure/fields/TextAreaInput';
import LoadingIndicator from '../../infrastructure/components/LoadingIndicator';
import BackButton from '../../infrastructure/components/BackButton';
import PatientSelectModal from '../PatientSelectModal';
import {
  PatientInformationDto,
  MicrobiologyResultDto,
  MicrobeDto,
  AntibiogrameRowDto,
  AntibiogrameDto,
  serverValidations,
} from '../../dto';

import './microbiology-results.scss';

interface RouteParams {
  patientRecID: string;
}

interface Props extends RouteComponentProps<RouteParams>, AppContextProps {
}

interface State {
  model: MicrobiologyResultDto | undefined;
  patient: PatientInformationDto | undefined;
  materials: SelectItem<string>[] | undefined;

  loading: boolean;

  errorMessages: string[];
  submitLoading: boolean;

  selectingPatient: boolean;

  editedMicrobe: { index?: number | undefined; model: MicrobeDto; } | undefined;
}

const initialState: State = {
  model: undefined,
  patient: undefined,
  materials: undefined,

  loading: true,

  errorMessages: [],
  submitLoading: false,

  selectingPatient: false,

  editedMicrobe: undefined,
};

export class MicrobiologyResultForm extends BaseComponent<Props, State> {

  state: State = initialState;

  getItemID = (): number => Number.parseInt(this.props.match.params.patientRecID, 10);
  isNew = () => !this.getItemID();

  componentDidMountAsync = async () => {
    await this.initForm();
  };

  componentDidUpdateAsync = async (prevProps: Props) => {
    if (prevProps.match.params.patientRecID !== this.props.match.params.patientRecID) {
      await this.initForm();
    }
  };

  initForm = async () => {
    await this.setStateAsync(initialState);

    if (this.isNew()) {
      await this.setStateAsync({selectingPatient: true});
    } else {
      const patientRecID = this.getItemID();
      await this.loadExistingItem(patientRecID);
    }
  };

  loadExistingItem = async (patientRecID: number) => {

    const {server, actions} = this.props.context;

    const resultResponse = await server.getMicrobiologyResult({patientRecID});

    if (!resultResponse.success) {
      actions.errors.setErrorMessages(resultResponse.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    const model = resultResponse.payload.item;

    await this.fetchAdditionalData(model);
  };

  loadNewItem = async (patientID: number) => {

    const {server, actions} = this.props.context;

    const resultResponse = await server.newMicrobiologyResult({});

    if (!resultResponse.success) {
      actions.errors.setErrorMessages(resultResponse.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    const model = {
      ...resultResponse.payload.item,
      patientID,
    };

    await this.fetchAdditionalData(model);
  };

  fetchAdditionalData = async (model: MicrobiologyResultDto) => {

    const {server, actions} = this.props.context;

    // materials
    const materialsResponse = await server.getMicrobiologyMaterials({
      referenceDate: model.examDateTime,
      currentACode: model.material,
    });

    if (!materialsResponse.success) {
      actions.errors.setErrorMessages(materialsResponse.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    const materials = materialsResponse.payload.items;

    // patient data
    const patientResponse = await server.patientInformation({patientID: model.patientID});

    if (!patientResponse.success) {
      actions.errors.setErrorMessages(patientResponse.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    const patient = patientResponse.payload.item;

    await this.setStateAsync({
      model,
      patient,
      materials,
      loading: false,
      selectingPatient: false,
    });
  };

  saveItem = async (model: MicrobiologyResultDto) => {

    if (!model) {
      throw new Error('The model submitted from the form is undefined.');
    }

    const {server, actions} = this.props.context;

    await this.setStateAsync({model, submitLoading: true});

    const response = await server.saveMicrobiologyResult({item: model});

    if (!response.success) {
      await this.setStateAsync({submitLoading: false, errorMessages: response.errorMessages});
      return;
    }

    await this.setStateAsync({submitLoading: false});
    actions.router.routerPush(`/doctor/microbiology-results/${response.payload.patientRecID}/print`);
  };

  render() {

    const {
      loading,
      submitLoading,
      model,
      patient,
      errorMessages,
      materials,
      selectingPatient,
      editedMicrobe,
    } = this.state;

    const title = `Микробиологичен резултат - ${(this.isNew() ? 'Нов' : 'Редакция')}`;

    return (
      <div className="microbiology-results-form-page">

        <DocumentTitle title={title}/>

        {selectingPatient && <PatientSelectModal
          onClose={() => this.props.context.actions.router.goBack()}
          onSelection={(x) => this.loadNewItem(x.patientID)}
        />}

        <div className="card">
          <div className="card-header blue white-text">{title}</div>
          <div className="card-body">

            {loading && <LoadingIndicator delay={0}/>}

            {model && patient && materials &&
            <CustomForm values={model} onSubmit={this.saveItem} render={({isSubmitting, values, setFieldValue}) => {

              const microbes: MicrobeDto[] = values.microbes;

              const validations = serverValidations.microbiologyResultDto;

              return (
                <Fragment>
                  <div className="row" style={{marginTop: '1rem'}}>
                    <div className="col-sm-8 vertical-center">
                      <h4>
                        {patient.patientFullName},
                        ЕГН: {patient.patientIdentNumber} Възраст: {patient.patientAge} год.
                      </h4>
                    </div>

                    <div className="col-sm-4">

                      <div className="button-group">
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="btn btn-md btn-info">
                          Запази
                        </button>

                        {!!model.patientRecordID && <Link
                          to={`/doctor/microbiology-results/${model.patientRecordID}/delete`}
                          className="btn btn-md btn-danger">
                          Изтриване
                        </Link>}

                        <BackButton render={(backButtonProps) =>
                          <a {...backButtonProps}
                             className="btn btn-md btn-warning">
                            Назад
                          </a>
                        }/>
                      </div>
                    </div>
                  </div>

                  <hr/>

                  {submitLoading && <LoadingIndicator/>}

                  {!!errorMessages.length && <div className="row">
                    <div className="col-sm-12">
                      <hr/>
                      <ErrorMessages errors={errorMessages}/>
                      <hr/>
                    </div>
                  </div>}

                  <div className="row" style={{marginTop: '1rem'}}>

                    <div className="col-sm-6">
                      <DateField
                        name="examDateTime"
                        label="Дата:"
                      />
                    </div>
                    <div className="col-sm-6">
                      <LegacyDoctorSelectField
                        name="senderDoctorID"
                        label="Насочен от:"
                        doctorCategoryIDs={[1]}
                        validation={validations.senderDoctorID}
                      />
                    </div>
                    <div className="col-sm-6">
                      <TextField
                        name="material"
                        label="Изследван материал:"
                        autocompleteItems={materials.map((x) => x.label)}
                        autocompleteExpandOnFocus
                      />
                    </div>

                    <div className="col-sm-6">

                      <div style={{position: 'relative', marginTop: '1.2rem'}}>
                        <TemplatesButton templateTypeID={23} onTemplateSelected={(text: string) => {
                          setFieldValue('microscopeExam', (values.microscopeExam || '') + text);
                        }}
                        />

                        <TextAreaField
                          name="microscopeExam"
                          label="Микроскопско изследване:"
                        />
                      </div>

                    </div>
                  </div>

                  <div className="row">

                    <FieldArray name="microbes" render={(arrayControl) => <Fragment>

                      {editedMicrobe !== undefined && <MicrobeModal
                        model={editedMicrobe.model}
                        index={editedMicrobe.index}
                        referenceDate={model.examDateTime}
                        onClose={() => this.setState({editedMicrobe: undefined})}
                        onSubmit={(index, microbeModel) => {
                          if (index === undefined) {
                            arrayControl.push(microbeModel);
                          } else {
                            arrayControl.replace(index, microbeModel);
                          }

                          this.setState({editedMicrobe: undefined});
                        }}
                      />}

                      <div className="col-sm-12">
                        <button
                          type="button"
                          onClick={() => {
                            this.setState({editedMicrobe: {model: ({} as any)}});
                          }}
                          style={{marginBottom: '0.6rem'}}
                          className="btn btn-md btn-success">
                          Нов (Изолиран микроогранизъм)
                        </button>
                      </div>

                      <div className="col-sm-12">

                        <div className="card">
                          <div className="card-header blue white-text">
                            Изолирани микроорганизми
                          </div>
                          <div className="card-body">
                            <div className="table-responsive">
                              <table className="table list-table">
                                <thead className="blue text-white">
                                  <tr>
                                    <th>Микроогранизъм</th>
                                    <th>Количество</th>
                                    <th>Оценка</th>

                                    <th/>
                                    <th/>
                                  </tr>
                                </thead>

                                {!!microbes.length && <tbody>
                                  {microbes.map((item: MicrobeDto, index: number) => (
                                    <tr key={index}>
                                      <td>{item.microbeName}</td>
                                      <td>{item.quantity}</td>
                                      <td>{item.grade}</td>
                                      <td>
                                        <button
                                          type="button"
                                          onClick={() => this.setState({editedMicrobe: {index, model: item}})}
                                          className="btn btn-sm btn-info">
                                          Редакция
                                        </button>
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          onClick={() => arrayControl.remove(index)}
                                          className="btn btn-sm btn-danger">
                                          Изтриване
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>}
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>

                    </Fragment>}/>
                  </div>

                  <hr/>

                  <div className="row" style={{marginTop: '1rem'}}>

                    <div className="col-sm-6">
                      <div style={{position: 'relative', marginTop: '1.2rem'}}>
                        <TemplatesButton templateTypeID={22} onTemplateSelected={(text: string) => {
                          setFieldValue('conclusion', (values.conclusion || '') + text);
                        }}
                        />
                        <TextAreaField
                          name="conclusion"
                          label="Интерпретация на резултата:"
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <LegacyDoctorSelectField
                        name="doctorID"
                        label="Лаб. лекар:"
                        readonly
                        doctorCategoryIDs={[1]}
                      />
                    </div>
                  </div>
                </Fragment>
              );
            }}/>}
          </div>
        </div>
      </div>
    );
  }
}

interface MicrobeModalProps extends AppContextFactoryProps {
  model: MicrobeDto;
  referenceDate: Date;
  index: number | undefined;
  onClose: () => void;
  onSubmit: (index: number | undefined, model: MicrobeDto) => void;
}

interface MicrobeModalState {
  loading: boolean;
  microbes: string[] | undefined;
  antibiogramesItems: AntibiogrameRowDto[] | undefined;
  antibiogrames: AntibiogrameDto[];
}

const MicrobeModal = withAppContext(class extends BaseComponent<MicrobeModalProps, MicrobeModalState> {

  state: MicrobeModalState = {
    loading: true,
    microbes: undefined,
    antibiogramesItems: undefined,
    antibiogrames: [],
  };

  async componentDidMountAsync() {

    const {server, actions} = this.props.getContext();

    const microbesResult = await server.getMicrobiologyMicrobes({
      currentACode: this.props.model.microbeName,
      referenceDate: this.props.referenceDate,
    });

    if (!microbesResult.success) {
      actions.errors.setErrors(microbesResult.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    const microbes = microbesResult.payload.items.map((x) => x.label);

    const antibiogrameResult = await server.getMicrobiologyAntibiogrames({
      referenceDate: this.props.referenceDate,
    });

    if (!antibiogrameResult.success) {
      actions.errors.setErrors(antibiogrameResult.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    const antibiogramesItems = antibiogrameResult.payload.items;

    await this.setStateAsync({
      loading: false,
      antibiogramesItems,
      microbes,
      antibiogrames: this.props.model.antibiograme,
    });
  }

  getAntibiogrameTypes = (): string[] => {
    const {antibiogramesItems} = this.state;

    if (!antibiogramesItems) {
      throw new Error('No "antibiogramesItems".');
    }

    return Array.from(new Set(antibiogramesItems
      .filter((x) => x.aCodeShortName && x.aCodeShortName !== 'N/A')
      .map((x) => x.aCodeShortName)));
  };

  antibiogrameTypeSelected = async (aCodeShortName: string) => {

    if (!aCodeShortName) {
      return;
    }

    const {antibiogramesItems} = this.state;

    if (!antibiogramesItems) {
      throw new Error('No "antibiogramesItems".');
    }

    const antibiogrames: AntibiogrameDto[] = antibiogramesItems
      .filter((x) => x.aCodeShortName === aCodeShortName);

    await this.setStateAsync({antibiogrames});
  };

  closeModal = () => {
    this.props.onClose();
  };

  submitForm = (model: MicrobeDto) => {

    this.props.onSubmit(this.props.index, {
      ...model,
      antibiograme: this.state.antibiogrames,
    });
  };

  setSensitivity = (sensitivity: string, index: number) => {
    this.setState(({antibiogrames}) => ({
      antibiogrames: antibiogrames.map((item, i) => {
        if (i === index) {
          return {...item, sensitivity};
        }
        return item;
      }),
    }));
  };

  render() {

    const {model} = this.props;
    const {microbes, antibiogramesItems, antibiogrames, loading} = this.state;

    return (
      <CustomModal
        title="Изолиран микроогранизъм"
        className="microbe-modal"
        onClose={this.closeModal}>
        {loading && <LoadingIndicator/>}

        {microbes && antibiogramesItems &&
        <CustomForm values={model} onSubmit={this.submitForm} render={({isSubmitting}) => (
          <div className="card">
            <div className="card-body">

              <div className="row">
                <div className="col-md-6">
                  <TextField
                    name="microbeName"
                    label="Микроогранизъм:"
                    autocompleteItems={microbes}
                    autocompleteExpandOnFocus
                  />
                </div>

                <div className="col-md-6">
                  <TextField
                    name="grade"
                    label="Оценка:"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <TextField
                    name="quantity"
                    label="Количество:"
                  />
                </div>

                <div className="col-md-6">
                  <SelectField
                    name="antibiogrameACodeShortName"
                    label="Вид антибиограма:"
                    items={this.getAntibiogrameTypes().map((x) => ({label: x, value: x}))}
                    onChange={this.antibiogrameTypeSelected}
                    nullable
                  />
                </div>
              </div>

              <div className="table-responsive">
                <table className="table list-table">
                  <thead className="blue text-white">
                    <tr>
                      <th>Антибиотик</th>
                      <th>Стойност (R, I, S)</th>
                      <th/>
                    </tr>
                  </thead>

                  {antibiogrames && !!antibiogrames.length && <tbody>
                    {antibiogrames.map((item: AntibiogrameDto, index: number) => (
                      <tr key={item.aCode}>
                        <td>{item.aCodeName}</td>
                        <td className="sensitivity-selector">
                          <span className="sensitivity-value">{item.sensitivity}</span>
                          <button className="btn btn-info btn-sm sensitivity-button"
                                  type="button"
                                  onClick={() => this.setSensitivity('R', index)}>R
                          </button>
                          <button className="btn btn-info btn-sm sensitivity-button"
                                  type="button"
                                  onClick={() => this.setSensitivity('S', index)}>S
                          </button>
                          <button className="btn btn-info btn-sm sensitivity-button"
                                  type="button"
                                  onClick={() => this.setSensitivity('I', index)}>I
                          </button>
                        </td>
                        <td>
                          <button
                            type="button"
                            onClick={() => {
                              this.setState((state) => {
                                if (!state.antibiogrames) {
                                  return null;
                                }
                                return {
                                  antibiogrames: state.antibiogrames.filter((_, i) => i !== index),
                                };
                              });
                            }}
                            className="btn btn-sm btn-danger">
                            Изтриване
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>}
                </table>
              </div>

              <div className="row" style={{marginTop: '1rem'}}>
                <div className="col-sm-6 vertical-center">
                  <button className="btn btn-md btn-default full-width" type="submit"
                          disabled={isSubmitting}>
                    Запази
                  </button>
                </div>

                <div className="col-sm-6 vertical-center">
                  <button className="btn btn-md btn-warning full-width" type="button"
                          onClick={this.closeModal}>
                    Назад
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}/>}
      </CustomModal>
    );
  }
});
