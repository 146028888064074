interface GroupByResult<K, T> {
  key: K;
  items: T[];
}

type GroupBySelector<K, T> = (item: T) => K;

export function groupBy<K, T>(inputArray: T[], selector: GroupBySelector<K, T>): GroupByResult<K, T>[] {
  return inputArray.reduce((result, currentItem) => {
    const key = selector(currentItem);
    const groupArray = result.filter((x) => x.key === key);
    let group;
    if (groupArray.length) {
      group = groupArray[0];
    } else {
      group = {key, items: []};
      result.push(group);
    }
    group.items.push(currentItem);
    return result;
  }, [] as GroupByResult<K, T>[]);
}
