import {CustomModal} from '../../infrastructure/components/CustomModal';
import React, {Fragment} from 'react';
import {BaseComponent} from '../../infrastructure/components/BaseComponent';
import {AppContextFactoryProps, withAppContext} from '../../infrastructure/react-context';

interface Props extends AppContextFactoryProps {
  onClose: () => void;
  saveResult: () => void;
}

interface State {
}

class ConfirmationModalImpl extends BaseComponent<Props, State> {

  render() {
    const {onClose, saveResult} = this.props;

    return (
      <CustomModal title="Потвърждение" onClose={onClose} className="confirmation-modal">
        <div className="card">
          <div className="card-body text-center">

            <Fragment>

              <h3 style={{marginTop: '2rem'}}>
                Резултатът не е валидиран. Искате ли да запишете този резултат въпреки това?
              </h3>

              <div className="button-group" style={{marginTop: '2rem'}}>
                <button className="btn btn-md btn-success" onClick={saveResult}>Запази</button>
                <button className="btn btn-md btn-warning" onClick={onClose}>Отказ</button>
              </div>

            </Fragment>
          </div>
        </div>
      </CustomModal>
    );
  }
}

export const ConfirmationModal = withAppContext(ConfirmationModalImpl);
