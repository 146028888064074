import React from 'react';
import {NavLink, Link} from 'react-router-dom';

import {AppContextProps} from '../../infrastructure/react-context';
import {BaseComponent} from '../../infrastructure/components/BaseComponent';
import {CustomForm} from '../../infrastructure/components/CustomForm';
import {DateField} from '../../infrastructure/fields/DateInput';
import LoadingIndicator from '../../infrastructure/components/LoadingIndicator';
import {DocumentTitle} from '../../infrastructure/DocumentTitle';
import {serverValidations, JRequestListRequest, JRequestListDto} from '../../dto';
import {DeleteJRequestModal} from './DeleteJRequestModal';
import {format} from '../../infrastructure/util';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFile} from '@fortawesome/free-regular-svg-icons';
import {faArrowCircleUp, faTimes} from '@fortawesome/free-solid-svg-icons';

interface Props extends AppContextProps {
}

interface State {
  filter: JRequestListRequest;
  listItems: JRequestListDto[] | undefined;
  loading: boolean;
  jRequestForDelete: JRequestListDto | undefined;
}

export class JRequestsList extends BaseComponent<Props, State> {

  state: State = {
    loading: false,
    filter: {
      fromDate: new Date(),
      toDate: new Date(),
    },
    listItems: undefined,
    jRequestForDelete: undefined,
  };

  async searchItems() {

    const {server, actions} = this.props.context;

    await this.setStateAsync({loading: true, listItems: []});

    const response = await server.jRequestList(this.state.filter);

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    await this.setStateAsync({loading: false, listItems: response.payload.items});
  }

  componentDidMountAsync = async () => {
    await this.searchItems();
  };

  submit = async (filter: JRequestListRequest) => {
    await this.setStateAsync({filter});
    await this.searchItems();
  };

  getJOrdersInfo = async (currentJRequestID: number) => {
    const {server, actions} = this.props.context;

    await this.setStateAsync({loading: true});

    const response = await server.jOrdersInfo({jRequestID: currentJRequestID});

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    await this.setStateAsync({loading: false});
    actions.modals.messageBox(response.payload.message);
  };

  sendPatientLoginInfo = async (patientID: number) => {
    const {server, actions} = this.props.context;

    await this.setStateAsync({loading: true});

    const response = await server.sendPatientLoginInfo({patientID});

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    await this.setStateAsync({loading: false});
    actions.modals.messageBox('Успешно изпратена парола! Използвайте тази опция само в случаи, когато пациента не е получил sms поради някакви технически причини. Иначе системата ' +
      'винаги изпраща паролата за достъп автоматично при регистрация на пациента!');
  };

  render() {
    const {listItems, loading, jRequestForDelete} = this.state;
    const validations = serverValidations.jRequestListRequest;
    return (
      <div className="jrequests-list-page">

        <DocumentTitle title="Поръчки - Картотека"/>

        <CustomForm
          values={this.state.filter}
          onSubmit={this.submit}
          render={(_) => (
            <div className="card">
              <div className="card-header blue white-text">
                Поръчки - {listItems?.length} бр.
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-6 col-md-3">
                    <DateField
                      name="fromDate"
                      label="От дата"
                      validation={validations.fromDate}
                    />
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <DateField
                      name="toDate"
                      label="До дата"
                      validation={validations.toDate}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 font-italic" style={{marginTop: '2rem'}}>
                    * За генериране на нова поръчка използвайте бутона 'нова поръчка'
                    <a href="/doctor/sender-doctor-results"> РЕЗУЛТАТИ</a>
                  </div>
                </div>

                <div className="row" style={{marginTop: '1rem'}}>
                  <div className="col-sm-6 vertical-center">
                    <button className="btn btn-md btn-default" style={{width: '100%'}} type="submit">
                      Обнови
                    </button>
                  </div>

                  <div className="col-sm-6 vertical-center">
                    <NavLink to={`/doctor/jrequests/new`}
                             className="btn btn-md btn-success"
                             style={{width: '100%'}}>
                      + Нова поръчка
                    </NavLink>
                  </div>
                </div>

                {listItems && listItems.length > 0 &&
                <div className="row">
                  {listItems.map((item) => (

                    <div key={item.jRequestID} className="col-12">
                      <div className="card mt-1">
                        <div className="card-body">

                          <div className="row">
                            <div className="col-md-4">
                                <span style={{fontSize: '0.9rem'}}>
                                  Пациент: <b>{item.patientFullName}</b>
                                </span>
                            </div>
                            <div className="col-md-4">
                                   <span style={{fontSize: '0.9rem'}}>
                                  Сектор: <b>{item.cabinetName}</b>
                                   </span>
                            </div>
                            <div className="col-md-4">
                                <span style={{fontSize: '0.9rem'}}>
                                    Баркод: <b>{item.materialBarCodeConcat}</b>
                                </span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                                <span style={{fontSize: '0.9rem'}}>
                                    Насочен от: <b>{item.senderDoctorName}</b>
                                </span>
                            </div>
                            <div className="col-md-4">
                                <span style={{fontSize: '0.9rem'}}>
                                  Финансиране: <b>{item.jPartnerName}</b>
                                </span>
                            </div>
                            <div className="col-md-4">
                                <span style={{fontSize: '0.9rem'}}>
                                  Дата: <b>{format(item.jRequestDateTime, 'dd.MM.yyyy HH:mm')}</b>
                                </span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                                <span className="pt-3" style={{fontSize: '0.9rem'}}>
                                 Статус: <b>{item.concatStatusJReqLab}</b>
                                </span>
                            </div>
                            <div className="col-md-2 text-center">
                              <Link className="btn btn-md btn-info w-100 no-wrap text-center ml-0"
                                    to={`/doctor/jrequests/${item.jRequestID}/print`}>
                                <FontAwesomeIcon icon={faFile} size="lg"/>
                                &nbsp;
                                &nbsp;
                                <span>
                                    Принтирай
                                  </span>
                              </Link>
                            </div>
                            <div className="col-md-2 text-center">
                              <button className="btn btn-md btn-amber w-100 no-wrap text-center ml-0"
                                      type="button"
                                      onClick={() => this.sendPatientLoginInfo(item.patientID)}>
                                <FontAwesomeIcon icon={faArrowCircleUp} size="lg"/>
                                &nbsp;
                                &nbsp;
                                Изпрати парола
                              </button>
                            </div>
                            <div className="col-md-2 text-center">
                              <button className="btn btn-md btn-danger w-100 no-wrap text-center ml-0"
                                      type="button"
                                      onClick={() => this.setState({jRequestForDelete: item})}>
                                <FontAwesomeIcon icon={faTimes} size="lg"/>
                                &nbsp;
                                &nbsp;
                                Изтрий
                              </button>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  ))}
                </div>}

                {loading && <LoadingIndicator/>}

              </div>
            </div>
          )}/>

        {jRequestForDelete &&
        <DeleteJRequestModal
            model={jRequestForDelete}
            onClose={() => this.setState({jRequestForDelete: undefined})}
            onSuccessfulDelete={() => this.searchItems()}
        />}

      </div>
    );
  }
}
