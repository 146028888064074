import React from 'react';

import {BaseComponent} from '../../infrastructure/components/BaseComponent';
import {AppContextProps} from '../../infrastructure/react-context';
import {DocumentTitle} from '../../infrastructure/DocumentTitle';
import {DateField} from '../../infrastructure/fields/DateInput';
import {CustomForm} from '../../infrastructure/components/CustomForm';
import {MedicalAgentCatalogDto, DoctorAgentMeetingsCatalogRequest} from '../../dto';
import LoadingIndicator from '../../infrastructure/components/LoadingIndicator';
import {DoctorAgentMeetingNewEditModal} from './DoctorAgentMeetingNewEditModal';
import {JDateTime} from '../../public/JDateTime';
import {ConfirmModal} from '../../common/ConfirmModal';

interface Props extends AppContextProps {
}

interface State {
  loading: boolean;
  items: MedicalAgentCatalogDto[];
  filter: DoctorAgentMeetingsCatalogRequest;
  medicalAgentDisplayName: string;

  doctorAgentMeetingsNewEditModal: {
    open: boolean, agentMeetingID?: number;
  };

  deleteDoctorAgentMeeting: {
    open: boolean, agentMeetingID: number,
  };
}

const currentDate = new Date();

export class DoctorAgentMeetingsCatalog extends BaseComponent<Props, State> {

  state: State = {
    loading: false,
    items: [],
    filter: {
      fromDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
      toDate: currentDate,
    },
    medicalAgentDisplayName: '',

    doctorAgentMeetingsNewEditModal: {
      open: false, agentMeetingID: undefined,
    },

    deleteDoctorAgentMeeting: {
      open: false, agentMeetingID: 0,
    },
  };

  searchItems = async (filter: DoctorAgentMeetingsCatalogRequest) => {
    const {server, actions} = this.props.context;

    await this.setStateAsync({loading: true, items: []});

    const response = await server.doctorAgentMeetingsCatalog(filter);

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    await this.setStateAsync({
      loading: false,
      filter,
      items: response.payload.items,
      medicalAgentDisplayName: response.payload.medicalAgentDisplayName,
    });
  };

  componentDidMountAsync = async () => {
    await this.searchItems(this.state.filter);
  };

  deleteMedicalAgent = async (agentMeetingId: number) => {
    const {server, actions} = this.props.context;

    await this.setStateAsync({loading: true});

    const response = await server.deleteDoctorAgentMeeting({agentMeetingId});

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    await this.setStateAsync({
      loading: false,
      deleteDoctorAgentMeeting: {
        open: false,
        agentMeetingID: 0,
      },
    });

    await this.searchItems(this.state.filter);
  };

  render() {

    const {
      loading,
      items,
      medicalAgentDisplayName,

      doctorAgentMeetingsNewEditModal,
      deleteDoctorAgentMeeting,
    } = this.state;

    return (
      <div className="medical-agent-page">

        <DocumentTitle title="Медицински представители"/>

        {!loading &&
            <CustomForm values={this.state.filter} onSubmit={this.searchItems} render={(_) => (
              <div className="card">
                <div className={'card-header white d-flex flex-column'}>
                  <div className={'d-lg-flex'}>
                    <DateField
                      className={'w-100'}
                      name={'fromDate'}
                      label={'От дата'}/>

                    <DateField
                      className={'w-100'}
                      name={'toDate'}
                      label={'До дата'}/>
                  </div>

                  <div className={'d-lg-flex pr-2 pr-lg-0'}>
                    <button
                      type="button"
                      className="btn btn-md btn-success w-100 "
                      onClick={() => this.setState({
                        doctorAgentMeetingsNewEditModal: {
                          open: true,
                          agentMeetingID: undefined,
                        },
                      })}>
                      нова среща
                    </button>

                    <button
                      type="submit"
                      className="btn btn-md btn-default w-100">
                      Обнови
                    </button>
                  </div>

                  <div className={'p-2'}>
                    <div><b>Представител:</b> {medicalAgentDisplayName}</div>
                    <div><b>Брой срещи: </b> {items.length}</div>
                  </div>

                </div>
                <div className="d-flex flex-column gap-2">
                  {items &&
                    items.map((x) => (
                      <div key={x.agentMeetingID} className="card mb-2">
                        <div className="card-header bg-primary text-white p-2 d-flex justify-content-between align-items-center">
                          <div>Час: {JDateTime.toJoystickDateTimeFormat(x.meetingDatetime)}ч</div>
                          <div className="d-flex gap-2">
                            <button
                              className="btn btn-warning btn-sm"
                              type="button"
                              onClick={() => this.setState({
                                doctorAgentMeetingsNewEditModal: {
                                  open: true,
                                  agentMeetingID: x.agentMeetingID,
                                },
                              })}
                            >
                              Редакция
                            </button>

                            <button
                              className="btn btn-danger btn-sm"
                              type="button"
                              onClick={() => this.setState({
                                deleteDoctorAgentMeeting: {
                                  open: true,
                                  agentMeetingID: x.agentMeetingID,
                                },
                              })}
                            >
                              Изтрии
                            </button>
                          </div>
                        </div>

                        <div className="card-body">
                          <div><strong>Лекар:</strong> {x.doctorfullname}</div>
                          <div><strong>Статус:</strong> {x.status}</div>
                          <div><strong>Място на срещата:</strong> {x.meetingPlace}</div>
                          <div><strong>Съдържание:</strong> {x.meetingSubject}</div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            )}/>}

        {loading && <LoadingIndicator delay={0}/>}

        {!loading && doctorAgentMeetingsNewEditModal.open &&
            <DoctorAgentMeetingNewEditModal
                agentMeetingID={doctorAgentMeetingsNewEditModal.agentMeetingID}
                context={this.props.context}
                onClose={async (success) => {
                  this.setState({
                    doctorAgentMeetingsNewEditModal: {
                      open: false,
                      agentMeetingID: undefined,
                    },
                  });
                  if (success) {
                    await this.searchItems(this.state.filter);
                  }
                }}
            />}

        {!loading && deleteDoctorAgentMeeting.open &&
            <ConfirmModal
                title={'Изтриване на срещата'}
                message="Моля, потвърдете изтриването на срещата."
                onConfirm={() => this.deleteMedicalAgent(deleteDoctorAgentMeeting.agentMeetingID)}
                onClose={() => this.setState({
                  deleteDoctorAgentMeeting: {
                    open: false,
                    agentMeetingID: 0,
                  },
                })}
            />}
      </div>
    );
  }
}
