import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { AppContextProps } from '../infrastructure/react-context';
import { BaseComponent } from '../infrastructure/components/BaseComponent';
import { CustomForm } from '../infrastructure/components/CustomForm';
import { DebugValue } from '../infrastructure/components/DebugValue';
import { required } from '../infrastructure/fields/validations';
import { FileSelectField } from '../infrastructure/fields/FileSelectInput';

import './demo.scss';

interface Props extends RouteComponentProps, AppContextProps {
}

interface State {
}

export class FileSelectFieldDemo extends BaseComponent<Props, State> {

  state: State = {};

  submit = async (values: any) => {

    console.info(values);
  };

  render() {

    return (
      <CustomForm onSubmit={this.submit} render={(form) => (
        <div className="card demo-form">
          <div className="card-body">

            <div>

              <FileSelectField
                name="default"
                label="Default"
              />

              <FileSelectField
                name="defaultIcon"
                label="Default with icon"
                icon="file"
              />

              <FileSelectField
                name="readonly"
                label="Readonly"
                readonly
              />


              <FileSelectField
                name="nullable"
                label="Nullable"
                nullable
              />

              <FileSelectField
                name="validate"
                label="With validation"
                validation={[required('Date is required.')]}
              />

              <FileSelectField
                name="validateWithIcon"
                label="With validation"
                icon="file"
                validation={[required('Date is required.')]}
              />

              <FileSelectField
                name="validateWithIconNullable"
                label="With validation"
                icon="file"
                nullable
                validation={[required('Date is required.')]}
              />

            </div>

            <div className="text-center py-3 mt-3">
              <button className="btn btn-warning btn-block">Submit</button>
            </div>

            <DebugValue value={form}/>

          </div>
        </div>
      )}/>
    );
  }
}
