import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { format, newDateWithoutTime } from '../../infrastructure/util';

import { AppContextProps } from '../../infrastructure/react-context';
import { BaseComponent } from '../../infrastructure/components/BaseComponent';
import { CustomForm } from '../../infrastructure/components/CustomForm';
import {
  AttachedResultsListRequest,
  serverValidations, PatientRecordDto,
} from '../../dto';
import { DateField } from '../../infrastructure/fields/DateInput';
import LoadingIndicator from '../../infrastructure/components/LoadingIndicator';
import { NumberField } from '../../infrastructure/fields/NumberInput';
import { TextField } from '../../infrastructure/fields/TextInput';
import { DocumentTitle } from '../../infrastructure/DocumentTitle';

import './attached-results.scss';

interface Props extends AppContextProps {
}

interface State {
  filter: AttachedResultsListRequest | undefined;
  listItems: PatientRecordDto[] | undefined;
  loading: boolean;
  firstQuery: boolean;
}

const defaultFilterState: AttachedResultsListRequest = {
  fromDate: newDateWithoutTime(),
  toDate: newDateWithoutTime(),
  docID: undefined,
  patientFirstNameBeginsWith: undefined,
  patientIdentNumberBeginsWith: undefined,
  patientLastNameBeginsWith: undefined,
  patientSecondNameBeginsWith: undefined,
};

export class AttachedResultList extends BaseComponent<Props, State> {

  state: State = {
    loading: false,
    filter: undefined,
    listItems: undefined,
    firstQuery: false,
  };

  async searchItems() {

    const {server, actions} = this.props.context;

    await this.setStateAsync({
      loading: true,
      listItems: [],
    });

    if (!this.state.filter) {
      throw new Error('The filter is falsy.');
    }

    const response = await server.attachedResultsList(this.state.filter);

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    await this.setStateAsync({
      loading: false,
      listItems: response.payload.items,
    });
  }

  async getCachedFilter() {
    const {server, actions} = this.props.context;

    await this.setStateAsync({loading: true});

    const response = await server.getSessionInfo({});

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    const filter = response.payload.state.attachedResultsListRequest || defaultFilterState;

    await this.setStateAsync({loading: false, filter});
  }

  async saveFilterCache() {
    const {server, actions} = this.props.context;

    await this.setStateAsync({loading: true});

    const response = await server.saveSessionInfo({state: {attachedResultsListRequest: this.state.filter}});

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    await this.setStateAsync({loading: false});
  }

  componentDidMountAsync = async () => {
    await this.getCachedFilter();
    await this.searchItems();
  };

  submit = async (filter: AttachedResultsListRequest) => {
    await this.setStateAsync({filter});
    await this.searchItems();
    await this.saveFilterCache();
    await this.setStateAsync({
      firstQuery: true,
    });
  };

  render() {
    const {listItems, loading, firstQuery} = this.state;
    const validations = serverValidations.attachedResultsListRequest;
    return (
      <div className="attached-results-list-page">

        <DocumentTitle title="Прикачени резултати - Картотека"/>

        {this.state.filter && <CustomForm values={this.state.filter} onSubmit={this.submit} render={(_) => (
          <div className="card">
            <div className="card-header blue white-text">Прикачени резултати - Картотека</div>
            <div className="card-body">
              <div className="row">
                <div className="col-sm-6 col-md-3">
                  <DateField
                    name="fromDate"
                    label="От дата:"
                  />
                </div>
                <div className="col-sm-6 col-md-3">
                  <DateField
                    name="toDate"
                    label="До дата:"
                  />
                </div>
                <div className="col-sm-6 col-md-3">
                  <NumberField
                    name="patientIdentNumberBeginsWith"
                    label="ЕГН:"
                    nullable
                    validation={validations.patientIdentNumberBeginsWith}
                  />
                </div>
                <div className="col-sm-6 col-md-3">
                  <NumberField
                    name="docID"
                    label="DocID:"
                    nullable
                  />
                </div>
                <div className="col-sm-6 col-md-3">
                  <TextField
                    name="patientFirstNameBeginsWith"
                    label="Име:"
                  />
                </div>
                <div className="col-sm-6 col-md-3">
                  <TextField
                    name="patientSecondNameBeginsWith"
                    label="Презиме:"
                  />
                </div>
                <div className="col-sm-6 col-md-3">
                  <TextField
                    name="patientLastNameBeginsWith"
                    label="Фамилия:"
                  />
                </div>
              </div>

              <div className="row" style={{marginTop: '1rem'}}>
                <div className="col-sm-6 vertical-center">
                  <button className="btn btn-md btn-default" style={{width: '100%'}} type="submit">
                    Обнови
                  </button>
                </div>

                <div className="col-sm-6 vertical-center">
                  <NavLink to={`/doctor/attached-results/new`}
                           className="btn btn-md btn-success"
                           style={{width: '100%'}}>
                    Ново изследване
                  </NavLink>
                </div>
              </div>

              {(firstQuery || (listItems && !!listItems.length)) &&
              <div className="table-responsive" style={{marginTop: '1rem'}}>
                <table className="table list-table">
                  <thead className="blue text-white">
                    <tr>
                      <th>№</th>
                      <th>Дата</th>
                      <th>Пациент</th>
                      <th>Описание</th>
                      <th/>
                      <th/>
                    </tr>
                  </thead>

                  {listItems && listItems.length > 0 && <tbody>
                    {listItems.map((item) => (
                      <tr key={item.patientRecID}>
                        <td>{item.docID || '-'}</td>
                        <td className="no-wrap">
                          {item.datePatientRecDateTime ? format(item.datePatientRecDateTime, 'dd.MM.yyyy') : '-'}
                        </td>
                        <td>{item.patientFullName} ({item.patientIdentNumber})</td>
                        <td>{item.patientRecShortName}</td>
                        <td>
                          <Link
                            to={`/doctor/attached-results/${item.patientRecID}/print`}
                            className="btn btn-sm btn-info">
                            Преглед/Печат
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={`/doctor/attached-results/${item.patientRecID}/edit`}
                            className="btn btn-sm btn-warning">
                            Редакция
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>}
                </table>
              </div>
              }
              {firstQuery && !loading && listItems && !listItems.length &&
              <div style={{margin: '1rem'}}>
                <h3 style={{color: 'black', textAlign: 'center'}}>Не са намерени резултати за това търсене.</h3>
              </div>}

              {loading && <LoadingIndicator/>}

            </div>
          </div>
        )}/>}
      </div>
    );
  }
}
