import React, { CSSProperties, PureComponent } from 'react';
import { OptionalChildrenProps } from '../react-helpers';
import { texts } from '../texts';

import './LoadingIndicator.scss';

interface Props extends OptionalChildrenProps {

  /***
   * How many milliseconds to wait before rendering animation.
   */
  delay?: number;

  style?: CSSProperties;

  className?: string;
}

interface State {
  renderAnimation: boolean;
}

/***
 * Shows an animation that indicates that something is being loaded.
 * Does not render animation immediately, waits for specified number of milliseconds.
 */
export default class LoadingIndicator extends PureComponent<Props, State> {

  state: State = {
    renderAnimation: false,
  };

  _unmounted = false;

  componentDidMount() {

    let delay;

    if (this.props.delay === 0) {
      delay = 0;
    } else if (!this.props.delay) {
      delay = 500;
    } else {
      delay = this.props.delay;
    }

    if (delay > 0) {
      setTimeout(() => {
        if (!this._unmounted) {
          this.setState({renderAnimation: true});
        }
      }, this.props.delay);
    } else {
      if (!this._unmounted) {
        this.setState({renderAnimation: true});
      }
    }
  }

  componentWillUnmount() {
    this._unmounted = true;
  }

  render() {

    const {renderAnimation} = this.state;
    const {style, children} = this.props;

    if (!renderAnimation) {
      return null;
    }

    return (
      <div className={`loader-wrapper ${this.props.className || ''}`} style={style}>
        <div className="sk-circle">
          <div className="sk-circle1 sk-child"/>
          <div className="sk-circle2 sk-child"/>
          <div className="sk-circle3 sk-child"/>
          <div className="sk-circle4 sk-child"/>
          <div className="sk-circle5 sk-child"/>
          <div className="sk-circle6 sk-child"/>
          <div className="sk-circle7 sk-child"/>
          <div className="sk-circle8 sk-child"/>
          <div className="sk-circle9 sk-child"/>
          <div className="sk-circle10 sk-child"/>
          <div className="sk-circle11 sk-child"/>
          <div className="sk-circle12 sk-child"/>
        </div>

        <div className="loading-text">{children || texts.LOADING_INDICATOR_DEFAULT_MESSAGE}</div>
      </div>
    );
  }

  static defaultProps = {
    delay: 500,
  };
}
