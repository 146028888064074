import { UserRole, UserPrivilege, Feature, SessionState, getUserRole, getUserPrivilege } from './session.state';
import { CacheState } from './cache.state';

export interface RenderPermissionOptions {
  requireLogin?: boolean;
  publicOnly?: boolean;
  role?: UserRole;
  userPrivilege?: UserPrivilege;
  feature?: Feature;
}

const hasPrivilege = (requiredPrivilege: UserPrivilege, sessionPrivileges: number[] | undefined) => {

  if (!sessionPrivileges) {
    return false;
  }

  return !!sessionPrivileges.map((x) => getUserPrivilege(x))
    .filter((x) => x && x === requiredPrivilege).length;
};

export const shouldRender = (options: RenderPermissionOptions, session: SessionState, cache: CacheState): boolean => {

  if (options.requireLogin === undefined) {
    options.requireLogin = true;
  }

  if (options.publicOnly === undefined) {
    options.publicOnly = false;
  }

  if (options.publicOnly) {
    options.requireLogin = false;
  }

  if (options.publicOnly && session.isLoggedIn) {
    return false;
  }

  if (options.requireLogin && !session.isLoggedIn) {
    return false;
  }

  if (options.feature && cache.settings && cache.settings.features && !cache.settings.features.includes(options.feature)) {
    return false;
  }

  if (options.role && session.isLoggedIn && getUserRole(session.userRole) !== options.role) {
    return false;
  }

  // noinspection RedundantIfStatementJS
  if (session.isLoggedIn && options.userPrivilege && !hasPrivilege(options.userPrivilege, session.userPrivileges)) {
    return false;
  }

  return true;
};
