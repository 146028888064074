import 'whatwg-fetch';
import { Result } from './api-result';
import { appVersion } from './version';
import { logError, ExtendedError } from './errors';
import { AppContextFactory } from './context';
import { JsonHelper } from './JsonHelper';

const networkIsDownMessage = 'Мрежови проблем, моля опитайте по-късно.';
const serverErrorMessage = 'Възникна грешка при четенето на резултат от сървъра.';

export interface JCoreClient {
  send: <TRequest, TResponse = undefined>(messageType: string, body?: TRequest) => Promise<Result<TResponse>>;
}

export interface GatewayMessage {
  messageType: string;
  messageJson: string;
}

interface GatewayResult {
  jSonValue: string;
  details: any[];
}

export const jCoreClient = (authToken: string, getContext: AppContextFactory): JCoreClient => ({
  send: async <TRequest, TResponse>(messageType: string, body: TRequest): Promise<Result<TResponse>> => {

    const message: GatewayMessage = {
      messageType,
      messageJson: JsonHelper.stringify(body),
    };

    const request = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-json-camelcase': '1',
        ...(authToken ? {Authorization: `JWT ${authToken}`} : {}),
      },
      body: JsonHelper.stringify(message),
    };

    try {

      const response = await window.fetch('/api/gateway/private', request);

      if (response.status !== 200) {
        return ({errorMessages: [serverErrorMessage], success: false});
      }

      const sessionRejected = response.headers.get('x-session-rejected') === '1';

      if (sessionRejected) {
        // noinspection ExceptionCaughtLocallyJS
        throw new ExtendedError(
          `Special command: FORCE_LOGOUT`,
          undefined,
          {},
          'FORCE_LOGOUT',
        );
      }

      if (messageType !== 'VersionedResourcesRequest') {

        const versionString = response.headers.get('x-resource-version');
        const version = Number.parseInt(versionString || 'NON_PARSABLE', 10);

        if (!isNaN(version)) {

          const {actions, store} = getContext();
          const {cache} = store.getState();

          if (cache.globalVersion < version) {
            actions.cache.updateCache();
          }
        }
      }

      const serverVersionString = response.headers.get('x-server-version');

      if (serverVersionString && serverVersionString !== appVersion) {
        const errorMessages = [`App version mismatch. Current version: ${appVersion}. Server version: ${serverVersionString}. Please reload the app to get the latest version.`];
        const {actions} = getContext();
        actions.errors.setErrorMessages(errorMessages);
        return {success: false, errorMessages};
      }

      try {

        const json = await response.text();

        const gatewayResult = JsonHelper.parse(json) as GatewayResult;

        if (!gatewayResult.details.length) {

          const payload = JsonHelper.parse(gatewayResult.jSonValue);

          return ({success: true, payload});
        } else {
          return ({success: false, errorMessages: gatewayResult.details.map((x) => x.message)});
        }
      } catch (error) {
        await logError(error, {
          extraMessage: 'Server error. The server didn\'t return any valid json.',
        });
        return ({errorMessages: [serverErrorMessage], success: false});
      }

    } catch (error) {

      if (error instanceof ExtendedError && error.specialCommand) {
        throw error;
      }

      // Network error. The request failed.
      await logError(error, {extraMessage: 'Network error.'});
      return ({errorMessages: [networkIsDownMessage], success: false});
    }
  },
});
