import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { AppContextProps } from '../infrastructure/react-context';
import { BaseComponent } from '../infrastructure/components/BaseComponent';
import { CustomForm } from '../infrastructure/components/CustomForm';

import './demo.scss';

import { DebugValue } from '../infrastructure/components/DebugValue';
import { minLength } from '../infrastructure/fields/validations';
import { MultiSelectField } from '../infrastructure/fields/MultiSelectInput';

interface Props extends RouteComponentProps, AppContextProps {
}

interface State {
}

export class MultiSelectFieldDemo extends BaseComponent<Props, State> {

  state: State = {};

  submit = async (values: any) => {

    console.info(values);
  };

  render() {

    const items = Array.from({length: 10})
      .map((_, i) => ({label: `Item${i}`, value: i}));

    return (
      <CustomForm onSubmit={this.submit} render={(form) => (
        <div className="card demo-form">
          <div className="card-body">

            <div>

              <MultiSelectField name="defaultWithoutIcon" label="Default" items={items}/>

              <MultiSelectField name="default" label="Default" items={items} icon="user"/>

              <MultiSelectField
                name="defaultValue"
                label="Default with value"
                items={items} icon="user"
                defaultValue={[1, 2]}
              />

              <MultiSelectField
                name="defaultValueNullableEmpty"
                label="Nullable"
                items={items}
                icon="user"
              />

              <MultiSelectField
                name="defaultValueNullable"
                label="Nullable with value"
                items={items}
                icon="user"
                defaultValue={[1, 2]}
              />

              <MultiSelectField
                name="readonly"
                label="Readonly"
                items={items}
                icon="user"
                readonly
              />

              <MultiSelectField
                name="readonlyWithValue"
                label="Readonly with value"
                items={items}
                icon="user"
                readonly
                defaultValue={[1, 2]}
              />

              <MultiSelectField
                name="defaultValidation"
                label="With validation"
                items={items}
                icon="user"
                validation={[minLength(1, 'Cats are required!')]}
                defaultValue={[1, 2]}
              />

              <MultiSelectField
                name="defaultValidationWithoutIcon"
                label="With validation"
                items={items}
                validation={[minLength(1, 'Cats are required!')]}
                defaultValue={[1, 2]}
              />

            </div>

            <div className="text-center py-3 mt-3">
              <button className="btn btn-warning btn-block">Submit</button>
            </div>

            <DebugValue value={form}/>

          </div>
        </div>
      )}/>
    );
  }
}
