import React, { ReactNode } from 'react';
import { BaseComponent } from './BaseComponent';
import { Form, FormikProps, FormikHelpers } from 'formik';
import { isPromise } from '../util';
import { FormikWithRef } from '../FormikWithRef';

interface Props<T = any> {
  values?: any;
  onChanges?: (values: any) => void | Promise<void>;
  // Какво действие да се изпълни след събмитване на формата
  onSubmit?: (values: any) => void | Promise<void>;
  render: ((props: FormikProps<T>) => ReactNode);
  forwardedRef?: any;
  enableReinitialize?: boolean;
}

interface State {
}

export class CustomForm extends BaseComponent<Props, State> {

  state: State = {};

  componentDidMountAsync = async () => {
  };

  submit = async (data: any, form: FormikHelpers<any>): Promise<void> => {

    const func = this.props.onSubmit;

    if (func) {
      const result = func(data);
      if (isPromise(result)) {
        await result;
      }

      form.setSubmitting(false);
    }
  };

  validate = async (data: any): Promise<void> => {

    const func = this.props.onChanges;

    if (func) {
      const result = func(data);
      if (isPromise(result)) {
        await result;
      }
    }
  };

  render() {

    return (
      <FormikWithRef
        forwardedRef={this.props.forwardedRef}
        initialValues={this.props.values || {}}
        enableReinitialize={this.props.enableReinitialize}
        onSubmit={this.submit}
        validate={this.validate}>
        {(props) => (<Form>{this.props.render(props)}</Form>)}
      </FormikWithRef>
    );
  }
}
