import React from 'react';
import { BaseComponent } from '../infrastructure/components/BaseComponent';
import { AppContextProps } from '../infrastructure/react-context';
import LoadingIndicator from '../infrastructure/components/LoadingIndicator';
import { DocumentTitle } from '../infrastructure/DocumentTitle';

interface Props extends AppContextProps {
}

interface State {
  loading: boolean;
  qrCode: string | undefined;
  deepLink: string | undefined;
}

export class ViberSubscription extends BaseComponent<Props, State> {
  state: State = {
    loading: true,
    qrCode: undefined,
    deepLink: undefined,
  };

  componentDidMountAsync = async () => {
    const {server, actions} = this.props.context;
    const response = await server.getViberQr({});
    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      this.setState({loading: false});
      return;
    }
    this.setState({
      loading: false,
      qrCode: response.payload.qrCode,
      deepLink: response.payload.deepLink,
    });
  };

  render() {

    const {qrCode, deepLink, loading} = this.state;

    return (
      <div className="viber-subscription-page">

        <DocumentTitle title="Viber Нотификации"/>

        <div className="card" style={{marginTop: '1rem'}}>
          <div className="card-body" style={{padding: '1rem'}}>
            {loading && <LoadingIndicator/>}

            <div className="center-block text-center">
              <div style={{marginBottom: '1rem', fontWeight: 'lighter', fontSize: '1rem', textAlign: 'left'}}>
              Информация директно на Вашия телефон за:<br/>
                *Готови резултати, *Записани часове<br/>
                *Новини и промоции.
              </div>
              <a href={deepLink} target="_blank" className="btn btn-primary">Абонирай се за VIBER</a>
            </div>
            {qrCode && deepLink && <div className="center-block text-center">
                <a href={deepLink} target="_blank">
                    <img alt="qrCode" style={{maxWidth: '100%'}} src={`data:image/png;base64,${qrCode}`}/>
                </a>
            </div>}

          </div>
        </div>
      </div>
    );
  }
}
