import { RenderPermissionOptions, shouldRender } from './render-permissions';
import { SessionState} from './session.state';
import { CacheState } from './cache.state';

export interface SessionService {
  isLoggedIn: () => boolean;
  identify: (options: RenderPermissionOptions) => boolean;
}

export const createSessionService = (session: SessionState, cache: CacheState): SessionService => {

  return {
    isLoggedIn: () => session.isLoggedIn,
    identify: (options) => shouldRender(options, session, cache),
  };
};

declare module './context' {

  interface AppContext {
    sessionService: SessionService;
  }
}
