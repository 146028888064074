import React from 'react';
import './DoctorSchedule.scss';
import {
  DoctorScheduleOperation,
  WAppointmentDto,
  DoctorAppoMakeRequest,
  AppointmentDoctorsResponse, AppointmentCabinetsResponse, DoctorScheduleRequest,
} from '../../dto';
import {AppContextProps} from '../../infrastructure/react-context';
import {BaseComponent} from '../../infrastructure/components/BaseComponent';
import {newDateWithoutTime, format} from '../../infrastructure/util';
import {DateInput} from '../../infrastructure/fields/DateInput';
import LoadingIndicator from '../../infrastructure/components/LoadingIndicator';
import DoctorScheduleModal from './DoctorScheduleModal';
import {CustomForm} from '../../infrastructure/components/CustomForm';
import {SelectItem} from '../../infrastructure/fields/select-item';
import {SelectField} from '../../infrastructure/fields/SelectInput';
import {FieldHandle, BaseInputProps} from '../../infrastructure/fields/field-helpers';
import {CheckboxField} from '../../infrastructure/fields/CheckboxInput';
import {ErrorMessages} from '../../infrastructure/errors';

interface Props extends AppContextProps {
}

interface State {
  filter: DoctorScheduleRequest;
  operations: DoctorScheduleOperation[];
  slots: WAppointmentDto[];
  loading: boolean;
  selectedAppointment: WAppointmentDto | undefined;
  showMakeAppointmentModal: boolean;
  modalErrorMessages: string[];
  modalLoadingIndicator: boolean;
  modalDropdownSlots: WAppointmentDto[];
  doctorResponse: AppointmentDoctorsResponse | undefined;
  cabinetsResponse: AppointmentCabinetsResponse | undefined;
  doctorLoading: boolean;
  cabinetsLoading: boolean;
  errorMessages: string[];
}

export class DoctorSchedule extends BaseComponent<Props, State> {

  doctorIDRef: FieldHandle<BaseInputProps<any>> | undefined;

  defaultState = {
    filter: {
      fromDate: newDateWithoutTime(),
      toDate: newDateWithoutTime(),
      doctorID: undefined,
      cabinetID: undefined,
      showAll: false,
    },
    loading: false,
    operations: [],
    slots: [],
    selectedAppointment: undefined,
    showMakeAppointmentModal: false,
    modalErrorMessages: [],
    modalLoadingIndicator: false,
    modalDropdownSlots: [],
    doctorResponse: undefined,
    cabinetsResponse: undefined,
    doctorLoading: false,
    cabinetsLoading: false,
    errorMessages: [],
  };

  state: State = {...this.defaultState};

  componentDidMountAsync = async () => {
    await this.refreshState();
  };

  async fetchDoctors(cabinetID: number): Promise<any> {
    const {server, actions} = this.props.context;

    await this.setStateAsync({doctorLoading: true});

    const result = await server.appointmentDoctors({cabinetID});

    if (result.success) {
      await this.setStateAsync({doctorResponse: result.payload, doctorLoading: false});
    } else {
      actions.errors.setErrorMessages(result.errorMessages);
      await this.setStateAsync({doctorLoading: false});
    }
  }

  async fetchCabinets(): Promise<any> {
    const {server, actions} = this.props.context;

    await this.setStateAsync({cabinetsLoading: true});

    const result = await server.appointmentCabinets({});

    if (result.success) {
      await this.setStateAsync({cabinetsResponse: result.payload, cabinetsLoading: false});
    } else {
      actions.errors.setErrorMessages(result.errorMessages);
      await this.setStateAsync({cabinetsLoading: false});
    }
  }

  refreshState = async () => {
    await this.setStateAsync({loading: true, errorMessages: []});
    const {fromDate, toDate, doctorID, cabinetID, showAll} = this.state.filter;

    const {server} = this.props.context;
    const response = await server.doctorSchedule({fromDate, toDate, doctorID, cabinetID, showAll});
    if (!response.success) {
      await this.setStateAsync({loading: false, errorMessages: response.errorMessages});
      return;
    }

    const slots = response.payload.appointments;
    const operations = response.payload.operations;

    await this.setStateAsync({slots, operations, loading: false});
    await this.setModalDropDownSlots(undefined);
  };

  setModalDropDownSlots = async (selectedAppointment: WAppointmentDto | undefined) => {
    await this.setStateAsync({loading: true});

    let modalDropdownSlots = this.state.slots
      .filter((val) => val.isfree && val.wcachefromtime >= new Date());

    if (selectedAppointment && !modalDropdownSlots.includes((selectedAppointment))) {
      modalDropdownSlots = [selectedAppointment, ...modalDropdownSlots];
    }

    await this.setStateAsync({modalDropdownSlots, loading: false});
  };

  showModalAsync = async (selectedAppointment: WAppointmentDto) => {
    await this.setModalDropDownSlots(selectedAppointment);
    await this.setStateAsync({selectedAppointment, showMakeAppointmentModal: true});
  };

  makeAppointmentAsync = async (model: WAppointmentDto) => {
    const item: DoctorAppoMakeRequest = {
      appoID: model.wappointmentpatientid,
      patientName: model.wpatientname,
      patientMobile: model.wpatientmobile,
      doctorID: model.doctorid,
      cabinetID: model.workkabinettypeid,
      dateTime: model.wcachefromtime,
      note: model.wappointmentnote,
      patientIdentNumber: '',
      patientID: 0,
      isReserved: false,
    };

    await this.setStateAsync({modalLoadingIndicator: true});

    const {server} = this.props.context;
    const response = await server.doctorAppoMake(item);
    if (!response.success) {
      await this.setStateAsync({modalLoadingIndicator: false, modalErrorMessages: response.errorMessages});
      return;
    }

    await this.setStateAsync({modalLoadingIndicator: false});

    await this.refreshState();
  };

  deleteAppointmentAsync = async (appoID: number) => {
    await this.setStateAsync({modalLoadingIndicator: true});

    const {server} = this.props.context;
    const response = await server.appoDelete({appoID});
    if (!response.success) {
      await this.setStateAsync({modalLoadingIndicator: false, modalErrorMessages: response.errorMessages});
      return;
    }

    await this.setStateAsync({modalLoadingIndicator: false});

    await this.refreshState();
  };

  getCabinets(): SelectItem[] {

    if (!this.state.cabinetsResponse) {
      return [];
    }

    return this.state.cabinetsResponse.items.map((x) => ({
      label: x.cabinetFullName,
      value: x.cabinetID,
    }));
  }

  getDoctors(): SelectItem[] {

    if (!this.state.doctorResponse) {
      return [];
    }

    return this.state.doctorResponse.items.map((x) => ({
      label: x.doctorName,
      value: x.doctorID,
    }));
  }

  render() {

    const cabinets = this.getCabinets();
    const doctors = this.getDoctors();

    const {
      loading, selectedAppointment, slots, showMakeAppointmentModal, filter, operations,
      modalErrorMessages, modalLoadingIndicator, modalDropdownSlots, errorMessages,
    } = this.state;

    const freeSlotsCount = slots.filter((x) => x.isfree).length;
    const scheduledSlotsCount = slots.length - freeSlotsCount;

    return (
      <div className="doctor-schedule-page">

        <div className="card-header blue white-text">
          Планирани: {scheduledSlotsCount} Свободни: {freeSlotsCount}
        </div>

        <div className="card" style={{marginTop: '0rem'}}>

          <CustomForm onSubmit={this.refreshState} render={({values, setFieldValue, setFieldTouched}) => (
            <div className="row">
              <div className="col-md-3">
                <DateInput
                  name="fromDate"
                  label="От дата"
                  value={filter.fromDate}
                  onChange={async (value) => {
                    await this.setStateAsync({filter: {...this.state.filter, fromDate: value || newDateWithoutTime()}});
                  }}
                />
              </div>

              <div className="col-md-3">
                <DateInput
                  name="toDate"
                  label="До дата"
                  value={filter.toDate}
                  onChange={async (value) => {
                    await this.setStateAsync({filter: {...this.state.filter, toDate: value || newDateWithoutTime()}});
                  }}
                />
              </div>

              <div className="col-md-6">
                <CheckboxField
                  label={'Покажи всички лекари'}
                  name={'showAll'}
                  defaultValue={filter.showAll}
                  onChange={async (value) => {
                    if (value) {
                      await this.fetchCabinets();
                    }

                    await this.setStateAsync({
                      slots: [],
                      operations: [],
                      errorMessages: [],
                      filter: {...this.state.filter, showAll: value},
                    });
                  }}
                />
              </div>

              {filter.showAll &&
                  <>
                      <div className="col-md-3">
                          <SelectField
                              label="Кабинет"
                              name="cabinetID"
                              items={cabinets}
                              searchable={true}
                              isLoading={this.state.cabinetsLoading}
                              onChange={async (cabinetID: number | undefined) => {
                                if (!cabinetID) {
                                  return;
                                }

                                await this.setStateAsync({doctorResponse: undefined});

                                setFieldTouched('doctorID', false);
                                setFieldValue('doctorID', undefined);

                                await this.setStateAsync({
                                  slots: [],
                                  operations: [],
                                  filter: {...this.state.filter, cabinetID},
                                });
                                await this.fetchDoctors(cabinetID);
                              }}
                          />
                      </div>
                      <div className="col-md-3">
                          <SelectField
                              label={'Лекар'}
                              name="doctorID"
                              items={doctors}
                              isLoading={this.state.doctorLoading}
                              defaultValueOnSelectedItemRemoval
                              readonly={!values.cabinetID}
                              searchable={true}
                              customRef={(x) => this.doctorIDRef = x}
                              onChange={async (doctorID: number | undefined) => {
                                if (!doctorID) {
                                  return;
                                }

                                await this.setStateAsync({
                                  slots: [],
                                  operations: [],
                                  filter: {...this.state.filter, doctorID},
                                });
                              }}
                          />
                      </div>
                  </>
              }

              <div className={`col-md-6 ${filter.showAll && 'pt-2'}`}>
                <div className="d-flex">
                  <button
                    type="submit"
                    className="btn btn-default w-100">
                    ОПРЕСНИ
                  </button>
                </div>
              </div>

              {errorMessages.length > 0 &&
                  <div className="col-12">
                      <ErrorMessages errors={errorMessages}/>
                  </div>
              }

            </div>
          )}/>

          {loading && <LoadingIndicator/>}

          {operations.length > 0 &&
            operations.map((item) => (
              <div key={item.grStartDateTimeStr} className="card text-white bg-secondary m-2 mt-0">
                <div className="card-header">
                  < i className={`fa fa-heartbeat fa-1x`}/> {format(item.grStartDateTime,
                  'HH:mm')} {item.grOperationPatientName}</div>
                <div className="card-body">
                  <h5 className="card-title">{item.grOperationTypeName}</h5>
                  <p className="text-white card-text">({item.grCabinetName}) <br/>Заб. {item.grDescription}</p>
                </div>
              </div>
            ))}

          {slots.length > 0 &&
            slots.map((item) =>
              <div key={item.timeAndWorkcabientFormated}>
                <div className={`card m-2 mt-0
                                          ${item.isfree && 'free-card'}
                                          ${!item.isfree && !item.isreserved && 'scheduled-card'}
                                          ${item.isreserved && 'reserved-card'}`}>

                  <div className="card-header">
                    {item.timeAndWorkcabientFormated} {item.paymentTypeName}
                    {item.wcachefromtime > new Date() &&
                        <button
                            className={`btn btn-sm ${item.isfree && 'btn-info' || 'btn-warning'}`}
                            onClick={async () => await this.showModalAsync(item)}>
                          {item.buttonCaption}
                        </button>}
                  </div>

                  {!item.isfree &&
                      <div className="card-body">
                          <div>{item.wpatientname}</div>
                          <p>{item.wpatientmobile && `(тел. ${item.wpatientmobile})`}</p>
                          <div>{item.appotypename !== '-' ? `Тип: ${item.appotypename}` : ''}</div>
                        {item.wappointmentnote !== null ? `Забележки: ${item.wappointmentnote}` : ''}
                      </div>}

                </div>
              </div>)}

          {selectedAppointment && showMakeAppointmentModal && modalDropdownSlots.length > 0 &&
              <DoctorScheduleModal
                  loading={modalLoadingIndicator}
                  errorMessages={modalErrorMessages}
                  selectedSlot={selectedAppointment}
                  dropdownSlots={modalDropdownSlots}
                  onClose={async () => {
                    await this.setStateAsync({showMakeAppointmentModal: false});
                    await this.refreshState();
                  }}
                  onSubmit={async (model) => {
                    await this.setStateAsync({showMakeAppointmentModal: false});
                    await this.makeAppointmentAsync(model);
                  }}
                  onDelete={async (appoID) => {
                    await this.deleteAppointmentAsync(appoID);
                    await this.setStateAsync({showMakeAppointmentModal: false});
                  }}
              />}
        </div>
      </div>
    );
  }
}
