import React from 'react';

import {AppContextProps} from '../infrastructure/react-context';
import {BaseComponent} from '../infrastructure/components/BaseComponent';
import {PdfDocument} from '../infrastructure/components/PdfDocument';
import {DocumentTitle} from '../infrastructure/DocumentTitle';
import {ErrorMessages} from '../infrastructure/errors';
import {RouteComponentProps} from 'react-router-dom';
import {base64ToArray, delay, downloadFile} from '../infrastructure/util';
import {Result} from '../infrastructure/api-result';
import LoadingIndicator from '../infrastructure/components/LoadingIndicator';
import {texts} from '../infrastructure/texts';
import {RsaSignDocumentModal} from './RsaSignDocumentModal';

interface RouterParams {
  signDocID: string;
}

interface Props extends RouteComponentProps<RouterParams>, AppContextProps {
}

interface State {
  errorMessages: string[];
  loading: boolean;
  documentBytes: Uint8Array | undefined;
  documentTitle: string | undefined;
  documentLoadResult: Result | undefined;
  documentLoading: boolean;
  documentContent: string | undefined;
  isDocumentSigned: boolean;
  showPinModal: boolean;
}

export class RsaDocumentPreview extends BaseComponent<Props, State> {
  state: State = {
    errorMessages: [],
    loading: true,
    documentBytes: undefined,
    documentTitle: undefined,
    documentLoadResult: undefined,
    documentLoading: false,
    documentContent: undefined,
    isDocumentSigned: false,
    showPinModal: false,
  };

  async componentDidMountAsync(): Promise<void> {
    await this.fetchDocument();
  }

  fetchDocument = async () => {
    const {server, actions} = this.props.context;

    const response = await server.getRsaDocumentPreview({signDocID: Number.parseInt(this.props.match.params.signDocID, 10)});
    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    await this.setStateAsync({
      documentBytes: base64ToArray(response.payload.pdfContent as string),
      documentContent: response.payload.pdfContent,
      documentTitle: response.payload.title,
      isDocumentSigned: response.payload.isDocumentSigned,
      loading: false,
    });
  };

  showSignModal = async () => {
    await this.setStateAsync({showPinModal: true});
  };

  closeSignModal = async () => {
    await this.setStateAsync({showPinModal: false});
  };

  onPdfLoad = async (pdfLoadResult: Result) => {
    await delay(0);

    await this.setStateAsync({
      documentLoadResult: pdfLoadResult,
      documentLoading: false,
    });
  };

  downloadPdf = () => {
    if (!this.state.documentContent) {
      throw new Error('Trying to print while documentContent is falsy.');
    }

    downloadFile(
      this.state.documentBytes,
      this.state.documentContent,
      `${this.state.documentTitle}.pdf`,
    );
  };

  render() {
    const {
      errorMessages
      , documentTitle
      , documentBytes
      , loading
      , documentLoading
      , documentLoadResult
      , isDocumentSigned
      , showPinModal
      ,
    } = this.state;

    return (
      <div className="rsa-document-preview">
        <DocumentTitle title="Документ"/>

        <div className="card mb-3">
          <h4 className="card-header">{documentTitle}</h4>
          {documentLoadResult && documentLoadResult.success &&
              <div className="card-body">
                  <div className="text-center">
                      <button className="btn btn-md btn-success"
                              onClick={this.downloadPdf}>
                        {texts.PATIENT_LABRESULT_DOWNLOAD}
                      </button>
                    {!isDocumentSigned &&
                        <button className="btn btn-md btn-warning"
                                onClick={this.showSignModal}>
                            Подпиши
                        </button>
                    }
                  </div>
              </div>}
        </div>

        {errorMessages.length > 0 && <ErrorMessages errors={errorMessages}/>}
        {(loading || documentLoading) && <LoadingIndicator className="document-loading" delay={500}/>}

        {documentBytes && <PdfDocument data={documentBytes} onLoad={this.onPdfLoad}/>}

        {showPinModal &&
            <RsaSignDocumentModal
                signDocID={Number.parseInt(this.props.match.params.signDocID, 10)}
                onClose={this.closeSignModal}
                onSigned={async () => {
                  await this.fetchDocument();
                  await this.closeSignModal();
                }}
                context={this.props.context}
            />
        }
      </div>
    );
  }
}
