import { trimDate, format } from '../infrastructure/util';
import { addDays, addHours, addSeconds, parse } from 'date-fns';

export const JDateTime = {
  now: () => new Date(),
  date: (date: Date) => trimDate(date),
  today: () => trimDate(new Date()),
  utcNow: () => new Date(),
  addDays: (date: Date, days: number) => addDays(date, days),
  addHours: (date: Date, hours: number) => addHours(date, hours),
  addSeconds: (date: Date, seconds: number) => addSeconds(date, seconds),
  toJoystickDateFormat: (date: Date) => format(date, 'dd.MM.yyyy'),
  toJoystickDateTimeFormat: (date: Date) => format(date, 'dd.MM.yyyy HH:mm'),
  parse: (text: string, formatString: string) => parse(text, formatString, new Date()),
  create: (year: number, month: number, day: number, hour?: number, minute?: number, second?: number) => {
    hour = hour || 0;
    minute = minute || 0;
    second = second || 0;
    return new Date(year, month - 1, day, hour, minute, second, 0);
  },
};
