import {Action, ActionCreatorsMapObject, Reducer} from 'redux';
import {LoginResponse} from '../dto';
import {distributedAction} from './redux/distribute-dispatch';
import { JsonHelper } from './JsonHelper';

export const sessionActions = {
  LOGIN: 'session/LOGIN',
  LOGOUT: 'session/LOGOUT',
  SET_USER_PRIVILEGES: 'session/SET_USER_PRIVILEGES',
  SET_USERNAME: 'session/SET_USERNAME',
};

export interface SessionActionCreators extends ActionCreatorsMapObject {
  logout: () => Action;
  login: (payload: LoginResponse) => Action;
  setUserPrivileges: (userPrivileges: number[]) => Action;
  setUsername: (username: string) => Action;
}

const actionCreators: SessionActionCreators = {
  logout: () => (distributedAction({type: sessionActions.LOGOUT})),
  login: (payload) => (distributedAction({type: sessionActions.LOGIN, payload})),
  setUserPrivileges: (userPrivileges) => (distributedAction({
    type: sessionActions.SET_USER_PRIVILEGES,
    userPrivileges,
  })),
  setUsername: (username) => (distributedAction({type: sessionActions.SET_USERNAME, username})),
};

export const sessionActionCreators = actionCreators;

export type SessionState = { token: string; }
  & ({
  isLoggedIn: false;
  userDisplayName: undefined;
  userRole: undefined;
  userPrivileges: undefined;
  userID: undefined;
  patientID: undefined;
  webSessionID: undefined;
  doctorID: undefined;
  username: undefined;
} | {
  isLoggedIn: true;
  userDisplayName: string;
  userRole: number;
  userPrivileges?: number[];
  userID: number;
  patientID: number;
  webSessionID: number;
  doctorID: number;
  username: string;
});
export type UserRole = 'patient' | 'doctor';

export type UserPrivilege = 'Microbiology' |
  'ExternalResults' |
  'MobileNotification' |
  'OnLineJRequests' |
  'EditHistology' |
  'DigitalSigning' |
  'MedicalAgent';

export type Feature = 'AppointmentPage' |
  'PatientAppointmentPage' |
  'DoctorAppointmentPage' |
  'ResultsPage' |
  'DoctorResultsPage' |
  'VisitsPage' |
  'BillingPage' |
  'PrescriptionPage' |
  'ChangePasswordPage' |
  'InvalidateAccountPage' |
  'MicrobiologyPage' |
  'ExternalResultsPage' |
  'DoctorSchedulePage' |
  'CytologyResultsPage' |
  'AppointmentDelayPage' |
  'DisplayAppointmentPage' |
  'NotificationsPage' |
  'VirtualPOSPage' |
  'PatientRegisterPage' |
  'DocumentSigningPage' |
  'OnlineConsultationPage' |
  'PasswordRecoveryPage' |
  'ViberSubscriptionPage' |
  'OperationPlanningPage' |
  'PatientIdCardDetails' |
  'DicomPatientPage' |
  'DicomPage' |
  'MedicalAgent' |
  'RSACertificate' |
  'CytologyDirectResultPage';

const userRoleEnumMap: { [key: number]: UserRole } = {
  1: 'doctor',
  2: 'patient',
};

export const getUserRole = (userRole: number): UserRole => {
  const value = userRoleEnumMap[userRole];
  if (!value) {
    throw new Error(`The value ${userRole} is not present in the allowed enum values: ${JsonHelper.stringify(Object.keys(userRoleEnumMap))}`);
  }
  return value;
};

const userPrivilegeEnumMap: { [key: number]: UserPrivilege } = {
  11: 'EditHistology',
  28: 'Microbiology',
  42: 'MobileNotification',
  43: 'ExternalResults',
  52: 'OnLineJRequests',
  60: 'DigitalSigning',
  61: 'MedicalAgent',
};

export const getUserPrivilege = (userPrivilege: number): UserPrivilege => userPrivilegeEnumMap[userPrivilege];

const initialState: SessionState = {
  isLoggedIn: false,
  userDisplayName: undefined,
  userRole: undefined,
  token: '',
  userPrivileges: undefined,
  patientID: undefined,
  userID: undefined,
  webSessionID: undefined,
  doctorID: undefined,
  username: undefined,
};

export const sessionReducer: Reducer<SessionState> = (state = initialState, action) => {
  switch (action.type) {
    case sessionActions.LOGIN: {
      return {
        ...state,
        ...action.payload,
        isLoggedIn: true,
      };
    }
    case sessionActions.SET_USER_PRIVILEGES: {
      if (!state.isLoggedIn) {
        return state;
      }

      return {
        ...state,
        userPrivileges: action.userPrivileges,
      };
    }
    case sessionActions.SET_USERNAME: {
      if (!state.isLoggedIn) {
        return state;
      }

      return {
        ...state,
        username: action.username,
      };
    }
    case sessionActions.LOGOUT: {
      return {
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
};

declare module './redux/store' {

  interface ReduxState {
    session: SessionState;
  }

  interface AllActionCreators {
    session: SessionActionCreators;
  }
}
