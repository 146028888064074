import React from 'react';

import {AppContextProps} from '../infrastructure/react-context';
import {BaseComponent} from '../infrastructure/components/BaseComponent';
import {DocumentTitle} from '../infrastructure/DocumentTitle';
import {CabinetDisplayCM} from '../dto';
import {newDateWithoutTime} from '../infrastructure/util';

interface Props extends AppContextProps {
}

interface State {
  loading: boolean;
  listItems: CabinetDisplayCM[] | undefined;
  secondsSinceRefresh: number;
  secondsTimerHandle: NodeJS.Timeout | undefined;
  dataTimerHandle: NodeJS.Timeout | undefined;
}

export class DisplayAppointment extends BaseComponent<Props, State> {

  state: State = {
    loading: false,
    listItems: undefined,
    secondsSinceRefresh: 0,
    secondsTimerHandle: undefined,
    dataTimerHandle: undefined,
  };

  componentDidMountAsync = async () => {

    await this.fetchData();

    await this.setStateAsync({
      secondsTimerHandle: setInterval(this.unwrapPromise(async () => {
        await this.setStateAsync(({secondsSinceRefresh}) => {
          return ({secondsSinceRefresh: secondsSinceRefresh + 1});
        });
      }), 1000),
      dataTimerHandle: setInterval(this.unwrapPromise(async () => {
        await this.fetchData();
      }), 1000 * 10),
    });
  };

  componentWillUnmountAsync = async () => {
    if (this.state.secondsTimerHandle) {
      clearInterval(this.state.secondsTimerHandle);
    }

    if (this.state.dataTimerHandle) {
      clearInterval(this.state.dataTimerHandle);
    }
  };

  fetchData = async () => {
    const {server, actions} = this.props.context;

    await this.setStateAsync({loading: true});

    const response = await server.displayAppointment({});
    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loading: false, listItems: undefined});
      return;
    }
    await this.setStateAsync({loading: false, listItems: response.payload.items, secondsSinceRefresh: 0});
  };

  render() {

    const {listItems} = this.state;
    return (
      <div className="appointment-delay-page">

        <DocumentTitle title="Дисплеи"/>
        <div className="card" style={{marginTop: '5rem'}}>
          <div className="card-header text-center font-weight-bold"> ПРЕГЛЕДИ ПО КАБИНЕТИ
            <div className="row">
             <div style={{textAlign: 'center'}}> </div>
              <div>  <button className="btn btn-sm btn-amber"
                             type="button"
                             onClick={() => this.fetchData()}>
                ОБНОВИ
              </button></div>
              <div>  { newDateWithoutTime().toLocaleDateString() + ' ' + newDateWithoutTime().toLocaleTimeString()
              + '' + this.state.secondsSinceRefresh} </div>
            </div>
          </div>
        {listItems && listItems.length > 0 &&
        <div className="row">
        {listItems.map((item) => (

          <div className="col-sm-4">
            <div className="card text-white bg-primary mb-3">
              <div className="card-header">КАБ. {item.displayName} Пациент №: {item.formatedCurrentNumber}</div>
              <div className="card-body">
                <h5 className="card-title">{item.doctorFirstLastName}</h5>
                <p className="text-white card-text">{item.activityName}</p>
              </div>
            </div>
          </div>
        ))}
        </div>}
        </div>
      </div>
    );
  }
}
