import dateFnsFormat from 'date-fns/format';
import print from 'print-js';

export const delay = (timeout: number) => new Promise((resolve) => setTimeout(resolve, timeout));

export const isPromise = (value: any): value is Promise<any> => Boolean(value && typeof value.then === 'function');

export const isFunction = (value: any) => value && (Object.prototype.toString.call(value) === '[object Function]' || 'function' === typeof value || value instanceof Function);

export const setClipboardText = (text: string) => {

  const textArea = document.createElement('textarea');

  textArea.style.position = 'fixed';
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.width = '2em';
  textArea.style.height = '2em';
  textArea.style.padding = '0';
  textArea.style.border = 'none';
  textArea.style.outline = 'none';
  textArea.style.boxShadow = 'none';
  textArea.style.background = 'transparent';

  textArea.value = text;

  document.body.appendChild(textArea);

  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const message = successful ? 'successful' : 'unsuccessful';
    console.log('Copying text command was ' + message);
  } catch (err) {
    console.log('Oops, unable to copy.');
  }

  document.body.removeChild(textArea);
};

export const uuid = (a?: any): string => (
  a ? (a ^ Math.random() * 16 >> a / 4)
    .toString(16) : ('10000000-1000-4000-8000-100000000000')
    .replace(/[018]/g, uuid)
);

export const downloadFile = (documentBytes: any, base64: string, fileName: string) => {

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(new Blob([documentBytes]), fileName);
    return;
  }

  const link = document.createElement('a');
  link.download = fileName;
  link.href = `data:application/octet-stream;base64,${base64}`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const printPdf = (documentBytes: any, base64: string, fileName: string) => {

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    downloadFile(documentBytes, base64, fileName);
  } else {
    print({
      printable: base64,
      type: 'pdf',
      base64: true,
    });
  }
};

export const getQuery = () => {

  return Object.fromEntries(
    window.location.search
      .slice(1)
      .split('&')
      .map((x) => x.split('=').map(decodeURIComponent)),
  );
};

export const isObject = (val: any): boolean => {
  return val != null && typeof val === 'object' && !Array.isArray(val);
};

const isObjectObject = (o: any) => isObject(o) && Object.prototype.toString.call(o) === '[object Object]';

export const isPlainObject = (o: any) => {

  if (!isObjectObject(o)) {
    return false;
  }

  // If has modified constructor
  if (typeof o.constructor !== 'function') {
    return false;
  }

  // If has modified prototype
  if (!isObjectObject(o.constructor.prototype)) {
    return false;
  }

  // If constructor does not have an Object-specific method
  if (!o.constructor.prototype.hasOwnProperty('isPrototypeOf')) {
    return false;
  }

  // Most likely a plain Object
  return true;
};

export const isOnlyMatch = (regex: RegExp, input: any) => {

  const inputString = input.toString();

  const res = inputString.match(regex);

  if (!res || !res.length) {
    return false;
  }

  return res[0] === inputString;
};

export const format = (date: Date, formatString: string): string => {
  return dateFnsFormat(date, formatString);
};

export const random = (min: number, max: number): number =>
  Math.floor(Math.random() * (max - min)) + min;

export const retryPromise = <T>(fn: () => Promise<T>, retriesLeft = 5, interval = 1000): Promise<T> => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retryPromise(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};

export const emptyFunc = (() => {
});

export const base64ToArray = (data: string): Uint8Array => {

  const raw = window.atob(data);
  const rawLength = raw.length;
  const array = new Uint8Array(new ArrayBuffer(rawLength));

  for (let i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }

  return array;
};

export const trimDate = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
};

export const newDateWithoutTime = () => {
  return trimDate(new Date());
};

export const endDate = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
};

export function throttle<T>(callback: T, duration: number): T {
  let waiting = false;
  return function () {
    if (!waiting) {
      // @ts-ignore
      const that = this;
      (callback as any).apply(that, arguments);
      waiting = true;
      setTimeout(() => waiting = false, duration);
    }
  } as any;
}

export function debounce<T>(func: T, duration: number, immediate?: boolean): T {
  let timeout: NodeJS.Timeout | null;

  return function () {

    // @ts-ignore
    const context = this;
    const args = arguments;
    const callNow = immediate && !timeout;

    clearTimeout(timeout as NodeJS.Timeout);

    timeout = setTimeout(() => {
      timeout = null;
      if (!immediate) {
        (func as any).apply(context, args);
      }
    }, duration);

    if (callNow) {
      (func as any).apply(context, args);
    }
  } as any;
}

export const escapeRegex = (text: string) => {
  return text.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
};

export const replaceAll = (value: string, oldValue: string, newValue: string) => {
  return value.replace(new RegExp(escapeRegex(oldValue), 'g'), newValue);
};

export const spliceString = (text: string, index: number, length?: number) => {
  if (index < 0) {
    index += text.length;
    if (index < 0) {
      index = 0;
    }
  }
  if (index >= text.length) {
    index -= text.length;
    if (index >= text.length) {
      index = text.length - 1;
    }
  }

  let result = text.slice(0, index);

  if (length !== undefined) {
    result += text.slice(index + length);
  }

  return result;
};
