import { Action, ActionCreatorsMapObject, Reducer } from 'redux';
import { logError, ExtendedError } from './errors';
import { SagaMap } from './redux/store';

const actions = {
  SET_ERROR: 'errors/SET_ERROR',
  SET_ERROR_MESSAGES: 'errors/SET_ERROR_MESSAGES',
  CLEAR_ERRORS: 'errors/CLEAR_ERRORS',
};

export interface ErrorsActionCreators extends ActionCreatorsMapObject {
  setError: (error: Error) => Action;
  setErrorMessages: (errorMessages: string[]) => Action;
  clearErrors: () => Action;
}

const actionCreators: ErrorsActionCreators = {
  setError: (error) => ({type: actions.SET_ERROR, error}),
  setErrorMessages: (errorMessages) => ({type: actions.SET_ERROR_MESSAGES, errorMessages}),
  clearErrors: () => ({type: actions.CLEAR_ERRORS}),
};

export const errorsActionCreators = actionCreators;

const gTranslatePatterns = [
  'unexpected identifier \'a\'. expected \')\' to end an argument list',
  'a[b].target.classname.indexof is not a function',
];

export interface ErrorsState {
  error: Error | undefined;
  errorMessages: string[] | undefined;
}

const initialState: ErrorsState = {
  error: undefined,
  errorMessages: undefined,
};

export const errorsReducer: Reducer<ErrorsState> = (state = initialState, action) => {
  switch (action.type) {
    case actions.CLEAR_ERRORS: {
      return {
        ...initialState,
      };
    }
    case actions.SET_ERROR: {

      if (action.error instanceof ExtendedError && action.error.specialCommand) {
        return state;
      }

      const errorString = action.error?.toString().toLowerCase();
      let gTranslateError = false;
      for (const pattern of gTranslatePatterns) {
        if (errorString.includes(pattern)) {
          gTranslateError = true;
          break;
        }
      }

      if (gTranslateError) {

        return {
          ...initialState,
          errorMessages: [
            'Причината за грешката, е че Вашия браузер е настроен автоматично да се опитва да превежда страницата на избран от Вас език!',
            'Моля, прегледайте си настройките и опитайте отново.',
            '(Можете да потърсите информация: Заявяване на превод или изключване на преводите)',
          ],
        };
      }

      return {
        ...initialState,
        error: action.error,
      };
    }
    case actions.SET_ERROR_MESSAGES: {
      return {
        ...initialState,
        errorMessages: action.errorMessages,
      };
    }
    default: {
      return state;
    }
  }
};

export const errorsSagas: SagaMap = {
  [`errors/error-saga`]: {
    actionType: actions.SET_ERROR,
    * saga({error}, context) {

      if (error instanceof ExtendedError && error.specialCommand) {
        context.actions.session.logout();
        context.actions.router.routerPush('/');
        return;
      }

      yield logError(error);
    },
  },
};

declare module './redux/store' {

  interface ReduxState {
    errors: ErrorsState;
  }

  interface AllActionCreators {
    errors: ErrorsActionCreators;
  }
}
