import { SessionState, Feature } from '../infrastructure/session.state';
import { CacheState } from '../infrastructure/cache.state';
import { shouldRender } from '../infrastructure/render-permissions';

export interface CommonService {
  isLoggedIn: () => boolean;
  getPatientID: () => number;
  getDoctorID: () => number;
  getTextResource: (id: number) => string;
  isFeatureEnabled: (feature: Feature) => boolean;
}

export const createCommonService = (session: SessionState, cache: CacheState): CommonService => {

  return {
    isLoggedIn: () => session.isLoggedIn,
    getPatientID: () => session.patientID || 0,
    getDoctorID: () => session.doctorID || 0,
    getTextResource: (id) => cache?.textResources[id] || '',
    isFeatureEnabled: (feature) => shouldRender({feature, requireLogin: false}, session, cache),
  };
};

declare module '../infrastructure/context' {

  interface AppContext {
    commonService: CommonService;
  }
}
