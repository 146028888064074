import { connect } from 'react-redux';
import { wrapActions } from '../redux/store';
import { SessionActionCreators, sessionActionCreators } from '../session.state';
import { FunctionComponent } from 'react';
import { RenderProps } from '../react-helpers';

interface RenderComponentProps {
  onClick: (e: any) => void;
}

interface Props extends RenderProps<RenderComponentProps> {
  actions: SessionActionCreators;
}

const LogOutButton: FunctionComponent<Props> = ({actions, render}) => render({
  onClick: (e) => {
    if (e instanceof Event) {
      e.preventDefault();
    }

    actions.logout();
  },
});

export default connect(null, wrapActions(sessionActionCreators))(LogOutButton);
