import React, {Fragment} from 'react';
import {RouteComponentProps} from 'react-router-dom';

import {AppContextProps} from '../infrastructure/react-context';
import {BaseComponent} from '../infrastructure/components/BaseComponent';
import {ScreenSizeComponent} from '../infrastructure/media-type';
import {Result} from '../infrastructure/api-result';
import {ErrorMessages} from '../infrastructure/errors';
import {PdfDocument} from '../infrastructure/components/PdfDocument';
import LoadingIndicator from '../infrastructure/components/LoadingIndicator';
import {downloadFile, delay, printPdf, base64ToArray} from '../infrastructure/util';

import './ViewLabResults.scss';
import {DocumentTitle} from '../infrastructure/DocumentTitle';

interface RouterParams {
  qrID: string;
}

interface Props extends RouteComponentProps<RouterParams>, AppContextProps {
}

interface State {

  documentLoading: boolean;
  documentContent: string | undefined;
  documentTitle: string | undefined;
  documentLoadResult: Result | undefined;

  initialLoading: boolean;

  documentBytes: Uint8Array | undefined;
}

export class ViewPublicLabResults extends BaseComponent<Props, State> {

  state: State = {
    documentLoading: false,
    documentContent: undefined,
    documentTitle: undefined,
    documentLoadResult: undefined,
    initialLoading: true,
    documentBytes: undefined,
  };

  async fetchPdfResult(qrID: string) {
    const {server, actions} = this.props.context;

    await this.setStateAsync({
      documentLoading: true,
      documentContent: undefined,
      documentBytes: undefined,
    });

    const response = await server.publicPatientResult({qrID});

    if (!response.success) {

      actions.errors.setErrorMessages(response.errorMessages);

      await this.setStateAsync({
        documentLoading: false,
        documentContent: undefined,
        documentBytes: undefined,
        initialLoading: false,
      });

      return;
    }

    await this.setStateAsync({
      documentContent: response.payload.pdfContent,
      documentBytes: base64ToArray(response.payload.pdfContent as string),
      documentTitle: response.payload.title,
      documentLoadResult: undefined,
      initialLoading: false,
    });
  }

  async fetchData() {
    const qrID = this.props.match.params.qrID;
    await this.fetchPdfResult(qrID);
  }

  componentDidMountAsync = async () => {
    await this.fetchData();
  };

  downloadPdf = () => {
    if (!this.state.documentContent) {
      throw new Error('Trying to print while documentContent is falsy.');
    }

    downloadFile(
      this.state.documentBytes,
      this.state.documentContent,
      `${this.state.documentTitle}.pdf`,
    );
  };

  printPdf = () => {
    if (!this.state.documentContent) {
      throw new Error('Trying to print with falsy documentContent.');
    }

    printPdf(
      this.state.documentBytes,
      this.state.documentContent,
      `${this.state.documentTitle}.pdf`,
    );
  };

  onPdfLoad = async (pdfLoadResult: Result) => {

    await delay(0);

    await this.setStateAsync({
      documentLoadResult: pdfLoadResult,
      documentLoading: false,
    });
  };

  render() {

    const {
      documentContent,
      documentLoading,
      documentTitle,
      documentLoadResult,
      initialLoading,
      documentBytes,
    } = this.state;

    return (
      <div className="patient-lab-results-page">

        <DocumentTitle title="Резултати"/>

        <div className="row" style={{margin: '0'}}>

          <div className="col-xl-8" style={{margin: 'auto'}}>

            {!!documentContent && !!documentBytes && <Fragment>

                <div className="card resource-box">
                    <div className="card-body">
                        <div>
                            <h2 className="text-center document-title">{documentTitle}</h2>
                        </div>

                        <div className="button-group">
                          {documentLoadResult && documentLoadResult.success && <Fragment>
                              <hr/>

                              <div className="text-center">
                                  <button className="btn btn-md btn-success"
                                          onClick={this.downloadPdf}>
                                      Изтегли
                                  </button>

                                  <ScreenSizeComponent size="not-phone">
                                      <button className="btn btn-md btn-default"
                                              onClick={this.printPdf}>
                                          Принтирай
                                      </button>
                                  </ScreenSizeComponent>
                              </div>
                          </Fragment>}
                        </div>
                    </div>
                </div>

              {!!documentLoadResult && !documentLoadResult.success &&
              <ErrorMessages errors={documentLoadResult.errorMessages}/>}

                <PdfDocument data={documentBytes} onLoad={this.onPdfLoad}/>

            </Fragment>}

            {(initialLoading || documentLoading) && <LoadingIndicator className="document-loading" delay={0}/>}
          </div>
        </div>
      </div>
    );
  }
}
