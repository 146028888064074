import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { AppContextProps } from '../infrastructure/react-context';
import { BaseComponent } from '../infrastructure/components/BaseComponent';
import { CustomForm } from '../infrastructure/components/CustomForm';

import './demo.scss';

import { DebugValue } from '../infrastructure/components/DebugValue';
import { ListMultiSelectField, ListMultiSelectItems } from '../infrastructure/fields/ListMultiSelectInput';
import { PriceListDepartmentCM } from '../dto';

interface Props extends RouteComponentProps, AppContextProps {
}

interface State {
}

export class ListMultiSelectFieldDemo extends BaseComponent<Props, State> {

  state: State = {};

  submit = async (values: any) => {
    console.info(values);
  };

  render() {

    return (
      <CustomForm
        onSubmit={this.submit}
        values={{myItems: []}}
        render={(form) => (
          <div className="card demo-form">
            <div className="card-body">
              <div className="row">
                <div className="col-sm-6">
                  <ListMultiSelectField
                    name="myItems"
                    items={[]}
                    getKey={(x: PriceListDepartmentCM) => x.serviceID}
                    render={(listProps: ListMultiSelectItems<PriceListDepartmentCM>) => (
                      <div className="table-scrollable">
                        <table className="table list-table">
                          <thead className="blue text-white">
                            <tr className="row">
                              <th className="col-1">-</th>
                              <th className="col-11">Име на услугата</th>
                            </tr>
                          </thead>

                          <tbody style={{maxHeight: '15rem', maxWidth: '36rem'}}>
                            {listProps.items.map((item) => {
                              const Checkbox = item.CheckBox;
                              return (
                                <tr className="row" style={{cursor: 'pointer'}}
                                    key={item.serviceID} onClick={item.onToggle}>
                                  <td className="col-1">
                                    <Checkbox/>
                                  </td>
                                  <td className="col-11 no-wrap">{item.serviceName}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    )}
                  />
                </div>
                <div className="col-sm-6" style={{backgroundColor: 'red', opacity: '.5'}}>
                </div>
              </div>
              <div className="text-center py-3 mt-3">
                <button className="btn btn-warning btn-block">Submit</button>
              </div>
              <DebugValue value={form}/>
            </div>
          </div>
        )}/>
    );
  }
}
