import {withAppContext, AppContextFactoryProps} from '../../infrastructure/react-context';
import {WAppointmentDto, serverValidations} from '../../dto';
import {BaseComponent} from '../../infrastructure/components/BaseComponent';
import React, {Fragment} from 'react';
import {CustomModal} from '../../infrastructure/components/CustomModal';
import {TextField} from '../../infrastructure/fields/TextInput';
import {CustomForm} from '../../infrastructure/components/CustomForm';
import {ErrorMessages} from '../../infrastructure/errors';
import {SelectField} from '../../infrastructure/fields/SelectInput';
import LoadingIndicator from '../../infrastructure/components/LoadingIndicator';

interface Props extends AppContextFactoryProps {
  selectedSlot: WAppointmentDto;
  dropdownSlots: WAppointmentDto[];
  onSubmit: (model: WAppointmentDto) => void;
  onDelete: (appoID: number) => void;
  onClose?: () => void;
  errorMessages: string[];
  loading: boolean;
}

interface State {
}

class DoctorScheduleModal extends BaseComponent<Props, State> {
  state: State = {};

  onSubmitAsync = async (modelToSubmit: WAppointmentDto) => {
    const slot = this.props.dropdownSlots
      .filter((x) => x.timeAndWorkcabientFormated === modelToSubmit.timeAndWorkcabientFormated);

    const model: WAppointmentDto = {
      ...modelToSubmit,
      workkabinettypeid: slot[0].workkabinettypeid,
      wcachefromtime: slot[0].wcachefromtime,
      wpatientname: modelToSubmit.wpatientname.toUpperCase(),
      appotypename: modelToSubmit.appotypename,
    };

    this.props.onSubmit(model);
  };

  render() {

    const {selectedSlot, onDelete, onClose, loading, errorMessages, dropdownSlots} = this.props;
    const validations = serverValidations.appointmentDto;

    const slots = dropdownSlots
      .map((x) => ({label: x.timeAndWorkcabientFormated, value: x.timeAndWorkcabientFormated}));

    return (
      <CustomModal className="make-appointment-modal" onClose={onClose} title="Запазване на час">

        {loading && <LoadingIndicator/>}

        <CustomForm
          onSubmit={this.onSubmitAsync}
          values={selectedSlot}
          render={() => (
            <Fragment>
              <button
                className="btn btn-success"
                type="submit">
                Запази
              </button>

              <button
                className="btn btn-warning"
                type="button"
                onClick={onClose}>
                Отказ
              </button>

              {!selectedSlot.isfree &&
                  <button
                      className="btn btn-danger"
                      type="button"
                      onClick={async () => onDelete(selectedSlot.wappointmentpatientid)}>
                      Изтрий
                  </button>}
              <SelectField
                label="Час"
                name="timeAndWorkcabientFormated"
                items={slots}
              />
              <TextField
                name="wpatientname"
                label="Име на пациента"
                inputProps={{style: {textTransform: 'uppercase'}}}
                validation={validations.patientName}
              />
              <TextField
                name="wpatientmobile"
                label="Телефон"
              />
              <TextField
                name="wappointmentnote"
                label="Забележка"
              />

              {selectedSlot?.worknote?.length > 0 && <p className={'pt-3'}>{selectedSlot.worknote}</p>}
            </Fragment>
          )}/>

        {errorMessages.length > 0 &&
            <div className="row">
                <div className="col-sm-12">
                    <ErrorMessages errors={errorMessages}/>
                </div>
            </div>}

      </CustomModal>
    );
  }
}

export default withAppContext(DoctorScheduleModal);
