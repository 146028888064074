import React from 'react';

import {AppContextProps} from '../infrastructure/react-context';
import {BaseComponent} from '../infrastructure/components/BaseComponent';
import {CustomForm} from '../infrastructure/components/CustomForm';
import {serverValidations, GenerateCertificateRequest, SignCertificateCM} from '../dto';
import {DocumentTitle} from '../infrastructure/DocumentTitle';
import LoadingIndicator from '../infrastructure/components/LoadingIndicator';
import {JDateTime} from '../public/JDateTime';
import {TextField} from '../infrastructure/fields/TextInput';
import {ErrorMessages} from '../infrastructure/errors';
import {CustomModal} from '../infrastructure/components/CustomModal';
import {Link} from 'react-router-dom';

interface Props extends AppContextProps {
}

interface State {
  errorMessages: string[];
  loading: boolean;
  model: SignCertificateCM | undefined;
  newCertificateCreated: boolean;
  showDeleteModal: boolean;
  isDeleting: boolean;
}

export class RsaCertificate extends BaseComponent<Props, State> {
  state: State = {
    errorMessages: [],
    loading: true,
    model: undefined,
    newCertificateCreated: false,
    showDeleteModal: false,
    isDeleting: false,
  };

  async componentDidMountAsync() {
    const {server} = this.props.context;

    const certificateResponse = await server.getCertificate({});
    if (!certificateResponse.success) {
      await this.setStateAsync({errorMessages: certificateResponse.errorMessages, loading: false});
      return;
    }

    await this.setStateAsync({loading: false, model: certificateResponse.payload.certificate});
  }

  showDeleteModal = async () => {
    await this.setStateAsync({showDeleteModal: true});
  };

  hideDeleteModal = async () => {
    await this.setStateAsync({showDeleteModal: false});
  };

  deleteCertificate = async () => {
    const {server} = this.props.context;

    await this.setStateAsync({isDeleting: true});

    const resp = await server.deleteCertificate({});
    if (!resp.success) {
      await this.setStateAsync({errorMessages: resp.errorMessages, isDeleting: false});
      return;
    }

    await this.setStateAsync({isDeleting: false, model: undefined});
    await this.hideDeleteModal();
  };

  createCertificate = async (data: GenerateCertificateRequest) => {
    const {server} = this.props.context;
    await this.setStateAsync({newCertificateCreated: false});

    const certificateResponse = await server.generateCertificate(data);
    if (!certificateResponse.success) {
      await this.setStateAsync({errorMessages: certificateResponse.errorMessages, loading: false});
      return;
    }

    await this.setStateAsync({model: certificateResponse.payload.certificate, newCertificateCreated: true});
  };

  render() {
    const {loading, errorMessages, model, newCertificateCreated, showDeleteModal, isDeleting} = this.state;

    const validations = serverValidations.generateCertificateRequest;

    return (
      <div className="rsa-certificate-page">

        <DocumentTitle title={'Сертификат'}/>

        {loading && <LoadingIndicator/>}

        {!loading &&
            <div>
                <div className="card border-info mb-4">
                    <h4 className="card-header d-flex justify-content-between">
                        <div>
                            Данни за подпис /сертификат/
                        </div>
                      {model &&
                          <button
                              className="btn btn-sm btn-danger m-0"
                              onClick={this.showDeleteModal}
                          >
                              ИЗТРИВАНЕ / АНУЛИРАНЕ
                          </button>
                      }
                    </h4>
                    <div className={`card-body bg-${model ? 'success' : 'danger'} text-white`}>
                      {model ?
                        <div>
                          <div>Валиден сертификат до: {JDateTime.toJoystickDateFormat(model.validateToDate)} г.</div>
                        </div> :
                        <div>В момента нямате сертификат. За генериране използвайте бутона по-долу.</div>
                      }
                    </div>
                </div>

                <CustomForm onSubmit={this.createCertificate} render={({isSubmitting}) => (
                  <div className="card">
                    <h4 className="card-header">Генерирай нов дигитален подпис /сертификат/</h4>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-4">
                          <TextField
                            label={'PIN'}
                            name={'PIN'}
                            validation={validations.pin}
                          />
                        </div>
                        <div className="col-md-3">
                          <button className="btn btn-info w-100 mt-2" disabled={isSubmitting}>
                            Генерирай
                          </button>
                        </div>
                      </div>
                      {errorMessages.length > 0 && <ErrorMessages errors={errorMessages}/>}
                    </div>
                    {newCertificateCreated &&
                        <div className="card-footer bg-success text-white">
                            <div>
                                Успешно създаден нов сертификат. Вече можете да подписвате електронно вашите документи.
                            </div>
                            <Link className="btn btn-md btn-info" to={'/patient/rsa/documents'}>Към страницата с документи</Link>
                        </div>
                    }
                  </div>
                )}/>
            </div>
        }

        {showDeleteModal &&
            <CustomModal title={'Изтриване на подпис /сертификат/'} onClose={this.hideDeleteModal}>
                <div>
                    <div>Сигурни ли сте, че искате да изтриете вашия подпис /сертификат/ ?</div>
                    <div className="row mt-2">
                        <div className="col-md-4">
                            <button
                                disabled={isDeleting}
                                className="btn btn-danger w-100"
                                onClick={this.deleteCertificate}>
                                Изтрий
                            </button>
                        </div>
                        <div className="col-md-4">
                            <button
                                disabled={isDeleting}
                                className="btn btn-warning w-100"
                                onClick={this.hideDeleteModal}>
                                ОТКАЗ
                            </button>
                        </div>
                    </div>
                </div>
            </CustomModal>
        }
      </div>
    );
  }
}
