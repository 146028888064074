/* tslint:disable:max-classes-per-file */
import React, { FunctionComponent, ErrorInfo, CSSProperties, Fragment, useCallback } from 'react';
import { logger as sentryInternalLogger } from '@sentry/utils';
import { BrowserClient, BrowserOptions, Event } from '@sentry/browser';

import { AppContextFactoryProps } from './react-context';
import { settings } from './settings';

import './errors.scss';
import { connect } from 'react-redux';
import { ErrorsState } from './errors.state';
import { ChildrenProps } from './react-helpers';
import { BaseComponent } from './components/BaseComponent';
import { appVersion } from './version';
import { CustomModal } from './components/CustomModal';
import { texts } from './texts';
import { app } from '../global';
import { getUserRole } from './session.state';
import { ReduxState } from './redux/store';

class CustomSentryClient extends BrowserClient {
  public constructor(options: BrowserOptions = {}) {
    super(options);
  }

  /**
   * @inheritDoc
   */
  public captureExceptionExt(exception: any, eventData?: Partial<Event>): string | undefined {

    // tslint:disable-next-line:no-unnecessary-initializer
    let eventId: string | undefined = undefined;
    this._processing = true;

    this._getBackend()
      .eventFromException(exception)
      .then((event) => {
        Object.assign(event, eventData);
        return event;
      })
      .then((event) => this._processEvent(event))
      .then((finalEvent) => {
        // We need to check for finalEvent in case beforeSend returned null
        eventId = finalEvent && finalEvent.event_id;
        this._processing = false;
      })
      .then(null, (reason) => {
        sentryInternalLogger.error(reason);
        this._processing = false;
      });

    return eventId;
  }
}

export type SpecialCommand = 'FORCE_LOGOUT';

export class ExtendedError extends Error {
  public context: any;
  public specialCommand: SpecialCommand | undefined;

  constructor(message: string, innerError?: Error, context?: any, specialCommand?: SpecialCommand) {
    super(message);

    if (innerError && innerError instanceof ExtendedError) {
      this.context = innerError.context;
      this.specialCommand = innerError.specialCommand;
    } else {
      this.context = {innerErrors: []};
    }

    if (specialCommand) {
      this.specialCommand = specialCommand;
    }

    if (innerError) {
      this.context.innerErrors = [
        {
          message: innerError.message,
          stack: innerError.stack,
        },
        ...this.context.innerErrors,
      ];
    }

    this.context = {
      ...this.context,
      ...context,
    };
  }
}

function copyCustomObject(obj: any): any {

  try {
    const result = {} as any;

    let _tmp;

    for (const propertyName in obj) {
      // noinspection JSUnfilteredForInLoop
      if (propertyName === 'enabledPlugin' || typeof obj[propertyName] === 'function') {
        continue;
      }

      // noinspection JSUnfilteredForInLoop
      if (typeof obj[propertyName] === 'object') {
        // get props recursively
        // noinspection JSUnfilteredForInLoop
        _tmp = copyCustomObject(obj[propertyName]);
        // if object is not {}
        if (Object.keys(_tmp).length) {
          // noinspection JSUnfilteredForInLoop
          result[propertyName] = _tmp;
        }
      } else {
        // string, number or boolean
        // noinspection JSUnfilteredForInLoop
        result[propertyName] = obj[propertyName];
      }
    }
    return result;
  } catch (e) {
    return 'An error occurred while accessing this object.';
  }
}

let sentryClient: CustomSentryClient | undefined;

export const logError = async (error: Error | ExtendedError, context?: any) => {
  try {

    let extra: any = {
      __browserInfo: {
        navigator: copyCustomObject(window.navigator || {}),
        performance: copyCustomObject(window.performance || {}),
        location: copyCustomObject(window.location || {}),
        screen: copyCustomObject(window.screen || {}),
      },
    };

    if (error instanceof ExtendedError) {
      extra = {
        ...extra,
        ...error.context,
      };
    }

    extra = {
      ...extra,
      ...context || {},
    };

    if (!sentryClient) {
      sentryClient = new CustomSentryClient({dsn: settings.sentryDns});
    }

    const supplementaryEventData: Partial<Event> = {
      extra,
      release: appVersion,
      environment: (process.env.NODE_ENV),
    };

    if (error instanceof ExtendedError && error.specialCommand) {
      supplementaryEventData.fingerprint = [`SpecialCommand(${error.specialCommand})`];
    }

    if (app.store) {
      const storeState = app.store.getState();

      if (storeState.cache && storeState.cache.settings) {
        supplementaryEventData.server_name = storeState.cache.settings.serverName;
      }

      if (storeState.session.isLoggedIn) {
        if (getUserRole(storeState.session.userRole) === 'doctor') {
          supplementaryEventData.user = {
            id: `DoctorID (${storeState.session.doctorID})`,
            doctorID: storeState.session.doctorID,
            userID: storeState.session.userID,
            webSessionID: storeState.session.webSessionID,
            role: getUserRole(storeState.session.userRole),
          };
        } else if (getUserRole(storeState.session.userRole) === 'patient') {
          supplementaryEventData.user = {
            id: `PatientID (${storeState.session.patientID})`,
            patientID: storeState.session.patientID,
            webSessionID: storeState.session.webSessionID,
            role: getUserRole(storeState.session.userRole),
          };
        }
      }
    }

    sentryClient.captureExceptionExt(error, supplementaryEventData);
    console.warn('ERROR CAUGHT: ', error.message, error.stack, extra);
  } catch (e) {
    console.error(e);
  }
};

interface ErrorMessageProps {
  errors: string[];
  className?: string;
  style?: CSSProperties;
}

export const ErrorMessages: FunctionComponent<ErrorMessageProps> = ({errors, style, className}) => {

  if (!errors || !errors.length) {
    return null;
  }

  return (
    <div className={`card red text-center ${className || ''}`} style={style}>
      <div className="card-body">
        {errors.map((errorMessage, i) =>
          <div key={i} className="error-messages-container">{errorMessage}</div>,
        )}
      </div>
    </div>
  );
};

interface DisplayExtendedErrorProps {
  error: ExtendedError;
}

const DisplayExtendedError: FunctionComponent<DisplayExtendedErrorProps> = ({error}) => {

  const {innerErrors, componentStack, ...rest} = error.context as {
    innerErrors: [{ message: string, stack: string }],
    componentStack: string,
  };

  return (
    <Fragment>
      {innerErrors.map((innerError, i) => (
        <div className="error-modal__panel" key={i}>
          <div>Inner Error: {innerError.message}</div>
          <pre>{innerError.stack}</pre>
        </div>
      ))}

      {componentStack && <div className="error-modal__panel">
        <div>React stack</div>
        <pre>{componentStack}</pre>
      </div>}

      <div className="error-modal__panel">
        <div>Context</div>
        <pre>{JSON.stringify(rest, null, '  ')}</pre>
      </div>
    </Fragment>
  );
};

interface CustomErrorBoundaryProps extends AppContextFactoryProps, ChildrenProps {
}

interface InjectedCustomErrorBoundaryProps extends CustomErrorBoundaryProps {
  errors: ErrorsState;
}

class CustomErrorBoundaryImpl extends BaseComponent<CustomErrorBoundaryProps> {

  isInLayoutBoundary() {
    return !!(this.props as any).history;
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    const newError = new ExtendedError('React rendering error', error, {
      componentStack: info.componentStack,
      inLayoutBoundary: this.isInLayoutBoundary(),
    });
    const {actions} = this.props.getContext();
    actions.errors.setError(newError);
  }

  render() {
    const injectedProps = this.props as InjectedCustomErrorBoundaryProps;
    const error = injectedProps.errors.error as ExtendedError;

    if (!error) {
      return this.props.children;
    }

    if (error.context && error.context.componentStack) {
      if (error.context.inLayoutBoundary && !this.isInLayoutBoundary()) {
        return this.props.children;
      } else {
        return null;
      }
    }

    return this.props.children;
  }
}

export const CustomErrorBoundary = connect(
  ({errors}: ReduxState) => ({errors}), null,
)(CustomErrorBoundaryImpl);

interface GlobalErrorComponentProps extends AppContextFactoryProps {
}

interface InjectedGlobalErrorComponent extends GlobalErrorComponentProps {
  errors: ErrorsState;
}

/**
 * Displays errors from the `errors` redux state if any are available.
 */
export const GlobalErrorComponentImpl: FunctionComponent<GlobalErrorComponentProps> = (props) => {

  const injectedProps = props as InjectedGlobalErrorComponent;
  const {error, errorMessages} = injectedProps.errors;

  const onClose = useCallback(() => {
    const context = props.getContext();
    context.actions.errors.clearErrors();
    context.actions.router.routerPush('/');
  }, [error, errorMessages]);

  if (!error && !errorMessages) {
    return null;
  }

  return (
    <CustomModal title={texts.ERROR_MODAL_TITLE} className="error-modal" onClose={onClose}>
      <div className="error-modal__button_row">
        <button
          className="btn btn-md btn-warning error-modal__button"
          onClick={() => window.location.reload(true)}>
          {texts.ERROR_RELOAD_BUTTON_TEXT}
        </button>

        <button
          className="btn btn-md btn-primary error-modal__button"
          onClick={onClose}>
          {texts.ERROR_HOME_BUTTON_TEXT}
        </button>
      </div>

      <div className="error-modal__content">

        {error && error instanceof Error && <div className="error-modal__panel">
          <div>JS error: {error.message}</div>
          <pre> {error.stack}</pre>
        </div>}

        {error && !(error instanceof Error) && <div className="error-modal__panel">
          <div>The thrown object is not an error. Type: {typeof error}. JSON:</div>
          <pre>{JSON.stringify(error, null, '  ')}</pre>
        </div>}

        {(error && error instanceof ExtendedError && <DisplayExtendedError error={error}/>)}

        {errorMessages && <ErrorMessages errors={errorMessages}/>}
      </div>
    </CustomModal>
  );
};

export const GlobalErrorComponent = connect(
  ({errors}: ReduxState) => ({errors}), null,
)(GlobalErrorComponentImpl);
