import React, { FunctionComponent, useState, memo } from 'react';

import { wrapField, cssClasses, BaseInputProps } from './field-helpers';

import './Input.scss';
import { ErrorMessages } from '../errors';
import { SelectItem } from './select-item';
import { isOnlyMatch, uuid } from '../util';
import { OutsideAlerter } from '../components/OutsideAlerter';

type StoredType = string | null;

interface Props extends BaseInputProps<StoredType> {
  label: string;
  icon?: string;
  defaultValue?: string;
  password?: boolean;
  mask?: RegExp;
  autocompleteItems?: string[];
  autocompleteExpandOnFocus?: boolean;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
}

const getDefaultValue: () => StoredType = () => null;

const getInitialValue = (props: Props) => {
  if (props.defaultValue !== undefined) {
    return props.defaultValue;
  } else {
    return getDefaultValue();
  }
};

const toInputValue = (value: StoredType): string => {
  if (value === null) {
    return '';
  } else {
    return value.toString();
  }
};

const fromInputValue = (inputValue: string): string | null => inputValue.toString() || getDefaultValue();

export const TextInput: FunctionComponent<Props> = memo((props: Props) => {

  const {
    label,
    icon,
    readonly,
    password,
    mask,
    style,
    className,
    setTouched,
    onChange,
    errorMessages,
    autocompleteItems,
    autocompleteExpandOnFocus,
    inputProps,
  } = props;

  let value = props.value;

  // Initialize
  if (value === undefined) {
    value = getInitialValue(props);
    setTimeout(() => onChange(value), 0);
  }

  const [focused, setFocused] = useState(false);
  const [htmlID] = useState(uuid());

  const active = value || !readonly && focused;

  const cssStates = cssClasses({
    readonly,
    invalid: !!errorMessages && !focused,
    active,
    focused,
  });

  const shouldAcceptValue = (newValue: any): boolean => {
    if (mask !== undefined && newValue) {
      if (!isOnlyMatch(mask, newValue)) {
        return false;
      }
    }

    return true;
  };

  const [currentAutocompleteItems, setAutocompleteItems] = useState<SelectItem[] | undefined>(undefined);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  const hideAutocomplete = () => {
    setAutocompleteItems(undefined);
  };

  const handleAutoComplete = (val: string | null) => {
    const allItems: SelectItem[] = (autocompleteItems || []).map((x, i) => ({value: i, label: x}));

    let filteredItems: SelectItem[] = [];

    if (val && val.trim() && !!allItems.length) {
      filteredItems = allItems.filter((x) => x.label.toLowerCase().includes(val.toLowerCase()));
    }

    if (filteredItems.length) {
      setAutocompleteItems(filteredItems);
    } else {
      hideAutocomplete();
    }
  };

  const handleAutocompleteOnFocus = () => {
    const allItems: SelectItem[] = (autocompleteItems || []).map((x, i) => ({value: i, label: x}));
    if (allItems.length) {
      setAutocompleteItems(allItems);
    } else {
      hideAutocomplete();
    }

    setSelectedIndex(-1);
  };

  const autocompleteItemSelected = (e: any) => {
    const id = Number.parseInt(e.target.attributes.dataid.value, 10);
    const allItems: SelectItem[] = (autocompleteItems || []).map((x, i) => ({value: i, label: x}));
    const item = allItems.filter((x) => x.value === id)[0];

    hideAutocomplete();
    onChange(item.label);
  };

  const onKeyDown = (e: any) => {

    if (e.which === 13) {

      e.preventDefault();

      if (!currentAutocompleteItems) {
        return;
      }

      if (selectedIndex < 0 || selectedIndex > currentAutocompleteItems.length - 1) {
        return;
      }

      const item = currentAutocompleteItems[selectedIndex];

      hideAutocomplete();
      onChange(item.label);
    } else if (e.which === 38) {

      setSelectedIndex((i: number) => {
        if (!currentAutocompleteItems) {
          return -1;
        }

        const next = i - 1;
        const len = currentAutocompleteItems.length;

        if (i === 0) {
          return i;
        }

        if (next < 0 || next > len - 1) {
          return -1;
        }
        return next;
      });
    } else if (e.which === 40) {
      setSelectedIndex((i: number) => {
        if (!currentAutocompleteItems) {
          return -1;
        }

        const next = i + 1;
        const len = currentAutocompleteItems.length;

        if (i === len - 1) {
          return i;
        }

        if (next < 0 || next > len - 1) {
          return -1;
        }
        return next;
      });
    }
  };

  const onSelectedRef = (instance: any) => {
    if (instance) {
      instance.scrollIntoView({block: 'center', inline: 'nearest'});
    }
  };

  const autoCompleteProps = (!!autocompleteItems ? ({onKeyDown}) : {});

  const {
    onBlur: inputPropOnBlur,
    onFocus: inputPropOnFocus,
    onChange: inputPropOnChange,
    ...restInputProps
  } = inputProps || {};

  return (
    <OutsideAlerter
      registered={!!currentAutocompleteItems}
      onOutsideClick={hideAutocomplete}>
      {(ref: any) => (
        <div ref={ref} style={style}
             className={`custom-input text-input custom-form-input form-group ${className} ${cssStates}`}>

          {icon && <i className={`fa fa-${icon} prefix`}/>}

          <input
            {...restInputProps}
            value={toInputValue(value)}
            onBlur={(x) => {
              if (setTouched) {
                setTouched();
              }
              setFocused(false);

              if (inputPropOnBlur) {
                inputPropOnBlur(x);
              }
            }}
            onFocus={(x) => {
              setFocused(true);

              if (autocompleteExpandOnFocus) {
                handleAutocompleteOnFocus();
              }

              if (inputPropOnFocus) {
                inputPropOnFocus(x);
              }
            }}
            onChange={(e: any) => {

              const newInputValue = e.target.value;

              if (!shouldAcceptValue(newInputValue)) {
                return;
              }

              const newValue = fromInputValue(newInputValue);

              if (autocompleteItems) {
                handleAutoComplete(newValue);
              }

              onChange(newValue);

              if (inputPropOnChange) {
                inputPropOnChange(e);
              }
            }}
            readOnly={readonly}
            type={(password ? 'password' : 'text')}
            id={htmlID}
            className={`${(readonly ? 'readonly' : '')} form-control`}
            {...autoCompleteProps}
          />

          <label htmlFor={htmlID}>{label}</label>

          {currentAutocompleteItems &&
          <ul className="mdb-autocomplete-wrap" onClick={autocompleteItemSelected}>
            {currentAutocompleteItems.map((x, i) => (
              (i === selectedIndex ? (
                // @ts-ignore
                <li key={x.value} dataid={x.value} className="selected" ref={onSelectedRef}>{x.label}</li>) : (
                // @ts-ignore
                <li key={x.value} dataid={x.value}>{x.label}</li>))
            ))}
          </ul>}

          {errorMessages && !focused && <ErrorMessages errors={errorMessages} style={{marginTop: '.3rem'}}/>}
        </div>
      )}
    </OutsideAlerter>
  );
});

export const TextField = wrapField(TextInput);
