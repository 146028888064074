import React, { FunctionComponent, useState, memo } from 'react';
import Select from 'react-select';

import { SelectItem } from './select-item';
import { wrapField, cssClasses, BaseInputProps } from './field-helpers';
import { ErrorMessages } from '../errors';

import './SelectInput.scss';

interface Props extends BaseInputProps<any> {
  label: string;
  items: SelectItem[] | undefined;
  icon?: string;
  nullable?: boolean;
  defaultValue?: StoredType;
  defaultValueOnSelectedItemRemoval?: boolean;
  searchable?: boolean;
  isLoading?: boolean;
}

type InputType = SelectItem | undefined;
type StoredType = any | null;

const getDefaultValue = ({nullable, items}: Props): StoredType => {
  if (nullable) {
    return null;
  } else {
    if (items && items.length) {
      return items[0].value;
    } else {
      return null;
    }
  }
};

const getInitialValue = (props: Props): StoredType => {
  if (props.defaultValue !== undefined) {
    return props.defaultValue;
  } else {
    return getDefaultValue(props);
  }
};

const EMPTY_SELECTION_KEY = '__EMPTY_SELECTED_VALUE__';

const getEmptySelection = (): SelectItem => ({label: '-', value: EMPTY_SELECTION_KEY});

const toInputValue = ({items, defaultValueOnSelectedItemRemoval}: Props, value: StoredType): InputType => {
  if (value === null || !items) {
    return getEmptySelection();
  } else {
    const selectedItems = items.filter((x) => x.value === value);
    if (!selectedItems.length) {
      if (defaultValueOnSelectedItemRemoval) {
        return getEmptySelection();
      } else {
        throw new Error(`Value '${value}' does not exist in the items array.`);
      }
    }
    return selectedItems[0];
  }
};

const fromInputValue = (inputValue: InputType): StoredType => {

  let newValue: StoredType;

  if (inputValue && inputValue.value !== EMPTY_SELECTION_KEY) {
    newValue = inputValue.value;
  } else {
    newValue = null;
  }

  return newValue;
};

const getItems = (value: StoredType, {items, nullable}: Props) => {

  if (nullable || value === null) {
    return [getEmptySelection(), ...(items || [])];
  }

  return items;
};

export const SelectInput: FunctionComponent<Props> = memo((props: Props) => {

  const {
    label, icon, readonly, nullable, style, className, onChange,
    setTouched, errorMessages, isLoading, searchable, customRef,
  } = props;

  let value = props.value;

  // Initialize
  if (value === undefined) {
    value = getInitialValue(props);
    setTimeout(() => onChange(value), 0);
  }

  // default when the value is not found in the items.
  if (props.items) {
    const selectedItems = props.items.filter((x) => x.value === value);
    if (value !== null && !selectedItems.length && props.defaultValueOnSelectedItemRemoval) {
      value = getInitialValue({...props, items: []});
      setTimeout(() => onChange(value), 0);
    }
  }

  const [focused, setFocused] = useState(false);

  const active = value !== null || (!readonly && focused);

  const cssStates = cssClasses({
    readonly,
    invalid: !!errorMessages && !focused,
    active,
    focused,
  });

  if (customRef) {
    customRef(props);
  }

  return (
    <div className={`select-input custom-form-input form-group ${className} ${cssStates}`} style={style}>

      {icon && <i className={`fa fa-${icon} prefix`}/>}

      <div className="select-wrapper">
        <Select
          options={getItems(value, props)}
          noOptionsMessage={() => '-'}
          isClearable={nullable}
          isSearchable={!!searchable}
          isLoading={isLoading}
          onChange={(inputValue: any) => {
            if (!readonly) {

              // to prevent flickering - when you clear the input
              // for a moment there are validation errors but the input is not yet focused
              // and they are shown an then hidden very quickly.
              setFocused(true);

              const newValue = fromInputValue(inputValue);
              onChange(newValue);
            }
          }}
          value={toInputValue(props, value)}
          onBlur={(_) => {
            if (setTouched) {
              setTouched();
            }
            setFocused(false);
          }}
          onFocus={() => {
            setFocused(true);
          }}
        />
      </div>

      <label>{label}</label>

      {errorMessages && !focused && <ErrorMessages errors={errorMessages}/>}
    </div>
  );
});

export const SelectField = wrapField(SelectInput);
