import {
  ActionCreatorsMapObject,
  AnyAction,
  applyMiddleware,
  bindActionCreators,
  combineReducers,
  compose,
  createStore,
  Dispatch,
  Reducer,
  ReducersMapObject,
  Store,
  Action,
} from 'redux';
import { Task } from '@redux-saga/types';
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga';
import { cancel, fork, put, take } from 'redux-saga/effects';

import { configureStatePersistence, getPersistedState } from './redux-persist';
import { errorsActionCreators } from '../errors.state';
import { ExtendedError } from '../errors';

import { AppContextFactory, AppContext } from '../context';

export interface AllActionCreators {
}

export interface ReduxState {
}

export type ReduxStore = Store<ReduxState>;

export interface SagaObject {
  actionType: string;
  saga: (action: AnyAction, context: AppContext) => IterableIterator<any>;
  failedActionCreator?: (error: any) => Action;
}

export interface CancellableSagaObject extends SagaObject {
  cancelActionType: string;
  canceledActionCreator: () => Action;
}

export interface SagaMap {
  [key: string]: SagaObject | CancellableSagaObject;
}

export interface StoreOptions {
  reducers: ReducersMapObject;
  sagas: SagaMap;
  persistentFields: string[];
  middleware: any[];
}

/**
 * Runs the sagas.
 * Wraps them in try-catch.
 * Injects them with a 'context' object that gets resolved for each call.
 */
const configureSagas = (sagaMiddleware: SagaMiddleware<any>, sagas: SagaMap, getContext: AppContextFactory) => {

  const isCancellable = (sagaObj: SagaObject | CancellableSagaObject): sagaObj is CancellableSagaObject => {
    return !!(sagaObj as any).cancelActionType;
  };

  for (const [sagaName, sagaObject] of Object.entries(sagas)) {

    sagaMiddleware.run(function* () {

      const errorWrappedSaga = function* (action: AnyAction) {

        try {

          const context = getContext();

          yield sagaObject.saga(action, context);

        } catch (error) {

          const extendedError = new ExtendedError('An error occurred in saga: ' + sagaName, error, {
            sagaName,
            action,
          });

          yield put(errorsActionCreators.setError(extendedError));

          if (sagaObject.failedActionCreator) {
            yield put(sagaObject.failedActionCreator([error.message]));
          }
        }
      };

      while (true) {

        const action: AnyAction = yield take(sagaObject.actionType);

        let canceledTask: any;

        const workTask: Task = yield fork(function* () {

          yield errorWrappedSaga(action);

          if (canceledTask) {
            yield cancel(canceledTask);
          }
        });

        if (isCancellable(sagaObject)) {

          canceledTask = yield fork(function* () {
            yield take(sagaObject.cancelActionType);
            yield cancel(workTask);
            yield put(sagaObject.canceledActionCreator());
          });
        }
      }
    });
  }
};

/**
 * Wrapper around `combineReducers` from Redux.
 * Logs errors thrown from the reducers.
 */
const configureReducers = (reducerMap: ReducersMapObject): Reducer => {

  const newReducerMap = Object.entries(reducerMap)
    .map(([reducerName, reducerFunction]) => [
      reducerName, (state: any, action: AnyAction) => {
        try {
          return reducerFunction(state, action);
        } catch (error) {
          throw new ExtendedError('An error occurred in reducer: ' + reducerName, error, {
            reducerName,
            reducerInvokingAction: action,
            reducerState: state,
          });
        }
      }])
    .map((pair) => ({[pair[0] as any]: pair[1]}))
    .reduce((prev, next) => Object.assign(prev, next), {}) as ReducersMapObject;

  return combineReducers(newReducerMap);
};

/**
 * Creates and configures the store.
 */
export const configureStore = (options: StoreOptions, getContext: AppContextFactory): ReduxStore => {

  const devTools = window.__REDUX_DEVTOOLS_EXTENSION__ || (() => ((x: any) => x));

  const sagaMiddleware = createSagaMiddleware();

  const enhancer = compose(
    applyMiddleware(
      sagaMiddleware,
      ...options.middleware,
    ),
    devTools(),
  );

  const store = createStore(
    configureReducers(options.reducers),
    getPersistedState(),
    enhancer,
  ) as ReduxStore;

  configureSagas(
    sagaMiddleware,
    options.sagas,
    getContext,
  );

  configureStatePersistence(store, options);

  return store;
};

/**
 * Wrapper around `bindActionCreators` that is hard-coding the actionCreator's name to `actions`
 */
export const wrapActions = (...actionCreators: ActionCreatorsMapObject<any>[]) => (dispatch: Dispatch) => ({
    actions: actionCreators.map((actionCreator) =>
      bindActionCreators(actionCreator, dispatch))
      .reduce((x, y) => Object.assign(x, y), {}) as any,
  }
);
