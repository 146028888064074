import React from 'react';
import {AppContextProps} from '../../infrastructure/react-context';
import {BaseComponent} from '../../infrastructure/components/BaseComponent';
import {newDateWithoutTime, format} from '../../infrastructure/util';
import LoadingIndicator from '../../infrastructure/components/LoadingIndicator';
import {OperationCabinetDto, GrOperationDto} from '../../dto';
import './OperationPlanningList.scss';
import {OperationPlanningNewEditModal} from './OperationPlanningNewEditModal';
import {DateInput} from '../../infrastructure/fields/DateInput';

interface Props extends AppContextProps {
}

interface State {
  loading: boolean;
  workDate: Date;
  operationCabinets: OperationCabinetDto[] | undefined;
  operations: GrOperationDto[] | undefined;
  RowMaps: Map<number, Map<number, number>> | undefined;

  isEditOperation: boolean;
  grOperationID: number | undefined;
}

export class OperationPlanningList extends BaseComponent<Props, State> {

  state: State = {
    loading: false,
    workDate: newDateWithoutTime(),
    operationCabinets: undefined,
    operations: undefined,
    RowMaps: undefined,

    isEditOperation: false,
    grOperationID: undefined,
  };

  componentDidMountAsync = async () => {
    this.generateRowMaps();
    await this.refreshPlannings();
  };

  getRowMinuteMap = (minute: number, currentRow: number) => {
    if (minute < 15) {
      return currentRow;
    } else if (minute < 30) {
      return currentRow + 1;
    } else if (minute < 45) {
      return currentRow + 2;
    }

    return currentRow + 3;
  };

  generateRowMaps = () => {
    const maps = new Map();
    let currentRow = 2;
    for (let hour = 8; hour < 22; hour++) {
      const minutes = new Map();
      maps.set(hour, minutes);
      for (let min = 0; min <= 59; min++) {
        const row = this.getRowMinuteMap(min, currentRow);
        minutes.set(min, row);
      }

      currentRow = minutes.get(59);
      currentRow++;
    }

    this.setState({RowMaps: maps});
  };

  refreshPlannings = async () => {

    const {server, actions} = this.props.context;

    await this.setStateAsync({loading: true});

    const responseCabinets = await server.getOperationCabinets({});
    if (!responseCabinets.success) {

      actions.errors.setErrorMessages(responseCabinets.errorMessages);
      await this.setStateAsync({loading: false});

      return;
    }

    const responseOperations = await server.getOperations({workDate: this.state.workDate});
    if (!responseOperations.success) {

      actions.errors.setErrorMessages(responseOperations.errorMessages);
      await this.setStateAsync({loading: false});

      return;
    }

    await this.setStateAsync({
      loading: false,
      operations: responseOperations.payload.operations,
      operationCabinets: responseCabinets.payload.operationCabinets,
    });
  };

  getOperationColumn = (op: GrOperationDto) => {
    if (!this.state.operationCabinets) {
      return -1;
    }

    const index = this.state.operationCabinets.findIndex((c) => c.grCabinetID === op.grCabinetID);

    return index + 2;
  };

  getOperationRow = (dateTime: Date) => {
    const hour = dateTime.getHours();
    const min = dateTime.getMinutes();

    const {RowMaps} = this.state;
    if (!RowMaps) {
      return -1;
    }

    const minutesMap = RowMaps.get(hour);
    if (!minutesMap) {
      return -1;
    }

    return minutesMap.get(min);
  };

  newOperation = () => {
    this.setState({isEditOperation: true, grOperationID: undefined});
  };

  editOperation = (grOperationID: number) => {
    this.setState({
      isEditOperation: true,
      grOperationID,
    });
  };

  render() {
    const {operations, operationCabinets, loading, isEditOperation, grOperationID} = this.state;

    const hours = [];
    for (let h = 8, i = 2; h < 22; h++) {
      hours.push(<h2 key={h + ':' + i} className="time-slot" style={{gridRow: i++}}>{h + ':00'}</h2>);
      hours.push(<h2 key={h + ':' + i} className="time-slot" style={{gridRow: i++}}>{h + ':15'}</h2>);
      hours.push(<h2 key={h + ':' + i} className="time-slot" style={{gridRow: i++}}>{h + ':30'}</h2>);
      hours.push(<h2 key={h + ':' + i} className="time-slot" style={{gridRow: i++}}>{h + ':45'}</h2>);
    }

    return (
      <div className="operation-planning-page">
        <div className="card-header blue white-text">Оперативно планиране</div>
        <div className="card" style={{marginTop: '0rem', padding: '20px'}}>
          <div className="row">
            <div className="col col-sm-4"><DateInput
              name="workDate"
              label=""
              value={this.state.workDate}
              onChange={async (value) => {
                await this.setStateAsync({
                  workDate: value || newDateWithoutTime(),
                });
                await this.refreshPlannings();
              }}
            /></div>
            <div className="col col-sm-4">
              <button className="btn btn-default"
                      type="button"
                      onClick={this.refreshPlannings}
                      style={{width: '100%', marginTop: '1.5rem'}}
              >ОПРЕСНИ
              </button>
            </div>
            <div className="col col-sm-4">
              <button className="btn btn-secondary"
                      type="button"
                      onClick={this.newOperation}
                      style={{width: '100%', marginTop: '1.5rem'}}
              >НОВО ОПЕРАТИВНО ПЛАНИРАНЕ
              </button>
            </div>
          </div>

          {!loading && <div className="schedule" aria-labelledby="schedule-heading">

            {!!operationCabinets && operationCabinets.map((cab, index) => (
              <span key={cab.grCabinetID} className="track-slot"
                    style={{gridColumn: index + 2, gridRow: 'tracks'}}>{cab.grCabinetName}</span>
            ))}

            {!!operationCabinets && operationCabinets.length > 0 && hours}

            {!!operationCabinets && operationCabinets.length > 0 && !!operations && operations.map((op) => (
              <div className="operation"
                   key={op.grOperationID}
                   style={{
                     gridColumn: this.getOperationColumn(op),
                     gridRow: `${this.getOperationRow(op.grStartDateTime)} / ${this.getOperationRow(op.grEndDateTime)}`,
                   }}>
                <a onClick={() => this.editOperation(op.grOperationID)}>
                  <h3
                    className="operation--time">{format(op.grStartDateTime, 'HH:mm')} - {format(op.grEndDateTime, 'HH:mm')}</h3>
                  <div className="operation--patient">{op.grOperationPatientName}</div>
                  <div className="operation--doctor">{op.operatorDoctorName}</div>
                  <div className="operation--note">{op.grDescription}</div>
                </a>
              </div>
            ))}

          </div>}
        </div>

        {isEditOperation && <OperationPlanningNewEditModal
            operationID={grOperationID}
            onClose={(success) => {
              this.setState({isEditOperation: false});
              if (success) {
                this.refreshPlannings();
              }
            }}
            context={this.props.context}/>}

        {!loading && (!operationCabinets || operationCabinets.length === 0) &&
        <h2>Няма конфигурирани операционни зали</h2>}

        <div>
          {loading && <LoadingIndicator/>}
        </div>
      </div>
    );
  }
}
