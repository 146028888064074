import React, {Fragment} from 'react';
import {BaseComponent} from '../infrastructure/components/BaseComponent';
import {DocumentTitle} from '../infrastructure/DocumentTitle';
import {GetPromotionsResponse} from '../dto';
import {AppContextProps} from '../infrastructure/react-context';
import LoadingIndicator from '../infrastructure/components/LoadingIndicator';
import {RouteComponentProps} from 'react-router-dom';
import {ConfirmationModal} from './ConfirmationModal';
import {ErrorMessages} from '../infrastructure/errors';

interface RouteParams {
  promotionGuid: string;
}

interface Props extends RouteComponentProps<RouteParams>, AppContextProps {
}

interface State {
  model: GetPromotionsResponse | undefined;
  loading: boolean;
  errorMessages: string[];
  showConfirmationModal: boolean;
  shouldSend: boolean;
  selectedPromotionDetailID: number;
}

const initialState: State = {
  model: undefined,
  loading: true,
  errorMessages: [],
  showConfirmationModal: false,
  shouldSend: true,
  selectedPromotionDetailID: 0,
};

export const promotionDetailsStatus = ['Няма статус', 'Нов', 'Раздаден', 'Използван', 'Невалиден'];

export class PromotionList extends BaseComponent<Props, State> {

  state: State = initialState;

  componentDidMountAsync = async () => {
    await this.loadExistingItem();
  };

  loadExistingItem = async () => {
    const {server, actions} = this.props.context;
    const promotionGuid = this.props.match.params.promotionGuid;

    const resultResponse = await server.getPromotions({promotionGuid});

    if (!resultResponse.success) {
      actions.errors.setErrorMessages(resultResponse.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }
    this.setState(
      {
        model: resultResponse.payload,
        loading: false,
      });
  };

  render() {
    const title = 'Ваучери';

    const {
      model,
      errorMessages,
    } = this.state;

    const list = this.state.model?.items;
    const hasExpired = this.state.model?.hasExpired;

    return (
      <div className="promotions-list-page">

        <DocumentTitle title={title}/>

        <div className="card">
          <div className="card-header blue white-text">
            {hasExpired &&
            <div className="danger-color text-center mb-2">Валидността на промоцията е изтекла</div>}
            {title}
          </div>
          <div className="ml-2 mt-2 mr-2" style={{padding: '10px', border: '1px solid black'}}>
            <div className="mb-4"><b>Контрагент: {model?.jPartnerName}</b></div>
            <div className="mb-1" style={{whiteSpace: 'pre-wrap'}}><b>Промоция:</b> {model?.promotionName}</div>
            <div style={{whiteSpace: 'pre-wrap'}}><b>Описание: </b>{model?.promotionDescription}</div>
          </div>
          <div className="ml-3 mt-2">
            Бр. ваучери: <b>{list?.length}</b>,
            раздадени: <b>{model?.countDistributed}</b>,
            използвани: <b>{model?.countUsed}</b>,
            невалидни: <b>{model?.countInvalid}</b>
          </div>
          <div className="card-body">

            {this.state.loading && <LoadingIndicator/>}

            <Fragment>
              {errorMessages && !!errorMessages.length && <div className="row">
                  <div className="col-sm-12">
                      <ErrorMessages errors={errorMessages}/>
                      <hr/>
                  </div>
              </div>}
              {hasExpired && <div className="text-center font-weight-bold mt-4">Останали ваучери: </div>}
              <div className="table-responsive" style={{marginTop: '2%'}}>
                <table className="table list-table">
                  <thead className="blue text-white">
                  <tr>
                    <th>Код</th>
                    <th/>
                    <th/>
                  </tr>
                  </thead>
                  {!!list?.length && <tbody>
                  {list.filter((x) => x.promotionStatus === 1).map((item) => (
                    <tr key={item.promotionDetailID}>
                      <td style={{width: '15%'}}>{item.promotionCode}</td>
                      <td style={{width: '5%'}}>
                        {!hasExpired &&
                          <button
                            type="button"
                            style={{width: '100%'}}
                            className="btn btn-md btn-success"
                            onClick={() => {
                              this.setState({
                                showConfirmationModal: true,
                                selectedPromotionDetailID: item.promotionDetailID,
                                shouldSend: true,
                              });
                            }}>
                            Изпрати
                          </button>}
                      </td>
                      <td style={{width: '5%'}}>
                        {!hasExpired &&
                        <button
                            type="button"
                            style={{width: '100%'}}
                            className="btn btn-md btn-info"
                            onClick={() => {
                              this.setState({
                                showConfirmationModal: true,
                                selectedPromotionDetailID: item.promotionDetailID,
                                shouldSend: false,
                              });
                            }}>
                            Маркирай
                        </button>}
                      </td>
                    </tr>
                  ))}
                  </tbody>}
                </table>
              </div>
              <hr/>
              <div className="mt-2 text-center font-weight-bold">Раздадени и използвани</div>
              <div className="table-responsive mt-2">
                <table className="table list-table">
                  <thead className="blue text-white">
                  <tr>
                    <th>Код</th>
                    <th>Бележка</th>
                    <th>Статус</th>
                  </tr>
                  </thead>
                  {!!list?.length && <tbody>
                  {list.filter((x) => x.promotionStatus !== 1).map((item) => (
                    <tr key={item.promotionDetailID}>
                      <td>{item.promotionCode}</td>
                      <td>{item.sendToNote}</td>
                      <td>{promotionDetailsStatus[item.promotionStatus]}</td>
                    </tr>
                  ))}
                  </tbody>}
                </table>
              </div>
              {this.state.showConfirmationModal && <ConfirmationModal
                  shouldSend={this.state.shouldSend}
                  promotionDetailID={this.state.selectedPromotionDetailID}
                  context={this.props.context}
                  onClose={() => this.setState({showConfirmationModal: false})}
                  onSaved={async () => await this.loadExistingItem()}
              />}
            </Fragment>
          </div>
        </div>
      </div>
    );
  }
}
