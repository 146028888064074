import React from 'react';
import { Link } from 'react-router-dom';
import { format, newDateWithoutTime } from '../../infrastructure/util';

import { AppContextProps } from '../../infrastructure/react-context';
import { BaseComponent } from '../../infrastructure/components/BaseComponent';
import { CustomForm } from '../../infrastructure/components/CustomForm';
import { DateField } from '../../infrastructure/fields/DateInput';
import LoadingIndicator from '../../infrastructure/components/LoadingIndicator';
import { DocumentTitle } from '../../infrastructure/DocumentTitle';
import { serverValidations, GetDoctorJRequestsRequest, PatientRecordDto } from '../../dto';
import { TextField } from '../../infrastructure/fields/TextInput';
import { SelectField } from '../../infrastructure/fields/SelectInput';

interface Props extends AppContextProps {
}

interface State {
  filter: GetDoctorJRequestsRequest | undefined;
  listItems: PatientRecordDto[] | undefined;
  loading: boolean;
  firstQuery: boolean;
}

const defaultFilterState: GetDoctorJRequestsRequest = {
  fromDate: newDateWithoutTime(),
  toDate: newDateWithoutTime(),
  barcode: undefined,
  patientRecordFilter: 1,
};

export class LabResultsList extends BaseComponent<Props, State> {

  state: State = {
    loading: false,
    filter: undefined,
    listItems: undefined,
    firstQuery: false,
  };

  async searchItems() {

    const {server, actions} = this.props.context;

    await this.setStateAsync({loading: true, listItems: []});

    if (!this.state.filter) {
      throw new Error('The filter is falsy.');
    }

    const response = await server.getDoctorJRequests(this.state.filter);

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    await this.setStateAsync({loading: false, listItems: response.payload.items});
  }

  async getCachedFilter() {
    const {server, actions} = this.props.context;

    await this.setStateAsync({loading: true});

    const response = await server.getSessionInfo({});

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    const filter = response.payload.state.getDoctorJRequestsRequest || defaultFilterState;

    await this.setStateAsync({loading: false, filter});
  }

  async saveFilterCache() {
    const {server, actions} = this.props.context;

    await this.setStateAsync({loading: true});

    const response = await server.saveSessionInfo({state: {getDoctorJRequestsRequest: this.state.filter}});

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    await this.setStateAsync({loading: false});
  }

  componentDidMountAsync = async () => {
    await this.getCachedFilter();
    await this.searchItems();
  };

  submit = async (filter: GetDoctorJRequestsRequest) => {
    await this.setStateAsync({filter});
    await this.searchItems();
    await this.saveFilterCache();

    await this.setStateAsync({
      firstQuery: true,
    });
  };

  render() {
    const {listItems, loading, firstQuery} = this.state;
    const validations = serverValidations.getDoctorJRequestsRequest;
    return (
      <div className="lab-results-list-page">

        <DocumentTitle title="Заявки - Картотека"/>

        {this.state.filter && <CustomForm values={this.state.filter} onSubmit={this.submit} render={(_) => (
          <div className="card">
            <div className="card-header blue white-text">
              Заявки - Картотека
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-sm-12 col-md-4">
                  <DateField
                    name="fromDate"
                    label="От дата"
                    validation={validations.fromDate}
                  />
                </div>
                <div className="col-sm-12 col-md-4">
                  <DateField
                    name="toDate"
                    label="До дата"
                    validation={validations.toDate}
                  />
                </div>

                <div className="col-sm-12 col-md-4">
                  <TextField
                    name="barcode"
                    label="Баркод"
                    validation={validations.barcode}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6 col-md-4">
                  <TextField
                    name="patientFirstNameBeginsWith"
                    label="Име:"
                  />
                </div>
                <div className="col-sm-6 col-md-4">
                  <TextField
                    name="patientSecondNameBeginsWith"
                    label="Презиме:"
                  />
                </div>
                <div className="col-sm-6 col-md-4">
                  <TextField
                    name="patientLastNameBeginsWith"
                    label="Фамилия:"
                  />
                </div>
              </div>

              <div className="row">

                <div className="col-sm-12 col-md-8" style={{marginTop: '1.1rem'}}>
                  <div className="col-sm-12 vertical-center">
                    <button className="btn btn-md btn-default" style={{width: '100%'}} type="submit">
                      Обнови
                    </button>
                  </div>
                </div>

                <div className="col-sm-12 col-md-4">
                  <SelectField
                    items={[
                      {label: 'Без резултат', value: 1},
                      {label: 'С резултат', value: 2},
                    ]}
                    nullable
                    name="patientRecordFilter"
                    label="По резултат:"
                  />
                </div>
              </div>

              {(firstQuery || (listItems && !!listItems.length)) &&
              <div className="table-responsive" style={{marginTop: '1rem'}}>
                <table className="table list-table">
                  <thead className="blue text-white">
                    <tr>
                      <th>JRequestID</th>
                      <th>PatientID</th>
                      <th>Пациент</th>
                      <th>Дата на регистрацията</th>
                      <th>Дата на резултата</th>
                      <th>Баркод</th>
                      <th>Има изследване</th>
                      <th/>
                      <th/>
                      <th/>
                    </tr>
                  </thead>

                  {listItems && listItems.length > 0 && <tbody>
                    {listItems.map((item) => (
                      <tr key={item.jRequestID}>
                        <td>#{item.jRequestID || ' -'}</td>
                        <td>{item.patientID}</td>
                        <td>{item.patientFullName}</td>
                        <td className="no-wrap">{format(item.dateJRequestDateTime, 'dd.MM.yyyy')}</td>
                        <td className="no-wrap">
                          {(item.datePatientRecDateTime ? format(item.datePatientRecDateTime, 'dd.MM.yyyy') : '-')}
                        </td>
                        <td>{item.materialBarcodes}</td>
                        <td>{(item.hasPatientRecord ? 'Да' : 'Не')} </td>

                        <td>
                          <Link
                            style={{width: '100%'}}
                            to={`/doctor/doctor-results/${item.patientID}`}
                            className="btn btn-sm btn-info">
                            Досие
                          </Link>
                        </td>

                        {!item.hasPatientRecord && <td><Link
                          style={{width: '100%'}}
                          to={`/doctor/lab-results/${item.jRequestID}`}
                          className="btn btn-sm btn-success">
                          Нов
                        </Link></td>}

                        {item.hasPatientRecord && <td><Link
                          style={{width: '100%'}}
                          to={`/doctor/lab-results/${item.jRequestID}`}
                          className="btn btn-sm btn-warning">
                          Редакция
                        </Link></td>}

                        <td>
                          {item.hasPatientRecord && <Link
                            to={`/doctor/lab-results/${item.jRequestID}/print`}
                            className="btn btn-sm btn-info">
                            Преглед
                          </Link>}
                        </td>
                      </tr>
                    ))}
                  </tbody>}
                </table>
              </div>}

              {firstQuery && !loading && listItems && !listItems.length &&
              <div style={{margin: '1rem'}}>
                <h3 style={{color: 'black', textAlign: 'center'}}>Не са намерени резултати за това търсене.</h3>
              </div>}

              {loading && <LoadingIndicator/>}

            </div>
          </div>
        )}/>}
      </div>
    );
  }
}
