import React, { FunctionComponent, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { SessionState } from '../session.state';
import { CacheState } from '../cache.state';
import { OutsideAlerter } from '../components/OutsideAlerter';
import { RenderPermissionOptions, shouldRender } from '../render-permissions';
import { ReduxState } from '../redux/store';

import './NavDropdown.scss';

/* Имплементация на навигиращ линк, меню */
export interface NavLinkItem extends RenderPermissionOptions {
  name: string; // наименование
  link: string; // линк
  icon?: string;  // иконка
  color?: string; // цвят
}

interface Props {
  text: string;
  onLinkClick: () => void;
  items: NavLinkItem[];
  session: SessionState;
  cache: CacheState;
}

const NavDropdownImpl: FunctionComponent<Props> = ({text, onLinkClick, items, session, cache}) => {

  const [isOpen, setOpen] = useState(false);

  const onOutsideClick = useCallback(() => setOpen(false), []);

  const onClick = () => {
    setOpen(false);
    onLinkClick();
  };

  const enabledItems = items.filter((x) => shouldRender(x, session, cache));

  if (!enabledItems.length) {
    return null;
  }

  return (
    <div className="custom-navbar-dropdown">
      <OutsideAlerter registered={isOpen} onOutsideClick={onOutsideClick}>
        {(ref: any) => (
          <li className={`nav-item dropdown ${(isOpen ? 'show' : '')}`} ref={ref}>
            <a className="nav-link dropdown-toggle" role="button" data-toggle="dropdown"
               onClick={() => setOpen(!isOpen)}>
              {text}
            </a>
            <div className={`dropdown-menu ${(isOpen ? 'show' : '')}`}>
              {enabledItems.map((x, i) => (
                <NavLink
                  key={i}
                  to={x.link}
                  className="nav-link"
                  onClick={onClick}>
                  {x.name}
                </NavLink>
              ))}

            </div>
          </li>
        )}
      </OutsideAlerter>
    </div>
  );
};

export const NavDropdown = connect(({session, cache}: ReduxState) => ({session, cache}))(NavDropdownImpl);
