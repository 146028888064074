import React, { Fragment } from 'react';

import BackButton from '../../infrastructure/components/BackButton';
import { BaseComponent } from '../../infrastructure/components/BaseComponent';
import { PatientInformationDto, CytologyResultDto } from '../../dto';
import { AppContextProps } from '../../infrastructure/react-context';
import LoadingIndicator from '../../infrastructure/components/LoadingIndicator';
import { DocumentTitle } from '../../infrastructure/DocumentTitle';

interface Props extends AppContextProps {
  patientRecordID: number;
  onSuccessfulDelete?: () => void;
}

interface State {
  loading: boolean;
  submitLoading: boolean;

  model: CytologyResultDto | undefined;
  patient: PatientInformationDto | undefined;
}

export class CytologyResultDelete extends BaseComponent<Props, State> {

  state: State = {
    loading: true,
    submitLoading: false,

    model: undefined,
    patient: undefined,
  };

  getItemID = (): number => this.props.patientRecordID;

  componentDidMountAsync = async () => {

    await this.getItem(this.getItemID());
  };

  async getItem(patientRecordID: number) {

    const {server, actions} = this.props.context;

    const response = await server.getCytologyResult({patientRecordID});

    if (!response.success) {
      await this.setStateAsync({loading: false});
      actions.errors.setErrorMessages(response.errorMessages);
      return;
    }

    const model = response.payload.item;

    const patientResponse = await server.patientInformation({patientID: model.patientID});

    if (!patientResponse.success) {
      await this.setStateAsync({loading: false});
      actions.errors.setErrorMessages(patientResponse.errorMessages);
      return;
    }

    const patient = patientResponse.payload.item;

    await this.setStateAsync({
      loading: false,
      model,
      patient,
    });
  }

  deleteItem = async () => {

    const {server, actions} = this.props.context;

    await this.setStateAsync({submitLoading: true});

    const patientRecordID = this.getItemID();

    const response = await server.deleteCytologyResult({patientRecordID});

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({submitLoading: false});
      return;
    }

    await this.setStateAsync({submitLoading: false});

    if (this.props.onSuccessfulDelete) {
      this.props.onSuccessfulDelete();
    } else {
      actions.router.routerPush('/doctor/cytology-results/');
    }
  };

  render() {

    const {model, loading, submitLoading, patient} = this.state;

    return (
      <div className="cytology-results-delete-page">

        <DocumentTitle title="Цитологичен резултат - Изтриване"/>

        <div className="card">
          <div className="card-header red white-text">Цитологичен резултат - Изтриване</div>
          <div className="card-body text-center">

            {(loading || submitLoading) && <LoadingIndicator delay={0}/>}

            {!loading && !submitLoading && model && patient && <Fragment>
              <h3 style={{marginTop: '2rem'}}>
                {patient.patientFullName} ЕГН: {patient.patientIdentNumber}, {patient.patientAge} г.
              </h3>

              <h3 style={{marginTop: '2rem'}}>Цитологичен резултат №: {model.patientRecID}</h3>

              <hr/>

              <h3 style={{marginTop: '2rem'}}>Сигурни ли сте, че искате да изтриете този резултат?</h3>

              <div className="button-group" style={{marginTop: '2rem'}}>
                <button className="btn btn-md btn-danger" onClick={this.deleteItem}>Изтриване</button>
                <BackButton render={(props) =>
                  <a {...props} className="btn btn-md btn-warning">
                    Назад
                  </a>
                }/>
              </div>
            </Fragment>}
          </div>
        </div>
      </div>
    );
  }
}
