import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { BaseComponent } from '../../infrastructure/components/BaseComponent';
import { AppContextProps } from '../../infrastructure/react-context';
import { AttachedResultDto, PatientInformationDto } from '../../dto';
import LoadingIndicator from '../../infrastructure/components/LoadingIndicator';
import { AttachedResultForm } from './AttachedResultForm';
import { DocumentTitle } from '../../infrastructure/DocumentTitle';

import './attached-results.scss';

interface RouterParams {
  patientRecID: string;
}

interface Props extends RouteComponentProps<RouterParams>, AppContextProps {
}

interface State {
  loading: boolean;

  model: AttachedResultDto | undefined;
  patient: PatientInformationDto | undefined;

  errorMessages: string[];
  submitLoading: boolean;
}

export class AttachedResultEdit extends BaseComponent<Props, State> {

  state: State = {
    loading: true,

    model: undefined,
    patient: undefined,

    errorMessages: [],
    submitLoading: false,
  };

  componentDidMountAsync = async () => {
    await this.loadItem();
  };

  loadItem = async () => {

    const {server, actions} = this.props.context;

    const patientRecID = Number.parseInt(this.props.match.params.patientRecID, 10);

    const resultResponse = await server.getAttachedResult({patientRecID, includeFileContents: false});

    if (!resultResponse.success) {
      actions.errors.setErrorMessages(resultResponse.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    const model = resultResponse.payload.item;

    const patientResponse = await server.patientInformation({patientID: model.patientID});

    if (!patientResponse.success) {
      actions.errors.setErrorMessages(patientResponse.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    const patient = patientResponse.payload.item;

    await this.setStateAsync({
      loading: false,
      patient,
      model,
    });
  };

  saveItem = async (model: AttachedResultDto) => {

    if (!model) {
      throw new Error('The model submitted from the form is undefined.');
    }

    const {server, actions} = this.props.context;

    await this.setStateAsync({model, submitLoading: true});

    const response = await server.saveAttachedResult({item: model});

    if (!response.success) {
      await this.setStateAsync({submitLoading: false, errorMessages: response.errorMessages});
      return;
    }

    await this.setStateAsync({submitLoading: false});
    actions.router.routerPush(`/doctor/attached-results/${response.payload.patientRecordID}/print`);
  };

  render() {

    const {loading, submitLoading, model, patient, errorMessages} = this.state;

    return (
      <div className="attached-results-edit-page">
        <DocumentTitle title="Прикачени резултати - Редакция"/>

        <div className="card">
          <div className="card-header blue white-text">Прикачени резултати - Редакция</div>
          <div className="card-body">
            {loading && <LoadingIndicator delay={0}/>}
            {model && patient && <AttachedResultForm
              model={model}
              patient={patient}
              errorMessages={errorMessages}
              onSubmit={this.saveItem}
              submitLoading={submitLoading}
            />}
          </div>
        </div>
      </div>
    );
  }
}
