import React, { FunctionComponent, useCallback, useMemo, CSSProperties } from 'react';
import { Field, FieldProps } from 'formik';

export type ValidationFunction = (val: any) => string[];

// subtract this type
export interface BaseInputProps<TStored> {

  value: TStored;
  onChange: (value: TStored) => void;
  touched?: boolean;
  setTouched?: () => void;
  errorMessages?: string[];

  style?: CSSProperties;
  readonly?: boolean;
  className?: string;
  name: string;
  customRef?: (selfProps: any) => void;
}

type InferStoredType<TOwnProps> = TOwnProps extends BaseInputProps<infer TT> ? TT : never;

export type FieldHandle<TOwnProps> = TOwnProps & { setValue: (val: InferStoredType<TOwnProps>) => void };

// and add this one
interface AdditionalFieldProps<TOwnProps> {
  validation?: ValidationFunction | ValidationFunction[];

  style?: CSSProperties;
  readonly?: boolean;
  className?: string;
  name: string;

  onChange?: (value: any) => void;
  customRef?: (selfProps: FieldHandle<TOwnProps>) => void;
}

type WrappedProps<TOwnProps> = Omit<TOwnProps, keyof BaseInputProps<any>> & AdditionalFieldProps<TOwnProps>;

// tslint:disable-next-line:only-arrow-functions
export const wrapField = function <TOwnProps>(FieldImpl: FunctionComponent<TOwnProps>) {

  return ((props: WrappedProps<TOwnProps>) => {

    const {validation, name, className, ...rest} = props;

    const validationFunctions = validation ? (Array.isArray(validation) ? validation : [validation]) : [];

    const validate = (val: any) => {
      const newValidationErrors = validationFunctions.map((x) => x(val)).flat().filter((x) => x);
      return newValidationErrors.length ? newValidationErrors : undefined;
    };

    return (
      <Field validate={validate} name={name}>
        {({field, form}: FieldProps<any>) => {
          const value = getObjValue(field.name, form.values);
          const onChange = (val: any) => {
            form.setFieldValue(field.name, val);
            if (rest.onChange) {
              rest.onChange(val);
            }
          };

          const setValue = (val: any) => {
            form.setFieldValue(field.name, val, false);
            form.setFieldTouched(field.name, val, false);
          };

          const touched = getObjValue(field.name, form.touched);
          const setTouched = (val: boolean) => form.setFieldTouched(field.name, val, false);

          let errorMessages: string[] | undefined = getObjValue(field.name, form.errors) || [] as string[];

          if ((!errorMessages || !errorMessages.length) || props.readonly || !touched) {
            errorMessages = undefined;
          }

          const myProps: any = {
            ...rest,
            value,
            onChange: useCallback(onChange, [value]),
            setValue: useCallback(setValue, [value]),
            touched,
            setTouched: useCallback(setTouched, [value]),
            errorMessages: useMemo(() => errorMessages, [(errorMessages ? errorMessages.join('|') : undefined)]),
            name,
            className: (className || ''),
          };

          return <FieldImpl {...myProps} />;
        }}
      </Field>
    );
  });
};

export const getObjValue = (key: string, obj: any): any =>
  key.split('.')
    .reduce((x, y) => (x ? x[y] : undefined), obj);

export const cssClasses = (param: { [key: string]: any }) =>
  Object.entries(param)
    .filter((x) => x[0] && x[1])
    .map((x) => x[0])
    .join(' ');
