import React, { FunctionComponent, useState, memo, useEffect } from 'react';

import { wrapField, cssClasses, BaseInputProps } from '../infrastructure/fields/field-helpers';
import { ErrorMessages } from '../infrastructure/errors';

import './DoctorSelectInput.scss';
import DoctorSelectModal from './DoctorSelectModal';
import { DoctorCatalogDto } from '../dto';
import { withAppContext, AppContextFactoryProps } from '../infrastructure/react-context';

type StoredType = number | null;

interface Props extends BaseInputProps<StoredType>, AppContextFactoryProps {
  label: string;
  icon?: string;
  nullable?: boolean;
}

const getDefaultValue = () => null;

export const DoctorSelectInput: FunctionComponent<Props> = memo((props: Props) => {

  const {label, readonly, icon, style, setTouched, onChange, errorMessages, nullable, className, getContext} = props;

  let value = props.value;

  // Initialize
  if (value === undefined) {
    value = getDefaultValue();
    setTimeout(() => onChange(value), 0);
  }

  const [displayName, setDisplayName] = useState<string>('');
  const [openModal, setOpenModal] = useState<boolean>(false);

  const active = !!value;

  const cssStates = cssClasses({
    readonly,
    invalid: !!errorMessages,
    active,
    nullable,
  });

  const onClear = () => {
    onChange(null);
    if (setTouched) {
      setTouched();
    }
    setDisplayName('');
  };

  const onSelection = (doctor: DoctorCatalogDto) => {
    setDisplayName(doctor.doctorFirstLastName);
    onChange(doctor.doctorID);
    if (setTouched) {
      setTouched();
    }
    setOpenModal(false);
  };

  useEffect(() => {
    (async () => {
      if (!value) {
        return;
      }

      const {server, actions} = getContext();

      const result = await server.doctorCatalog({doctorID: value as number});

      if (result.success) {
        setDisplayName(result.payload.items[0].doctorFirstLastName);
      } else {
        actions.errors.setErrors(result.errorMessages);
      }
    })();
  }, []);

  return (
    <div className={`doctor-select-input custom-form-input form-group ${className} ${cssStates}`} style={style}>
      {icon && <i className={`fa fa-${icon} prefix`}/>}
      <div className="box-wrapper">
        <div className="select-wrapper" onClick={() => {
          if (readonly) {
            return;
          }
          setOpenModal(true);
        }}>
          <div className="display-name">{displayName}</div>
        </div>
        {active && nullable && !readonly && <span className="btn btn-default" onClick={onClear}>✘</span>}
        {!readonly && <span className="btn btn-success" onClick={() => setOpenModal(true)}>Избери</span>}
      </div>
      <label>{label}</label>
      {errorMessages && <ErrorMessages errors={errorMessages}/>}
      {openModal && <DoctorSelectModal
        onClose={() => setOpenModal(false)}
        onSelection={onSelection}
      />}
    </div>
  );
});

export const DoctorSelectField = withAppContext(wrapField(DoctorSelectInput));
