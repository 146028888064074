import { LazyComponent } from '../LazyComponent';
import { retryPromise } from '../util';

export const EditorField = LazyComponent(() => retryPromise(() =>
  import(/* webpackChunkName: "editor-field" */'./EditorInputImpl')
    .then((x) => ({default: x.EditorField}))));

export const EditorInput = LazyComponent(() => retryPromise(() =>
  import(/* webpackChunkName: "editor-field" */'./EditorInputImpl')
    .then((x) => ({default: x.EditorInput}))));
