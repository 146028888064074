import React, { FunctionComponent, memo, useState, useRef, ChangeEvent } from 'react';
import { wrapField, cssClasses, BaseInputProps } from './field-helpers';
import { ErrorMessages } from '../errors';

import './CheckboxInput.scss';

type InputType = boolean | undefined;
type StoredType = boolean | null;

export interface CheckboxInputProps extends BaseInputProps<StoredType> {
  label: string;
  nullable?: boolean;
  defaultValue?: StoredType;
}

const getDefaultValue = ({nullable}: CheckboxInputProps): StoredType => {
  if (nullable) {
    return null;
  } else {
    return false;
  }
};

const getInitialValue = (props: CheckboxInputProps): StoredType => {
  if (props.defaultValue !== undefined) {
    return props.defaultValue;
  } else {
    return getDefaultValue(props);
  }
};

const toInputValue = (value: StoredType): InputType => value === null ? undefined : value;

export const CheckboxInput: FunctionComponent<CheckboxInputProps> = memo((props: CheckboxInputProps) => {

  const {label, readonly, style, onChange, setTouched, errorMessages, className, customRef} = props;

  if (customRef) {
    customRef(props);
  }

  let value = props.value;

  // Initialize
  if (value === undefined) {
    value = getInitialValue(props);
    setTimeout(() => onChange(value), 0);
  }

  const active = value !== null;

  const [focused, setFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>();

  const cssStates = cssClasses({
    readonly,
    invalid: !!errorMessages,
    active,
    focused,
  });

  const labelClick = () => {

    if (readonly) {
      return;
    }

    const newValue = !value;

    onChange(newValue);
    if (setTouched) {
      setTouched();
    }
    setFocused(true);

    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const onInputChanged = (e: ChangeEvent<HTMLInputElement>) => {

    if (readonly) {
      return;
    }

    onChange(e.target.checked);

    if (setTouched) {
      setTouched();
    }

    setFocused(true);
  };

  return (
    <div className={`checkbox-input custom-form-input form-group ${className} ${cssStates}`} style={style}>
      <input
        checked={toInputValue(value)}
        readOnly
        className={`form-control`}
        type="checkbox"
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        ref={(x) => inputRef.current = x}
        onChange={onInputChanged}
      />

      <label onClick={labelClick}>{label}</label>

      {errorMessages && <ErrorMessages errors={errorMessages}/>}

    </div>
  );
});

export const CheckboxField = wrapField(CheckboxInput);
