import { uuid } from './util';

export interface TabComMessage {
  type: string;
  payload: any;
}

export interface TabComSubscriber {
  func: (message: TabComMessage) => void;
  type?: string;
}

export interface TabCom {
  subscribe: (func: (message: TabComMessage) => void, type?: string) => void;
  sendMessage: (message: TabComMessage) => void;

  close(): void;
}

export const createTabCom = (storeKey: string = 'tab-com-message'): TabCom => {

  const subscribers: TabComSubscriber[] = [];

  const onStorage = (e: StorageEvent) => {

    if (e.storageArea !== localStorage || e.key !== storeKey) {
      return;
    }

    const message = JSON.parse(e.newValue || '{}') as TabComMessage;

    for (const subscriber of subscribers) {

      if (subscriber.type) {
        if (subscriber.type === message.type) {
          subscriber.func(message);
        }
      } else {
        subscriber.func(message);
      }
    }
  };

  window.addEventListener('storage', onStorage);

  let closed = false;

  return {
    subscribe(func: (message: TabComMessage) => void, type?: string): void {

      if (closed) {
        throw new Error('The tab-com is closed.');
      }

      subscribers.push({type, func});
    },
    sendMessage(message: TabComMessage): void {

      if (closed) {
        throw new Error('The tab-com is closed.');
      }

      localStorage.setItem(storeKey, JSON.stringify({
        ...message,
        __id: uuid(),
      }));
    },
    close(): void {
      window.removeEventListener('storage', onStorage);
      closed = true;
    },
  };
};
