import React, {Fragment} from 'react';
import {AppContextProps} from '../../infrastructure/react-context';
import {BaseComponent} from '../../infrastructure/components/BaseComponent';
import {StudyArchiveStatus, studyArchiveStatusEnumMap} from '../Viewer/DicomViewer';
import {ErrorMessages} from '../../infrastructure/errors';
import {JDateTime} from '../../public/JDateTime';
import {PatientImagingDto} from '../../dto';
import {Link} from 'react-router-dom';
import {DicomProcessButton} from '../DicomHelper';

interface Props extends AppContextProps {
  model: PatientImagingDto;
}

interface State {
  studyArchiveStatus: StudyArchiveStatus | undefined;
  errorMessages: string[];
  dicomDownloadID: number;
}

export class PatientImagingCard extends BaseComponent<Props, State> {
  state: State = {
    studyArchiveStatus: undefined,
    errorMessages: [],
    dicomDownloadID: 0,
  };

  async componentDidMountAsync(): Promise<void> {
    this.refreshJStudyArchiveStatus();
  }

  refreshJStudyArchiveStatus = async () => {
    if (!this.props.model.studyInstanceUID) {
      return;
    }

    await this.setStateAsync({errorMessages: []});
    const {server} = this.props.context;

    const archiveStatusResp = await server.getPatientDicomJStudyArchiveStatus({studyInstanceUID: this.props.model.studyInstanceUID});
    if (!archiveStatusResp.success) {
      await this.setStateAsync({errorMessages: archiveStatusResp.errorMessages});
      return;
    }

    await this.setStateAsync({
      studyArchiveStatus: studyArchiveStatusEnumMap[archiveStatusResp.payload.statusID],
      dicomDownloadID: archiveStatusResp.payload.dicomDownloadID,
    });
  };

  render() {
    const {
      studyArchiveStatus,
      errorMessages,
      dicomDownloadID,
    } = this.state;
    const {model} = this.props;
    const headerText = model.type === 1 ? `${model.patientRecShortName} | ${JDateTime.toJoystickDateFormat(model.patientRecDateTime)}` :
      `DICOM изображения | ${JDateTime.toJoystickDateFormat(model.scheduledProcedureDateTime)} | ${model.modality} | ${model.description} `;
    const borderColor = model.type === 1 ? 'info' : 'warning';

    return (
      <Fragment>
        <div key={model.studyInstanceUID} className={`card border border-${borderColor}`}>
          <div className="card-header d-flex flex-wrap p-1 pl-2">
            <h5 className="align-self-center">{headerText}</h5>
            <div className="ml-auto">
              <DicomProcessButton dicomDownloadID={dicomDownloadID} context={this.props.context}
                                  studyInstanceUID={model.studyInstanceUID} status={studyArchiveStatus!}
                                  onForProcessing={async () => await this.setStateAsync({studyArchiveStatus: 'Processing'})}
                                  onError={async (errors) => await this.setStateAsync({errorMessages: errors})}/>
              {model.type === 1 &&
                  <Link className="btn btn-md btn-primary m-0" to={`/patient/results/patrec${model.patientRecID}`}>
                    ПРЕГЛЕД НА РАЗЧИТАНЕТО
                  </Link>
              }
            </div>
          </div>

          {model.type === 1 &&
              <div className="card-body">
                {model.patientFullName}
              </div>
          }
        </div>

        <ErrorMessages className="mb-2" errors={errorMessages}/>

      </Fragment>
    );
  }
}
