import React from 'react';
import {AppContextProps} from '../infrastructure/react-context';
import {BaseComponent} from '../infrastructure/components/BaseComponent';
import {ErrorMessages} from '../infrastructure/errors';
import LoadingIndicator from '../infrastructure/components/LoadingIndicator';
import axios from 'axios';

interface Props extends AppContextProps {
  downloadID: number;
  downloadKey: string;
}

interface State {
  loading: boolean;
  errorMessages: string[];
}

export class DownloadStudyArchive extends BaseComponent<Props, State> {
  state: State = {
    loading: false,
    errorMessages: [],
  };

  async componentDidMountAsync(): Promise<void> {
    await this.setStateAsync({loading: true});
    const {server} = this.props.context;

    const response = await server.downloadDicomJStudyArchive({dicomDownloadID: this.props.downloadID});
    if (!response.success) {
      await this.setStateAsync({errorMessages: response.errorMessages, loading: false});
      return;
    }

    axios({
      method: 'get',
      url: response.payload.dicomEndpoint,
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    })
      .then(async ({data: blob}) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = response.payload.archiveName + '.zip';
        link.click();
        await this.setStateAsync({loading: false});
      });
  }

  render() {
    const {loading, errorMessages} = this.state;

    return (
      <div className="download-dicom-jstudy-wrapper">

        {!!errorMessages.length &&
            <ErrorMessages errors={errorMessages}/>}

        {loading && <LoadingIndicator delay={0}/>}

      </div>
    );
  }
}
