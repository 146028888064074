import React, {MouseEvent} from 'react';
import {DicomInstancePreview, DicomJStudyCM} from '../../dto';
import {AppContextProps} from '../../infrastructure/react-context';
import {BaseComponent} from '../../infrastructure/components/BaseComponent';
import cornerstone from 'cornerstone-core';
import * as helpers from './Cornerstone/CornerstoneHelpers';
import {CornerstoneTools, CornerstoneTool, CornerstoneToolStack} from './Cornerstone/CornerstoneTools';
import {LoadingIndicator} from '../LoadingIndicator/LoadingIndicator';
import {ErrorMessages} from '../../infrastructure/errors';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner';
import {CustomForm} from '../../infrastructure/components/CustomForm';
import BackButton from '../../infrastructure/components/BackButton';
import {EmailForStudyArchiveModal} from '../EmailForStudyArchiveModal';
// @ts-ignore
import cornerstoneTools from 'cornerstone-tools';

import './DicomViewer.scss';
import axios from 'axios';

// Информация за имплементацията е взета от:
// https://docs.cornerstonejs.org/concepts/image-loaders.html
// https://tools.cornerstonejs.org/
// https://github.com/cornerstonejs/react-cornerstone-viewport
// https://github.com/cornerstonejs/cornerstoneWADOImageLoader

export type StudyArchiveStatus = 'None' | 'Processing' | 'Ready' | 'ForDelete' | 'Failed';

export const studyArchiveStatusEnumMap: { [key: number]: StudyArchiveStatus } = {
  0: 'None',
  1: 'Processing',
  2: 'Ready',
  3: 'ForDelete',
  4: 'Failed',
};

interface Props extends AppContextProps {
  studyInstanceUID: string;
}

interface State {
  imageIds: string[];
  imageIndex: number;
  scale: number | undefined;
  windowWidth: number | undefined;
  windowCenter: number | undefined;
  rotationDegrees: number | undefined;
  loadingPreviewImages: boolean;
  errorMessages: string[];
  loadingNewImage: boolean;
  cornerstoneElement: HTMLElement | null;
  previewImages: DicomInstancePreview[];
  model: DicomJStudyCM | undefined;
  archivesErrorMessages: string[];
  studyArchiveStatus: StudyArchiveStatus | undefined;
  dicomDownloadID: number;
  showEmailModal: boolean;
  loadingNewArchive: boolean;
  isDownloading: boolean;
  percentDownloaded: number | undefined;
}

export class DicomViewer extends BaseComponent<Props, State> {
  state: State = {
    imageIds: [''],
    imageIndex: 0,
    scale: undefined,
    windowCenter: undefined,
    windowWidth: undefined,
    rotationDegrees: undefined,
    loadingPreviewImages: true,
    errorMessages: [],
    loadingNewImage: true,
    cornerstoneElement: null,
    previewImages: [],
    model: undefined,
    archivesErrorMessages: [],
    studyArchiveStatus: undefined,
    dicomDownloadID: 0,
    showEmailModal: false,
    loadingNewArchive: false,
    isDownloading: false,
    percentDownloaded: undefined,
  };

  async componentDidMountAsync(): Promise<void> {
    await this.refreshJStudyArchiveStatus();

    const {server} = this.props.context;

    const resp = await server.getDicomInstances({studyInstanceUID: this.props.studyInstanceUID});
    if (!resp.success) {
      await this.setStateAsync({
        errorMessages: resp.errorMessages,
        loadingNewImage: false,
        loadingPreviewImages: false,
      });
      return;
    }

    await this.setStateAsync({
      imageIds: resp.payload.wadoURIs,
      previewImages: resp.payload.dicomInstancePreviews,
      loadingPreviewImages: false,
    });
    await this.initCornerstone();
  }

  initCornerstone = async () => {
    const element = document.getElementById('cornerstone-element');
    if (element === null) {
      return;
    }

    cornerstone.enable(element);
    await this.setStateAsync({cornerstoneElement: element});
    await this.changeDicomImage(0);
    this.bindInternalElementEventListeners(element);

    const stack: CornerstoneToolStack = {currentImageIdIndex: 0, imageIds: this.state.imageIds};
    cornerstoneTools.addStackStateManager(element, ['stack']);
    cornerstoneTools.addToolState(element, 'stack', stack);

    CornerstoneTools.map((x) => {
      const toolName = `${x.name}Tool`;
      const cornerstoneTool = cornerstoneTools[toolName];
      cornerstoneTools.addToolForElement(element, cornerstoneTool);
    });
  };

  changeDicomImage = async (imageIndex: number, event?: MouseEvent) => {
    const {cornerstoneElement} = this.state;
    if (!cornerstoneElement) {
      return;
    }

    if (event) {
      const images = document.querySelectorAll('.dicom-viewer-PreviewImage');
      images.forEach((img) => {
        img.classList.remove('active-image-preview');
      });
      event.currentTarget.classList.add('active-image-preview');
    }

    await this.setStateAsync({imageIndex, loadingNewImage: true});

    const image = await cornerstone.loadAndCacheImage(this.state.imageIds[imageIndex]);
    cornerstone.displayImage(cornerstoneElement, image);
    cornerstone.reset(cornerstoneElement);
  };

  bindInternalElementEventListeners(element: HTMLElement, clear: boolean = false) {
    const addOrRemoveEventListener = clear ? 'removeEventListener' : 'addEventListener';
    element[addOrRemoveEventListener](cornerstone.EVENTS.IMAGE_RENDERED, this.onImageRendered);
  }

  activateTool = async (toolName: string, event: MouseEvent) => {
    const buttons = document.querySelectorAll('.tool-button');
    buttons.forEach((btn) => {
      btn.classList.remove('active-tool');
    });
    event.currentTarget.classList.add('active-tool');

    cornerstoneTools.setToolActive(toolName, {mouseButtonMask: 1});
  };

  onImageRendered = async (event: any) => {
    const viewport = event.detail.viewport;

    await this.setStateAsync({
      scale: viewport.scale,
      windowCenter: viewport.voi.windowCenter,
      windowWidth: viewport.voi.windowWidth,
      rotationDegrees: viewport.rotation,
    });

    await this.setStateAsync({loadingNewImage: false});
  };

  setJStudyArchiveForProcessing = async (downloadEmail?: string) => {
    await this.setStateAsync({archivesErrorMessages: [], loadingNewArchive: true});
    const {server} = this.props.context;

    const response = await server.setDoctorDicomJStudyArchiveForProcessing({
      studyInstanceUID: this.props.studyInstanceUID,
      downloadEmail,
    });
    if (!response.success) {
      await this.setStateAsync({archivesErrorMessages: response.errorMessages});
      return;
    }

    await this.setStateAsync({studyArchiveStatus: 'Processing'});
  };

  downloadJStudyArchive = async () => {
    await this.setStateAsync({archivesErrorMessages: [], isDownloading: true});
    const {server} = this.props.context;

    const response = await server.downloadDicomJStudyArchive({dicomDownloadID: this.state.dicomDownloadID});
    if (!response.success) {
      await this.setStateAsync({archivesErrorMessages: response.errorMessages});
      return;
    }

    axios({
      method: 'get',
      url: response.payload.dicomEndpoint,
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      onDownloadProgress: async (e) => {
        await this.setStateAsync({percentDownloaded: Math.floor(e.loaded / e.total! * 100)});
      },
    })
      .then(async ({data: blob}) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = response.payload.archiveName + '.zip';
      link.click();
      await this.setStateAsync({isDownloading: false});
    });
  };

  refreshJStudyArchiveStatus = async () => {
    await this.setStateAsync({archivesErrorMessages: []});
    const {server} = this.props.context;

    const archiveStatusResp = await server.getDoctorDicomJStudyArchiveStatus({studyInstanceUID: this.props.studyInstanceUID});
    if (!archiveStatusResp.success) {
      await this.setStateAsync({archivesErrorMessages: archiveStatusResp.errorMessages});
      return;
    }

    await this.setStateAsync({
      studyArchiveStatus: studyArchiveStatusEnumMap[archiveStatusResp.payload.statusID],
      dicomDownloadID: archiveStatusResp.payload.dicomDownloadID,
    });
  };

  ClearToolState = async () => {
    const {cornerstoneElement} = this.state;
    if (!cornerstoneElement) {
      return;
    }

    CornerstoneTools.map((x) => {
      cornerstoneTools.clearToolState(cornerstoneElement, x.name);
    });
    cornerstone.reset(cornerstoneElement);
  };

  render() {
    const {
      scale,
      windowWidth,
      windowCenter,
      imageIndex,
      imageIds,
      loadingPreviewImages,
      errorMessages,
      loadingNewImage,
      previewImages,
      archivesErrorMessages,
      studyArchiveStatus,
      loadingNewArchive,
      showEmailModal,
      isDownloading,
      percentDownloaded,
    } = this.state;

    const zoomPercentage = helpers.formatNumberPrecision(scale ? scale * 100 : 0, 0);

    const imageId = imageIds[imageIndex];

    const imagePlaneModule = cornerstone.metaData.get('imagePlaneModule', imageId) || {};
    const {rows, columns} = imagePlaneModule;

    // const seriesMetadata = cornerstone.metaData.get('generalSeriesModule', imageId) || {};
    // const { seriesNumber } = seriesMetadata;

    const generalStudyModule = cornerstone.metaData.get('generalStudyModule', imageId) || {};
    const {studyDate, studyTime} = generalStudyModule;

    const patientModule = cornerstone.metaData.get('patientModule', imageId) || {};
    const {patientId, patientName} = patientModule;

    const generalImageModule = cornerstone.metaData.get('generalImageModule', imageId) || {};
    const {instanceNumber} = generalImageModule;

    const wwwcW = windowWidth?.toFixed ? windowWidth.toFixed(0) : windowWidth;
    const wwwcL = (!windowWidth?.toFixed ? windowCenter : windowCenter?.toFixed(0));

    const imageDimensions = `${columns} x ${rows}`;

    const stackSize = this.state.imageIds.length;

    return (
      <div className="dicom-viewer-wrapper">

        {!!errorMessages.length &&
            <ErrorMessages className="mb-2" errors={errorMessages}/>}

        <div className="row">
          <div className="col-lg-8">
            <div className="d-flex flex-wrap bg-dark">
              {CornerstoneTools.filter((x) => x.type === 'Main')
                .map((x: CornerstoneTool, i) => (
                  <button key={i} className="btn btn-sm tool-button" type="button"
                          onClick={(event) => this.activateTool(x.name, event)}>{x.name}
                  </button>
                ))}

              <p className="mb-0 ml-auto">
                <button className="btn btn-sm btn-outline-success tool-button-more-instruments"
                        type="button" data-toggle="collapse"
                        data-target="#collapseOtherInstruments"
                        aria-expanded="false"
                        aria-controls="collapseOtherInstruments">
                  ОЩЕ ИНСТРУМЕНТИ
                </button>
              </p>
              <div className="collapse p-0" id="collapseOtherInstruments">
                {CornerstoneTools.filter((x) => x.type === 'Additional')
                  .map((x: CornerstoneTool, i) => (
                    <button key={i} className="btn btn-sm tool-button" type="button"
                            onClick={(event) => this.activateTool(x.name, event)}>{x.name}
                    </button>
                  ))}
              </div>
            </div>

            <div className="d-flex flex-wrap pl-3 pr-3 pt-1 dicom-image-info">
              <div className="flex-column">
                <div className="mb-1">
                  <span className="text-white">Пациент: </span>
                  <span className="text-info">{patientName ? helpers.formatPN(patientName) : '-'}</span>
                </div>
                <div>
                  <span className="text-white">ID: </span>
                  <span className="text-info">{patientId ? patientId : '-'}</span>
                </div>
              </div>
              <div className="ml-auto">
                <span className="text-white">Дата на прегледа: </span>
                <span
                  className="text-info">{studyDate && studyTime ? `${helpers.formatDA(studyDate)} ${helpers.formatTM(studyTime)}` : '-'}
                </span>
              </div>
            </div>

            <div id="cornerstone-element">
              {loadingNewImage &&
                  <LoadingIndicator showLoadingText={true} style={{position: 'absolute', zIndex: 1}}/>}
            </div>

            <div className="d-flex flex-wrap pl-3 pr-3 pb-2 bottom-nav">
              <div className="flex-column">
                <div className="mb-1">
                  <span className="text-white">Zoom:</span> <span className="text-info">{zoomPercentage}%</span>
                </div>
                <div>
                  <span className="text-white">W: </span><span className="text-info">{wwwcW}</span>
                  <span className="text-danger"> | </span>
                  <span className="text-white">L: </span><span className="text-info">{wwwcL}</span>
                </div>
              </div>

              <div className="flex-column text-info">
                <div className="mb-1">
                  <div>
                    <span className="text-white">Img: </span>
                    <span>{instanceNumber} {imageIndex + 1}/{stackSize}</span>
                  </div>
                </div>
                <div>
                  <span className="text-white">Dimensions: </span>
                  <span className="text-info">{imageDimensions}</span>
                </div>
              </div>
            </div>

            {/*<div className="d-flex remove-instruments-button justify-content-center align-items-center">*/}
            {/*  <ConfirmModalButton*/}
            {/*    modalDescription={'Сигурни ли сте, че искате да изтриете всички инструменти за текущата снимка?'}*/}
            {/*    onConfirm={this.ClearToolState}*/}
            {/*    btnClassName={'btn-danger'}*/}
            {/*    btnText={'ПРЕМАХНИ ИЗПОЛЗВАНИТЕ ИНСТРУМЕНТИ ЗА ТЕКУЩАТА СНИМКА'}/>*/}
            {/*</div>*/}
          </div>

          <div className="col-lg-4 mt-2 mt-lg-0">
            <div className="card h-100 bg-dark bg-gradient">
              <div className="card-header p-1 d-flex border-bottom border-info">
                <BackButton render={(props) =>
                  <a {...props}
                     className="btn btn-md btn-warning m-0">
                    Назад
                  </a>
                }/>
                <div className="ml-auto">
                  {
                    (() => {
                      if (studyArchiveStatus === 'None') {
                        return <button
                          type="submit"
                          disabled={loadingNewArchive || loadingPreviewImages}
                          className="btn btn-md btn-info m-0"
                          onClick={async () => await this.setStateAsync({showEmailModal: true})}>
                          Създай архив {loadingNewArchive && <FontAwesomeIcon icon={faSpinner} spin size="lg"/>}
                        </button>;
                      }
                      if (studyArchiveStatus === 'Ready') {
                        return <CustomForm onSubmit={this.downloadJStudyArchive} render={() => (
                          <button type="submit" disabled={isDownloading} className="btn btn-md m-0 btn-success">
                            {isDownloading ?
                              <span style={{fontSize: '.9rem'}}>{percentDownloaded}% <FontAwesomeIcon icon={faSpinner} spin size="lg"/></span> :
                              <span>Изтегли архива</span>
                            }
                          </button>
                        )}/>;
                      }
                      if (studyArchiveStatus === 'Processing') {
                        return <button type="button" disabled={true} className="btn btn-md m-0 btn-info">
                          Архива се обработва <FontAwesomeIcon icon={faSpinner} spin size="lg"/>
                        </button>;
                      }
                      if (studyArchiveStatus === 'Failed') {
                        return <button type="button" disabled={true} className="btn btn-md m-0 btn-danger">
                          Грешка с архива <FontAwesomeIcon icon={faSpinner} spin size="lg"/>
                        </button>;
                      }
                    })()
                  }
                </div>
              </div>
              <div className="card-body dicom-viewer-PreviewImages">
                {!!archivesErrorMessages.length && <ErrorMessages className="mt-2" errors={archivesErrorMessages}/>}
                {loadingPreviewImages && <LoadingIndicator showLoadingText={true}/>}

                {!loadingPreviewImages && previewImages &&
                  previewImages.map((x, i) => (
                    <div key={i} className="dicom-preview-container">
                      <img
                        src={`data:image/jpeg;base64,${x.imagePreview}`}
                        alt="..."
                        className="dicom-viewer-PreviewImage"
                        onClick={async (e) => await this.changeDicomImage(i, e)}/>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>

        {showEmailModal &&
            <EmailForStudyArchiveModal
                onClose={async () => {
                  await this.setStateAsync({showEmailModal: false});
                  await this.setJStudyArchiveForProcessing();
                }}
                onConfirm={async (downloadEmail) => {
                  await this.setStateAsync({showEmailModal: false});
                  await this.setJStudyArchiveForProcessing(downloadEmail);
                }}
            />
        }
      </div>
    );
  }
}
