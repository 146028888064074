/* tslint:disable:max-classes-per-file */

import React, {Fragment} from 'react';
import {FormikProps} from 'formik';

import {AppContextFactoryProps, withAppContext, AppContextProps} from '../infrastructure/react-context';
import {
  PatientInformationDto,
  SearchPatientsRequest,
  NewPatientDto,
  serverValidations,
} from '../dto';
import {BaseComponent} from '../infrastructure/components/BaseComponent';
import {CustomModal} from '../infrastructure/components/CustomModal';
import {CustomForm} from '../infrastructure/components/CustomForm';
import {NumberField} from '../infrastructure/fields/NumberInput';
import {TextField} from '../infrastructure/fields/TextInput';
import LoadingIndicator from '../infrastructure/components/LoadingIndicator';
import {ErrorMessages} from '../infrastructure/errors';
import {SelectField} from '../infrastructure/fields/SelectInput';
import {isValidEGN, getBirthdateFromEGN, getSexFromEGN} from '../infrastructure/fields/validations';
import {DateEditField} from '../infrastructure/dx/DateEdit';
import {SelectItem} from '../infrastructure/fields/select-item';
import {debounce} from '../infrastructure/util';

import './PatientSelectModal.scss';

interface Props extends AppContextFactoryProps {
  onSelection?: (patientItem: PatientInformationDto) => void | Promise<any>;
  onClose?: () => void;
}

interface State {
  filter: SearchPatientsRequest;
  listItems: PatientInformationDto[] | undefined;
  loading: boolean;
  firstQuery: boolean;
  showNewPatientDialog: boolean | undefined;
  showEditPatientDialog: boolean | undefined;
  patientID: number | undefined;
}

class PatientSelectModal extends BaseComponent<Props, State> {

  static defaultProps: Partial<Props> = {
    onSelection: (_) => {
    },
    onClose: () => {
    },
  };

  state: State = {
    loading: false,
    filter: {
      patientID: undefined,
      patientIdentNumberBeginsWith: undefined,
      patientFirstName: undefined,
      patientLastName: undefined,
      patientSecondName: undefined,
    },
    listItems: undefined,
    firstQuery: false,
    showNewPatientDialog: false,
    showEditPatientDialog: false,
    patientID: undefined,
  };

  async searchItems() {

    const {server, actions} = this.props.getContext();

    await this.setStateAsync({
      loading: true,
      listItems: [],
    });

    const response = await server.searchPatients(this.state.filter);

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    await this.setStateAsync({
      loading: false,
      listItems: response.payload.items,
    });
  }

  componentDidMountAsync = async () => {
    await this.searchItems();
  };

  submit = async (data: SearchPatientsRequest) => {
    await this.setStateAsync({filter: data});
    await this.searchItems();
    await this.setStateAsync({firstQuery: true});
  };

  render() {
    const {listItems, loading, firstQuery, showNewPatientDialog, showEditPatientDialog} = this.state;
    return (
      <CustomModal title="Моля, изберете пациент..." className="patients-modal"
                   onClose={() => this.props.onClose && this.props.onClose()}>
        <CustomForm
          values={this.state.filter} onSubmit={this.submit}
          render={({values}) => (
            <div className="card">
              <div className="card-header blue white-text">
                Пациенти
              </div>
              <div className="card-body results-card">
                <div className="font-italic">
                  *Ако пациента не е посещавал лабораторията преди, използвайте бутона 'НОВ ПАЦИЕНТ'.
                </div>
                <div className="row">
                  <div className="col-sm-6 col-md-2">
                    <NumberField
                      nullable
                      name="patientID"
                      label="ID:"
                    />
                  </div>
                  <div className="col-sm-6 col-md-2">
                    <TextField
                      name="patientIdentNumberBeginsWith"
                      label="ЕГН:"
                    />
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <TextField
                      name="patientFirstName"
                      label="Име:"
                    />
                  </div>
                  <div className="col-sm-6 col-md-2">
                    <TextField
                      name="patientSecondName"
                      label="Презиме:"
                    />
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <TextField
                      name="patientLastName"
                      label="Фамилия:"
                    />
                  </div>
                  <div className="col-sm-4 col-md-4 vertical-center">
                    <button className="btn btn-md btn-primary full-width" type="submit">
                      Обнови
                    </button>
                  </div>
                  <div className="col-sm-4 col-md-4 vertical-center">
                    <button className="btn btn-md btn-warning full-width" type="button"
                            onClick={() => {
                              if (this.props.onClose) {
                                this.props.onClose();
                              }
                            }}>
                      Назад
                    </button>
                  </div>
                  <div className="col-sm-4 col-md-4 vertical-center">
                    <button className="btn btn-md btn-success full-width" type="button"
                            onClick={() => this.setState({showNewPatientDialog: true})}>
                      +Нов пациент
                    </button>
                  </div>
                </div>

                {firstQuery &&
                <div className="table-responsive table-wrapper">
                    <table className="table list-table">
                        <thead className="blue text-white">
                        <tr>
                            <th/>
                            <th/>
                            <th>№</th>
                            <th>ЕГН</th>
                            <th>Име</th>
                            <th>Презиме</th>
                            <th>Фамилия</th>
                            <th>Телефон</th>
                        </tr>
                        </thead>
                        <tbody>
                        {listItems && listItems.map((item) => (
                          <Fragment>
                            <tr key={item.patientID}>
                              <td style={{width: '1%'}}>
                                <button
                                  className="btn btn-sm btn-warning"
                                  onClick={() => this.setState(
                                    {
                                      showEditPatientDialog: true,
                                      patientID: item.patientID,
                                    })}>
                                  Редакция
                                </button>
                              </td>
                              <td style={{width: '1%'}}>
                                <button
                                  className="btn btn-sm btn-success"
                                  onClick={async () => {
                                    if (this.props.onSelection) {
                                      await this.props.onSelection(item);
                                    }
                                  }}>
                                  Избери
                                </button>
                              </td>
                              <td>#{item.patientID}</td>
                              <td>{item.patientIdentNumber}</td>
                              <td>{item.patientFirstName}</td>
                              <td>{item.patientSecondName}</td>
                              <td>{item.patientLastName}</td>
                              <td>{item.patientMobile}</td>
                            </tr>
                          </Fragment>
                        ))}
                        </tbody>
                    </table>
                </div>}

                {firstQuery && !loading && listItems && !listItems.length &&
                <div style={{margin: '1rem'}}>
                    <h3 style={{color: 'black', textAlign: 'center'}}>Не са намерени резултати за това търсене.</h3>
                </div>}

                {loading && <LoadingIndicator/>}

              </div>

              {showNewPatientDialog && <CreatePatientModal
                  context={this.props.getContext()}
                  patientIdentNumber={values.patientIdentNumberBeginsWith}
                  onClose={() => this.setState({showNewPatientDialog: false})}
                  onSelection={(x) => {
                    if (this.props.onSelection) {
                      this.props.onSelection(x);
                    }
                  }}
              />}

              {showEditPatientDialog && <EditPatientModal
                  context={this.props.getContext()}
                  patientID={this.state.patientID}
                  onClose={() => this.setState({showEditPatientDialog: false})}
                  onEdit={async () => {
                    await this.searchItems();
                    this.setState({showEditPatientDialog: false});
                  }}
                  onSelection={async (x) => {
                    if (this.props.onSelection) {
                      await this.props.onSelection(x);
                    }
                  }}
              />}
            </div>
          )}/>
      </CustomModal>
    );
  }
}

export default withAppContext(PatientSelectModal);

interface CreatePatientModalProps extends AppContextProps {
  patientIdentNumber: string;
  onSelection: (patientItem: PatientInformationDto) => void | Promise<any>;
  onClose?: () => void;
}

interface CreatePatientModalState {
  model: NewPatientDto | undefined;
  loading: boolean;
  submitLoading: boolean;
  errorMessages: string[] | undefined;
  identTypes: SelectItem<number>[];
}

const sexTypes = [{value: 1, label: 'Мъж'}, {value: 2, label: 'Жена'}];

class CreatePatientModal extends BaseComponent<CreatePatientModalProps, CreatePatientModalState> {

  state: CreatePatientModalState = {
    loading: true,
    submitLoading: false,
    model: undefined,
    errorMessages: undefined,
    identTypes: [],
  };

  formHandle: FormikProps<any> | undefined = undefined;

  componentDidMountAsync = async () => {
    const {server, actions} = this.props.context;

    const result = await server.identTypes({});

    if (!result.success) {
      actions.errors.setErrorMessages(result.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    const included = [1, 2, 3];

    const identTypes = [
      ...result.payload.identTypes
        .filter((i) => included.includes(i.identTypeID))
        .map((i) => ({value: i.identTypeID, label: i.identTypeName})),
    ];

    await this.setStateAsync({identTypes});

    await this.loadNewItem();
  };

  loadNewItem = async () => {

    const {server, actions} = this.props.context;

    const result = await server.newPatient({});

    if (!result.success) {
      actions.errors.setErrorMessages(result.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    const {patientFirstName, patientSecondName, patientLastName, ...rest} = result.payload.item;

    const model = {
      patientFirstName1: patientFirstName,
      patientSecondName1: patientSecondName,
      patientLastName1: patientLastName,
      ...rest,
      patientIdentNumber: this.props.patientIdentNumber,
    } as any;

    await this.setStateAsync({
      model,
      loading: false,
    });

    if (this.props.patientIdentNumber) {
      await this.loadPatient(this.props.patientIdentNumber);
    }
  };

  saveItem = async (model: NewPatientDto) => {

    if (!model) {
      throw new Error('The model submitted from the form is undefined.');
    }

    const {server} = this.props.context;

    await this.setStateAsync({model, submitLoading: true});

    const {patientFirstName1, patientSecondName1, patientLastName1, ...rest} = model as any;

    const actualModel = {
      patientFirstName: patientFirstName1,
      patientSecondName: patientSecondName1,
      patientLastName: patientLastName1,
      ...rest,
    } as NewPatientDto;

    const response = await server.savePatient({item: actualModel});

    if (!response.success) {
      await this.setStateAsync({submitLoading: false, errorMessages: response.errorMessages});
      return;
    }

    await this.setStateAsync({submitLoading: false});

    this.props.onSelection(response.payload.item);
  };

  loadPatient = debounce(async (patientIdentNumber: string) => {
    const {server, actions} = this.props.context;
    const result = await server.getPatientForEdit({patientIdentNumber});
    if (!result.success) {
      actions.errors.setErrorMessages(result.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    if (!result.payload.item) {
      return;
    }

    if (this.formHandle) {

      const {patientFirstName, patientSecondName, patientLastName, ...rest} = result.payload.item;

      const model = {
        patientFirstName1: patientFirstName,
        patientSecondName1: patientSecondName,
        patientLastName1: patientLastName,
        ...rest,
      } as any;

      this.formHandle.setValues(model);
    }
  }, 300);

  render() {

    const {onClose} = this.props;
    const {model, loading, submitLoading, errorMessages} = this.state;
    const validations = serverValidations.newPatientDto;

    return (
      <CustomModal className="create-patient-modal" onClose={onClose} title="Нов пациент">

        {(loading || submitLoading) && <LoadingIndicator/>}

        {model && <CustomForm
            forwardedRef={(instance: any) => this.formHandle = instance}
            values={model} onSubmit={this.saveItem}
            render={({isSubmitting, values, setFieldValue, setFieldTouched}) => (
              <Fragment>

                <div className="row">

                  <div className="col-sm-6">
                    <SelectField
                      name="identTypeID"
                      label="Идентификация(ЕГН или ЛНЧ)"
                      items={this.state.identTypes}
                      validation={validations.identTypeID}
                      defaultValueOnSelectedItemRemoval
                      onChange={async (selectedIdentTypeID) => {
                        if (selectedIdentTypeID === 2 && isValidEGN(values.patientIdentNumber)) {
                          const birthDate = getBirthdateFromEGN(values.patientIdentNumber);
                          const sex = getSexFromEGN(values.patientIdentNumber);

                          setFieldValue('patientBirthDay', birthDate, false);
                          setFieldTouched('patientBirthDay', true, false);

                          setFieldValue('patientSex', sex, false);
                          setFieldTouched('patientSex', true, false);
                        }
                      }}
                    />
                  </div>

                  <div className="col-sm-6">
                    <TextField
                      name="patientIdentNumber"
                      label="ЕГН/ЛНЧ"
                      mask={/\d+/g}
                      validation={validations.patientIdentNumber}
                      onChange={async (egn) => {
                        if (values.identTypeID === 2 && isValidEGN(egn)) {
                          const birthDate = getBirthdateFromEGN(egn);
                          const sex = getSexFromEGN(egn);

                          setFieldValue('patientBirthDay', birthDate, false);
                          setFieldTouched('patientBirthDay', true, false);

                          setFieldValue('patientSex', sex, false);
                          setFieldTouched('patientSex', true, false);
                        }

                        await this.loadPatient(egn);
                      }}
                    />
                  </div>

                  <div className="col-sm-4">
                    <TextField
                      name="patientFirstName1"
                      label="Име"
                      validation={validations.patientFirstName}
                    />
                  </div>

                  <div className="col-sm-4">
                    <TextField
                      name="patientSecondName1"
                      label="Презиме"
                      validation={validations.patientSecondName}
                    />
                  </div>

                  <div className="col-sm-4">
                    <TextField
                      name="patientLastName1"
                      label="Фамилия"
                      validation={validations.patientLastName}
                    />
                  </div>

                  <div className="col-sm-6">
                    <SelectField
                      name="patientSex"
                      label="Пол"
                      items={sexTypes}
                      validation={validations.patientSex}
                      defaultValueOnSelectedItemRemoval
                    />
                  </div>

                  <div className="col-sm-6">

                    <DateEditField
                      name="patientBirthDay"
                      label="Дата на раждане"
                      validation={validations.patientBirthDay}
                    />
                  </div>

                  <div className="col-sm-6">
                    <TextField
                      name="patientMobile"
                      label="Телефон"
                      validation={validations.patientMobile}
                    />
                  </div>

                  <div className="col-sm-6">
                    <TextField
                      name="patientEmail"
                      label="Имейл"
                      validation={validations.patientEmail}
                    />
                  </div>

                  <div className="col-sm-6">
                    <TextField
                      name="patientNameEN"
                      label="Три имена на английски по ЛК"
                      validation={validations.patientNameEN}
                    />
                  </div>

                  <div className="col-sm-6">
                    <TextField
                      name="patientPersonalDocNumber"
                      label="Лична карта №"
                      validation={validations.patientPersonalDocNumber}
                    />
                  </div>
                </div>

                {errorMessages && !!errorMessages.length && <div className="row">
                    <div className="col-sm-12">
                        <ErrorMessages errors={errorMessages}/>
                        <hr/>
                    </div>
                </div>}

                <div className="text-center">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn btn-md btn-default">
                    Запази
                  </button>
                </div>
              </Fragment>
            )}/>}
      </CustomModal>
    );
  }
}

interface EditPatientModalProps extends AppContextProps {
  onSelection: (patientItem: PatientInformationDto) => void | Promise<any>;
  patientID: number | undefined;
  onClose?: () => void;
  onEdit: () => void;
}

interface EditPatientModalState {
  model: NewPatientDto | undefined;
  loading: boolean;
  submitLoading: boolean;
  errorMessages: string[] | undefined;
  identTypes: SelectItem<number>[];
  isConfirmed: boolean;
  showConfirmationMessage: boolean;
  isValidated: boolean;
}

class EditPatientModal extends BaseComponent<EditPatientModalProps, EditPatientModalState> {

  state: EditPatientModalState = {
    loading: true,
    submitLoading: false,
    model: undefined,
    errorMessages: undefined,
    identTypes: [],
    isConfirmed: false,
    showConfirmationMessage: false,
    isValidated: false,
  };

  formHandle: FormikProps<any> | undefined = undefined;

  componentDidMountAsync = async () => {
    const {server, actions} = this.props.context;

    const result = await server.identTypes({});

    if (!result.success) {
      actions.errors.setErrorMessages(result.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    const included = [1, 2, 3];

    const identTypes = [
      ...result.payload.identTypes
        .filter((i) => included.includes(i.identTypeID))
        .map((i) => ({value: i.identTypeID, label: i.identTypeName})),
    ];

    await this.setStateAsync({identTypes});

    await this.loadItem();
  };

  loadItem = async () => {

    const {server, actions} = this.props.context;

    const result = await server.getPatientForEdit({patientID: this.props.patientID, patientIdentNumber: ' '});

    if (!result.success) {
      actions.errors.setErrorMessages(result.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    if (result.payload.item === undefined) {
      return;
    }

    const {patientFirstName, patientSecondName, patientLastName, ...rest} = result.payload.item;

    const model = {
      patientFirstName1: patientFirstName,
      patientSecondName1: patientSecondName,
      patientLastName1: patientLastName,
      ...rest,
    } as any;

    await this.setStateAsync({
      model,
      loading: false,
    });
  };

  saveItem = async (model: NewPatientDto) => {

    if (!model) {
      throw new Error('The model submitted from the form is undefined.');
    }

    const {server} = this.props.context;

    await this.setStateAsync({model, submitLoading: true});

    const {patientFirstName1, patientSecondName1, patientLastName1, ...rest} = model as any;

    const actualModel = {
      patientFirstName: patientFirstName1,
      patientSecondName: patientSecondName1,
      patientLastName: patientLastName1,
      ...rest,
    } as NewPatientDto;

    if (!this.state.isValidated) {
      const check = await server.validatePatientInformation({item: actualModel});

      if (!check.success) {
        await this.setStateAsync({
          submitLoading: false,
          errorMessages: check.errorMessages,
          showConfirmationMessage: true,
        });

        return;
      }
    }

    const response = await server.savePatient({item: actualModel});

    if (!response.success) {
      await this.setStateAsync({submitLoading: false, errorMessages: response.errorMessages});
      return;
    }

    await this.setStateAsync({submitLoading: false});
    this.props.onEdit();
  };

  loadPatient = debounce(async (patientIdentNumber: string) => {
    const {server, actions} = this.props.context;
    const result = await server.getPatientForEdit({patientIdentNumber});
    if (!result.success) {
      actions.errors.setErrorMessages(result.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    if (!result.payload.item) {
      return;
    }

    if (this.formHandle) {

      const {patientFirstName, patientSecondName, patientLastName, ...rest} = result.payload.item;

      const model = {
        patientFirstName1: patientFirstName,
        patientSecondName1: patientSecondName,
        patientLastName1: patientLastName,
        ...rest,
      } as any;

      this.formHandle.setValues(model);
    }
  }, 300);

  render() {

    const {onClose} = this.props;
    const {model, loading, submitLoading, errorMessages, isConfirmed, showConfirmationMessage} = this.state;
    const validations = serverValidations.newPatientDto;

    return (
      <CustomModal
        className="edit-patient-modal"
        onClose={onClose}
        title={isConfirmed ? 'В режим редакция на пациент' : 'Редакция пациент'}
      >
        {!isConfirmed && <Fragment>
            <div className="row">
                <div className="col-sm-3">
                    <button
                        type="button"
                        className="btn btn-md btn-success full-width"
                        onClick={() => this.setState({isConfirmed: true})}>
                        Потвърди
                    </button>
                </div>
                <div className="col-sm-3">
                    <button
                        type="button"
                        className="btn btn-md btn-warning full-width"
                        onClick={this.props.onClose}>
                        Отказ
                    </button>
                </div>
            </div>
            <h3
                className="mt-3">
                Сигурни ли сте, че искате да редактирате този пациент? Това ще промени неговите данни.
            </h3>
        </Fragment>}

        {(loading || submitLoading) && <LoadingIndicator/>}

        {isConfirmed && model && <CustomForm
            forwardedRef={(instance: any) => this.formHandle = instance}
            values={model} onSubmit={this.saveItem}
            render={({isSubmitting, values, setFieldValue, setFieldTouched}) => (
              <Fragment>

                <div className="row">
                  <div className="col-sm-6">
                    <SelectField
                      name="identTypeID"
                      label="Идентификация(ЕГН или ЛНЧ)"
                      items={this.state.identTypes}
                      validation={validations.identTypeID}
                      defaultValueOnSelectedItemRemoval
                      onChange={async (selectedIdentTypeID) => {
                        if (selectedIdentTypeID === 2 && isValidEGN(values.patientIdentNumber)) {
                          const birthDate = getBirthdateFromEGN(values.patientIdentNumber);
                          const sex = getSexFromEGN(values.patientIdentNumber);

                          setFieldValue('patientBirthDay', birthDate, false);
                          setFieldTouched('patientBirthDay', true, false);

                          setFieldValue('patientSex', sex, false);
                          setFieldTouched('patientSex', true, false);
                        }
                      }}
                    />
                  </div>

                  <div className="col-sm-6">
                    <TextField
                      name="patientIdentNumber"
                      label="ЕГН/ЛНЧ"
                      mask={/\d+/g}
                      validation={validations.patientIdentNumber}
                      onChange={async (egn) => {
                        if (values.identTypeID === 2 && isValidEGN(egn)) {
                          const birthDate = getBirthdateFromEGN(egn);
                          const sex = getSexFromEGN(egn);

                          setFieldValue('patientBirthDay', birthDate, false);
                          setFieldTouched('patientBirthDay', true, false);

                          setFieldValue('patientSex', sex, false);
                          setFieldTouched('patientSex', true, false);
                        }

                        await this.loadPatient(egn);
                      }}
                    />
                  </div>

                  <div className="col-sm-4">
                    <TextField
                      name="patientFirstName1"
                      label="Име"
                      validation={validations.patientFirstName}
                    />
                  </div>

                  <div className="col-sm-4">
                    <TextField
                      name="patientSecondName1"
                      label="Презиме"
                      validation={validations.patientSecondName}
                    />
                  </div>

                  <div className="col-sm-4">
                    <TextField
                      name="patientLastName1"
                      label="Фамилия"
                      validation={validations.patientLastName}
                    />
                  </div>

                  <div className="col-sm-6">
                    <SelectField
                      name="patientSex"
                      label="Пол"
                      items={sexTypes}
                      validation={validations.patientSex}
                      defaultValueOnSelectedItemRemoval
                    />
                  </div>

                  <div className="col-sm-6">

                    <DateEditField
                      name="patientBirthDay"
                      label="Дата на раждане"
                    />
                  </div>

                  <div className="col-sm-6">
                    <TextField
                      name="patientMobile"
                      label="Телефон"
                      validation={validations.patientMobile}
                    />
                  </div>

                  <div className="col-sm-6">
                    <TextField
                      name="patientEmail"
                      label="Имейл"
                      validation={validations.patientEmail}
                    />
                  </div>

                  <div className="col-sm-6">
                    <TextField
                      name="patientNameEN"
                      label="Три имена на английски по ЛК"
                      validation={validations.patientNameEN}
                    />
                  </div>

                  <div className="col-sm-6">
                    <TextField
                      name="patientPersonalDocNumber"
                      label="Лична карта №"
                      validation={validations.patientPersonalDocNumber}
                    />
                  </div>
                </div>
                <div className="col-sm-12 mt-2 text-center">
                  <button
                    type="submit"
                    disabled={isSubmitting || showConfirmationMessage}
                    className="btn btn-md btn-default">
                    Запази
                  </button>
                </div>
                {errorMessages && !!errorMessages.length && <div className="row">
                    <div className="col-sm-12">
                        <ErrorMessages errors={errorMessages}/>
                        <hr/>
                    </div>
                </div>}
                {showConfirmationMessage && <div className="row">
                    <div className="col-sm-12 text-center">Сигурни ли сте, че искате да промените данните?</div>
                    <div className="col-sm-12 text-center mt-3">
                        <button
                            type="submit"
                            disabled={isSubmitting}
                            className="btn btn-md btn-default"
                            onClick={() => this.setState({isValidated: true})}>
                            Потвърждавам
                        </button>
                        <button
                            type="button"
                            disabled={isSubmitting}
                            className="btn btn-md btn-warning"
                            onClick={() => this.props.onClose!()}>
                            Отказ
                        </button>
                    </div>
                </div>}
              </Fragment>
            )}/>}
      </CustomModal>
    );
  }
}
