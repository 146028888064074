import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import { CacheState } from './cache.state';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReduxState } from './redux/store';

import './CacheResource.scss';

interface Props {
  resourceId: number;
  injectHtml?: boolean;
  className?: string;
  style?: CSSProperties;
  defaultValue?: string;
  minimumLength?: number;
}

interface InjectedProps extends Props {
  cache: CacheState;
}

const TextResourceImpl = (props: Props) => {

  const injectedProps = props as InjectedProps;
  const resources = injectedProps.cache.textResources;
  const resource = resources[props.resourceId];

  if (props.defaultValue && props.minimumLength && (!resource || resource.length <= props.minimumLength)) {
    return  (
      <div
        data-resource-id={props.resourceId}
        className={props.className}
        style={props.style}
        dangerouslySetInnerHTML={{__html: props.defaultValue}}
      />
    );
  }

  if (!resource) {
    return (
      <FontAwesomeIcon
        icon={faSync}
        color="#aba8a8"
        size="1x"
        className="cache-resource-loading"
      />
    );
  }

  if (props.injectHtml) {
    return (
      <div
        data-resource-id={props.resourceId}
        className={props.className}
        style={props.style}
        dangerouslySetInnerHTML={{__html: resource}}
      />
    );
  } else {
    return (
      <div
        data-resource-id={props.resourceId}
        className={props.className}
        style={props.style}>
        {resource}
      </div>
    );
  }
};

export const TextResource = connect(({cache}: ReduxState) => ({cache}))(TextResourceImpl);
