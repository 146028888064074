import React, {StrictMode} from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {Router, withRouter} from 'react-router-dom';
import {registerObserver} from 'react-perf-devtool';

import 'normalize.css';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import 'mdbreact/dist/css/mdb.css';
import 'react-toastify/dist/ReactToastify.css';
import './infrastructure/styles.scss';
import './infrastructure/overrides';

import {AppContextProvider} from './infrastructure/react-context';
import {distributeDispatch} from './infrastructure/redux/distribute-dispatch';
import RenderRouteTable from './infrastructure/components/RenderRouteTable';
import {authRoutes} from './auth/auth.module';
import {demoRoutes} from './demo/demo.module';
import {patientRoutes} from './patient/patient.module';
import {CustomErrorBoundary, GlobalErrorComponent, ExtendedError, logError} from './infrastructure/errors';
import {Home} from './Home';
import isEmbedded from './infrastructure/isEmbedded';
import {doctorRoutes} from './doctor/doctor.module';
import {publicRoutes} from './public/public.module';
import './infrastructure/unhandledrejection-polyfill';
import {ToastContainer, Zoom} from 'react-toastify';
import {Header} from './infrastructure/layout/Header';
import {CustomFooter} from './infrastructure/layout/CustomFooter';
import {app, initializeGlobal} from './global';
import {componentUnmountedKey} from './infrastructure/components/BaseComponent';
import {GlobalModals} from './infrastructure/GlobalModals';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import {dicomRoutes} from './Dicom/dicom.module';
import initCornerstone from './Dicom/Viewer/Cornerstone/Cornerstone';

(() => {
  try {
    if (window.browserNotSupported) {
      return;
    }

    if (process.env.NODE_ENV === 'development') {
      registerObserver();
    }

    initializeGlobal();
    initCornerstone();

    window.addEventListener('unhandledrejection', (event: any) => {
      if (!event || !event.promise) {
        return;
      }
      event.promise.catch((error: any) => {
        if (!error) {
          return;
        }
        if (error[componentUnmountedKey]) {
          return;
        }
        app.getContext().actions.errors.setError(error);
      });
    });

    window.addEventListener('error', (event: any) => {
      if (!event || !event.error) {
        return;
      }
      if (event.error[componentUnmountedKey]) {
        return;
      }
      app.getContext().actions.errors.setError(event.error);
    });

    distributeDispatch.subscribe((msg) => {
      app.store.dispatch(msg.payload);
    });

    const RouterAwareErrorBoundary = withRouter(CustomErrorBoundary as any) as any;

    const Root = () => (
      <StrictMode>
        <Provider store={app.store}>
          <CustomErrorBoundary getContext={app.getContext}>
            <AppContextProvider getContext={app.getContext}>
              <Router history={app.history}>
                {!isEmbedded && <Header getContext={app.getContext}/>}
                <section className={`main ${(isEmbedded ? 'embedded' : '')}`}>
                  <RouterAwareErrorBoundary getContext={app.getContext}>
                    <GoogleReCaptchaProvider reCaptchaKey="6LfJPlYcAAAAAOipNcgjApJSxPY498m64Qlmc5pm">
                      <RenderRouteTable routeTable={[
                        {location: '/', component: Home, requireLogin: false},
                        ...authRoutes,
                        ...publicRoutes,
                        ...patientRoutes,
                        ...doctorRoutes,
                        ...dicomRoutes,
                        ...(() => {
                          if (process.env.NODE_ENV === 'development') {
                            return demoRoutes;
                          }
                          return [];
                        })(),
                      ]}/>
                    </GoogleReCaptchaProvider>
                  </RouterAwareErrorBoundary>
                </section>
                {!isEmbedded && <CustomFooter/>}
              </Router>
            </AppContextProvider>
          </CustomErrorBoundary>
          <GlobalErrorComponent getContext={app.getContext}/>
          <GlobalModals getContext={app.getContext}/>
          <ToastContainer transition={Zoom}/>
        </Provider>
      </StrictMode>
    );

    ReactDOM.render(<Root/>, document.getElementById('root'));

    window.addEventListener('load', async () => {
      if ('serviceWorker' in navigator) {
        // await navigator.serviceWorker.register('/sw.js');
      }
    });

    const {actions} = app.getContext();
    actions.cache.updateCache();

  } catch (e) {

    const wrappedError = new ExtendedError('Startup error...', e);
    logError(wrappedError);

    const rootElement = document.getElementById('root');
    if (rootElement) {
      rootElement.remove();
    }

    const errContainer = document.querySelector('.startup-error-container') as any;
    errContainer.setAttribute('style', '');

    const pre = document.querySelector('.startup-error-container .startup-error-container__stacktrace') as any;
    pre.innerHTML = e.stack;
  }
})();
