import React from 'react';

import {AppContextProps} from '../infrastructure/react-context';
import {BaseComponent} from '../infrastructure/components/BaseComponent';
import {DocumentTitle} from '../infrastructure/DocumentTitle';
import {TextResource} from '../infrastructure/TextResource';

interface Props extends AppContextProps {
}

interface State {
}

export class TermsOfService extends BaseComponent<Props, State> {
  render() {

    return (
      <div>

        <DocumentTitle title="Общи условия"/>
        <TextResource resourceId={22} injectHtml/>

      </div>
    );
  }
}
