import React from 'react';

import {AppContextProps} from '../infrastructure/react-context';
import {BaseComponent} from '../infrastructure/components/BaseComponent';
import {SignDocCM} from '../dto';
import {DocumentTitle} from '../infrastructure/DocumentTitle';
import LoadingIndicator from '../infrastructure/components/LoadingIndicator';
import {ErrorMessages} from '../infrastructure/errors';
import {RsaDocument} from './RsaDocument';
import {Link} from 'react-router-dom';

interface Props extends AppContextProps {
}

interface State {
  errorMessages: string[];
  loading: boolean;
  signedDocuments: SignDocCM[];
  notSignedDocuments: SignDocCM[];
  hasCertificate: boolean;
}

export class RsaDocuments extends BaseComponent<Props, State> {
  state: State = {
    errorMessages: [],
    loading: true,
    signedDocuments: [],
    notSignedDocuments: [],
    hasCertificate: false,
  };

  async componentDidMountAsync() {
    await this.refreshDocuments();
    await this.setStateAsync({loading: false});
  }

  refreshDocuments = async () => {
    const {server} = this.props.context;

    const certificatesResponse = await server.getRsaDocuments({});
    if (!certificatesResponse.success) {
      await this.setStateAsync({errorMessages: certificatesResponse.errorMessages, loading: false});
      return;
    }

    await this.setStateAsync({
      signedDocuments: certificatesResponse.payload.signedDocs,
      notSignedDocuments: certificatesResponse.payload.notSignedDocs,
      hasCertificate: certificatesResponse.payload.hasCertificate,
    });
  };

  render() {
    const {loading, errorMessages, signedDocuments, notSignedDocuments, hasCertificate} = this.state;

    return (
      <div className="rsa-documents-page">

        <DocumentTitle title={'Документи'}/>

        {errorMessages.length > 0 && <ErrorMessages errors={errorMessages}/>}
        {loading && <LoadingIndicator/>}

        {!loading &&
            <div>
              {!hasCertificate &&
              <div className="row border border-info p-2 mb-3">
                  <h4 className="col-md-8 align-self-center">Нямате генериран сертификат!</h4>
                  <div className="col-md-4">
                      <Link className="btn btn-info w-100" to={'/patient/rsa/certificate'}>ГЕНЕРИРАНЕ НА СЕРТИФИКАТ</Link>
                  </div>
              </div>
              }
                <div className="card mb-4">
                    <h4 className="card-header">Неподписани документи</h4>
                    <div className="card-body p-1">
                      {notSignedDocuments.length > 0 && notSignedDocuments.map((item) => (
                        <RsaDocument
                          hasCertificate={hasCertificate}
                          key={item.signDocID}
                          signDocument={item}
                          context={this.props.context}
                          onSigned={this.refreshDocuments}
                        />
                      ))}
                    </div>
                </div>

                <div className="card mb-4">
                    <h4 className="card-header">Подписани документи</h4>
                    <div className="card-body p-1">
                      {signedDocuments.length > 0 && signedDocuments.map((item) => (
                        <RsaDocument
                          hasCertificate={hasCertificate}
                          key={item.signDocID}
                          signDocument={item}
                          context={this.props.context}
                          onSigned={this.refreshDocuments}
                        />
                      ))}
                    </div>
                </div>
            </div>
        }

      </div>
    );
  }
}
