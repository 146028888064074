import React, { FunctionComponent, useState, memo } from 'react';

import { wrapField, cssClasses, BaseInputProps } from './field-helpers';
import { ErrorMessages } from '../errors';

import './Input.scss';
import { uuid } from '../util';

type StoredType = number | null;

interface Props extends BaseInputProps<StoredType> {
  label: string;
  icon?: string;
  nullable?: boolean;
  defaultValue?: StoredType;
  decimalPlaces?: number;
}

const getStepValue = (decimalPlaces: number | undefined): string => {
  if (decimalPlaces === undefined) {
    return '1';
  }
  return (1 / (10 ** decimalPlaces)).toString();
};

const roundFloat = (value: number, decimalPlaces: number): number =>
   Number((value).toFixed((decimalPlaces)));

const parse = (value: any, decimalPlaces: number | undefined) => {
  if (decimalPlaces !== undefined && decimalPlaces > 0) {
    return roundFloat(Number.parseFloat(value), decimalPlaces);
  } else {
    return Number.parseInt(value, 10);
  }
};

const getDefaultValue = ({nullable}: Props): StoredType => {
  if (nullable) {
    return null;
  } else {
    return 0;
  }
};

const getInitialValue = (props: Props): StoredType => {
  if (props.defaultValue !== undefined) {
    if (props.decimalPlaces !== undefined) {
      return parse(props.defaultValue, props.decimalPlaces);
    } else {
      return props.defaultValue;
    }
  } else {
    return getDefaultValue(props);
  }
};

const toInputValue = (props: Props, value: StoredType): string => {
  if (value === null) {
    return '';
  } else {
    return (parse(value, props.decimalPlaces) || 0).toString();
  }
};

const fromInputValue = (props: Props, inputValue: string): StoredType => {

  let newValue: number | null = parse(inputValue, props.decimalPlaces);

  if (newValue !== 0 && !newValue) {
    newValue = getDefaultValue(props);
  }

  return newValue;
};

export const NumberInput: FunctionComponent<Props> = memo((props: Props) => {

  const {label, icon, readonly, decimalPlaces, style, setTouched, onChange, errorMessages, className} = props;

  let value = props.value;

  // Initialize
  if (value === undefined) {
    value = getInitialValue(props);
    setTimeout(() => onChange(value), 0);
  }

  const [focused, setFocused] = useState(false);
  const [htmlID] = useState(uuid());

  const active = value !== null || !readonly && focused;

  const cssStates = cssClasses({
    readonly,
    invalid: !!errorMessages && !focused,
    active,
    focused,
  });

  return (
    <div className={`custom-input number-input custom-form-input form-group ${className} ${cssStates}`} style={style}>

      {icon && <i className={`fa fa-${icon} prefix`}/>}

      <input
        value={toInputValue(props, value)}
        onBlur={() => {
          if (setTouched) {
            setTouched();
          }
          setFocused(false);
        }}
        onFocus={() => {
          setFocused(true);
        }}
        onChange={(e: any) => {
          onChange(fromInputValue(props, e.target.value));
        }}
        type="number"
        className={`${(readonly ? 'readonly' : '')} form-control`}
        readOnly={readonly}
        id={htmlID}
        step={getStepValue(decimalPlaces)}
      />

      <label htmlFor={htmlID}>{label}</label>

      {errorMessages && !focused && <ErrorMessages errors={errorMessages} style={{marginTop: '.3rem'}}/>}
    </div>
  );
});

export const NumberField = wrapField(NumberInput);
