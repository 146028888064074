/* tslint:disable:max-classes-per-file */
import React, { Fragment } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { BaseComponent } from '../../infrastructure/components/BaseComponent';
import { AppContextProps } from '../../infrastructure/react-context';
import LoadingIndicator from '../../infrastructure/components/LoadingIndicator';
import { CytologyResultForm } from '../cytology-results/CytologyResultForm';
import { JRequestDto } from '../../dto';

interface RouteParams {
  jRequestID: string;
}

interface Props extends RouteComponentProps<RouteParams>, AppContextProps {
}

interface State {
  model: JRequestDto | undefined;
  loading: boolean;
}

const initialState: State = {
  model: undefined,
  loading: true,
};

export class LabResultsForm extends BaseComponent<Props, State> {

  state: State = initialState;

  getItemID = (): number => Number.parseInt(this.props.match.params.jRequestID, 10);

  componentDidMountAsync = async () => {
    await this.initForm();
  };

  componentDidUpdateAsync = async (prevProps: Props) => {
    if (prevProps.match.params.jRequestID !== this.props.match.params.jRequestID) {
      await this.initForm();
    }
  };

  initForm = async () => {
    await this.setStateAsync(initialState);
    const {server, actions} = this.props.context;
    const jRequestID = this.getItemID();
    const response = await server.getJRequest({jRequestID});

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    const model = response.payload.item;
    await this.setStateAsync({model, loading: false});
  };

  redirectToPrint = () => {

    const {model} = this.state;
    const {actions} = this.props.context;

    if (!model) {
      throw new Error('model is falsy in redirectToPrint.');
    }

    actions.router.routerPush(`/doctor/lab-results/${model.jRequestID}/print`);
  };

  render() {

    const {loading, model} = this.state;
    const {context} = this.props;

    return (
      <Fragment>
        {loading && <LoadingIndicator delay={0}/>}

        {model && <Fragment>
          <CytologyResultForm
            context={context}
            patientID={model.patientID}
            patientRecordID={model.patientRecordID}
            jRequestID={model.jRequestID}
            onSuccessfulSave={this.redirectToPrint}
            onDelete={() => {
              context.actions.router.routerPush(`/doctor/lab-results/${model.jRequestID}/delete`);
            }}
          />
        </Fragment>}
      </Fragment>
    );
  }
}
