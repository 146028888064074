import React, { CSSProperties, memo, FunctionComponent, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { ChildrenProps } from '../react-helpers';
import { emptyFunc } from '../util';

const isModalOpen = (): boolean => !!document.querySelectorAll('body [data-custom-modal]').length;

interface ModalPortalProps {
}

const ModalPortal: FunctionComponent<ModalPortalProps> = memo(({children}) => {

  const [element] = useState(() => {
    const el = document.createElement('div');
    el.setAttribute('style', 'position: relative; z-index: 1050;');
    el.setAttribute('tabindex', '-1');
    el.setAttribute('data-custom-modal', '');
    return el;
  });

  useEffect(() => {
    document.body.appendChild(element);
    document.body.setAttribute('class', 'modal-open');
    document.body.setAttribute('style', 'padding-right: 17px;');
    return () => {
      document.body.removeChild(element);
      if (!isModalOpen()) {
        document.body.setAttribute('class', '');
        document.body.setAttribute('style', '');
      }
    };
  }, []);

  return ReactDOM.createPortal(children, element);
});

interface Props extends ChildrenProps {
  title: string;
  onClose?: () => void;
  className?: string;
  style?: CSSProperties;
}

export const CustomModal: FunctionComponent<Props> = memo((props: Props) => {

  const {title, children, onClose, style, className} = props;

  return (
    <ModalPortal>
      <span>
      <div role="dialog" tabIndex={-1} className="modal top fade show" style={{display: 'block'}}>
        <div className={`modal-dialog modal-lg ${className || ''}`} role="document" style={style}>
          <div className="modal-content">
            <div className="modal-header white-text">
              <h4 className="title">{title}</h4>
              <button type="button" className="close" onClick={onClose || emptyFunc}>
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">{children}</div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"/>
    </span>
    </ModalPortal>
  );
});
