import {FormikProps} from 'formik';
import React, {Fragment} from 'react';
import {CustomForm} from '../../infrastructure/components/CustomForm';
import {DocumentTitle} from '../../infrastructure/DocumentTitle';
import {SelectItem} from '../../infrastructure/fields/select-item';
import {
  ConsultationCabinetAppointmentDto,
  ConsultationCabinetDoctorDto,
  ConsultationPriceItemDto,
  ConsultSaveJRequestRequest,
  ConsultSaveJRequestResponse,
  PatientDisplayDto,
  serverValidations,
} from '../../dto';
import {BaseComponent} from '../../infrastructure/components/BaseComponent';
import {AppContextProps} from '../../infrastructure/react-context';
import {SelectField} from '../../infrastructure/fields/SelectInput';
import LoadingIndicator from '../../infrastructure/components/LoadingIndicator';
import {TextField} from '../../infrastructure/fields/TextInput';
import {MultiSelectField} from '../../infrastructure/fields/MultiSelectInput';
import {TextAreaField} from '../../infrastructure/fields/TextAreaInput';
import {Result} from '../../infrastructure/api-result';
import {ErrorMessages} from '../../infrastructure/errors';
import BackButton from '../../infrastructure/components/BackButton';
import {ConfirmModal} from '../../common/ConfirmModal';
import {required} from '../../infrastructure/fields/validations';

interface Props extends AppContextProps {
}

interface State {
  cabinetsDoctorsDtos: ConsultationCabinetDoctorDto[] | undefined;
  cabinetsDoctorsLoading: boolean;

  cabinetAppointmentsDtos: ConsultationCabinetAppointmentDto[] | undefined;
  cabinetAppointmentsLoading: boolean;

  cabinetPriceListDtos: ConsultationPriceItemDto[] | undefined;
  cabinetPriceListLoading: boolean;

  consultSaveJRequestResult: Result<ConsultSaveJRequestResponse> | undefined;

  patient: PatientDisplayDto | undefined;
  cabinetTypeID: number | undefined;
  sumToPay: number | undefined;
  errorMessages: string[] | undefined;
  showConfirmationModal: boolean | undefined;

  loading: boolean;
}

const initialState: State = {
  cabinetsDoctorsDtos: undefined,
  cabinetsDoctorsLoading: false,

  cabinetAppointmentsDtos: undefined,
  cabinetAppointmentsLoading: false,

  cabinetPriceListDtos: undefined,
  cabinetPriceListLoading: false,

  consultSaveJRequestResult: undefined,

  patient: undefined,
  cabinetTypeID: undefined,
  sumToPay: undefined,
  errorMessages: undefined,
  showConfirmationModal: false,

  loading: false,
};

export class ConsultationForm extends BaseComponent<Props, State> {
  state: State = initialState;
  formHandle: FormikProps<any> | undefined = undefined;

  async initForm() {
    await this.fetchCabinetsDoctors();
    await this.fetchPatientInfo();
    if (this.formHandle) {
      this.formHandle.setFieldValue('patientEmail', this.state.patient?.patientEmail);
    }
  }

  async componentDidMountAsync() {
    await this.initForm();
  }

  async fetchPatientInfo(): Promise<any> {
    const {server, actions} = this.props.context;

    const result = await server.patientPersonalData({});

    if (result.success) {
      await this.setStateAsync({patient: result.payload.patient});
    } else {
      actions.errors.setErrorMessages(result.errorMessages);
    }
  }

  async fetchCabinetsDoctors(): Promise<any> {
    const {server, actions} = this.props.context;

    await this.setStateAsync({cabinetsDoctorsLoading: true});

    const result = await server.consultGetCabinetsDoctors({});

    if (result.success) {
      await this.setStateAsync({cabinetsDoctorsDtos: result.payload.items, cabinetsDoctorsLoading: false});
    } else {
      actions.errors.setErrorMessages(result.errorMessages);
      await this.setStateAsync({cabinetsDoctorsLoading: false});
    }
  }

  getCabinetsDoctors(): SelectItem[] {
    const dto = this.state.cabinetsDoctorsDtos;
    return !dto ? [] : dto.map((i) => ({
      label: i.displayMember,
      value: i.cabinetID + '_' + i.doctorID + '_' + i.cabinetTypeID,
    }));
  }

  async fetchCabinetAppointments(doctorID: number): Promise<any> {
    const {server, actions} = this.props.context;

    await this.setStateAsync({cabinetAppointmentsLoading: true});

    const result = await server.consultGetCabinetAppointments({doctorID});

    if (result.success) {
      await this.setStateAsync({
        cabinetAppointmentsDtos: result.payload.items,
        cabinetAppointmentsLoading: false,
      });
    } else {
      actions.errors.setErrorMessages(result.errorMessages);
      await this.setStateAsync({cabinetAppointmentsLoading: false});
    }
  }

  getCabinetAppointments(): SelectItem[] {
    const dto = this.state.cabinetAppointmentsDtos;

    if (!dto) {
      return [];
    } else {
      return dto.map((i) =>
        ({
          label: i.displayMember + ' ч.',
          value: i.workAppMetaID + '_' +
            i.appointmentFromDateTime,
        }));
    }
  }

  async fetchCabinetPriceList(cabinetID: number): Promise<any> {
    const {server, actions} = this.props.context;

    await this.setStateAsync({cabinetPriceListLoading: true});

    const result = await server.consultGetPriceList({cabinetID});

    if (result.success) {
      await this.setStateAsync({cabinetPriceListDtos: result.payload.items, cabinetPriceListLoading: false});
    } else {
      actions.errors.setErrorMessages(result.errorMessages);
      await this.setStateAsync({cabinetPriceListLoading: false});
    }

  }

  getCabinetPriceList(): SelectItem[] {
    const dto = this.state.cabinetPriceListDtos;
    if (!dto) {
      return [];
    } else {

      return dto.map((i) => ({
        label: (i.serviceCategoryName + ' ' + i.serviceName + ' - ' + i.servicePrice + ' лв.'),
        value: i.serviceID + '_' + i.servicePrice,
      }));
    }
  }

  calculateTotalPrice(model: []): number {
    let totalPrice: number = 0;

    if (model) {
      model.map((x: string) => {
        totalPrice += (+x.split('_')[1]);
      });
    }

    return totalPrice;
  }

  onSubmit = async (model: any) => {

    const isNullOrWhitespace = (input: string): boolean => {

      if (typeof input === 'undefined' || input == null) {
        return true;
      }

      return input.replace(/\s/g, '').length < 1;
    };

    if (model.valueMember &&
      model.serviceIDs.length > 0 &&
      !isNullOrWhitespace(model.patientEmail)) {

      const {server} = this.props.context;
      const servicesIDs: number[] = [];

      model.serviceIDs.map((x: string) => {
        servicesIDs.push(+x.split('_')[0]);
      });

      const item: ConsultSaveJRequestRequest = {
        cabinetID: model.valueMember.split('_')[0],
        doctorID: model.valueMember.split('_')[1],
        workAppMetaID: model.workAppMeta?.split('_')[0],
        appointmentFromDateTime: new Date(model.workAppMeta?.split('_')[1]),
        serviceIDs: servicesIDs,
        jRequestNote: model.jRequestNote,
        patientEmail: model.patientEmail,
      };

      const cabinetTypeID = model.valueMember.split('_')[2];

      if (cabinetTypeID === 4 && !model.workAppMeta) {
        return;
      }

      await this.setStateAsync({loading: true});

      const result = await server.consultSaveJRequest(item);

      await this.setStateAsync({consultSaveJRequestResult: result, loading: false});

    }
  };

  render() {

    const cabinetDoctors = this.getCabinetsDoctors();
    const cabinetAppointments = this.getCabinetAppointments();
    const cabinetPricelist = this.getCabinetPriceList();

    const {consultSaveJRequestResult, loading} = this.state;
    const {actions} = this.props.context;

    const validations = serverValidations.consultSaveJRequestRequest;

    if (consultSaveJRequestResult
      && consultSaveJRequestResult.success) {
      actions.router.routerPush('/patient/online-consultations');
    }

    return (
      <div className="online-consults-page">
        <DocumentTitle title="Онлайн консултация"/>

        {/* форма за записване на час за онлайн консултация*/}
        <CustomForm
          forwardedRef={(instance: any) => this.formHandle = instance}
          onSubmit={this.onSubmit}
          render={({values, setFieldValue, setFieldTouched, isSubmitting}) => (

            <div className="card">
              <div className="card-header blue white-text">Форма за онлайн консултация:</div>
              <span style={{padding: '20px 0px 0px 20px', fontStyle: 'italic', fontWeight: 'bold'}}>
                  Моля, преди да продължите да използвате услугите на сайта да се
                  запознаете с Общите условия и политиките за поверителност.
                </span>
              <div className="card-body">
                <SelectField
                  label="Дейност:"
                  name="valueMember"
                  items={cabinetDoctors}
                  isLoading={this.state.cabinetsDoctorsLoading}
                  onChange={async (cabinetDoctor: string) => {

                    if (cabinetDoctor) {
                      setFieldValue('workAppMeta', undefined);
                      setFieldTouched('workAppMeta', false);
                      setFieldValue('serviceIDs', undefined);
                      setFieldTouched('serviceIDs', false);
                      setFieldTouched('patientEmail', false);

                      await this.setStateAsync({
                        cabinetAppointmentsDtos: undefined,
                        cabinetPriceListDtos: undefined,
                      });

                      const cabinetID = cabinetDoctor.split('_')[0];
                      const doctorID = cabinetDoctor.split('_')[1];
                      const cabinetTypeID = cabinetDoctor.split('_')[2];

                      await this.setStateAsync({cabinetTypeID: +cabinetTypeID});
                      await this.fetchCabinetAppointments(+doctorID);
                      await this.fetchCabinetPriceList(+cabinetID);
                    }

                  }}
                  validation={required('Не е избрана дейност!')}
                />

                {this.state.cabinetTypeID === 4 &&
                <SelectField
                    label="Дата и час:"
                    name="workAppMeta"
                    items={cabinetAppointments}
                    isLoading={this.state.cabinetAppointmentsLoading}
                    validation={required('Не е избрана дата и час!')}
                />}

                <MultiSelectField
                  label="Избери услуги:"
                  name="serviceIDs"
                  items={cabinetPricelist}
                  onChange={async (services: []) => {
                    if (services.length) {
                      await this.setStateAsync({sumToPay: this.calculateTotalPrice(services)});
                    } else {
                      await this.setStateAsync({sumToPay: 0});
                    }
                  }}
                  validation={validations.serviceIDs}
                />

                <TextField
                  label="Вашия email:"
                  name="patientEmail"
                  defaultValue={this.state.patient?.patientEmail}
                  validation={validations.patientEmail}
                />

                <TextAreaField
                  label="Забележка:"
                  name="jRequestNote"
                />
                <div>{this.state.sumToPay ? ('Сума за плащане: ' + this.state.sumToPay + ' лв.') : ''} </div>

                {loading && <LoadingIndicator/>}

                <div className="text-center">
                  <button type="button"
                          className="btn btn-md btn-default"
                          disabled={isSubmitting}
                          onClick={async () => {
                            await this.setStateAsync({showConfirmationModal: true});
                          }}>
                    Запази консултация
                  </button>
                  <BackButton render={(props) =>
                    <a {...props} className="btn btn-md btn-warning">
                      Назад
                    </a>
                  }/>
                  {this.state.showConfirmationModal &&
                  <ConfirmModal
                      title="Запазване на онлайн консултация"
                      message="Моля, потвърдете запазването на онлайн консултация."
                      onClose={async () => {
                        await this.setStateAsync({showConfirmationModal: false});
                      }}
                      onConfirm={async () => {
                        setFieldTouched('valueMember', true);
                        setFieldTouched('workAppMeta', true);
                        setFieldTouched('patientEmail', true);
                        setFieldTouched('serviceIDs', true);
                        await this.setStateAsync({showConfirmationModal: false});
                        await this.onSubmit(values);
                      }}/>}

                </div>

                {consultSaveJRequestResult && !consultSaveJRequestResult.success && <Fragment>
                    <br/>
                    <ErrorMessages errors={consultSaveJRequestResult.errorMessages}/>
                    <br/>
                </Fragment>}
              </div>
            </div>
          )}
        />
      </div>
    );
  }
}
