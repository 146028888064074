export const texts = {
  LOGIN_USERNAME_LABEL: 'Потребител / Username',
  LOGIN_PASSWORD_LABEL: 'Парола / Password',
  LOGIN_PAGE_TITLE: 'Вход / Login',
  LOGIN_FORM_TITLE: 'Потребителски вход / Login',
  LOGIN_REMEMBER_ME_LABEL: 'Запомни ме / Remember me',
  LOGIN_BUTTON_TEXT: 'Вход / Login',
  LOGIN_FORGOT_PASS: 'Забравена парола / Forgot password?',

  LOADING_INDICATOR_DEFAULT_MESSAGE: 'Моля, изчакайте...',
  LOADING_PDF_MODULE: 'Зареждане на модул "PdfDocument"...',

  NAVBAR_LOGIN_BUTTON: 'Вход / Login',
  NAVBAR_LOGOUT_BUTTON: 'Изход / Logout',

  MENU_ITEMS_LABTESTS: 'Лаб. изследвания / Lab. tests',
  MENU_ITEMS_PRESCRIPTIONS: 'Рецепти / Prescriptions',
  MENU_ITEMS_OBLIGATIONS: 'Плащане с карта / Payment',
  MENU_ITEMS_BILLING: 'Финансова справка / Billing',
  MENU_ITEMS_VISITS: 'Предстоящи посещения / Visits',
  MENU_ITEMS_APPO_DELAY: 'Ред за преглед / Appointment turn',
  MENU_ITEMS_PASS_CHANGE: 'Смяна на парола / Change password',
  MENU_ITEMS_ACC_DISABLE: 'Деактивиране на достъпа / Deactivate account',
  MENU_ITEMS_TERMS: 'Общи условия и поверителност / Terms',
  MENU_ITEMS_VIBER: 'Viber Нотификации / Viber notifications',
  MENU_ITEMS_MEDICAL_AGENT: 'Медицински представител',

  PASS_RECOVERY_TITLE: 'Възстановяване на парола / Recover password',
  PASS_RECOVERY_PHNUM: 'Телефон / Phone number',
  PASS_RECOVERY_EMAIL: 'Електронна поща / Email',
  PASS_RECOVERY_FNAME: 'Име* / Firstname*',
  PASS_RECOVERY_SNAME: 'Презиме / Secondname',
  PASS_RECOVERY_LNAME: 'Фамилия* / Lastname*',
  PASS_RECOVERY_GENDER: 'Пол* / Gender*',
  PASS_RECOVERY_GENDER_M: 'Мъж / Male',
  PASS_RECOVERY_GENDER_F: 'Жена / Female',
  PASS_RECOVERY_DOB: 'Дата на раждане* / Date of birth*',
  PASS_RECOVERY_VALIDTYPE: 'Тип валидация* / Validation type*',
  PASS_RECOVERY_VALIDTYPE_VISITDATE: 'Дата на посещение / Visit date',
  PASS_RECOVERY_VALIDTYPE_DOCDATE: 'Дата на издаден финансов документ / Document date',
  PASS_RECOVERY_VALIDDATE: 'Дата за валидация* / Validation date*',
  PASS_RECOVERY_RESETBUTTON: 'Възстанови парола / Reset password',
  PASS_RECOVERY_BACKBUTTON: 'Отказ / Cancel',

  PATIENT_LABRESULT_DOWNLOAD: 'ИЗТЕГЛИ / DOWNLOAD',
  PATIENT_LABRESULT_PRINT: 'ПРИНТИРАЙ / PRINT',
  PATIENT_LABRESULT_EXAMS: 'Изследвания / Lab tests',

  ERROR_RELOAD_BUTTON_TEXT: 'Презареди / Reload',
  ERROR_HOME_BUTTON_TEXT: 'Начало / Home',
  ERROR_MODAL_TITLE: 'Възникна грешка в системата / System error',

  APP_NAME: 'Joystick',
  COPYRIGHT_TEXT: 'Smart Software Systems',
  COPYRIGHT_URL: 'https://smart-ss.org',

  INVALID_PDF_MESSAGE: 'Грешка - Невалиден PDF файл.',
};
