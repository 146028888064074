import React, {Fragment} from 'react';

import {BaseComponent} from '../../infrastructure/components/BaseComponent';
import {AppContextProps} from '../../infrastructure/react-context';
import {ConsultationJRequestDto, PatientRecordDto} from '../../dto';
import {DocumentTitle} from '../../infrastructure/DocumentTitle';
import LoadingIndicator from '../../infrastructure/components/LoadingIndicator';
import Moment from 'moment';
import {FileUploadModal} from '../FileUploadModal';
import {ErrorMessages} from '../../infrastructure/errors';
import {ConfirmModal} from '../../common/ConfirmModal';
import {notify} from '../../common/notify';

interface Props extends AppContextProps {
}

interface State {
  loadingForm: boolean;
  loadingItem: number | undefined;
  consultationJRequests: ConsultationJRequestDto[] | undefined;
  patientsRecords: PatientRecordDto[] | undefined;
  formErrorMessages: string[];
  showFileUploadModal: boolean;
  jReqIDFileUploadModal: number | undefined;
  showConfirmPDFModal: boolean;
  pRecIDConfirmPDFModal: number | undefined;
  jReqIDConfirmPDFModal: number | undefined;
  showConfirmConsultModal: boolean;
  jReqIDConfirmConsultModal: number | undefined;
}

export class ConsultationList extends BaseComponent<Props, State> {
  state: State = {
    loadingForm: false,
    loadingItem: undefined,
    consultationJRequests: undefined,
    patientsRecords: undefined,
    formErrorMessages: [],
    showFileUploadModal: false,
    jReqIDFileUploadModal: undefined,
    showConfirmPDFModal: false,
    pRecIDConfirmPDFModal: undefined,
    jReqIDConfirmPDFModal: undefined,
    showConfirmConsultModal: false,
    jReqIDConfirmConsultModal: undefined,
  };

  componentDidMountAsync = async () => {
    await this.loadForm();
  };

  async loadForm() {

    const {server, actions} = this.props.context;

    await this.setStateAsync({loadingForm: true});

    const response = await server.consultGetJRequests({});

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loadingForm: false});
      return;
    }

    if (!response.payload.jRequests.length) {
      actions.router.routerPush('/patient/online-consultations/new');
      await this.setStateAsync({loadingForm: false});
      return;
    }

    await this.fetchLabResultList();

    await this.setStateAsync({loadingForm: false, consultationJRequests: response.payload.jRequests});
  }

  async fetchLabResultList() {

    const {server, actions} = this.props.context;

    await this.setStateAsync({loadingForm: true});

    const response = await server.patientLabResults({});

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loadingForm: false, patientsRecords: undefined});
      return;
    }

    await this.setStateAsync({loadingForm: false, patientsRecords: response.payload.items});
  }

  checkObligations = async (jReqID: number) => {
    const {server, actions} = this.props.context;

    const response = await server.patientObligations({});

    if (response.success && response.payload.obligations.length > 0) {
      notify('error', 'Преди да качите файлове, моля, погасете задълженията си.' );
      return;
    }
    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      return;
    }

    await this.setStateAsync({
      showFileUploadModal: true,
      jReqIDFileUploadModal: jReqID,
    });
  };

  deleteJRequest = async (jReqID: number) => {

    const {server, actions} = this.props.context;

    await this.setStateAsync({loadingItem: jReqID});

    const response = await server.consultDeleteJRequest({jRequestID: jReqID});

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loadingItem: undefined});
      return;
    }

    await this.setStateAsync({loadingItem: undefined});
  };

  deleteLabResult = async (pRecID: number, jReqID: number) => {

    const {server, actions} = this.props.context;

    await this.setStateAsync({loadingItem: jReqID});

    const response = await server.consultDeleteAttachedResult({patientRecID: pRecID});

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loadingItem: undefined});
      return;
    }

    await this.setStateAsync({loadingItem: undefined});
  };

  render() {

    const {
      loadingForm,
      loadingItem,
      consultationJRequests,
      patientsRecords,
      formErrorMessages,
      showFileUploadModal,
      jReqIDFileUploadModal,
      showConfirmPDFModal,
      pRecIDConfirmPDFModal,
      jReqIDConfirmPDFModal,
      showConfirmConsultModal,
      jReqIDConfirmConsultModal,
    } = this.state;

    return (
      <Fragment>

        {consultationJRequests?.length && <div className="online-consultations-page col-sm-8" style={{margin: 'auto'}}>

            <DocumentTitle title="Онлайн консултации"/>

          {formErrorMessages.length > 0 &&
          <div className="card mb-2">
            <ErrorMessages errors={formErrorMessages}/>
          </div>}

          <div className="card col-lg-12 p-2 mb-2" style={{fontWeight: 'bold', fontStyle: 'italic'}}>
                Моля, преди да продължите да използвате услугите на сайта да се запознаете с Общите условия и политиките
                за поверителност.
            </div>

            <div className="card">
                <div className="card-header p-0" style={{backgroundColor: 'white'}}>
                    <button className="btn btn-md btn-default"
                            onClick={() => this.props.context.actions.router.routerPush('/patient/online-consultations/new')}>
                        Нова консултация
                    </button>
                </div>

              {loadingForm && <LoadingIndicator/>}

                <div className="card-body row">

                  {consultationJRequests?.map((jRequest) =>
                    <div key={jRequest.jRequestID} className="col-sm-12 card p-0 mb-2 ">
                      <div className="card-header blue white-text">
                        {!jRequest.isPaid && <Fragment>
                            <button
                                className="btn btn-md btn-success m-0 mr-2"
                                onClick={() => this.props.context.actions.router.routerPush('/patient/obligations')}>
                                Плати
                            </button>
                            <button
                                className="btn btn-md btn-danger m-0 mr-2"
                                onClick={() => this.setState({
                                  showConfirmConsultModal: true,
                                  jReqIDConfirmConsultModal: jRequest.jRequestID,
                                })}>
                                Изтрий
                            </button>
                        </Fragment>}

                        {jRequest.cabinetTypeID === 4 && <div
                            style={{display: 'inline-block'}}>
                            Дата:{Moment(jRequest.jRequestDateTime).format('DD.MM.YYYYг. HH:mm ч.')}
                        </div>}

                        {jRequest.cabinetTypeID === 5 && <div
                            style={{display: 'inline-block'}}>
                            Онлайн консултация без запазен час.
                        </div>}

                        {' (jRecID:' + jRequest.jRequestID + ')'}

                      </div>

                      <div className="card-body">
                        <div className="row">
                          {loadingItem && loadingItem === jRequest.jRequestID && <LoadingIndicator/>}
                          <div className="col-lg-8 p-0">
                            <div className="col-sm-12 pb-1">
                              Платено: {jRequest.isPaid ? 'Да' : 'Не'}
                            </div>
                            <div className="col-sm-12 pb-1">
                              Услуга: {jRequest.cabinetName}
                            </div>
                            <div className="col-sm-12 pb-1">
                              Специалист: {jRequest.doctorName}
                            </div>
                          </div>

                          <div className="col-lg-4 pb-1">
                            <button className="btn btn-md btn-info mr-2"
                                    onClick={async () => await this.checkObligations(jRequest.jRequestID)}>
                              Добави файл
                            </button>
                          </div>

                          <div className="col-lg-12 pb-1">
                            Качени файлове:

                            <div className="col-lg-12" style={{borderBottom: '1px solid black'}}/>

                            {patientsRecords?.map((pRecord, i) =>
                              (pRecord.jRequestID === jRequest.jRequestID &&
                                  <div className="row col-lg-12 p-0" key={i}>

                                      <div className="col-lg-8 p-0 mt-3">
                                        {pRecord.onlineDisplayName}
                                      </div>

                                      <div className="col-lg-4 pb-1">
                                        <button className="btn btn-sm btn-danger"
                                                onClick={() =>
                                                  this.setState({
                                                    showConfirmPDFModal: true,
                                                    pRecIDConfirmPDFModal: pRecord.patientRecID,
                                                    jReqIDConfirmPDFModal: jRequest.jRequestID,
                                                  })}>
                                            Изтрий
                                        </button>
                                      </div>

                                      <div className="col col-lg-12" style={{borderBottom: '1px solid black'}}/>

                                  </div>))}
                          </div>
                        </div>
                      </div>
                    </div>)}
                </div>
            </div>
        </div>}

        {showConfirmConsultModal && jReqIDConfirmConsultModal && <ConfirmModal
            title="Изтриване на запазен час"
            message="Сигурни ли сте, че искате да изтриете онлайн часа."
            onClose={async () => {
              await this.setStateAsync({showConfirmConsultModal: false});
            }}
            onConfirm={async () => {
              await this.setStateAsync({showConfirmConsultModal: false, jReqIDConfirmConsultModal: undefined});
              await this.deleteJRequest(jReqIDConfirmConsultModal);
              await this.loadForm();
            }}/>}

        {showConfirmPDFModal && pRecIDConfirmPDFModal && jReqIDConfirmPDFModal && <ConfirmModal
            title={'Изтриване на pdf Файл ' + '(jReqID:' + jReqIDConfirmPDFModal + ' pRecID:' + pRecIDConfirmPDFModal + ')'}
            message="Сигурни ли сте, че искате да изтриете pdf файла."
            onClose={async () => {
              await this.setStateAsync({showConfirmPDFModal: false});
            }}
            onConfirm={async () => {
              await  this.setStateAsync({
                showConfirmPDFModal: false,
                pRecIDConfirmPDFModal: undefined,
                jReqIDConfirmConsultModal: undefined,
              });
              await this.deleteLabResult(pRecIDConfirmPDFModal, jReqIDConfirmPDFModal);
              await this.loadForm();
            }}/>}

        {showFileUploadModal && jReqIDFileUploadModal && <FileUploadModal
            jRequestId={jReqIDFileUploadModal}
            context={this.props.context}
            onClose={() => {
              this.setState({showFileUploadModal: false, jReqIDFileUploadModal: undefined});
            }}
            afterSubmit={async () => {
              await this.loadForm();
              this.setState({showFileUploadModal: false, jReqIDFileUploadModal: undefined});
            }}/>}
      </Fragment>
    );
  }
}
