import { isOnlyMatch } from './util';

export const JsonHelper = {
  parse: (text: string) => JSON.parse(text, receiver),
  stringify: (value: any) => JSON.stringify(value, replacer),
};

const unspecifiedDateRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;

const receiver = (_: string, value: any) => {

  if (typeof value === 'string' && isOnlyMatch(unspecifiedDateRegex, value)) {

    const parts = value.split(/[-T:]/);

    return new Date(
      Number.parseInt(parts[0], 10),
      Number.parseInt(parts[1], 10) - 1,
      Number.parseInt(parts[2], 10),
      Number.parseInt(parts[3], 10),
      Number.parseInt(parts[4], 10),
      Number.parseInt(parts[5], 10),
    );
  }

  return value;
};

const utcDateRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z/;

const replacer = (_: string, value: any) => {

  if (typeof value === 'string' && isOnlyMatch(utcDateRegex, value)) {

    const date = new Date(value);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const milliseconds = date.getMilliseconds().toString().padStart(3, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
  }

  return value;
};
