import React from 'react';

import { AppContextProps } from '../infrastructure/react-context';
import { BaseComponent } from '../infrastructure/components/BaseComponent';
import { Result } from '../infrastructure/api-result';
import { ErrorMessages } from '../infrastructure/errors';
import { PdfDocument } from '../infrastructure/components/PdfDocument';
import LoadingIndicator from '../infrastructure/components/LoadingIndicator';
import { delay, base64ToArray } from '../infrastructure/util';
import { DocumentTitle } from '../infrastructure/DocumentTitle';

import './RenderPdf.scss';

interface Props extends AppContextProps {
}

interface State {
  documentLoading: boolean;
  documentContent: Uint8Array | undefined;
  documentTitle: string | undefined;
  documentLoadResult: Result | undefined;
}

export class RenderPdf extends BaseComponent<Props, State> {

  state: State = {
    documentLoading: true,
    documentContent: undefined,
    documentTitle: undefined,
    documentLoadResult: undefined,
  };

  componentDidMountAsync = async () => {

    let data;

    while (!data) {
      await delay(300);
      data = window.__pdfResultData__;
    }

    await this.setStateAsync({
      documentContent: base64ToArray(data.pdf),
      documentTitle: data.title,
    });
  };

  onPdfLoad = async (pdfLoadResult: Result) => {
    await this.setStateAsync({
      documentLoadResult: pdfLoadResult,
      documentLoading: false,
    });
  };

  render() {

    const {
      documentContent,
      documentLoading,
      documentLoadResult,
    } = this.state;

    return (
      <div className="render-pdf-page">

        <DocumentTitle title="Резултат"/>

        {documentContent && <div className="document-wrapper">
          {!!documentLoadResult && !documentLoadResult.success &&
          <ErrorMessages errors={documentLoadResult.errorMessages}/>}

          <PdfDocument data={documentContent} onLoad={this.onPdfLoad}/>
        </div>}

        {documentLoading && <LoadingIndicator className="document-loading" delay={0}/>}
      </div>
    );
  }
}
