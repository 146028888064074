import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { AppContextProps } from '../infrastructure/react-context';
import { BaseComponent } from '../infrastructure/components/BaseComponent';
import { CustomForm } from '../infrastructure/components/CustomForm';

import './demo.scss';

import { DebugValue } from '../infrastructure/components/DebugValue';
import { required } from '../infrastructure/fields/validations';
import { TextAreaField } from '../infrastructure/fields/TextAreaInput';

interface Props extends RouteComponentProps, AppContextProps {
}

interface State {
}

export class TextAreaFieldDemo extends BaseComponent<Props, State> {

  state: State = {};

  submit = async (values: any) => {

    console.info(values);
  };

  render() {

    return (
      <CustomForm onSubmit={this.submit} render={(form) => (
        <div className="card demo-form">
          <div className="card-body">

            <div>

              <TextAreaField
                name="default"
                label="Default"
              />

              <TextAreaField
                name="defaultWithIcon"
                icon="user"
                label="Default"
              />

              <TextAreaField
                name="defaultCustomStyle"
                icon="user"
                label="Default"
                inputStyle={{height: '20rem'}}
              />

              <TextAreaField
                name="shortHeight"
                icon="user"
                label="Short height"
                inputStyle={{height: '5rem'}}
              />

              <TextAreaField
                name="defaultWithIconDefaultValue"
                icon="user"
                label="Default"
                defaultValue="cats123"
              />

              <TextAreaField
                name="readonly"
                label="Readonly"
                readonly
              />

              <TextAreaField
                name="readonlyWithIcon"
                icon="user"
                label="Readonly"
                readonly
              />

              <TextAreaField
                name="readonlyWithIconDefaultValue"
                icon="user"
                label="Readonly"
                readonly
                defaultValue="cats123"
              />

              <TextAreaField
                name="defaultValidate"
                icon="user"
                label="Default Validate"
                validation={[required('The field is required.')]}
              />

            </div>

            <div className="text-center py-3 mt-3">
              <button className="btn btn-warning btn-block">Submit</button>
            </div>

            <DebugValue value={form}/>

          </div>
        </div>
      )}/>
    );
  }
}
