import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { AppContextProps } from '../infrastructure/react-context';
import { BaseComponent } from '../infrastructure/components/BaseComponent';
import { CustomForm } from '../infrastructure/components/CustomForm';

import './demo.scss';

import { DebugValue } from '../infrastructure/components/DebugValue';
import { required } from '../infrastructure/fields/validations';
import { DateField } from '../infrastructure/fields/DateInput';

interface Props extends RouteComponentProps, AppContextProps {
}

interface State {
}

export class DateFieldDemo extends BaseComponent<Props, State> {

  state: State = {};

  submit = async (values: any) => {

    console.info(values);
  };

  render() {

    const val = new Date(2019, 2, 2);

    return (
      <CustomForm onSubmit={this.submit} render={(form) => (
        <div className="card demo-form">
          <div className="card-body">

            <div>
              <DateField
                name="defaultWithoutIcon"
                label="Default Without Icon"
              />

              <DateField
                name="default"
                label="Default"
                icon="user"
              />

              <DateField
                name="defaultWithValue"
                label="Default value"
                icon="user"
                defaultValue={val}
              />

              <DateField
                name="nullable"
                label="Nullable"
                icon="user"
                nullable
              />

              <DateField
                name="nullableWithValue"
                label="Nullable"
                icon="user"
                nullable
                defaultValue={val}
              />

              <DateField
                name="readonly"
                label="Readonly"
                icon="user"
                readonly
                defaultValue={val}
              />

              <DateField
                name="readonlyEmpty"
                label="Readonly"
                icon="user"
                readonly
                nullable
              />

              <DateField
                name="validate"
                label="With validation"
                icon="user"
                nullable
                validation={[required('Date is required.')]}
              />

              <DateField
                name="validateWithoutIcon"
                label="With validation"
                nullable
                validation={[required('Date is required.')]}
              />

              <DateField
                name="validateWithoutIconNonNullable"
                label="With validation"
                validation={[(_) => ['ddd']]}
              />

            </div>

            <div className="text-center py-3 mt-3">
              <button className="btn btn-warning btn-block">Submit</button>
            </div>

            <DebugValue value={form}/>

          </div>
        </div>
      )}/>
    );
  }
}
