import React, {Fragment} from 'react';
import {CustomModal} from '../../infrastructure/components/CustomModal';
import LoadingIndicator from '../../infrastructure/components/LoadingIndicator';
import {ErrorMessages} from '../../infrastructure/errors';
import {BaseComponent} from '../../infrastructure/components/BaseComponent';
import {AppContextProps} from '../../infrastructure/react-context';
import {PromotionDetailDto} from '../../dto';
import {promotionDetailsStatus} from '../../promotions/PromotionsList';

interface Props extends AppContextProps {
  onClose: () => void;
  vaucherCode: string;
}

interface State {
  model: PromotionDetailDto | undefined;
  errorMessages: string[];
  submitLoading: boolean;
  loading: boolean;
}

const initialState: State = {
  model: undefined,
  errorMessages: [],
  submitLoading: false,
  loading: true,
};

export class VaucherModal extends BaseComponent<Props, State> {

  state: State = initialState;

  componentDidMountAsync = async () => {
    await this.loadModel(this.props.vaucherCode);
  };

  loadModel = async (vaucherCode: string) => {
    const {server} = this.props.context;
    const resultResponse = await server.checkPromotionDetail({promotionDetailCode: vaucherCode});

    if (!resultResponse.success) {
      await this.setStateAsync({loading: false, errorMessages: resultResponse.errorMessages});
      return;
    }
    await this.setStateAsync({loading: false, model: resultResponse.payload.item});
  };

  render() {

    const {
      errorMessages,
      model,
      loading,
    } = this.state;

    const status = this.state.model?.promotionStatus || 0;

    return (
      <CustomModal
        className="confirmation-modal"
        onClose={this.props.onClose}
        title={'Проверка на ваучер код'}>

        {loading && <LoadingIndicator/>}

        <div>
          <button
            onClick={this.props.onClose}
            type="button"
            className="btn btn-md btn-warning">
            Назад
          </button>
        </div>

        <hr/>

        {errorMessages && !!errorMessages.length && <div className="row">
            <div className="col-sm-12">
                <ErrorMessages errors={errorMessages}/>
                <hr/>
            </div>
        </div>}

        {!loading && !errorMessages.length &&
        <Fragment>
            <div className="row">
                <div className="col-sm-12" style={{fontSize: 20}}>
                    <span className="font-weight-bold">
                    Ваучер код:&nbsp;
                    </span>
                  {model?.promotionCode}
                </div>
                <div className="col-sm-12" style={{marginTop: '1rem'}}>
                    <div style={{fontSize: 20}}>
                        <span className={`font-weight-bold`}>
                        Ваучер статус:&nbsp;
                        </span>
                        <span className={`font-weight-bold text-${status < 3 ? 'success' : 'danger'}`}>
                      {promotionDetailsStatus[model?.promotionStatus || 0]}
                        </span>
                    </div>
                </div>
            </div>
            <div className="row" style={{marginTop: '1rem'}}>
                <div className="col"
                     style={{fontSize: 20, wordWrap: 'break-word'}}>
                    <span className="font-weight-bold">Контрагент:&nbsp;</span>
                  {model?.jPartnerName}
                </div>
            </div>
            <hr/>
            <div className="row">
                <div className="col-sm-12 mb-1">
                    <div className="text-center font-weight-bold" style={{fontSize: 20}}>Промоция:</div>
                </div>
                <div className="col-sm-12 text-center mb-2">{model?.promotionName}</div>
            </div>
            <hr/>
            <div className="row">
                <div className="col-sm-12">
                    <div className="text-center mt-2 mb-1 font-weight-bold" style={{fontSize: 20}}>Описание:</div>
                </div>
                <div className="col-sm-12" style={{whiteSpace: 'pre-wrap'}}>
                  {model?.promotionDescription}
                </div>
            </div>
            <hr/>
            <div className="row">
                <div className="col-sm-12">
                    <div className="text-center mt-2 mb-1 font-weight-bold" style={{fontSize: 20}}>Бележка:</div>
                </div>
                <div className="col-sm-12" style={{whiteSpace: 'pre-wrap'}}>
                  {model?.promotionNoteForUsers}
                </div>
            </div>
            <hr/>
        </Fragment>}
      </CustomModal>
    );
  }

}
