import React, { Fragment} from 'react';

import { BaseComponent } from '../infrastructure/components/BaseComponent';
import { AppContextProps } from '../infrastructure/react-context';
import { CustomForm } from '../infrastructure/components/CustomForm';
import { TextField, TextInput } from '../infrastructure/fields/TextInput';
import LoadingIndicator from '../infrastructure/components/LoadingIndicator';
import { ErrorMessages } from '../infrastructure/errors';
import { DocumentTitle } from '../infrastructure/DocumentTitle';
import { ChangeUsernameAndPasswordResponse, ChangeUsernameAndPasswordRequest, serverValidations } from '../dto';
import { connect } from 'react-redux';
import { ReduxState } from '../infrastructure/redux/store';
import { SessionState } from '../infrastructure/session.state';
import {NavLink} from 'react-router-dom';

interface Props extends AppContextProps {
}

interface InjectedProps extends Props {
  session: SessionState;
}

interface State {
  loading: boolean;
  response: ChangeUsernameAndPasswordResponse | undefined;
  errorMessages: string[] | undefined;
}

const initialState: State = {
  loading: false,
  response: undefined,
  errorMessages: undefined,
};

class ChangeUsernameAndPassword extends BaseComponent<Props, State> {

  state: State = initialState;

  onSubmit = async (data: ChangeUsernameAndPasswordRequest) => {

    const {server, actions} = this.props.context;

    await this.setStateAsync({loading: true});

    const response = await server.changeUsernameAndPassword(data);

    if (!response.success) {
      await this.setStateAsync({
        loading: false,
        errorMessages: response.errorMessages,
      });

      return;
    }

    actions.session.setUsername(data.newUsername);

    await this.setStateAsync({loading: false, response: response.payload});
  };

  render() {

    const validations = serverValidations.changeUsernameAndPasswordRequest;

    const {session} = this.props as InjectedProps;

    const {loading, response, errorMessages} = this.state;

    return (
      <div className="change-password-page">

        <DocumentTitle title="Промяна на парола"/>

        {!response && <CustomForm onSubmit={this.onSubmit} render={(_) => (
          <div className="card">
            <div className="card-header blue white-text">
              Промяна на потребителско име и парола
            </div>
            <div className="card-body">

              <TextInput
                label="Потребителско име (email адрес)"
                name="username"
                icon="user"
                readonly
                inputProps={{autoComplete: 'current-username'}}
                value={session.username || ''}
                onChange={() => undefined}
              />

              <TextField
                label="Ново потребителско име (email адрес)"
                name="newUsername"
                icon="user"
                validation={validations.newUsername}
                inputProps={{autoComplete: 'new-username'}}
              />

              <TextField
                label="Текуща парола"
                name="currentPassword"
                password
                icon="lock"
                validation={validations.currentPassword}
                inputProps={{autoComplete: 'current-password'}}
              />

              <TextField
                label="Нова парола"
                name="newPassword"
                icon="lock"
                password
                validation={validations.newPassword}
                inputProps={{autoComplete: 'new-password'}}
              />

              <TextField
                label="Потвърждаване на паролата"
                name="newPasswordConfirm"
                icon="lock"
                password
                validation={validations.newPasswordConfirm}
                inputProps={{autoComplete: 'new-password'}}
              />

              {loading && <LoadingIndicator/>}

              {errorMessages && <ErrorMessages errors={errorMessages}/>}

              <div className="text-center">
                  <button type="submit" className="btn btn-md btn-success">
                    Промени
                  </button>
                <NavLink to={`/`}
                         className="btn btn-md btn-secondary">
                 Отказ
                </NavLink>
              </div>
            </div>
          </div>
        )}/>}

        {response && <Fragment>
          <div className="card">
            <div className="card-header green white-text">Потребителското име и паролата са сменени.</div>
            <div className="card-body">
              <div className="text-center">Потребителското име и паролата Ви са сменени успешно!</div>
              <div className="text-center">
                <button
                  className="btn btn-md btn-default"
                  onClick={() => this.setState(initialState)}>
                  Промени отново
                </button>
              </div>
            </div>
          </div>
        </Fragment>}
      </div>
    );
  }
}

export default connect(({session}: ReduxState) => ({session}))(ChangeUsernameAndPassword);
