import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import { AppContextProps } from '../../infrastructure/react-context';
import { BaseComponent } from '../../infrastructure/components/BaseComponent';
import { CustomForm } from '../../infrastructure/components/CustomForm';
import { DateField } from '../../infrastructure/fields/DateInput';
import LoadingIndicator from '../../infrastructure/components/LoadingIndicator';
import { DocumentTitle } from '../../infrastructure/DocumentTitle';
import { CytologyResultListRequest, PatientRecordDto, serverValidations } from '../../dto';
import { TextField } from '../../infrastructure/fields/TextInput';
import { format, newDateWithoutTime } from '../../infrastructure/util';

interface Props extends AppContextProps {
}

interface State {
  filter: CytologyResultListRequest | undefined;
  listItems: PatientRecordDto[] | undefined;
  loading: boolean;
  firstQuery: boolean;
}

const defaultFilterState: CytologyResultListRequest = {
  fromDate: newDateWithoutTime(),
  toDate: newDateWithoutTime(),
};

export class CytologyResultList extends BaseComponent<Props, State> {

  state: State = {
    loading: false,
    filter: undefined,
    listItems: undefined,
    firstQuery: false,
  };

  async searchItems() {

    const {server, actions} = this.props.context;

    await this.setStateAsync({
      loading: true,
      listItems: [],
    });

    if (!this.state.filter) {
      throw new Error('The filter is falsy.');
    }

    const response = await server.cytologyResultList(this.state.filter);

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    await this.setStateAsync({
      loading: false,
      listItems: response.payload.items,
    });
  }

  async getCachedFilter() {
    const {server, actions} = this.props.context;

    await this.setStateAsync({loading: true});

    const response = await server.getSessionInfo({});

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    const filter = response.payload.state.cytologyResultListRequest || defaultFilterState;

    await this.setStateAsync({loading: false, filter});
  }

  async saveFilterCache() {
    const {server, actions} = this.props.context;

    await this.setStateAsync({loading: true});

    const response = await server.saveSessionInfo({state: {cytologyResultListRequest: this.state.filter}});

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    await this.setStateAsync({loading: false});
  }

  componentDidMountAsync = async () => {
    await this.getCachedFilter();
    await this.searchItems();
  };

  submit = async (filter: CytologyResultListRequest) => {
    await this.setStateAsync({filter});
    await this.searchItems();
    await this.saveFilterCache();
    await this.setStateAsync({
      firstQuery: true,
    });
  };

  render() {
    const {listItems, loading, firstQuery} = this.state;
    const validations = serverValidations.cytologyResultListRequest;
    return (
      <div className="cytology-results-list-page">

        <DocumentTitle title="Цитологични резултати - Картотека"/>

        {this.state.filter && <CustomForm values={this.state.filter} onSubmit={this.submit} render={(_) => (
          <div className="card">
            <div className="card-header blue white-text">
              Цитологични резултати - Картотека
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-sm-6 col-md-3">
                  <DateField
                    name="fromDate"
                    label="От дата"
                  />
                </div>
                <div className="col-sm-6 col-md-3">
                  <DateField
                    name="toDate"
                    label="До дата"
                  />
                </div>

                <div className="col-sm-6 col-md-3">
                  <TextField
                    name="patientIdentNumberBeginsWith"
                    label="ЕГН"
                    validation={validations.patientIdentNumberBeginsWith}
                    mask={/\d+/g}
                  />
                </div>

                <div className="col-sm-6 col-md-3">
                  <TextField
                    name="docID"
                    label="DocID"
                  />
                </div>
              </div>

              <div className="row" style={{marginTop: '1rem'}}>
                <div className="col-sm-6 vertical-center">
                  <button className="btn btn-md btn-default" style={{width: '100%'}} type="submit">
                    Обнови
                  </button>
                </div>

                <div className="col-sm-6 vertical-center">
                  <NavLink to={`/doctor/cytology-results/new`}
                           className="btn btn-md btn-success"
                           style={{width: '100%'}}>
                    Нов резултат
                  </NavLink>
                </div>
              </div>

              {(firstQuery || (listItems && !!listItems.length)) &&
              <div className="table-responsive" style={{marginTop: '1rem'}}>
                <table className="table list-table">
                  <thead className="blue text-white">
                    <tr>
                      <th>PatientID</th>
                      <th>Дата</th>
                      <th>Пациент</th>
                      <th>Анализирал</th>
                      <th>Описание</th>
                      <th/>
                      <th/>
                    </tr>
                  </thead>

                  {listItems && !!listItems.length && <tbody>
                    {listItems.map((item) => (
                      <tr key={item.patientRecID}>
                        <td>{item.patientRecID || '-'}</td>
                        <td
                          className="no-wrap">{item.datePatientRecDateTime ? format(item.datePatientRecDateTime, 'dd.MM.yyyy') : '-'}</td>
                        <td className="no-wrap">{item.patientFullName}</td>
                        <td className="no-wrap">{item.doctorShortName}</td>
                        <td>{item.patientRecShortName}</td>
                        <td>
                          <Link
                            to={`/doctor/cytology-results/${item.patientRecID}/print`}
                            className="btn btn-sm btn-info">
                            Преглед/Печат
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={`/doctor/cytology-results/${item.patientRecID}/edit`}
                            className="btn btn-sm btn-warning">
                            Редакция
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>}
                </table>
              </div>}

              {firstQuery && !loading && listItems && !listItems.length &&
              <div style={{margin: '1rem'}}>
                <h3 style={{color: 'black', textAlign: 'center'}}>Не са намерени резултати за това търсене.</h3>
              </div>}

              {loading && <LoadingIndicator/>}

            </div>
          </div>
        )}/>}
      </div>
    );
  }
}
