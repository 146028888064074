import React, { FunctionComponent, useState, memo } from 'react';

import { wrapField, cssClasses, BaseInputProps } from './field-helpers';
import { ErrorMessages } from '../errors';

import './FileSelectInput.scss';

type StoredType = string | null;

interface Props extends BaseInputProps<StoredType> {
  label: string;
  icon?: string;
  mimeTypes?: string;
  nullable?: boolean;
  defaultFileName?: string | undefined;
  onFileChange?: (name: string) => void;
}

const getDefaultValue = () => null;

export const FileSelectInput: FunctionComponent<Props> = memo((props: Props) => {

  const {label, readonly, icon, style, mimeTypes, setTouched, onChange, errorMessages, nullable, className} = props;

  let value = props.value;

  // Initialize
  if (value === undefined) {
    value = getDefaultValue();
    setTimeout(() => onChange(value), 0);
  }

  const [fileName, setFileName] = useState<string | undefined>(props.defaultFileName);

  const active = !!value || !!fileName;

  const cssStates = cssClasses({
    readonly,
    invalid: !!errorMessages,
    active,
    nullable,
  });

  const onFileChange = (fileChangeEvent: any) => {

    const file = fileChangeEvent.target.files[0];

    const reader = new FileReader();

    reader.onload = (readerLoadEvent: any) => {
      const data = readerLoadEvent.target.result;
      const base64 = window.btoa(data);

      onChange(base64);
      if (setTouched) {
        setTouched();
      }

      setFileName(file.name);
      if (props.onFileChange) {
        props.onFileChange(file.name);
      }
    };

    if (file) {
      reader.readAsBinaryString(file);
    }
  };

  const onClear = () => {
    onChange(null);
    if (setTouched) {
      setTouched();
    }
    setFileName('');
    if (props.onFileChange) {
      props.onFileChange('');
    }
  };

  return (
    <div className={`file-select-input custom-form-input form-group ${className} ${cssStates}`} style={style}>
      {icon && <i className={`fa fa-${icon} prefix`}/>}
      <div className="box-wrapper">
        <div className="select-wrapper">
          <div className="file-name" style={{paddingLeft: 20}}>{fileName}</div>
          <input
            type="file"
            className="form-control"
            onChange={onFileChange}
            readOnly={readonly}
            disabled={readonly}
            accept={mimeTypes}
          />
        </div>
        {nullable && !readonly && <span className="btn btn-default clear-button" onClick={onClear}>✘</span>}
      </div>
      <label>{label}</label>
      {errorMessages && <ErrorMessages errors={errorMessages}/>}
    </div>
  );
});

export const FileSelectField = wrapField(FileSelectInput);
