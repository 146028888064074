import React from 'react';
import {AppContextProps} from '../../infrastructure/react-context';
import {BaseComponent} from '../../infrastructure/components/BaseComponent';
import {DicomJStudyCM, GetDicomJStudiesRequest} from '../../dto';
import {DocumentTitle} from '../../infrastructure/DocumentTitle';
import {ErrorMessages} from '../../infrastructure/errors';
import {TextField} from '../../infrastructure/fields/TextInput';
import {CustomForm} from '../../infrastructure/components/CustomForm';
import {DateField} from '../../infrastructure/fields/DateInput';
import {JDateTime} from '../../public/JDateTime';
import {JString} from '../../public/JString';

import './StudiesCatalog.scss';
import LoadingIndicator from '../../infrastructure/components/LoadingIndicator';

interface Props extends AppContextProps {
}

interface State {
  loading: boolean;
  errorMessages: string[];
  model: DicomJStudyCM[] | undefined;
  filter: GetDicomJStudiesRequest;
}

export class StudiesCatalog extends BaseComponent<Props, State> {
  state: State = {
    loading: false,
    errorMessages: [],
    model: undefined,
    filter: {
      patientNames: '',
      patientUID: '',
      fromDate: new Date(),
      toDate: new Date(),
    },
  };

  async componentDidMountAsync(): Promise<void> {
    await this.refresh(this.state.filter);
  }

  refresh = async (filter: GetDicomJStudiesRequest) => {
    await this.setStateAsync({loading: true, errorMessages: []});

    const {server} = this.props.context;

    const resp = await server.getDicomJStudies(filter);
    if (!resp.success) {
      await this.setStateAsync({errorMessages: resp.errorMessages, loading: false});
      return;
    }

    await this.setStateAsync({model: resp.payload.dicomStudies, loading: false});
  };

  render() {
    const {loading, errorMessages, model, filter} = this.state;
    const {actions} = this.props.context;

    return (
      <div className="studies-catalog-wrapper">
        <DocumentTitle title={'Изследвания'}/>

        <CustomForm values={filter} onSubmit={this.refresh} render={({isSubmitting}) => (
          <div>
            <div className="row filter-row">
              <div className="col-6 col-lg-3">
                <TextField
                  label={'пациентско име:'}
                  name={'patientNames'}
                />
              </div>
              <div className="col-6 col-lg-3">
                <TextField
                  label={'пациентско UID:'}
                  name={'patientUID'}
                />
              </div>
              <div className="col-12 col-sm-6 col-lg-3">
                <DateField
                  nullable={true}
                  label={'от дата'}
                  name={'fromDate'}/>
              </div>
              <div className="col-12 col-sm-6 col-lg-3">
                <DateField
                  nullable={true}
                  label={'до дата'}
                  name={'toDate'}/>
              </div>
            </div>

            <div className="d-flex justify-content-center">
              <button className="btn btn-md btn-success" disabled={isSubmitting} type="submit">
                Търси
              </button>
            </div>
          </div>
        )}/>

        <hr/>

        {loading && <LoadingIndicator delay={0}/>}
        {!!errorMessages.length && <ErrorMessages errors={errorMessages}/>}

        {model &&
            <div className="studies-list">
              {model.map((x, i) => (
                <div key={i} className="card study-card"
                     onClick={() => actions.router.routerPush(`/dicom/viewer/${x.studyInstanceUid}`)}>
                  <div className="card-header border-bottom border-info p-1">
                    <div>{x.patientName}</div>
                    <div>{x.patientUID}</div>
                    <h2 className="badge badge-info position-absolute" style={{top: 0, right: 0}}>
                      {x.instancesCount}
                    </h2>
                  </div>
                  {!JString.isNullOrWhiteSpace(x.studyDescription) &&
                      <div className="card-body p-1 d-flex flex-column">
                          <p className="card-text mb-1">{x.studyDescription}</p>
                      </div>}
                  <div className="card-footer p-1 mt-auto">
                    {JDateTime.toJoystickDateTimeFormat(x.studyDateTime)}
                  </div>
                </div>
              ))}
            </div>}
      </div>
    );
  }
}
