import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import BackButton from '../../infrastructure/components/BackButton';
import { BaseComponent } from '../../infrastructure/components/BaseComponent';
import { MicrobiologyResultDto, PatientInformationDto } from '../../dto';
import { AppContextProps } from '../../infrastructure/react-context';
import LoadingIndicator from '../../infrastructure/components/LoadingIndicator';
import { DocumentTitle } from '../../infrastructure/DocumentTitle';

import './microbiology-results.scss';

interface RouterParams {
  patientRecID: string;
}

interface Props extends RouteComponentProps<RouterParams>, AppContextProps {
}

interface State {
  model: MicrobiologyResultDto | undefined;
  patient: PatientInformationDto | undefined;
  loading: boolean;
  submitLoading: boolean;
}

export class MicrobiologyResultDelete extends BaseComponent<Props, State> {

  state: State = {
    loading: true,

    model: undefined,
    patient: undefined,

    submitLoading: false,
  };

  componentDidMountAsync = async () => {
    const patientRecID = Number.parseInt(this.props.match.params.patientRecID, 10);
    await this.getItem(patientRecID);
  };

  async getItem(patientRecID: number) {

    const {server, actions} = this.props.context;

    const response = await server.getMicrobiologyResult({patientRecID});

    if (!response.success) {
      await this.setStateAsync({loading: false});
      actions.errors.setErrorMessages(response.errorMessages);
      return;
    }

    const model = response.payload.item;

    const patientResponse = await server.patientInformation({patientID: model.patientID});

    if (!patientResponse.success) {
      await this.setStateAsync({loading: false});
      actions.errors.setErrorMessages(patientResponse.errorMessages);
      return;
    }

    const patient = patientResponse.payload.item;

    await this.setStateAsync({
      loading: false,
      model,
      patient,
    });
  }

  async deleteItem(patientRecID: number) {

    const {server, actions} = this.props.context;

    await this.setStateAsync({submitLoading: true});

    const response = await server.deleteMicrobiologyResult({patientRecID});

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({submitLoading: false});

      return;
    }

    await this.setStateAsync({submitLoading: false});
    actions.router.routerPush('/doctor/microbiology-results');
  }

  actualDelete = async () => {

    const patientRecID = Number.parseInt(this.props.match.params.patientRecID, 10);

    await this.deleteItem(patientRecID);
  };

  render() {

    const {model, loading, submitLoading, patient} = this.state;

    if (loading || submitLoading) {
      return <LoadingIndicator delay={0}/>;
    }

    if (!model) {
      return null;
    }

    if (!patient) {
      return null;
    }

    return (
      <div className="microbiology-results-delete-page">

        <DocumentTitle title="Микробиологичен резултат - Изтриване"/>

        <div className="card">
          <div className="card-header red white-text">Микробиологичен резултат - Изтриване</div>
          <div className="card-body text-center">
            <h3 style={{marginTop: '2rem'}}>
              {patient.patientFullName} ЕГН: {patient.patientIdentNumber}, {patient.patientAge} г.
            </h3>

            <h3 style={{marginTop: '2rem'}}>Микробиологичен резултат №: {model.patientRecordID}</h3>

            <hr/>

            <h3 style={{marginTop: '2rem'}}>Сигурни ли сте, че искате да изтриете този резултат?</h3>

            <div className="button-group" style={{marginTop: '2rem'}}>
              <button className="btn btn-md btn-danger" onClick={this.actualDelete}>Изтриване</button>
              <BackButton
                render={(props) =>
                  <a {...props} className="btn btn-md btn-warning">
                    Назад
                  </a>
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
