import React, {Fragment} from 'react';
import {CustomModal} from '../infrastructure/components/CustomModal';
import LoadingIndicator from '../infrastructure/components/LoadingIndicator';
import {CustomForm} from '../infrastructure/components/CustomForm';
import {ErrorMessages} from '../infrastructure/errors';
import {BaseComponent} from '../infrastructure/components/BaseComponent';
import {AppContextProps} from '../infrastructure/react-context';
import {PromotionDto} from '../dto';
import {TextField} from '../infrastructure/fields/TextInput';
import {TextAreaField} from '../infrastructure/fields/TextAreaInput';

interface Props extends AppContextProps {
  onClose: () => void;
  onSaved: () => void;
  promotionDetailID: number;
  shouldSend: boolean;
}

interface State {
  model: PromotionDto | undefined;
  errorMessages: string[];
  submitLoading: boolean;
  loading: boolean;
}

const initialState: State = {
  model: undefined,
  errorMessages: [],
  submitLoading: false,
  loading: true,
};

export class ConfirmationModal extends BaseComponent<Props, State> {

  state: State = initialState;

  componentDidMountAsync = async () => {
    await this.loadModel(this.props.promotionDetailID);
  }

  loadModel = async (promotionDetailID: number) => {
    const {server, actions} = this.props.context;
    const resultResponse = await server.getPromotionDetail({promotionDetailID});

    if (!resultResponse.success) {
      actions.errors.setErrorMessages(resultResponse.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }
    await this.setStateAsync({loading: false, model: resultResponse.payload.item});
  }

  onSubmit = async (data: PromotionDto) => {
    if (this.props.shouldSend) {
      data.shouldSend = true;
    }

    const result = {...this.state.model, ...data};

    const {server} = this.props.context;
    const resultResponse = await server.savePromotionDetail({item: result});

    if (!resultResponse.success) {
      await this.setStateAsync({errorMessages: resultResponse.errorMessages});
      return;
    }

    this.props.onSaved();
    this.props.onClose();
  };

  render() {

    const {
      errorMessages,
      model,
      loading,
    } = this.state;

    return (
      <CustomModal
        className="confirmation-modal"
        style={{maxWidth: '100%', width: '700px'}}
        onClose={this.props.onClose}
        title={this.props.shouldSend ? 'Изпращане' : 'Маркиране'}>

        {loading && <LoadingIndicator/>}

        {!loading && <CustomForm
          onSubmit={this.onSubmit}
          values={model}
          render={({isSubmitting}) => (

            <Fragment>
              <div>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn btn-md btn-default">
                  Изпрати
                </button>

                <button
                  onClick={this.props.onClose}
                  type="button"
                  disabled={isSubmitting}
                  className="btn btn-md btn-danger">
                  Отхвърли
                </button>
              </div>
              {this.props.shouldSend ? <Fragment>
                  <div className="row" style={{marginTop: '1rem'}}>
                    <div
                      className="col-sm-12 text-center mt-3"
                      style={{fontSize: '1.5rem'}}>
                      Код на ваучера: <span className="font-weight-bold">{model?.promotionCode}</span>
                    </div>
                    <div className="col-sm-12">
                      <TextField label={'Телефон'} name={'sendToMobile'}/>
                    </div>
                    <div className="col-sm-12">
                      <TextField label={'Имейл'} name={'sendToEmail'}/>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <TextAreaField
                      inputStyle={{height: 'auto'}}
                      rows={3}
                      label={'Бележка'}
                      name={'sendToNote'}
                    />
                  </div>
                </Fragment>
                :
                <div className="text-center mb-3 mt-3">
                  <h4>
                    Сигурни ли сте, че искате да маркирате ваучер с код:
                    <span className="font-weight-bold">{model?.promotionCode}</span>
                  </h4>
                </div>}

              {errorMessages && !!errorMessages.length && <div className="row">
                  <div className="col-sm-12">
                      <ErrorMessages errors={errorMessages}/>
                      <hr/>
                  </div>
              </div>}

              {isSubmitting && <LoadingIndicator delay={0}/>}

            </Fragment>
          )}/>}
      </CustomModal>
    );
  }
}
