import React from 'react';

import {BaseComponent} from '../../infrastructure/components/BaseComponent';
import {AppContextProps} from '../../infrastructure/react-context';
import {DocumentTitle} from '../../infrastructure/DocumentTitle';
import {CustomForm} from '../../infrastructure/components/CustomForm';
import {ErrorMessages} from '../../infrastructure/errors';
import LoadingIndicator from '../../infrastructure/components/LoadingIndicator';
import BackButton from '../../infrastructure/components/BackButton';
import PatientSelectModal from '../PatientSelectModal';
import {serverValidations, JRequestEditDto, PriceListDepartmentCM} from '../../dto';
import {SelectItem} from '../../infrastructure/fields/select-item';
import {SelectField} from '../../infrastructure/fields/SelectInput';
import {TextAreaField} from '../../infrastructure/fields/TextAreaInput';
import {ListMultiSelectItems, ListMultiSelectField} from '../../infrastructure/fields/ListMultiSelectInput';
import {DoctorSelectField} from '../DoctorSelectInput';
import {VaucherModal} from './VaucherModal';
import '../DoctorSelectInput.scss';

interface Props extends AppContextProps {
}

interface State {
  model: JRequestEditDto | undefined;
  cabinets: SelectItem[];
  materials: SelectItem[];
  services: PriceListDepartmentCM[];
  patientID: number;
  patientInfo: string;
  loading: boolean;
  errorMessages: string[];
  submitLoading: boolean;
  selectingPatient: boolean;
  showVaucherModal: boolean;
}

export class JRequestsForm extends BaseComponent<Props, State> {

  state: State = {
    model: undefined,
    cabinets: [],
    materials: [],
    services: [],
    patientID: 0,
    patientInfo: '',
    loading: true,
    errorMessages: [],
    submitLoading: false,
    selectingPatient: true,
    showVaucherModal: false,
  };

  loadNewItem = async (patientID: number) => {

    const {server, actions} = this.props.context;

    const response = await server.newJRequest({patientID});

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    await this.setStateAsync({
      model: response.payload.item,
      loading: false,
      selectingPatient: false,
      patientID,
      cabinets: response.payload.cabinets.map((f) => ({
        label: f.displayMember,
        value: f.valueMember,
      })),
      materials: response.payload.materials.map((f) => ({
        label: f.displayMember,
        value: f.valueMember,
      })),
      patientInfo: response.payload.patientInfo,
    });

    if (response.payload.item.cabinetID) {
      await this.loadServices(response.payload.item.cabinetID);
    }

  };

  saveItem = async (model: JRequestEditDto) => {

    if (!model) {
      throw new Error('The model submitted from the form is undefined.');
    }

    const {server, actions} = this.props.context;

    await this.setStateAsync({model, submitLoading: true});

    const response = await server.saveJRequest({item: model});

    if (!response.success) {
      await this.setStateAsync({submitLoading: false, errorMessages: response.errorMessages});
      return;
    }

    await this.setStateAsync({submitLoading: false});

    actions.router.routerPush(`/doctor/jrequests/${response.payload.jRequestID}/print`);
  };

  loadServices = async (cabinetID: number) => {

    if (!cabinetID) {
      return;
    }
    const {server, actions} = this.props.context;

    this.setState({loading: true});

    const response = await server.jRequestPriceList({cabinetID, patientID: this.state.patientID});

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    await this.setStateAsync({
      loading: false,
      services: response.payload.items,
    });
  };

  render() {

    const {
      loading,
      submitLoading,
      model,
      errorMessages,
      selectingPatient,
      patientInfo,
      cabinets,
      services,
      showVaucherModal,
    } = this.state;

    const title = `Поръчка - Нова`;
    const validations = serverValidations.jRequestEditDto;

    return (
      <div className="jrequests-form-page">

        <DocumentTitle title={title}/>

        {selectingPatient && <PatientSelectModal
            onClose={() => this.props.context.actions.router.goBack()}
            onSelection={(x) => this.loadNewItem(x.patientID)}
        />}

        <div className="card">
          <div className="card-body">

            {loading && <LoadingIndicator/>}

            {model && <CustomForm values={model} onSubmit={this.saveItem} render={({isSubmitting, values}) => {

              return (
                <>
                  <div className="card" style={{overflow: 'auto'}}>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-8 vertical-center">
                          <h5>{patientInfo}</h5>
                        </div>

                        <div className="col-sm-4">
                          <div className="button-group">
                            <button
                              type="submit"
                              disabled={isSubmitting}
                              className="btn btn-md btn-info">
                              Запази
                            </button>

                            <BackButton render={(backButtonProps) =>
                              <a {...backButtonProps}
                                 className="btn btn-md btn-warning">
                                Назад
                              </a>
                            }/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {submitLoading && <LoadingIndicator/>}

                  {!!errorMessages.length && <div className="row">
                      <div className="col-sm-12">
                          <hr/>
                          <ErrorMessages errors={errorMessages}/>
                          <hr/>
                      </div>
                  </div>}

                  <div className="row" style={{marginTop: '1rem'}}>
                    <div className="col-sm-6">
                      <div className="row">
                        <div className="col-sm-12">
                          <SelectField
                            name="cabinetID"
                            label="Сектор*:"
                            items={cabinets}
                            validation={validations.cabinetID}
                            nullable
                            defaultValueOnSelectedItemRemoval
                            onChange={this.loadServices}
                          />
                        </div>
                      </div>

                    </div>
                    <div className="col-sm-6">
                      <DoctorSelectField
                        name="senderDoctorID"
                        label="Насочен от*:"
                        validation={validations.senderDoctorID}
                      />
                      <TextAreaField
                        name="jRequestNote"
                        label="Забележка/особености:"
                        validation={validations.jRequestNote}
                      />
                    </div>
                    <div className="col-sm-6">
                      <ListMultiSelectField
                        name="services"
                        items={services}
                        getKey={(x: PriceListDepartmentCM) => x.serviceID}
                        render={(listProps: ListMultiSelectItems<PriceListDepartmentCM>) => (
                          <div className="table-scrollable">
                            <table className="table list-table">
                              <thead className="blue text-white">
                              <tr className="row">
                                <th className="col-1">-</th>
                                <th className="col-2">Код</th>
                                <th className="col-5">Име на услугата</th>
                                <th className="col-4">Група</th>
                              </tr>
                              </thead>

                              <tbody style={{maxHeight: '20rem', maxWidth: '36rem'}}>
                              {listProps.items.map((item) => {
                                const Checkbox = item.CheckBox;
                                return (
                                  <tr className="row" style={{cursor: 'pointer'}}
                                      key={item.serviceID} onClick={item.onToggle}>
                                    <td className="col-1">
                                      <Checkbox/>
                                    </td>
                                    <td className="col-2 no-wrap">{item.serviceID}</td>
                                    <td className="col-5">{item.serviceName}</td>
                                    <td className="col-4 no-wrap">{item.serviceCategoryName}</td>
                                  </tr>
                                );
                              })}
                              </tbody>
                            </table>
                          </div>
                        )}
                      />
                    </div>
                    <div className="col-sm-6">
                      <div className="card">
                        <div className="card-header green white-text">Избрани услуги в поръчката</div>
                        <div className="card-body">
                          <ul className="list-group">
                            {(values.services as PriceListDepartmentCM[]).map((item) => (
                              <li key={item.serviceID} style={{padding: '.5rem'}}
                                  className="list-group-item">
                                {item.serviceName}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    {showVaucherModal && <VaucherModal
                        context={this.props.context}
                        onClose={() => this.setState({showVaucherModal: false})}
                        vaucherCode={values.vaucherCode}/>
                    }
                  </div>
                </>
              );
            }}/>}
          </div>
        </div>
      </div>
    );
  }
}
