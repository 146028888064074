import React from 'react';
import { BaseComponent } from '../infrastructure/components/BaseComponent';
import { AppContextProps } from '../infrastructure/react-context';
import LogOutButton from '../infrastructure/components/LogOutButton';
import BackButton from '../infrastructure/components/BackButton';
import { DocumentTitle } from '../infrastructure/DocumentTitle';

interface Props extends AppContextProps {
}

interface State {
  loading: boolean;
  confirmed: boolean;
}

export class InvalidateAccount extends BaseComponent<Props, State> {

  state: State = {
    loading: false,
    confirmed: false,
  };

  onClick = async () => {

    if (!this.state.confirmed) {
      await this.setStateAsync({confirmed: true});
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      return;
    }

    const {server, actions} = this.props.context;

    await this.setStateAsync({loading: true});

    const response = await server.invalidateAccount({});

    if (!response.success) {
      await this.setStateAsync({loading: false});
      actions.errors.setErrorMessages(response.errorMessages);
      return;
    }

    await this.setStateAsync({loading: false});
  };

  render() {

    const {confirmed} = this.state;

    const {actions} = this.props.context;

    return (
      <div className="invalidate-account-page">

        <DocumentTitle title="Изтриване на акаунт"/>

        {!confirmed && <div>
          <div className="card">
            <div className="card-body text-center">
              <h2>
                  Деактивиране на акаунт
              </h2>
            </div>
          </div>

          <div className="card" style={{marginTop: '1.5rem'}}>
              <div className="card-body">
                  <h4>Моля, прочетете внимателно и направете своя избор!</h4>
                  <p>
                      С тази функционалност ние уважаваме Вашето право да се
                      откажете от използването на онлайн услугите на този сайт.</p>
                      <p> Преди да деактивирате вашия достъп, трябва да сте наясно,
                      че след това действие Вие ще бъдете ограничени да използате онлайн услугите на сайта.</p>
                      <p>
                      Последващо възстановяване на достъпа е сложен процес,
                      който ще изисква задължително Ваше присъствие на място и
                          удостоверяване с документ на самоличността Ви.
                  </p>
              <div className="row" style={{marginTop: '1rem'}}>
                <div className="col-sm-6">
                  <BackButton render={(props: any) => (
                    <button className="btn btn-md btn-success" style={{width: '100%'}} {...props}>
                      Отказ
                    </button>
                  )}/>
                </div>
                <div className="col-sm-6">
                  <button className="btn btn-md btn-danger" onClick={this.onClick}
                          style={{width: '100%'}}>
                    продължи с деактивацията
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>}

        {confirmed && <div>

          <div className="card" style={{marginTop: '1.5rem'}}>
            <div className="card-body">
             <h3 className="text-center">Моля, направете вашия избор!</h3>
              <div className="row" style={{marginTop: '1rem'}}>
                <div className="col-sm-6">
                  <BackButton render={(props: any) => (
                    <button className="btn btn-md btn-success" style={{width: '100%'}} {...props}>
                      не сега
                    </button>
                  )}/>
                </div>
                <div className="col-sm-6">
                  <LogOutButton render={(props) => (
                    <button
                      className="btn btn-md btn-danger"
                      onClick={async (e: any) => {
                        await this.onClick();
                        props.onClick(e);
                        actions.router.routerPush('/');
                      }}
                      style={{width: '100%'}}
                    >Потвърждавам деактивацията</button>
                  )}/>
                </div>
              </div>
            </div>
          </div>
        </div>}
      </div>
    );
  }
}
