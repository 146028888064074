import React from 'react';

import { LazyComponent } from '../LazyComponent';
import LoadingIndicator from './LoadingIndicator';
import { texts } from '../texts';
import { retryPromise } from '../util';

export const PdfDocument = LazyComponent(
  () => retryPromise(() => import(/* webpackChunkName: "pdf-document" */'./PdfDocumentImpl')),
  () => <LoadingIndicator>{texts.LOADING_PDF_MODULE}</LoadingIndicator>,
);
