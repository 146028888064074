import React from 'react';
import { AppContextFactoryProps, withAppContext } from '../infrastructure/react-context';
import { DoctorCatalogDto, DoctorCatalogRequest } from '../dto';
import { BaseComponent } from '../infrastructure/components/BaseComponent';
import { CustomModal } from '../infrastructure/components/CustomModal';
import { CustomForm } from '../infrastructure/components/CustomForm';
import { NumberField } from '../infrastructure/fields/NumberInput';
import { TextField } from '../infrastructure/fields/TextInput';
import LoadingIndicator from '../infrastructure/components/LoadingIndicator';

import './DoctorSelectModal.scss';

interface Props extends AppContextFactoryProps {
  onSelection?: (selectedItem: DoctorCatalogDto) => void | Promise<any>;
  onClose?: () => void;
}

interface State {
  filter: DoctorCatalogRequest;
  listItems: DoctorCatalogDto[] | undefined;
  loading: boolean;
  firstQuery: boolean;
}

class DoctorSelectModal extends BaseComponent<Props, State> {

  static defaultProps: Partial<Props> = {
    onSelection: (_) => {
    },
    onClose: () => {
    },
  };

  state: State = {
    loading: false,
    filter: {},
    listItems: undefined,
    firstQuery: false,
  };

  async searchItems() {

    const {server, actions} = this.props.getContext();

    await this.setStateAsync({loading: true, listItems: []});

    const response = await server.doctorCatalog(this.state.filter);

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    await this.setStateAsync({loading: false, listItems: response.payload.items});
  }
   loadDefaultDoctor = async () => {

    const {server, actions} = this.props.getContext();

    await this.setStateAsync({loading: true});

    const response = await server.doctorCatalog({doctorID: 1});

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    await this.setStateAsync({loading: false});
    if (response.payload.items.length && this.props.onSelection) {
     await this.props.onSelection(response.payload.items[0]);
    }
  }
  componentDidMountAsync = async () => {
    await this.searchItems();
  };

  submit = async (data: DoctorCatalogRequest) => {
    await this.setStateAsync({filter: data});
    await this.searchItems();
    await this.setStateAsync({firstQuery: true});
  };

  render() {
    const {listItems, loading, firstQuery} = this.state;
    return (
      <CustomModal title="От кой е посочен пациента..." className="doctor-select-modal"
                   onClose={() => this.props.onClose && this.props.onClose()}>
        <CustomForm
          values={this.state.filter} onSubmit={this.submit}
          render={(_) => (
            <div className="card">
              <div className="card-header blue white-text">
                Насочващи лекари/договорни партньори фирми
              </div>
              <div className="card-body results-card">
                <div className="row">
                  <div className="col-sm-6 col-md-2">
                    <NumberField
                      nullable
                      name="doctorID"
                      label="ID:"
                    />
                  </div>
                  <div className="col-sm-6 col-md-2">
                    <TextField
                      name="doctorUIN"
                      label="УИН:"
                    />
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <TextField
                      name="doctorFirstName"
                      label="Име:"
                    />
                  </div>
                  <div className="col-sm-6 col-md-2">
                    <TextField
                      name="doctorSecondName"
                      label="Презиме:"
                    />
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <TextField
                      name="doctorLastName"
                      label="Фамилия:"
                    />
                  </div>
                  <div className="col-sm-4 col-md-4 vertical-center">
                    <button className="btn btn-md btn-primary full-width" type="button"
                      onClick={this.loadDefaultDoctor}>
                      НЕ Е ПОСОЧЕН
                    </button>
                  </div>
                  <div className="col-sm-4 col-md-4 vertical-center">
                    <button className="btn btn-md btn-success full-width" type="submit">
                      Обнови
                    </button>
                  </div>
                  <div className="col-sm-4 col-md-4 vertical-center">
                    <button className="btn btn-md btn-warning full-width" type="button"
                            onClick={() => {
                              if (this.props.onClose) {
                                this.props.onClose();
                              }
                            }}>
                      Назад
                    </button>
                  </div>
                </div>

                {firstQuery &&
                <div className="table-responsive table-wrapper">
                  <table className="table list-table">
                    <thead className="blue text-white">
                      <tr>
                        <th>№</th>
                        <th>УИН</th>
                        <th>Име</th>
                        <th>Презиме</th>
                        <th>Фамилия</th>
                        <th>Телефон</th>
                        <th/>
                      </tr>
                    </thead>
                    <tbody>
                      {listItems && listItems.map((item) => (
                        <tr key={item.doctorID}>
                          <td>#{item.doctorID}</td>
                          <td>{item.doctorUIN}</td>
                          <td>{item.doctorFirstName}</td>
                          <td>{item.doctorSecondName}</td>
                          <td>{item.doctorLastName}</td>
                          <td>
                            <button
                              className="btn btn-sm btn-info full-width select-button"
                              type="button"
                              onClick={async () => {
                                if (this.props.onSelection) {
                                  await this.props.onSelection(item);
                                }
                              }}>
                              Избери
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>}

                {firstQuery && !loading && listItems && !listItems.length &&
                <div style={{margin: '1rem'}}>
                  <h3 style={{color: 'black', textAlign: 'center'}}>Не са намерени резултати за това търсене.</h3>
                </div>}

                {loading && <LoadingIndicator/>}

              </div>
            </div>
          )}/>
      </CustomModal>
    );
  }
}

export default withAppContext(DoctorSelectModal);
