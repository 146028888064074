import React, { Fragment } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { AppContextProps } from '../../infrastructure/react-context';
import { BaseComponent } from '../../infrastructure/components/BaseComponent';
import { ScreenSizeComponent } from '../../infrastructure/media-type';
import { Result } from '../../infrastructure/api-result';
import { ErrorMessages } from '../../infrastructure/errors';
import { PdfDocument } from '../../infrastructure/components/PdfDocument';
import LoadingIndicator from '../../infrastructure/components/LoadingIndicator';
import { downloadFile, base64ToArray, printPdf } from '../../infrastructure/util';
import BackButton from '../../infrastructure/components/BackButton';
import { AttachedResultDto } from '../../dto';
import { DocumentTitle } from '../../infrastructure/DocumentTitle';
import { DropdownButton } from '../../infrastructure/components/DropdownButton';

import './attached-results.scss';

interface RouterParams {
  patientRecID: string;
}

interface Props extends RouteComponentProps<RouterParams>, AppContextProps {
}

interface State {
  loading: boolean;
  model: AttachedResultDto | undefined;
  documentLoadResult: Result | undefined;
  documentBytes: Uint8Array | undefined;
}

export class AttachedResultPrint extends BaseComponent<Props, State> {

  state: State = {
    loading: true,
    model: undefined,
    documentLoadResult: undefined,
    documentBytes: undefined,
  };

  async fetchPdfResult(patientRecID: number) {
    const {server, actions} = this.props.context;

    const response = await server.getAttachedResult({patientRecID, includeFileContents: true});

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    const model = response.payload.item;

    await this.setStateAsync({
      documentLoadResult: undefined,
      model,
      documentBytes: base64ToArray(model.pdfFileContent),
    });
  }

  async fetchData() {

    const patientRecID = Number.parseInt(this.props.match.params.patientRecID, 10);

    await this.fetchPdfResult(patientRecID);
  }

  componentDidMountAsync = async () => {
    await this.fetchData();
  };

  componentDidUpdateAsync = async (prevProps: Props) => {

    if (prevProps.match.params.patientRecID !== this.props.match.params.patientRecID) {
      await this.fetchData();
    }
  };

  downloadPdf = () => {

    const {model} = this.state;

    if (!model) {
      throw new Error('Trying to print while model is falsy.');
    }

    downloadFile(
      this.state.documentBytes,
      model.pdfFileContent,
      `${model.patientRecShortName}.pdf`,
    );
  };

  printPdf = () => {

    const {model} = this.state;

    if (!model) {
      throw new Error('Trying to print while model is falsy.');
    }

    printPdf(
      this.state.documentBytes,
      model.pdfFileContent,
      `${model.patientRecShortName}.pdf`,
    );
  };

  onPdfLoad = (pdfLoadResult: Result) => {
    setTimeout(() => {
      this.setState({
        documentLoadResult: pdfLoadResult,
        loading: false,
      });
    }, 0);
  };

  render() {

    const {
      loading,
      documentLoadResult,
      model,
      documentBytes,
    } = this.state;

    const documentLoadedSuccessfully = !!documentLoadResult && documentLoadResult.success;

    return (
      <div className="attached-results-print-page" style={{margin: '0 auto'}}>

        <DocumentTitle title="Прикачени резултати - Преглед/Печат"/>

        {model && documentBytes && <Fragment>

          <div className="card">
            <div className="card-header blue white-text">
              Прикачени резултати - Преглед/Печат
            </div>
            <div className="card-body">
              <div className="button-group">
                {documentLoadedSuccessfully && <Fragment>
                  <button className="btn btn-md btn-success" onClick={this.downloadPdf}>Изтегли</button>
                  <ScreenSizeComponent size="not-phone">
                    <button className="btn btn-md btn-default" onClick={this.printPdf}>Принтирай</button>
                  </ScreenSizeComponent>
                </Fragment>}

                <DropdownButton label="Опции" className="btn btn-md btn-info">

                  <Link
                    to={`/doctor/attached-results`}
                    className="dropdown-item">
                    Картотека
                  </Link>

                  <Link
                    to={`/doctor/attached-results/${model.patientRecordID}/edit`}
                    className="dropdown-item">
                    Редакция
                  </Link>

                  <Link
                    to={`/doctor/attached-results/${model.patientRecordID}/delete`}
                    className="dropdown-item">
                    Изтриване
                  </Link>

                </DropdownButton>

                <BackButton render={(props) =>
                  <a {...props}
                     className="btn btn-md btn-warning">
                    Назад
                  </a>
                }/>
              </div>
            </div>
          </div>

          {!!documentLoadResult && !documentLoadResult.success &&
          <ErrorMessages errors={documentLoadResult.errorMessages}/>}

          <div style={{marginTop: '1rem'}}>
            <PdfDocument data={documentBytes} onLoad={this.onPdfLoad}/>
          </div>
        </Fragment>}

        {loading && <LoadingIndicator className="document-loading" delay={0}/>}
      </div>
    );
  }
}
