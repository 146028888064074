import React from 'react';

import {AppContextProps} from '../infrastructure/react-context';
import {BaseComponent} from '../infrastructure/components/BaseComponent';
import {DocumentTitle} from '../infrastructure/DocumentTitle';
import {CustomForm} from '../infrastructure/components/CustomForm';
import {JDateTime} from '../public/JDateTime';
import {CustomModal} from '../infrastructure/components/CustomModal';
import {TextField} from '../infrastructure/fields/TextInput';
import {SmailDemoResetPassword} from './SmailDemoResetPassword';

interface Props extends AppContextProps {
}

interface MyObj {
  name: string;
  age: number;
  birthDate: Date;
  formated: string;
}

interface State {
  my_text: string;
  counter: number;
  my_path: MyObj;
  IsOpen: boolean;
  IsOpenResetPassword: boolean;
}

// до тук е демо
export class SmailDemo extends BaseComponent<Props, State> {
  state: State = {
    my_text: 'sss',
    counter: 1,
    IsOpen: false,
    IsOpenResetPassword: false,
    my_path: {name: 'smail', age: 27, birthDate: JDateTime.now(), formated: 'nqma'},
  };

  myClick = () => {

    return 'ssss';
  };

  submit = ( zz: MyObj ) => {
    this.setState({my_path: zz, IsOpen: false});

  };

  iWantOpenForm = () => {
    this.setState({IsOpen: true});
  };

  onCloseForm = () => {
    this.setState({IsOpen: false});
  };

  onCloseResetPassword = () => {
    this.setState({IsOpenResetPassword: false});
  };

  mySubmit = (text: string) => {

    console.log(text);
    this.setState({IsOpen: false});
  };

  mySubmit2 = (text: string) => {

    console.log(text);
    return 15;
    // this.setState({IsOpen: false});
  };

  onClose = () => {

  };

  render() {
    return (
      <div>

        <DocumentTitle title="Тестова страница Смаил"/>
        {this.state.IsOpen &&
          <SmailDemoResetPassword
            value1={'sss'}
            context={this.props.context}
            my_submit={this.mySubmit}
            onCancel={() => {this.setState({IsOpen: false}); }}
            my_submit2={this.mySubmit2}
          />
        }

        <h5>Сега ще видим какво става {this.state.my_path.formated}</h5>
        <h5>Сега ще видим какво става {this.state.my_path.name}</h5>
        <div className="container-fluid">
          <div className="row">
            <div className="button-group">
              <button className="btn btn-secondary"
                      onClick={() => this.setState({IsOpenResetPassword: true})}>
                забравена парола</button>
              <button className="btn btn-amber"
                      onClick={() => this.iWantOpenForm()}>НОВА РЕГИСТРАЦИЯ</button>
              <button className="btn btn-danger"
                      onClick={() => this.setState({IsOpen: true})}>НОВА РЕГИСТРАЦИЯ</button>
            </div>
          </div>
        </div>

        {this.state.IsOpenResetPassword &&
        <CustomModal title="Възтановяване на парола"
                     onClose={() => this.setState({IsOpenResetPassword: false})}>
            <CustomForm
                values={this.state.my_path}
                onSubmit={this.submit}
                render = {() => (
                  <div className="card">
                    <div className="row">
                      <div className="col-sm-6">
                        <TextField label="Имейл:" name="name"></TextField>
                      </div>
                      <div className="col-sm-7">
                        <TextField label="Телефон:" name="name"></TextField>
                      </div>
                    </div>
                    <div className="col">
                      <div className="float-right">
                        <button className="btn bg-success" type="submit">ПОТВЪРДИ</button>
                        <button className="btn bg-info" type="button"
                                onClick={() => this.setState({IsOpenResetPassword: false})}>
                          ОТКАЖИ</button>
                      </div>
                    </div>
                  </div>
                )}>
            </CustomForm>
        </CustomModal>
        }

      </div>
    );
  }
}

