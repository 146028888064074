import { History, LocationState } from 'history';
import { ServerApiClient } from '../dto';
import { ActionCreatorsMapObject, bindActionCreators } from 'redux';
import { customMemo } from './custom-memo';
import { ReduxStore, AllActionCreators } from './redux/store';

export interface AppContext {
  store: ReduxStore;
  actions: AllActionCreators;
  history: History<LocationState>;
  server: ServerApiClient;
}

export type AppContextFactory = () => AppContext;

export interface ConfigureContext {
  (store: ReduxStore, getContext?: AppContextFactory): Omit<AppContext, 'actions' | 'store' | 'history'>;
}

export const getContextFactory = (
  store: ReduxStore,
  configureContext: ConfigureContext,
  history: History<LocationState>): AppContextFactory => {

  const getContextMemo = customMemo(() => {

    const bindActions = (actionCreatorMap: ActionCreatorsMapObject) => {
      return Object.entries(actionCreatorMap)
        .map((x) => ({[x[0]]: bindActionCreators(x[1], store.dispatch)}))
        .reduce((x, y) => Object.assign(x, y), {}) as any;
    };

    const context: any = configureContext(store);

    return {
      ...context,
      actions: {
        ...bindActions(context.actions),
      },
      store,
      history,
    };
  });

  return () => {

    const {session, cache} = store.getState();

    return getContextMemo([session, cache]);
  };
};
