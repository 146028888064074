import { AppContextFactoryProps, withAppContext } from '../infrastructure/react-context';
import { BaseComponent } from '../infrastructure/components/BaseComponent';
import { CustomModal } from '../infrastructure/components/CustomModal';
import React from 'react';

/* Форма за потвърждване от потребителя */
interface Props extends AppContextFactoryProps {
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  message?: string;
}

class ConfirmModalImpl extends BaseComponent<Props> {

  render() {

    const {title, message, onClose, onConfirm} = this.props;

    return (
      <div className="confirmation-modal">
        {<CustomModal title={title} onClose={onClose}>
          <div className="row">
            <div className="col-12 p-2 m-2">
              {message && <h3 className="text-center">{message}</h3> }
            </div>
          </div>
          <div className="col-12">
            <div className="text-center">
              <button className="btn btn-md btn-default" type="submit" onClick={onConfirm}>Потвърди</button>
              <button className="btn btn-md btn-warning" type="button" onClick={onClose}>Отказ</button>
            </div>
          </div>
        </CustomModal>
        }
      </div>
    );
  }
}
/* Прозорец за потвърждаване на операция*/
export const ConfirmModal = withAppContext(ConfirmModalImpl);
