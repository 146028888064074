import React, { FunctionComponent, useState, memo, CSSProperties } from 'react';

import { wrapField, cssClasses, BaseInputProps } from './field-helpers';
import { ErrorMessages } from '../errors';

import './TextAreaInput.scss';

type StoredType = string | null;

export interface TextAreaInputProps extends BaseInputProps<StoredType> {
  label: string;
  defaultValue?: StoredType;
  icon?: string;
  inputStyle?: CSSProperties;
  inputClassName?: string;
  rows?: number;
}

const getDefaultValue: () => StoredType = () => null;

const getInitialValue = (props: TextAreaInputProps): StoredType => {
  if (props.defaultValue !== undefined) {
    return props.defaultValue;
  } else {
    return getDefaultValue();
  }
};

const toInputValue = (value: StoredType): string => {
  if (value === null) {
    return '';
  } else {
    return value.toString();
  }
};

const fromInputValue = (inputValue: string): string | null => inputValue.toString() || getDefaultValue();

export const TextAreaInput: FunctionComponent<TextAreaInputProps> = memo((props: TextAreaInputProps) => {

  const {
    label,
    readonly,
    icon,
    style,
    errorMessages,
    onChange,
    setTouched,
    className,
    inputStyle,
    inputClassName,
    customRef,
    rows,
  } = props;

  if (customRef) {
    customRef(props);
  }

  let value = props.value;

  // Initialize
  if (value === undefined) {
    value = getInitialValue(props);
    setTimeout(() => onChange(value), 0);
  }

  const [focused, setFocused] = useState(false);

  const active = value || (!readonly && focused);

  const cssStates = cssClasses({
    readonly,
    invalid: !!errorMessages && !focused,
    active,
    focused,
  });

  return (
    <div className={`textarea-input custom-form-input form-group ${className} ${cssStates}`} style={style}>

      {icon && <i className={`fa fa-${icon} prefix`}/>}

      <label>{label}</label>

      <textarea
        style={inputStyle}
        className={`form-control ${inputClassName || ''}`}
        value={toInputValue(value)}
        rows={rows}
        onBlur={() => {
          if (setTouched) {
            setTouched();
          }

          setFocused(false);
        }}
        onFocus={() => {
          setFocused(true);
        }}
        onChange={(e: any) => {
          onChange(fromInputValue(e.target.value));
        }}
        readOnly={readonly}
      />

      {errorMessages && !focused && <ErrorMessages errors={errorMessages} style={{marginTop: '.3rem'}}/>}
    </div>
  );
});

export const TextAreaField = wrapField(TextAreaInput);
