import { CustomModal } from '../../infrastructure/components/CustomModal';
import React, { Fragment } from 'react';
import LoadingIndicator from '../../infrastructure/components/LoadingIndicator';
import { BaseComponent } from '../../infrastructure/components/BaseComponent';
import { AppContextFactoryProps, withAppContext } from '../../infrastructure/react-context';
import { JRequestListDto } from '../../dto';
import { ErrorMessages } from '../../infrastructure/errors';

interface Props extends AppContextFactoryProps {
  onClose: () => void;
  onSuccessfulDelete: () => void;
  model: JRequestListDto;
}

interface State {
  loading: boolean;
  errorMessages: string[];
}

class DeleteJRequestModalImpl extends BaseComponent<Props, State> {

  state: State = {
    loading: false,
    errorMessages: [],
  };

  deleteItem = async () => {

    const {server} = this.props.getContext();

    this.setState({loading: true});

    const response = await server.deleteJRequest({jRequestID: this.props.model.jRequestID});

    if (!response.success) {
      this.setState({
        loading: false,
        errorMessages: response.errorMessages,
      });
      return;
    }

    this.setState({loading: false});
    this.props.onSuccessfulDelete();
    this.props.onClose();
  };

  render() {

    const {loading, errorMessages} = this.state;
    const {onClose, model} = this.props;

    return (
      <CustomModal title="Анулиране на поръчка" onClose={onClose} className="error-modal">
        <div className="card">
          <div className="card-body text-center">

            {loading && <LoadingIndicator/>}

            {!loading && model && <Fragment>

              <h3 style={{marginTop: '2rem'}}>Поръчка №: {model.jRequestID}</h3>

              <hr/>

              <h3 style={{marginTop: '2rem'}}>Сигурни ли сте, че искате да анулирате тази поръчка?</h3>

              <div>
                {errorMessages && !!errorMessages.length && <ErrorMessages errors={errorMessages}/>}
              </div>

              <div className="button-group" style={{marginTop: '2rem'}}>
                <button className="btn btn-md btn-danger" onClick={this.deleteItem}>ДА</button>
                <button className="btn btn-md btn-warning" onClick={onClose}>Отказ</button>
              </div>


            </Fragment>}
          </div>
        </div>
      </CustomModal>
    );
  }
}

export const DeleteJRequestModal = withAppContext(DeleteJRequestModalImpl);
