import {isOnlyMatch} from '../util';

export const required = (message: string ) => (val: any): string[] => {

  if (val && typeof(val) == 'string' && isWhitespace(val)){
    return [message];
  }
  if (val || val === 0 ) {
    return [];
  }
  else {
    return [message];
  }

  function isWhitespace(input:string) {
    return input.trim()==='';
  }

};

export const rangeNumber = (min: number, max: number, message: string) => (val: any) => {
  if (!val) {
    return [message];
  }

  if (val < min || val > max) {
    return [message];
  }

  return [];
};

export const notEmptyCollection = (message: string) => (val:any[]): string[] => {
  if (val && val.length>0){
    return [];
  }
  else{
    return [message];
  }
}

export const rangeDate = (min: Date, max: Date, message: string) => (val: any) => {
  if (!val) {
    return [message];
  }

  if (val < min || val > max) {
    return [message];
  }

  return [];
};

export const minLength = (length: number, message: string) => (val: any): string[] =>
  !val || (val.length >= length) ? [] : [message];

export const maxLength = (length: number, message: string) => (val: any): string[] =>
  !val || (val.length <= length) ? [] : [message];

// Проверява дали дадено ЕГН е валидно
export const isValidEGN = (egn: any) => {
  if (!egn || egn.length !== 10) {
    return false;
  }

  if (isNaN(egn)) {
    return false;
  }

  const dayCheck = parseInt(egn.substr(4, 2), 10);
  if (dayCheck < 1 || dayCheck > 31) {
    return false;
  }

  let month = parseInt(egn.substr(2, 2), 10);
  if (month > 40) {
    month = month - 40;
  }

  if (month < 1 || month > 12) {
    return false;
  }

  const weights = [2, 4, 8, 5, 10, 9, 7, 3, 6];
  const checkSum = parseInt(egn.substr(9, 1), 10);
  let egnSum = 0;

  for (let i = 0; i < 9; i++) {
    egnSum += parseInt(egn.substr(i, 1), 10) * weights[i];
  }

  let validCheckSum = egnSum % 11;

  if (validCheckSum === 10) {
    validCheckSum = 0;
  }

  return checkSum === validCheckSum;
};

// Проверява дали дадено ЛНЧ е валидно
export const isValidLNCH = (lnch: any) => {
  if (!lnch || lnch.length !== 10) {
    return false;
  }

  const array = [21, 19, 17, 13, 11, 9, 7, 3, 1, 0];

  let result = 0;

  for (let i = 0; i < lnch.length - 1; i++) {
    result += parseInt(lnch[i], 10) * array[i];
  }

  return !isNaN(result) && result % 10 === parseInt(lnch[9], 10);
};

export const patientIdentNumber = (message: string) => (val: any): string[] =>
  !val || (isValidEGN(val) || isValidLNCH(val)) ? [] : [message];

export const phoneNumber = (message: string) => (val: any): string[] =>
  !val || isOnlyMatch(/\+?\d{3,}/, val) ? [] : [message];

export const regex = (pattern: RegExp, message: string) => (val: any): string[] =>
  !val || isOnlyMatch(pattern, val) ? [] : [message];

export const emailRegex = /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export const email = (message: string) => (val: any): string[] =>
  !val || isOnlyMatch(emailRegex, val) ? [] : [message];

export const getBirthdateFromEGN = (egn: string): Date | null => {
  if (!isValidEGN(egn)) {
    return null;
  }

  let year = parseInt(egn.substr(0, 2), 10);
  let month = parseInt(egn.substr(2, 2), 10);
  if (month > 40) {
    month = month - 40;
    year = year + 2000;
  } else {
    year = year + 1900;
  }

  if (month < 1 || month > 12) {
    return null;
  }

  const day = parseInt(egn.substr(4, 2), 10);
  if (day < 1 || day > 31) {
    return null;
  }

  return new Date(year, month - 1, day);
};

export const getSexFromEGN = (egn: string): number => {
  if (!isValidEGN(egn)) {
    return 0;
  }

  const sex = parseInt(egn.substr(8, 1), 10) % 2;
  // Male
  if (sex === 0) {
    return 1;
  }
  // Female
  if (sex === 1) {
    return 2;
  }

  return 0;
};
