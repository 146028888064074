/* tslint:disable:max-classes-per-file */
import React, { Fragment } from 'react';

import { BaseComponent } from '../../infrastructure/components/BaseComponent';
import { AppContextProps } from '../../infrastructure/react-context';
import { DocumentTitle } from '../../infrastructure/DocumentTitle';
import { CustomForm } from '../../infrastructure/components/CustomForm';
import { ErrorMessages } from '../../infrastructure/errors';
import { NumberField } from '../../infrastructure/fields/NumberInput';
import LoadingIndicator from '../../infrastructure/components/LoadingIndicator';
import BackButton from '../../infrastructure/components/BackButton';
import PatientSelectModal from '../PatientSelectModal';
import {
  serverValidations,
  PatientInformationDto,
  CytologyResultDto,
  CytologyNomenclaturesResponse,
} from '../../dto';
import { DateField } from '../../infrastructure/fields/DateInput';
import { SelectField } from '../../infrastructure/fields/SelectInput';
import { CheckboxField, CheckboxInputProps } from '../../infrastructure/fields/CheckboxInput';
import { MultiSelectField, MultiSelectInputProps } from '../../infrastructure/fields/MultiSelectInput';
import { TextAreaField, TextAreaInputProps } from '../../infrastructure/fields/TextAreaInput';
import { TemplatesButton } from '../TemplatesButton';
import { FieldHandle } from '../../infrastructure/fields/field-helpers';

import './cytology-results.scss';
import {Link} from 'react-router-dom';
import {ConfirmationModal} from './ConfirmationModal';

interface Props extends AppContextProps {
  patientRecordID?: number;
  patientID?: number;
  jRequestID?: number;
  onSuccessfulSave?: () => void;
  onDelete: () => void;
}

interface State {
  model: CytologyResultDto | undefined;
  patient: PatientInformationDto | undefined;
  nomenclatures: CytologyNomenclaturesResponse | undefined;

  loading: boolean;

  errorMessages: string[];
  submitLoading: boolean;

  selectingPatient: boolean;
  showConfirmationModal: boolean;
}

const initialState: State = {
  model: undefined,
  patient: undefined,
  nomenclatures: undefined,

  loading: true,

  errorMessages: [],
  submitLoading: false,

  selectingPatient: false,
  showConfirmationModal: false,
};

export class CytologyResultForm extends BaseComponent<Props, State> {

  state: State = initialState;

  conclusionInput: FieldHandle<TextAreaInputProps> | undefined = undefined;
  groupASatisfactoryInput: FieldHandle<CheckboxInputProps> | undefined = undefined;
  adekvatnostBInput: FieldHandle<MultiSelectInputProps> | undefined = undefined;
  adekvatnostVInput: FieldHandle<MultiSelectInputProps> | undefined = undefined;
  adekvatnostGInput: FieldHandle<MultiSelectInputProps> | undefined = undefined;

  getItemID = (): number => this.props.patientRecordID || 0;
  isNew = () => !this.getItemID();

  componentDidMountAsync = async () => {
    await this.initForm();
  };

  componentDidUpdateAsync = async (prevProps: Props) => {
    if (prevProps.patientRecordID !== this.props.patientRecordID) {
      await this.initForm();
    }
  };

  initForm = async () => {
    await this.setStateAsync(initialState);

    if (this.isNew()) {
      if (this.props.patientID) {
        await this.loadNewItem(this.props.patientID);
      } else {
        await this.setStateAsync({selectingPatient: true});
      }
    } else {
      const patientRecordID = this.getItemID();
      await this.loadExistingItem(patientRecordID);
    }
  };

  loadExistingItem = async (patientRecordID: number) => {

    const {server, actions} = this.props.context;

    const response = await server.getCytologyResult({patientRecordID});

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    const model = response.payload.item;

    await this.fetchAdditionalData(model);
  };

  loadNewItem = async (patientID: number) => {

    const {server, actions} = this.props.context;

    const response = await server.newCytologyResult({jRequestID:  this.props.jRequestID || 0});

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    const model = {
      ...response.payload.item,
      patientID,
    };

    await this.fetchAdditionalData(model);
  };

  fetchAdditionalData = async (model: CytologyResultDto) => {

    const {server, actions} = this.props.context;

    // patient data
    const patientResponse = await server.patientInformation({patientID: model.patientID});

    if (!patientResponse.success) {
      actions.errors.setErrorMessages(patientResponse.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    const patient = patientResponse.payload.item;

    // nomenclatures
    const nomenclaturesResponse = await server.cytologyNomenclatures({});

    if (!nomenclaturesResponse.success) {
      actions.errors.setErrorMessages(nomenclaturesResponse.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    const nomenclatures = nomenclaturesResponse.payload;

    await this.setStateAsync({
      model,
      patient,
      nomenclatures,
      loading: false,
      selectingPatient: false,
    });
  };

  saveItem = async (model: CytologyResultDto) => {

    if (!model) {
      throw new Error('The model submitted from the form is undefined.');
    }
    if (!model.isHasValue && !this.state.errorMessages.length && !this.state.showConfirmationModal) {
      await this.setStateAsync({showConfirmationModal: true});
      return;
    }

    const {server, actions} = this.props.context;

    await this.setStateAsync({model, submitLoading: true});

    const response = await server.saveCytologyResult({
      item: {
        ...model,
        jRequestID: this.props.jRequestID || 0,
      },
    });

    if (!response.success) {
      await this.setStateAsync({submitLoading: false, errorMessages: response.errorMessages});
      return;
    }

    await this.setStateAsync({submitLoading: false});

    if (this.props.onSuccessfulSave) {
      this.props.onSuccessfulSave();
    } else {
      actions.router.routerPush(`/doctor/cytology-results/${response.payload.patientRecordID}/print`);
    }
  };

  render() {

    const {
      loading,
      submitLoading,
      model,
      patient,
      nomenclatures,
      errorMessages,
      selectingPatient,
      showConfirmationModal,
    } = this.state;

    const title = `Цитологичен резултат - ${(this.isNew() ? 'Нов' : 'Редакция')}`;

    return (
      <div className="cytology-results-form-page">
        <DocumentTitle title={title}/>

        {selectingPatient && <PatientSelectModal
          onClose={() => this.props.context.actions.router.goBack()}
          onSelection={(x) => this.loadNewItem(x.patientID)}
        />}

        <div className="card">
          <div className="card-header blue white-text">{title}</div>
          <div className="card-body">
            {loading && <LoadingIndicator delay={0}/>}
            {model && patient && nomenclatures &&
            <CustomForm
              values={model}
              onSubmit={this.saveItem}
              render={({isSubmitting, values, submitForm}) => {
                const validations = serverValidations.cytologyResultDto;
                return (
                  <Fragment>

                    {submitLoading && <LoadingIndicator/>}

                    <div className="row" style={{marginTop: '1rem'}}>
                      <div className="col-sm-8 vertical-center">
                        <h4>
                          {patient.patientFullName},
                          ЕГН: {patient.patientIdentNumber} Възраст: {patient.patientAge} год.
                        </h4>
                      </div>
                      <div className="col-sm-4">
                        <div className="button-group">
                          <Link
                            to={`/doctor/doctor-results/${this.props.patientID}`}
                            className="btn btn-md btn-success">
                            Досие
                          </Link>

                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="btn btn-md btn-info">
                            Запази
                          </button>

                          {!this.isNew() && <button
                            onClick={this.props.onDelete}
                            className="btn btn-md btn-danger">
                            Изтриване
                          </button>}

                          <BackButton render={(backButtonProps) =>
                            <a {...backButtonProps}
                               className="btn btn-md btn-warning">
                              Назад
                            </a>
                          }/>
                        </div>
                      </div>
                    </div>

                    <TextAreaField
                        name="clinicalInfoData"
                        label="Клинични данни информационно поле"
                        inputStyle={{ height: 'auto'}}
                        rows={model.clinicalInfoData?.split(/\r\n|\r|\n/).length}
                        readonly={true}
                    />

                    <hr/>

                    {!!errorMessages.length && <div className="row">
                      <div className="col-sm-12">
                        <ErrorMessages errors={errorMessages}/>
                        <hr/>
                      </div>
                    </div>}

                    <div className="row" style={{marginTop: '1rem'}}>
                      <div className="col-md-4 col-sm-6">
                        <NumberField
                          name="resultNum"
                          label="№:"
                          validation={validations.resultNum}
                        />
                      </div>

                      <div className="col-md-4 col-sm-6">
                        <DateField
                          name="sendLabDateTime"
                          label="От дата:"
                          validation={validations.sendLabDateTime}
                        />
                      </div>

                      {/*<div className="col-md-4 col-sm-6">*/}
                      {/*  <DoctorSelectField*/}
                      {/*    name="senderDoctorID"*/}
                      {/*    label="Насочен от :"*/}
                      {/*    validation={validations.senderDoctorID}*/}
                      {/*  />*/}
                      {/*</div>*/}

                      <div className="col-md-4 col-sm-6">
                        <DateField
                          name="resultDate"
                          label="Отговор:"
                          validation={validations.resultDate}
                        />
                      </div>

                      <div className="col-md-4 col-sm-6">
                        <SelectField
                          name="testMethod"
                          label="Начин на изследване :"
                          validation={validations.testMethod}
                          items={nomenclatures.testMethods}
                        />
                      </div>

                      {values.testMethod === 1 && <div className="col-lg-6 col-sm-12">
                        <SelectField
                          name="groupPapanikolauPorcio"
                          label="ГРУПА ПО ПАПАНИКОЛАУ - Порцио:"
                          validation={validations.groupPapanikolauPorcio}
                          items={nomenclatures.papanikoauGroups}
                          nullable
                        />
                      </div>}

                      {values.testMethod === 1 && <div className="col-lg-6 col-sm-12">
                        <SelectField
                          name="groupPapanikolauCervikalenKanal"
                          label="ГРУПА ПО ПАПАНИКОЛАУ - Цервикален канал:"
                          validation={validations.groupPapanikolauCervikalenKanal}
                          items={nomenclatures.papanikoauGroups}
                          nullable
                        />
                      </div>}

                      {values.testMethod === 2 && <div className="col-lg-6 col-sm-12">
                        <SelectField
                          name="groupPapanikolau"
                          label="ГРУПА ПО ПАПАНИКОЛАУ:"
                          validation={validations.groupPapanikolau}
                          items={nomenclatures.papanikoauGroups}
                          nullable
                        />
                      </div>}
                    </div>

                    <hr/>

                    <div className="row" style={{marginTop: '1rem'}}>
                      <div className="col-sm-6">
                        <div className="text-center separator-label">Категории :</div>
                        <CheckboxField
                          label="А. Негативен за интраепителна лезия или злокачествен процес"
                          name="normalCell"
                          validation={validations.normalCell}
                        />

                        <MultiSelectField
                          label="Б. Клетъчни промени свързани с:"
                          items={nomenclatures.categoryBValues}
                          validation={validations.cellChangeWithList}
                          name="cellChangeWithList"
                        />

                        <MultiSelectField
                          label="В. Плоскоклетъчни Интраепителни Лезии/ПИЛ/:"
                          items={nomenclatures.categoryCValues}
                          validation={validations.pilList}
                          name="pilList"
                        />

                        <MultiSelectField
                          label="Г. Клетъчни промени в жлезните клетки:"
                          items={nomenclatures.categoryDValues}
                          validation={validations.cellChangeGlandAdenList}
                          name="cellChangeGlandAdenList"
                        />

                        <CheckboxField
                          label="Ендометриални при жени в менопауза"
                          name="cellChangeGland"
                          validation={validations.cellChangeGland}
                        />

                        <SelectField
                          name="hormoneValue"
                          label="Е. Хормонална оценка"
                          validation={validations.hormoneValue}
                          items={nomenclatures.categoryEValues}
                          nullable
                        />
                      </div>

                      <div className="col-sm-6">
                        <div className="text-center separator-label">БЕТЕЗДА ТЕРМИНОЛОГИЧНА СИСТЕМА :</div>

                        <CheckboxField
                          name="groupASatisfactory"
                          label="А. Задоволителна"
                          validation={validations.groupASatisfactory}
                          customRef={(x) => this.groupASatisfactoryInput = x}
                          onChange={() => {

                            if (this.adekvatnostBInput) {
                              this.adekvatnostBInput.setValue([]);
                            }

                            if (this.adekvatnostVInput) {
                              this.adekvatnostVInput.setValue([]);
                            }

                            if (this.adekvatnostGInput) {
                              this.adekvatnostGInput.setValue([]);
                            }
                          }}
                        />

                        <MultiSelectField
                          name="adekvatnostB"
                          label="Б. Задоволителна, но ограничена от:"
                          items={nomenclatures.adekvatnostBValues}
                          validation={validations.adekvatnostB}
                          customRef={(x) => this.adekvatnostBInput = x}
                          onChange={() => {
                            if (this.groupASatisfactoryInput) {
                              this.groupASatisfactoryInput.setValue(false);
                            }

                            if (this.adekvatnostVInput) {
                              this.adekvatnostVInput.setValue([]);
                            }

                            if (this.adekvatnostGInput) {
                              this.adekvatnostGInput.setValue([]);
                            }
                          }}
                        />

                        <MultiSelectField
                          name="adekvatnostV"
                          label="В. Незадоволителна поради:"
                          items={nomenclatures.adekvatnostVValues}
                          validation={validations.adekvatnostV}
                          customRef={(x) => this.adekvatnostVInput = x}
                          onChange={() => {

                            if (this.groupASatisfactoryInput) {
                              this.groupASatisfactoryInput.setValue(false);
                            }

                            if (this.adekvatnostBInput) {
                              this.adekvatnostBInput.setValue([]);
                            }

                            if (this.adekvatnostGInput) {
                              this.adekvatnostGInput.setValue([]);
                            }

                          }}
                        />

                        <MultiSelectField
                          name="adekvatnostG"
                          label="Г. Незадоволителен за интерпретация:"
                          items={nomenclatures.adekvatnostGValues}
                          validation={validations.adekvatnostG}
                          customRef={(x) => this.adekvatnostGInput = x}
                          onChange={() => {
                            if (this.groupASatisfactoryInput) {
                              this.groupASatisfactoryInput.setValue(false);
                            }

                            if (this.adekvatnostBInput) {
                              this.adekvatnostBInput.setValue([]);
                            }

                            if (this.adekvatnostVInput) {
                              this.adekvatnostVInput.setValue([]);
                            }
                          }}
                        />

                        <CheckboxField
                          label="Д. Други малигнени тумори"
                          name="otherTumor"
                          validation={validations.otherTumor}
                        />

                        <SelectField
                          name="nextExamList"
                          label="Следваща намазка:"
                          validation={validations.nextExamList}
                          items={nomenclatures.nextExamListValues}
                          nullable
                        />

                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-6">

                        <div style={{position: 'relative', marginTop: '1.2rem'}}>
                          <TemplatesButton
                            templateTypeID={18}
                            onTemplateSelected={(text: string) => {
                              if (this.conclusionInput) {
                                this.conclusionInput.setValue((this.conclusionInput.value || '') + text);
                              }
                            }}
                          />

                          <TextAreaField
                            name="conclusion"
                            label="Допълнитено описание:"
                            validation={validations.conclusion}
                            inputStyle={{height: '3rem'}}
                            customRef={(x) => this.conclusionInput = x}
                          />
                        </div>

                      </div>
                      <div className="col-sm-6">

                        <MultiSelectField
                          label="Препоръки:"
                          items={nomenclatures.recommendationsValues}
                          validation={validations.recommendationsList}
                          name="recommendationsList"
                        />

                        <div className="is-validated-box">
                          <CheckboxField
                            name="isHasValue"
                            label="РЕЗУЛТАТЪТ Е ВАЛИДИРАН"
                            validation={validations.isHasValue}
                          />
                        </div>
                      </div>
                      {showConfirmationModal && <ConfirmationModal
                          onClose={() => this.setState({showConfirmationModal: false})}
                          saveResult={submitForm}
                      />}
                    </div>
                  </Fragment>
                );
              }}/>}
          </div>
        </div>
      </div>
    );
  }
}
