import React from 'react';

import {AppContextProps} from '../infrastructure/react-context';
import {BaseComponent} from '../infrastructure/components/BaseComponent';

interface Props extends AppContextProps {
}

interface State {
}

export class ViewObligationsError extends BaseComponent<Props, State> {

  render() {
    return (
      <div className="patient-obligations-error-page">
        <div className="card text-white mb-3 bg-danger">
          <div className="card-header">Възникна грешка</div>
          <div className="card-body">
            <p className="card-text text-white">Възникна грешка при обработване на вашето плащане, моля проверете
              поръчката и опитайте отново.</p>
          </div>
        </div>
      </div>
    );
  }
}
