import React from 'react';
import {RouteComponentProps} from 'react-router-dom';

import {AppContextProps} from '../infrastructure/react-context';
import {BaseComponent} from '../infrastructure/components/BaseComponent';
import {CustomForm} from '../infrastructure/components/CustomForm';

import './demo.scss';

import {DebugValue} from '../infrastructure/components/DebugValue';
import { required } from '../infrastructure/fields/validations';
import {CheckboxField} from '../infrastructure/fields/CheckboxInput';

interface Props extends RouteComponentProps, AppContextProps {
}

interface State {
}

export class CheckboxFieldDemo extends BaseComponent<Props, State> {

  state: State = {};

  submit = async (values: any) => {

    console.info(values);
  };

  render() {

    return (
      <CustomForm onSubmit={this.submit} render={(form) => (
        <div className="card demo-form">
          <div className="card-body">

            <div>

              <CheckboxField
                name="default"
                label="Default"
              />

              <CheckboxField
                name="readonly"
                label="Readonly"
                readonly
              />

              <CheckboxField
                name="defaultValue"
                label="Default value"
                defaultValue={true}
              />

              <CheckboxField
                name="validateWithoutIcon"
                label="With validation"
                validation={[required('Date is required.')]}
              />

            </div>

            <div className="text-center py-3 mt-3">
              <button className="btn btn-warning btn-block">Submit</button>
            </div>

            <DebugValue value={form}/>

          </div>
        </div>
      )}/>
    );
  }
}
