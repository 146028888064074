import { FunctionComponent, useEffect, useCallback, useRef, memo } from 'react';

interface Props {
  onInsideClick?: () => void;
  onOutsideClick?: () => void;
  children?: any;
  registered: boolean;
}

export const OutsideAlerter: FunctionComponent<Props> = memo((props: Props) => {

  const {children, onOutsideClick, onInsideClick, registered} = props;

  const childRef = useRef<any>();

  const clickHandler = useCallback((e) => {
    if (childRef.current) {
      const node = childRef.current;
      if (!node || (node !== e.target && !node.contains(e.target))) {
        if (onOutsideClick) {
          onOutsideClick();
        }
      } else {
        if (onInsideClick) {
          onInsideClick();
        }
      }
    }
  }, []);

  useEffect(() => {
    const shouldAddListeners = (onOutsideClick || onInsideClick) && registered;
    if (shouldAddListeners) {
      document.addEventListener('click', clickHandler, true);
    }
    return () => {
      document.removeEventListener('click', clickHandler, true);
    };
  }, [registered]);

  const refCb = useCallback((ref) => childRef.current = ref, []);

  if (!children) {
    return null;
  }

  return children(refCb);
});
