import React, {
  FunctionComponent,
  useState,
  DetailedHTMLProps,
  InputHTMLAttributes,
  PureComponent, memo,
} from 'react';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import { format, trimDate, newDateWithoutTime } from '../util';

import 'react-day-picker/lib/style.css';

import './DateInput.scss';

import { wrapField, cssClasses, BaseInputProps } from './field-helpers';
import { ErrorMessages } from '../errors';

type InputType = Date | undefined;
type StoredType = Date | null;

interface Props extends BaseInputProps<StoredType> {
  label: string;
  defaultValue?: StoredType;
  nullable?: boolean;
  icon?: string;
  disabledDays?: (date: Date) => boolean;
}

const MONTHS = [
  'Януари', 'Февруари', 'Март', 'Април', 'Май', 'Юни',
  'Юли', 'Август', 'Септември', 'Октомври', 'Ноември', 'Декември',
];
const WEEKDAYS_LONG = ['Понеделник', 'Вторник', 'Сряда', 'Четвъртък', 'Петък', 'Събота', 'Неделя'];
const WEEKDAYS_SHORT = ['Нед', 'Пон', 'Вт', 'Ср', 'Чет', 'Пет', 'Съб'];

const getDefaultValue = ({nullable}: Props): StoredType => {
  if (nullable) {
    return null;
  } else {
    return newDateWithoutTime();
  }
};

const getInitialValue = (props: Props): StoredType => {
  if (props.defaultValue !== undefined) {
    return props.defaultValue;
  } else {
    return getDefaultValue(props);
  }
};

const toInputValue = (value: StoredType): InputType => value === null ? undefined : value;
const fromInputValue = (inputValue: InputType): StoredType => {
  return inputValue === undefined ? null : trimDate(inputValue);
};

interface FancyInputProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  focused: boolean;
  forwardedRef: any;
}

class FocusableInput extends PureComponent<FancyInputProps> {

  _input: HTMLInputElement | null = null;

  processFocusedState = () => {
    if (this.props.focused && this._input !== document.activeElement) {
      (this._input as any).focus();
    } else if (!this.props.focused && this._input === document.activeElement) {
      (this._input as any).blur();
    }
  };

  // noinspection JSUnusedGlobalSymbols
  componentDidMount() {
    this.processFocusedState();
  }

  // noinspection JSUnusedGlobalSymbols
  componentDidUpdate(_prevProps: any, _prevState: any) {
    this.processFocusedState();
  }

  render() {
    const {focused, forwardedRef, ...rest} = this.props;

    return (
      <input {...rest} ref={(instance) => {
        this._input = instance;
        forwardedRef(instance);
      }}/>
    );
  }
}

export const DateInput: FunctionComponent<Props> = memo((props: Props) => {

  const {label, readonly, icon, nullable, style, disabledDays, errorMessages, setTouched, onChange, className} = props;

  let value = props.value;

  // Initialize
  if (value === undefined) {
    value = getInitialValue(props);
    setTimeout(() => onChange(value), 0);
  }

  const [focused, setFocused] = useState(false);

  const active = value !== null || (!readonly && focused);

  const cssStates = cssClasses({
    readonly,
    invalid: !!errorMessages && !focused,
    active,
    focused,
  });

  const onClear = () => {
    onChange(null);

    setFocused(false);
    if (setTouched) {
      setTouched();
    }
  };

  return (
    <div className={`date-input custom-form-input form-group ${className} ${cssStates}`} style={style}>

      {icon && <i className={`fa fa-${icon} prefix`}/>}

      <div className="datepicker-wrapper">
        <DayPickerInput
          dayPickerProps={{
            locale: 'bg',
            months: MONTHS,
            weekdaysLong: WEEKDAYS_LONG,
            weekdaysShort: WEEKDAYS_SHORT,
            firstDayOfWeek: 1,
            disabledDays,
          }}
          format=""
          value={toInputValue(value)}
          onDayChange={(val) => {

            const newValue = fromInputValue(val);

            onChange(newValue);

            if (setTouched) {
              setTouched();
            }

            setFocused(false);
          }}
          component={React.forwardRef((compProps: any, ref: any) => (
            <FocusableInput
              forwardedRef={ref}
              focused={focused}
              className=""
              onChange={compProps.onChange}
              onClick={compProps.onClick}
              onKeyDown={compProps.onKeyDown}
              onKeyUp={compProps.onKeyUp}
              value={(value !== null ? format(value, 'dd.MM.yyyy г.') : '')}
              readOnly
              onBlur={(...args) => {
                if (setTouched) {
                  setTouched();
                }
                setFocused(false);

                compProps.onBlur(...args);
              }}
              onFocus={(...args) => {
                setFocused(true);

                compProps.onFocus(...args);
              }}
            />
          ))}
        />

        {nullable && !readonly && <span className="btn btn-default clear-button" onClick={onClear}>✘</span>}
      </div>

      <label>{label}</label>

      {errorMessages && !focused && <ErrorMessages errors={errorMessages}/>}

    </div>
  );
});

export const DateField = wrapField(DateInput);
