import React from 'react';

import {AppContextProps} from '../infrastructure/react-context';
import {BaseComponent} from '../infrastructure/components/BaseComponent';
import {serverValidations, SignRsaDocumentRequest} from '../dto';
import {CustomForm} from '../infrastructure/components/CustomForm';
import {TextField} from '../infrastructure/fields/TextInput';
import {ErrorMessages} from '../infrastructure/errors';
import {CustomModal} from '../infrastructure/components/CustomModal';

interface Props extends AppContextProps {
  signDocID: number;
  onClose: () => void;
  onSigned: () => void;
}

interface State {
  errorMessages: string[];
}

export class RsaSignDocumentModal extends BaseComponent<Props, State> {
  state: State = {
    errorMessages: [],
  };

  signDocument = async (data: SignRsaDocumentRequest) => {
    await this.setStateAsync({errorMessages: []});

    const {server} = this.props.context;
    const certificatesResponse = await server.signRsaDocument({signDocID: this.props.signDocID, pin: data.pin});
    if (!certificatesResponse.success) {
      await this.setStateAsync({errorMessages: certificatesResponse.errorMessages});
      return;
    }

    this.props.onSigned();
  };

  render() {
    const {errorMessages} = this.state;
    const {onClose} = this.props;

    const val = serverValidations.generateCertificateRequest;

    return (
      <CustomModal title={'Въвеждане на PIN'} onClose={onClose}>
        <CustomForm onSubmit={this.signDocument} render={({isSubmitting}) => (
          <div className="row">
            <div className="col-md-12">
              <TextField label={'ПИН'} name={'pin'} validation={val.pin}/>
            </div>
            <div className="col-md-6">
              <button className="btn btn-success w-100" disabled={isSubmitting}>подпиши</button>
            </div>
            <div className="col-md-6">
              <button className="btn btn-warning w-100" disabled={isSubmitting} onClick={onClose}>Отказ</button>
            </div>
          </div>
        )}/>
        {errorMessages.length > 0 &&
            <div className="row mt-2">
                <div className="col-12">
                    <ErrorMessages errors={errorMessages}/>
                </div>
            </div>
        }
      </CustomModal>
    );
  }
}
