import React from 'react';

import { AppContextProps } from '../infrastructure/react-context';
import { BaseComponent } from '../infrastructure/components/BaseComponent';
import { PatientVisitsResponse } from '../dto';
import LoadingIndicator from '../infrastructure/components/LoadingIndicator';
import { DocumentTitle } from '../infrastructure/DocumentTitle';
import { JUserConfirmModal } from '../common/JUserConfirmModal';

interface Props extends AppContextProps {
}

interface State {
  loading: boolean;
  deleteWAppointmentPatientID: number | undefined;
  response: PatientVisitsResponse | undefined;
}

export class ViewVisits extends BaseComponent<Props, State> {

  state: State = {
    loading: false,
    response: undefined,
    deleteWAppointmentPatientID: undefined,
  };

  componentDidMountAsync = async () => {

    await this.reloadItems();
  };

  reloadItems = async () => {

    const {server, actions} = this.props.context;

    await this.setStateAsync({loading: true});

    const response = await server.patientVisits({});

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loading: false, response: undefined});
      return;
    }

    await this.setStateAsync({
      loading: false,
      response: response.payload,
    });
  };

  onDeleteWappointment = async (wAppointmenID: number) => {

    this.setState({deleteWAppointmentPatientID: undefined});
    const {server, actions} = this.props.context;

    await this.setStateAsync({loading: true});

    const response = await server.cancellationAppointment({wAppointmentPatientID: wAppointmenID});

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loading: false, response: undefined});
      return;
    }

    await this.reloadItems();

  };

  render() {

    const {loading, response} = this.state;

    return (
      <div className="patient-visits-page">

        <DocumentTitle title="Посещения"/>

        {loading && <LoadingIndicator/>}
        <h3 className="text-center">Планирани посещения</h3>
        {response && !!response.appointments.length && response.appointments.map((day) => (
          <div className="card" key={day.wAppointmentPatientID} style={{marginTop: '1.5rem'}}>
            <div className="card-header blue white-text">
              Дата и час на посещението: {day.visitDateTimeFormated}
            </div>
            <div className="card-body">
              При: <span className="font-weight-bold">{day.doctorName}</span>
            </div>
            <div className="card-body">
              Дейност/Кабинет: <span className="font-weight-bold">{day.workKabinetTypeName}</span>
            </div>
            <div className="card-body">
              Пореден номер за деня: <span className="font-weight-bold">({day.calculatedNum})</span>
            </div>
            <div className="text-right">
              <button type="button" className="btn btn-amber"
                      onClick={() => this.setState({deleteWAppointmentPatientID: day.wAppointmentPatientID})}>
                Анулирай
              </button>
            </div>
          </div>
        ))}
        {response && !response.appointments.length && <div className="card" style={{marginTop: '1.5rem'}}>
          <div className="card-body text-center padded">
            Към момента няма данни за предстоящи посещения.
          </div>
        </div>}

        {!!this.state.deleteWAppointmentPatientID && <JUserConfirmModal
          deleteMessage={'Моля, потвърдете анулирането на часа!'}
          onCancel={() => this.setState({deleteWAppointmentPatientID: undefined})}
          onSuccess={() => this.onDeleteWappointment(this.state.deleteWAppointmentPatientID as number)}>
        </JUserConfirmModal>}
      </div>
    );
  }
}
