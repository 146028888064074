import React from 'react';

import {BaseComponent} from './components/BaseComponent';
import {CustomModal} from './components/CustomModal';

interface Props {
  onClose: () => void;
  message: string | string[];
  title?: string;
}

interface State {
}

export class AlertModal extends BaseComponent<Props, State> {
  render() {
    return (

      <CustomModal title={this.props.title || 'Внимание'} onClose={this.props.onClose} className="error-modal">

        {Array.isArray(this.props.message) && this.props.message.map((msg, index) => <h2 key={index}>{msg}</h2>)}
        {!Array.isArray(this.props.message) && <h2>{this.props.message}</h2>}

        <button className="btn btn-danger btn-md" onClick={this.props.onClose}>Затвори</button>

      </CustomModal>
    );
  }
}
