import {parse, format} from 'date-fns';
import cornerstone from 'cornerstone-core';

export const formatNumberPrecision = (p_number: number, p_precision: number) => {
  if (p_number !== undefined) {
    return parseFloat(p_number.toString()).toFixed(p_precision);
  }
};

export  const areStringArraysEqual = (arr1: [], arr2: []) => {
  if (arr1 === arr2) { return true; } // Identity
  if (!arr1 || !arr2) { return false; } // One is undef/null
  if (arr1.length !== arr2.length) { return false; } // Diff length

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) { return false; }
  }

  return true;
};

export const formatDA = (date: string, strFormat = 'dd/MM/yyyy г.') => {
  if (!date) {
    return;
  }

  try {
    const parsedDateTime = parse(date, 'yyyyMMdd', new Date());
    return format(parsedDateTime, strFormat);
  } catch (err) {
    // swallow?
  }

  return;
};

export const formatPN = (name: string) => {
  if (!name) {
    return;
  }

  // Convert the first ^ to a ', '. String.replace() only affects
  // the first appearance of the character.
  // const commaBetweenFirstAndLast = name.replace('^', ', ');

  // Replace any remaining '^' characters with spaces
  const cleaned = name.replace(/\^/g, ' ');

  // Trim any extraneous whitespace
  return cleaned.trim();
};

export const formatTM = (time: string, strFormat = 'HH:mm:ss') => {
  if (!time) {
    return;
  }

  // DICOM Time is stored as HHmmss.SSS, where:
  //      HH 24 hour time:
  //      m mm    0..59   Minutes
  //      s ss    0..59   Seconds
  //      S SS SSS    0..999  Fractional seconds
  //
  // Goal: '24:12:12'
  try {
    const inputFormat = 'HHmmss.SSS';
    const strTime = time.substring(0, inputFormat.length);
    const parsedDateTime = parse(strTime, inputFormat.substring(0, strTime.length), new Date(0));
    return format(parsedDateTime, strFormat);
  } catch (err) {
    // swallow?
  }

  return;
};

export const isValidNumber = (value: number) => {
  return !isNaN(value);
};

export const getCompression = (imageId: string): string => {
  const generalImageModule =
    cornerstone.metaData.get('generalImageModule', imageId) || {};
  const {
    lossyImageCompression,
    lossyImageCompressionRatio,
    lossyImageCompressionMethod,
  } = generalImageModule;

  if (lossyImageCompression === '01' && lossyImageCompressionRatio !== '') {
    const compressionMethod = lossyImageCompressionMethod || 'Lossy: ';
    const compressionRatio = formatNumberPrecision(lossyImageCompressionRatio, 2);
    return compressionMethod + compressionRatio + ' : 1';
  }

  return 'Lossless / Uncompressed';
};
