import {PatientObligation} from '../dto';
import React, {PureComponent} from 'react';

import './ObligationList.scss';

interface Props {
  obligations: PatientObligation[];
  onCreateCart: (selectedObligations: string[]) => void;
}

interface State {
  obligations: PatientObligation[];
}

export class ObligationList extends PureComponent<Props, State> {
  state: State = {
    obligations: this.props.obligations,
  };

  onItemCheck = (obligationKey: string) => {
    this.setState((state) => {
        return {
          ...state,
          obligations: state.obligations.map((p) => {
            if (p.obligationKey === obligationKey) {
              return {...p, isCheck: !p.isCheck};
            }

            return p;
          }),
        };
      },
    );
  };

  render() {
    const {obligations} = this.state;

    return (
      <div className="obligations-wrapper">
        <table>
          <thead className="blue text-white p-3">
          <tr>
            <th>Инфо</th>
            <th>Име на услугата</th>
            <th>Сума за плащане</th>
            <th>Юридическо лице</th>
            <th/>
          </tr>
          </thead>

          <tbody>
          {obligations.map((item) => (
            <tr key={item.obligationKey}>
              <td>{item.obligationInfo}</td>
              <td>{item.serviceName}</td>
              <td>{item.forPay} лв.</td>
              <td>{item.hospitalName}</td>
              <td>
                <input type="checkbox" checked={item.isCheck}
                       onChange={() => this.onItemCheck(item.obligationKey)}/>
                <button className={!item.isCheck ? 'btn btn-red btn-sm ml-4' : 'btn btn-blue btn-sm ml-4'}
                        onClick={() => this.onItemCheck(item.obligationKey)}>
                  {item.isCheck ? 'Отмаркирай' : 'Избери'}
                </button>
              </td>
            </tr>
          ))}
          </tbody>
        </table>

        <div className="row mt-3">
          <div className="col-sm-6 col-md-8">
            <h5>Общо за групата: {obligations.filter((p) => p.isCheck)
              .reduce((a, item) => a + item.forPay, 0)} лв.
            </h5>
          </div>
          <div className="col-sm-6 col-md-4">
            <button
              className="btn btn-red w-100"
              onClick={() => this.props.onCreateCart(obligations
                .filter((p) => p.isCheck)
                .map((p) => p.obligationKey))
              }>
              Направи плащане &gt;&gt;
            </button>
          </div>
        </div>

      </div>);
  }
}
