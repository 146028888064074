import React from 'react';

import {AppContextProps} from '../infrastructure/react-context';
import {BaseComponent} from '../infrastructure/components/BaseComponent';
import {PatientBillingInformationResponse, BillingInvoiceItem} from '../dto';
import LoadingIndicator from '../infrastructure/components/LoadingIndicator';
import {format, newDateWithoutTime, downloadFile} from '../infrastructure/util';
import {DocumentTitle} from '../infrastructure/DocumentTitle';

interface Props extends AppContextProps {
}

interface State {
  loading: boolean;
  response: PatientBillingInformationResponse | undefined;
}

export class ViewBillingInformation extends BaseComponent<Props, State> {

  state: State = {
    loading: false,
    response: undefined,
  };

  componentDidMountAsync = async () => {

    const {server, actions} = this.props.context;

    await this.setStateAsync({loading: true});

    const response = await server.patientBillingInformation({});

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loading: false, response: undefined});
      return;
    }

    await this.setStateAsync({loading: false, response: response.payload});
  };

  downloadInvoice = async (fdocID: number, fdocNum: number) => {
    const {server, actions} = this.props.context;

    await this.setStateAsync({loading: true});

    const response = await server.patientBillingInvoiceDownload({fDocID: fdocID});

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    await this.setStateAsync({loading: false});
    downloadFile(null, response.payload.invoicePdf, `invoice_${fdocNum}.pdf`);
  };

  render() {

    const {loading, response} = this.state;

    return (
      <div className="patient-billing-information-page">

        <DocumentTitle title="Финанси"/>
        <div className="card" style={{marginTop: '2rem'}}>
          <div className="card-header blue white-text">
            Вашите задължения към {format(newDateWithoutTime(), 'dd.MM.yyyy г.')} са:
          </div>
          <div className="card-body" style={{padding: '1rem'}}>

            {loading && <LoadingIndicator/>}

            {response && !!response.items.length && response.items.map((x, key) => (
              <div className="card" key={key} style={{marginBottom: '.7rem'}}>
                <div className="card-body padded">
                  {x.hospitalName}: {x.patientRestPrice} лв
                </div>
              </div>
            ))}

            {response && !response.items.length && <div className="card">
                <div className="card-body text-center padded">
                    <h3>Към момента нямате начислени задължения.</h3>
                </div>
            </div>}
          </div>
        </div>

        <div className="card" style={{marginTop: '2rem'}}>
          <div className="card-header blue white-text">
            Фактури към дата {format(newDateWithoutTime(), 'dd.MM.yyyy г.')} за последните 6 месеца:
          </div>
          <div className="card-body" style={{padding: '1rem'}}>

            {loading && <LoadingIndicator/>}

            {response && !!response.invoices.length &&
            <div className="table-responsive" style={{marginTop: '1rem'}}>
                <table className="table list-table">
                    <thead className="blue text-white">
                    <tr>
                        <th>Дата на издаване</th>
                        <th>Фактура №</th>
                        <th>Изтегли</th>
                    </tr>
                    </thead>

                    <tbody>
                    {response.invoices.map((item: BillingInvoiceItem) => (
                      <tr key={item.fDocID}>
                        <td className="no-wrap">{format(item.fDocDate, 'dd.MM.yyyy г.')}</td>
                        <td className="no-wrap">{item.fDocNum}</td>
                        <td>
                          <button className="btn btn-primary"
                                  onClick={() => this.downloadInvoice(item.fDocID, item.fDocNum)}>Изтегли
                          </button>
                        </td>
                      </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            }

            {response && !response.invoices.length && <div className="card">
                <div className="card-body text-center padded">
                    <h3>Нямате издадени фактури в последните 6 месеца.</h3>
                </div>
            </div>}
          </div>
        </div>
      </div>
    );
  }
}
