import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { BaseComponent } from '../../infrastructure/components/BaseComponent';
import { AppContextProps } from '../../infrastructure/react-context';
import { AttachedResultDto, PatientInformationDto } from '../../dto';
import LoadingIndicator from '../../infrastructure/components/LoadingIndicator';
import { AttachedResultForm } from './AttachedResultForm';
import { DocumentTitle } from '../../infrastructure/DocumentTitle';

import './attached-results.scss';
import PatientSelectModal from '../PatientSelectModal';

interface Props extends RouteComponentProps, AppContextProps {
}

interface State {
  model: AttachedResultDto | undefined;
  loading: boolean;

  patient: PatientInformationDto | undefined;
  selectingPatient: boolean;

  errorMessages: string[];
  submitLoading: boolean;
}

export class AttachedResultNew extends BaseComponent<Props, State> {

  state: State = {
    loading: true,

    model: undefined,
    patient: undefined,

    selectingPatient: true,

    errorMessages: [],
    submitLoading: false,
  };

  componentDidMountAsync = async () => {
  };

  saveItem = async (model: AttachedResultDto) => {

    if (!model) {
      throw new Error('The model submitted from the form is undefined.');
    }

    const {server, actions} = this.props.context;

    await this.setStateAsync({model, submitLoading: true});

    const response = await server.saveAttachedResult({item: model});

    if (!response.success) {
      await this.setStateAsync({submitLoading: false, errorMessages: response.errorMessages});
      return;
    }

    await this.setStateAsync({submitLoading: false});
    actions.router.routerPush(`/doctor/attached-results/${response.payload.patientRecordID}/print`);
  };

  goBack = () => {
    const {actions} = this.props.context;
    actions.router.goBack();
  };

  patientSelected = async (patient: PatientInformationDto) => {

    const {server, actions} = this.props.context;

    await this.setStateAsync({loading: true, model: undefined});

    const response = await server.newAttachedResult({});

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    await this.setStateAsync({
      loading: false,
      selectingPatient: false,
      model: {
        ...response.payload.item,
        patientID: patient.patientID,
      },
      patient,
    });
  };

  render() {

    const {loading, submitLoading, model, patient, errorMessages, selectingPatient} = this.state;

    if (selectingPatient) {
      return (<PatientSelectModal onClose={this.goBack} onSelection={this.patientSelected}/>);
    }

    return (
      <div className="attached-results-new-page">

        <DocumentTitle title="Прикачени резултати - Нов резултат"/>

        <div className="card">
          <div className="card-header blue white-text">Прикачени резултати - Нов резултат</div>
          <div className="card-body">
            {loading && <LoadingIndicator delay={0}/>}
            {model && patient && <AttachedResultForm
              model={model}
              patient={patient}
              errorMessages={errorMessages}
              onSubmit={this.saveItem}
              submitLoading={submitLoading}
            />}
          </div>
        </div>
      </div>
    );
  }
}
