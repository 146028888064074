import {BaseComponent} from '../infrastructure/components/BaseComponent';
import {studyArchiveStatusEnumMap} from './Viewer/DicomViewer';
import React, {Fragment} from 'react';
import {DicomProcessButton} from './DicomHelper';
import {ErrorMessages} from '../infrastructure/errors';
import {AppContextProps} from '../infrastructure/react-context';
import {DicomImageItem} from '../dto';

interface Props extends AppContextProps {
  model: DicomImageItem;
}

interface State {
  errorMessages: string[];
}

export class SenderDoctorDicomResultRow extends BaseComponent<Props, State> {
  state: State = {
    errorMessages: [],
  };

  render() {
    const {
      errorMessages,
    } = this.state;

    const {model} = this.props;

    return (
      <Fragment>
        <tr key={model.patientRecID} className="table-primary">
          <td>
            <DicomProcessButton dicomDownloadID={model.dicomDownloadID} status={studyArchiveStatusEnumMap[model.statusID]}
                                studyInstanceUID={model.studyInstanceUID}
                                onError={async (errors) => await this.setStateAsync({errorMessages: errors})}
                                context={this.props.context}/>
          </td>
          <td colSpan={2}>{model.description}</td>
          <td>{model.patientIdentNumber}</td>
          <td>{model.patientNames}</td>
          <td></td>
          <td>DICOM изследване</td>
          <td></td>
        </tr>

        <ErrorMessages className="mb-2" errors={errorMessages}/>

      </Fragment>
    );
  }
}
