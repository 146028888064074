import {BaseComponent} from '../infrastructure/components/BaseComponent';
import {StudyArchiveStatus} from './Viewer/DicomViewer';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner';
import React from 'react';
import axios from 'axios';
import {AppContextProps} from '../infrastructure/react-context';
import {EmailForStudyArchiveModal} from './EmailForStudyArchiveModal';

interface Props extends AppContextProps {
  dicomDownloadID: number;
  status: StudyArchiveStatus;
  studyInstanceUID: string;
  onError: (errors: string[]) => void;
  onForProcessing?: () => void;
}

interface State {
  isDownloading: boolean;
  loadingNewArchive: boolean;
  percentDownloaded: number | undefined;
  showEmailModal: boolean;
}

export class DicomProcessButton extends BaseComponent<Props, State> {
  state: State = {
    isDownloading: false,
    loadingNewArchive: false,
    percentDownloaded: undefined,
    showEmailModal: false,
  };

  setJStudyArchiveForProcessing = async (downloadEmail?: string) => {
    await this.setStateAsync({loadingNewArchive: true});
    const {server} = this.props.context;

    const response = await server.setPatientDicomJStudyArchiveForProcessing({
      studyInstanceUID: this.props.studyInstanceUID,
      downloadEmail,
    });

    if (!response.success) {
      this.props.onError(response.errorMessages);
      await this.setStateAsync({loadingNewArchive: false});
      return;
    }

    if (this.props.onForProcessing) {
      this.props?.onForProcessing();
    }

  };

  downloadJStudyArchive = async () => {
    await this.setStateAsync({isDownloading: true});
    const {server} = this.props.context;

    const response = await server.downloadDicomJStudyArchive({dicomDownloadID: this.props.dicomDownloadID});
    if (!response.success) {
      this.props.onError(response.errorMessages);
      return;
    }

    axios({
      method: 'get',
      url: response.payload.dicomEndpoint,
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      onDownloadProgress: async (e) => {
        await this.setStateAsync({percentDownloaded: Math.floor(e.loaded / e.total! * 100)});
      },
    })
      .then(async ({data: blob}) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = response.payload.archiveName + '.zip';
        link.click();
        await this.setStateAsync({isDownloading: false});
      });
  };

  render() {
    const {status} = this.props;
    const {loadingNewArchive, percentDownloaded, isDownloading, showEmailModal} = this.state;

    return (
      <div>
        {(() => {
          if (status === 'None') {
            return <button
              type="button"
              disabled={loadingNewArchive}
              className="btn btn-md btn-info m-0"
              onClick={async () => await this.setStateAsync({showEmailModal: true})}>
              СВАЛИ ОБРАЗИТЕ{loadingNewArchive && <FontAwesomeIcon icon={faSpinner} spin size="lg"/>}
            </button>;
          }
          if (status === 'Ready') {
            return <button type="button" onClick={() => this.downloadJStudyArchive()} disabled={isDownloading}
                           className="btn btn-md m-0 btn-primary">
              {isDownloading ?
                <span style={{fontSize: '.9rem'}}>{percentDownloaded}% <FontAwesomeIcon icon={faSpinner} spin
                                                                                        size="lg"/></span> :
                <span>Изтегли ОБРАЗИТЕ</span>
              }
            </button>;
          }
          if (status === 'Processing') {
            return <button type="button" disabled={true} className="btn btn-md m-0 btn-info">
              СЪЗДАВА СЕ <FontAwesomeIcon icon={faSpinner} spin size="lg"/>
            </button>;
          }
          if (status === 'Failed') {
            return <button type="button" disabled={true} className="btn btn-md m-0 btn-danger">
              Грешка с архива
            </button>;
          }
        })()}

        {showEmailModal &&
            <EmailForStudyArchiveModal
                onClose={async () => {
                  await this.setStateAsync({showEmailModal: false});
                  await this.setJStudyArchiveForProcessing();
                }}
                onConfirm={async (downloadEmail) => {
                  await this.setStateAsync({showEmailModal: false});
                  await this.setJStudyArchiveForProcessing(downloadEmail);
                }}
            />
        }
      </div>

    );
  }
}
