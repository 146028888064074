import React, {Fragment} from 'react';

import {BaseComponent} from '../../infrastructure/components/BaseComponent';
import {AppContextProps} from '../../infrastructure/react-context';
import LoadingIndicator from '../../infrastructure/components/LoadingIndicator';
import {CustomModal} from '../../infrastructure/components/CustomModal';
import {CustomForm} from '../../infrastructure/components/CustomForm';
import {DateField} from '../../infrastructure/fields/DateInput';
import {TextField} from '../../infrastructure/fields/TextInput';
import {HoursMinutes} from '../../infrastructure/fields/DateHoursMinutes';
import {SelectField} from '../../infrastructure/fields/SelectInput';
import {DoctorAgentMeetingDto, serverValidations} from '../../dto';
import {SelectItem} from '../../infrastructure/fields/select-item';

interface Props extends AppContextProps {
  agentMeetingID?: number;
  onClose: (success: boolean) => void;
}

interface State {
  loading: boolean;
  model: DoctorAgentMeetingDto | undefined;
  medicalAgents: SelectItem[];
  statuses: SelectItem[];
}

export class DoctorAgentMeetingNewEditModal extends BaseComponent<Props, State> {

  state: State = {
    loading: false,
    model: undefined,
    medicalAgents: [],
    statuses: [],
  };

  loadModelNewEdit = async (agentMeetingId: number | undefined) => {
    const {server, actions} = this.props.context;

    await this.setStateAsync({loading: true});

    const response = await server.getMedicalAgentNewEdit({agentMeetingId});

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    await this.setStateAsync({
      loading: false,
      medicalAgents: response.payload.medicalAgentsSelectItems,
      model: response.payload.item,
      statuses: response.payload.meetingStatuses,
    });
  };

  componentDidMountAsync = async () => {
    await this.loadModelNewEdit(this.props.agentMeetingID);
  };

  onSubmit = async (item: any) => {
    const {server, actions} = this.props.context;

    await this.setStateAsync({loading: true});

    const hourAndTime = item.fakeTime.split(':');
    item.meetingDatetime.setHours(parseInt(hourAndTime![0], 10));
    item.meetingDatetime.setMinutes(parseInt(hourAndTime![1]!, 10));

    const response = await server.saveMedicalAgent({item});

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    this.props.onClose(true);
  };

  render() {
    const {
      loading,
      model,
      medicalAgents,
      statuses,
    } = this.state;

    const validations = serverValidations.doctorAgentMeetingDto;

    return (
      <CustomModal title={`Среща (agentMeetingID: ${model?.agentMeetingID})`} onClose={() => this.props.onClose(false)}>

        {loading && <LoadingIndicator delay={0}/>}

        {!loading &&
            <CustomForm values={model} onSubmit={this.onSubmit} render={(_) => (
              <Fragment>
                <div className="row filter-row">
                  <DateField
                    className="col-sm-6"
                    label={'Дата и час на срещата'}
                    name={'meetingDatetime'}
                    validation={validations.meetingDatetime}/>

                  <SelectField
                    className="col-sm-6"
                    label="Час"
                    name={'fakeTime'}
                    items={HoursMinutes}
                    defaultValue={model?.meetingTime}
                    searchable={false}
                    validation={validations.meetingDatetime}/>
                </div>

                <SelectField
                  label={'Лекар'}
                  items={medicalAgents}
                  name={'doctorid'}
                  searchable={true}
                  validation={validations.agentDoctorid}/>

                <SelectField
                  label={'Статус'}
                  items={statuses}
                  name={'acodeStatusID'}
                  searchable={true}
                  validation={validations.acodeStatusID}/>

                <TextField
                  label={'Място на срещата'}
                  name={'meetingPlace'}
                  validation={validations.meetingPlace}/>

                <TextField
                  label={'Съдържание, на срещата'}
                  name={'meetingSubject'}
                  validation={validations.meetingSubject}/>

                <div className="button-group">
                  <button
                    type="submit"
                    className="btn btn-md btn-info">
                    Запази
                  </button>

                  <button
                    type="button"
                    className="btn btn-md btn-default"
                    onClick={() => this.props.onClose(false)}>
                    Изход
                  </button>
                </div>
              </Fragment>
            )}/>}
      </CustomModal>
    );
  }
}
