import React from 'react';
import {AppContextProps} from '../../infrastructure/react-context';
import {BaseComponent} from '../../infrastructure/components/BaseComponent';
import {PatientImagingDto} from '../../dto';
import {DocumentTitle} from '../../infrastructure/DocumentTitle';
import {ErrorMessages} from '../../infrastructure/errors';
import LoadingIndicator from '../../infrastructure/components/LoadingIndicator';
import {PatientImagingCard} from './PatientImagingCard';

import './PatientImaging.scss';

interface Props extends AppContextProps {
}

interface State {
  loading: boolean;
  errorMessages: string[];
  model: PatientImagingDto[] | undefined;
}

export class PatientImaging extends BaseComponent<Props, State> {
  state: State = {
    loading: false,
    errorMessages: [],
    model: undefined,
  };

  async componentDidMountAsync(): Promise<void> {
    const {server} = this.props.context;

    const resp = await server.getPatientImaging({});
    if (!resp.success) {
      await this.setStateAsync({errorMessages: resp.errorMessages, loading: false});
      return;
    }

    await this.setStateAsync({model: resp.payload.patientImagingDto, loading: false});
  }

  render() {
    const {loading, errorMessages, model} = this.state;

    return (
      <div className="patient-worklist-wrapper">
        <DocumentTitle title={'Пациентски изследвания'}/>

        {loading && <LoadingIndicator delay={0}/>}
        {!!errorMessages.length && <ErrorMessages errors={errorMessages}/>}

        {!loading &&
            <div className="card">
                <h5 className="card-header blue text-white">Образна диагностика</h5>
                <div className="card-body d-flex flex-column" style={{gap: '.5rem'}}>
                  {model &&
                    model.map((x) => (
                      <PatientImagingCard
                        model={x}
                        context={this.props.context}
                      />
                    ))
                  }
                </div>
            </div>}
      </div>
    );
  }
}
