import { AppContextFactoryProps } from './react-context';
import React, { FunctionComponent, useCallback } from 'react';
import { CustomModal } from './components/CustomModal';
import { connect } from 'react-redux';
import { ReduxState } from './redux/store';
import { ModalsState } from './global.state';

interface GlobalModalsProps extends AppContextFactoryProps {
}

interface InjectedGlobalModalsProps extends GlobalModalsProps {
  modals: ModalsState;
}

export const GlobalModalsImpl: FunctionComponent<GlobalModalsProps> = (props) => {

  const injectedProps = props as InjectedGlobalModalsProps;
  const {message} = injectedProps.modals;

  const onClose = useCallback(() => {
    const context = props.getContext();
    context.actions.modals.clearAll();
  }, [message]);

  if (!message) {
    return null;
  }

  return (
    <CustomModal title="Внимание" className="" onClose={onClose}>
      {message && <div className="text-center" style={{marginTop: '1rem'}}>{message}</div>}

      <div className="button-group" style={{marginTop: '2rem'}}>
        <button className="btn btn-md btn-warning" onClick={onClose}>Затвори</button>
      </div>
    </CustomModal>
  );
};

export const GlobalModals = connect(
  ({modals}: ReduxState) => ({modals}), null,
)(GlobalModalsImpl);
