import React, {Fragment} from 'react';

import {BaseComponent} from '../infrastructure/components/BaseComponent';
import {AppContextProps} from '../infrastructure/react-context';
import {CustomForm} from '../infrastructure/components/CustomForm';
import {ErrorMessages} from '../infrastructure/errors';
import {FileSelectField} from '../infrastructure/fields/FileSelectInput';
import {ConsultSaveAttachedResultRequest} from '../dto';
import {required} from '../infrastructure/fields/validations';

import {CustomModal} from '../infrastructure/components/CustomModal';
import {TextField} from '../infrastructure/fields/TextInput';

interface Props extends AppContextProps {
    jRequestId: number;
    onClose: () => void;
    afterSubmit: () => void;
}

interface State {
    model: ConsultSaveAttachedResultRequest | undefined;
    note: string | undefined;
    errorMessages: string[];
    fileType: string;
}

const initialState: State = {
    model: undefined,
    errorMessages: [],
    note: undefined,
    fileType: '',
};

export class FileUploadModal extends BaseComponent<Props, State> {

  state: State = initialState;

  onSubmit = async (model: ConsultSaveAttachedResultRequest) => {

    if (this.state.fileType !== 'pdf') {
      await this.setStateAsync({errorMessages: ['файла трябва задължително да е pdf!']});
      return;
    }

    const {server} = this.props.context;

    await this.setStateAsync({model});

    const request: ConsultSaveAttachedResultRequest = {
      ...model,
      jRequestID: this.props.jRequestId,
    };

    const response = await server.consultSaveAttachedResult(request);

    if (!response.success) {
      await this.setStateAsync({errorMessages: response.errorMessages});
      return;
    }

    this.props.afterSubmit();
  };

  onFileChange = (fileName: string) => {

    const fName = fileName.split('.').pop()?.toLowerCase();

    if (fName !== 'pdf') {
      this.setState({
        fileType: fName === undefined ? '' : fName,
        errorMessages: ['файла трябва задължително да е pdf!'],
      });
      return;
    }

    this.setState({fileType: fName, errorMessages: []});
  }

  render() {

    const {model, errorMessages} = this.state;
    const {onClose} = this.props;

    return (
      <Fragment>
        <CustomModal
          className="file-upload-modal"
          onClose={onClose}
          title="Прикачване на PDF документ">
          <CustomForm
            values={model}
            onSubmit={this.onSubmit}
            render={() => (
              <Fragment>
                <div className="ml-2">
                  <button type="submit" className="btn btn-md btn-default">
                    Запази
                  </button>
                  <button type="button" className="btn btn-md btn-warning"
                          onClick={onClose}>
                    Отказ
                  </button>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <FileSelectField
                      name="pdfFileContent"
                      icon="file"
                      label="Избери Файл:"
                      mimeTypes="application/pdf"
                      onFileChange={this.onFileChange}
                      validation={[required('pdf файла е задължителен')]}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <TextField
                      onChange={(e) => {
                        if (e !== null) {
                          this.state.note = e;
                        }
                      }}
                      icon="file"
                      label="Кратко описание за файла:"
                      name="patientRecShortName"
                      validation={[required('Описанието е задължително.')]}/>
                  </div>
                </div>
                {!!errorMessages.length && <ErrorMessages errors={errorMessages}/>}
              </Fragment>)}/>
        </CustomModal>
      </Fragment>
    );
  }
}
