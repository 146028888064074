import React, { Fragment } from 'react';
import { AppContextProps } from '../../infrastructure/react-context';
import { BaseComponent } from '../../infrastructure/components/BaseComponent';
import { ScreenSizeComponent } from '../../infrastructure/media-type';
import { Result } from '../../infrastructure/api-result';
import { ErrorMessages } from '../../infrastructure/errors';
import { PdfDocument } from '../../infrastructure/components/PdfDocument';
import LoadingIndicator from '../../infrastructure/components/LoadingIndicator';
import { downloadFile, base64ToArray, printPdf } from '../../infrastructure/util';
import { DocumentTitle } from '../../infrastructure/DocumentTitle';
import { JRequestReportResponse } from '../../dto';

interface Props extends AppContextProps {
  jRequestID: number;
}

interface State {
  loading: boolean;
  model: JRequestReportResponse | undefined;
  documentLoadResult: Result | undefined;
  documentBytes: Uint8Array | undefined;
}

export class JRequestsPrint extends BaseComponent<Props, State> {

  state: State = {
    loading: true,
    model: undefined,
    documentLoadResult: undefined,
    documentBytes: undefined,
  };

  async getPdf(jRequestID: number) {
    const {server, actions} = this.props.context;

    const response = await server.jRequestReport({jRequestID});

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    const model = response.payload;

    await this.setStateAsync({
      documentLoadResult: undefined,
      model,
      documentBytes: base64ToArray(model.pdfFileContent),
    });
  }

  componentDidMountAsync = async () => {
    await this.getPdf(this.props.jRequestID);
  };

  componentDidUpdateAsync = async (prevProps: Props) => {

    if (prevProps.jRequestID !== this.props.jRequestID) {
      await this.getPdf(this.props.jRequestID);
    }
  };

  downloadPdf = () => {

    const {model} = this.state;

    if (!model) {
      throw new Error('Trying to print while model is falsy.');
    }

    downloadFile(
      this.state.documentBytes,
      model.pdfFileContent,
      `${model.pdfFileTitle}.pdf`,
    );
  };

  printPdf = () => {

    const {model} = this.state;

    if (!model) {
      throw new Error('Trying to print while model is falsy.');
    }

    printPdf(
      this.state.documentBytes,
      model.pdfFileContent,
      `${model.pdfFileTitle}.pdf`,
    );
  };

  onPdfLoad = (pdfLoadResult: Result) => {
    setTimeout(() => {
      this.setState({
        documentLoadResult: pdfLoadResult,
        loading: false,
      });
    }, 0);
  };

  OnToJrequests = () => {
    const { actions} = this.props.context;
    actions.router.routerPush(`/doctor/jrequests`);
  };

  render() {

    const {
      loading,
      documentLoadResult,
      model,
      documentBytes,
    } = this.state;

    const documentLoadedSuccessfully = !!documentLoadResult && documentLoadResult.success;

    return (
      <div className="jrequests-print-page" style={{margin: '0 auto'}}>

        <DocumentTitle title="Поръчка - Печат"/>

        {model && documentBytes && <Fragment>

          <div className="card">
            <div className="card-header blue white-text">
              Поръчка - Печат
            </div>
            <div className="card-body">

              <div className="button-group">
                {documentLoadedSuccessfully && <Fragment>
                  <button className="btn btn-md btn-success" onClick={this.downloadPdf}>Изтегли</button>
                  <ScreenSizeComponent size="not-phone">
                    <button className="btn btn-md btn-default" onClick={this.printPdf}>Принтирай</button>
                  </ScreenSizeComponent>
                    <button className="btn btn-md btn-warning" onClick={this.OnToJrequests}>Поръчки</button>
                </Fragment>}
              </div>
            </div>
          </div>

          {!!documentLoadResult && !documentLoadResult.success &&
          <ErrorMessages errors={documentLoadResult.errorMessages}/>}

          <div style={{marginTop: '1rem'}}>
            <PdfDocument data={documentBytes} onLoad={this.onPdfLoad}/>
          </div>
        </Fragment>}

        {loading && <LoadingIndicator className="document-loading" delay={0}/>}
      </div>
    );
  }
}
