import React from 'react';

import {AppContextProps} from '../infrastructure/react-context';
import {BaseComponent} from '../infrastructure/components/BaseComponent';
import {DocumentTitle} from '../infrastructure/DocumentTitle';
import {RouteComponentProps} from 'react-router-dom';
import LoadingIndicator from '../infrastructure/components/LoadingIndicator';

interface RouteParams {
  identification: string;
}

interface Props extends RouteComponentProps<RouteParams>, AppContextProps {
}

interface State {
  loading: boolean;
  qrCode: string | undefined;
  deepLink: string | undefined;
}

export class ViberPublicQr extends BaseComponent<Props, State> {
  state: State = {
    loading: true,
    qrCode: undefined,
    deepLink: undefined,
  };

  componentDidMountAsync = async () => {
    const {server, actions} = this.props.context;

    const identification = this.props.match.params.identification;
    const resp = await server.getViberPublicQr({identification});
    if (!resp.success) {
      actions.errors.setErrorMessages(resp.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    await this.setStateAsync({qrCode: resp.payload.qrCode, deepLink: resp.payload.deepLink, loading: false});
  };

  render() {
    const {qrCode, deepLink, loading} = this.state;

    return (
      <div className="viber-public-qr-page">
        <DocumentTitle title="Viber QR"/>

        <div className="card" style={{marginTop: '1rem'}}>
          <div className="card-body" style={{padding: '1rem'}}>
            {loading && <LoadingIndicator/>}

            <div className="center-block text-center">
              <div style={{marginBottom: '1rem', fontWeight: 'lighter', fontSize: '1rem', textAlign: 'left'}}>
                Информация директно на Вашия телефон за:<br/>
                *Готови резултати, *Записани часове<br/>
                *Новини и промоции.
              </div>
              <a href={deepLink} target="_blank" className="btn btn-primary">Абонирай се за VIBER</a>
            </div>
            {qrCode && deepLink && <div className="center-block text-center">
                <a href={deepLink} target="_blank">
                    <img alt="qrCode" style={{maxWidth: '100%'}} src={`data:image/png;base64,${qrCode}`}/>
                </a>
            </div>}
          </div>
        </div>
      </div>
    );
  }
}
