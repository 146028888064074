import React from 'react';
import {AppContextProps} from '../../infrastructure/react-context';
import {BaseComponent} from '../../infrastructure/components/BaseComponent';
import LoadingIndicator from '../../infrastructure/components/LoadingIndicator';
import {DocumentTitle} from '../../infrastructure/DocumentTitle';
import {Subscription, interval} from 'rxjs';
import {filter as rxFilter} from 'rxjs/operators';
import {fromPromise} from '../../infrastructure/rx-helpers';

import './DocumentSigningList.scss';

interface Props extends AppContextProps {
}

interface State {
  loading: boolean;
  intervalSubscription$: Subscription | undefined;
}

export class DocumentSigningList extends BaseComponent<Props, State> {
  state: State = {
    loading: false,
    intervalSubscription$: undefined,
  };

  componentDidMountAsync = async () => {
    await this.setStateAsync({loading: true});

    const {server, actions} = this.props.context;

    const intervalSubscription =
      interval(1000)
        .pipe(rxFilter(() => !document.hidden))
        .pipe(fromPromise((_) => server.getNextDocumentToSign({})))
        .subscribe(async (x) => {
          if (x.success) {
            if (x.payload.signDocID) {
              actions.router.routerPush(`/doctor/document-signing/${x.payload.signDocID}/sign`);
            }
          } else {
            actions.errors.setErrorMessages(x.errorMessages);
          }
        });

    await this.setStateAsync({intervalSubscription$: intervalSubscription, loading: false});
  };

  async componentWillUnmountAsync() {
    this.state.intervalSubscription$?.unsubscribe();
  }

  render() {
    const {loading} = this.state;

    return (
      <div className="document-signing-list-page">
        <DocumentTitle title="Подписване на документи"/>

        {loading && <LoadingIndicator/>}

        {!loading &&
            <div className="card">
                <div className="card-header blue white-text">
                    Подписване на документи
                </div>
                <div className="card-body d-flex flex-column flex-wrap align-items-center">
                    <div className="signing-card-text">
                        Очакваме да бъдат подадени документи за подписване...
                    </div>
                    <div className="lds-facebook">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        }
      </div>
    );
  }
}
