type CornerstoneToolType = 'Main' | 'Additional' | 'Touch' | 'Scroll';

export interface CornerstoneToolStack {
  currentImageIdIndex: number;
  imageIds: string[];
}

export interface CornerstoneTool {
  name: string;
  mode: string;
  type: CornerstoneToolType;
}

export const CornerstoneTools: CornerstoneTool[] = [
  // Main
  {name: 'Length', mode: 'inactive', type: 'Main'},
  {name: 'Pan', mode: 'inactive', type: 'Main'},
  {name: 'Angle', mode: 'inactive', type: 'Main'},
  {name: 'CircleRoi', mode: 'inactive', type: 'Main'},
  {name: 'DragProbe', mode: 'inactive', type: 'Main'},
  {name: 'FreehandRoi', mode: 'inactive', type: 'Main'},
  {name: 'Magnify', mode: 'inactive', type: 'Main'},
  {name: 'Probe', mode: 'inactive', type: 'Main'},
  {name: 'RectangleRoi', mode: 'inactive', type: 'Main'},
  {name: 'Rotate', mode: 'inactive', type: 'Main'},
  {name: 'Wwwc', mode: 'inactive', type: 'Main'},
  {name: 'Zoom', mode: 'inactive', type: 'Main'},

  // Scroll
  {name: 'StackScrollMouseWheel', mode: 'active', type: 'Scroll'},
  // Touch
  {name: 'PanMultiTouch', mode: 'active', type: 'Touch'},
  {name: 'ZoomTouchPinch', mode: 'active', type: 'Touch'},
  {name: 'StackScrollMultiTouch', mode: 'active', type: 'Touch'},
  {name: 'RotateTouch', mode: 'active', type: 'Touch'},

  // Additional
  {name: 'Brush', mode: 'inactive', type: 'Additional'},
  {name: 'CircleScissors', mode: 'inactive', type: 'Additional'},
  {name: 'FreehandScissors', mode: 'inactive', type: 'Additional'},
  {name: 'CorrectionScissors', mode: 'inactive', type: 'Additional'},
  {name: 'RectangleScissors', mode: 'inactive', type: 'Additional'},
  {name: 'SphericalBrush', mode: 'inactive', type: 'Additional'},
  {name: 'Bidirectional', mode: 'inactive', type: 'Additional'},
  {name: 'CobbAngle', mode: 'inactive', type: 'Additional'},
  {name: 'EllipticalRoi', mode: 'inactive', type: 'Additional'},
  {name: 'ScaleOverlay', mode: 'inactive', type: 'Additional'},
  {name: 'Eraser', mode: 'inactive', type: 'Additional'},
];
