import React, { Fragment } from 'react';

import { AppContextProps } from '../../infrastructure/react-context';
import { BaseComponent } from '../../infrastructure/components/BaseComponent';
import { ScreenSizeComponent } from '../../infrastructure/media-type';
import { Result } from '../../infrastructure/api-result';
import { ErrorMessages } from '../../infrastructure/errors';
import { PdfDocument } from '../../infrastructure/components/PdfDocument';
import LoadingIndicator from '../../infrastructure/components/LoadingIndicator';
import { downloadFile, base64ToArray, printPdf } from '../../infrastructure/util';
import BackButton from '../../infrastructure/components/BackButton';
import { DocumentTitle } from '../../infrastructure/DocumentTitle';
import { PrintCytologyResultResponse } from '../../dto';
import { DropdownButton } from '../../infrastructure/components/DropdownButton';

import './cytology-results.scss';

interface Props extends AppContextProps {
  patientRecordID: number;
  onDisplayList?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
}

interface State {
  loading: boolean;
  model: PrintCytologyResultResponse | undefined;
  documentLoadResult: Result | undefined;
  documentBytes: Uint8Array | undefined;
}

export class CytologyResultPrint extends BaseComponent<Props, State> {

  state: State = {
    loading: true,
    model: undefined,
    documentLoadResult: undefined,
    documentBytes: undefined,
  };

  async getPdf(patientRecordID: number) {
    const {server, actions} = this.props.context;

    const response = await server.printCytologyResult({patientRecordID});

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    const model = response.payload;

    await this.setStateAsync({
      documentLoadResult: undefined,
      model,
      documentBytes: base64ToArray(model.pdfFileContent),
    });
  }

  async fetchData() {

    await this.getPdf(this.props.patientRecordID);
  }

  componentDidMountAsync = async () => {
    await this.fetchData();
  };

  componentDidUpdateAsync = async (prevProps: Props) => {

    if (prevProps.patientRecordID !== this.props.patientRecordID) {
      await this.fetchData();
    }
  };

  downloadPdf = () => {

    const {model} = this.state;

    if (!model) {
      throw new Error('Trying to print while model is falsy.');
    }

    downloadFile(
      this.state.documentBytes,
      model.pdfFileContent,
      `${model.pdfFileTitle}-${model.patientRecordID}.pdf`,
    );
  };

  printPdf = () => {

    const {model} = this.state;

    if (!model) {
      throw new Error('Trying to print while model is falsy.');
    }

    printPdf(
      this.state.documentBytes,
      model.pdfFileContent,
      `${model.pdfFileTitle}-${model.patientRecordID}.pdf`,
    );
  };

  onPdfLoad = (pdfLoadResult: Result) => {
    setTimeout(() => {
      this.setState({
        documentLoadResult: pdfLoadResult,
        loading: false,
      });
    }, 0);
  };

  render() {

    const {
      loading,
      documentLoadResult,
      model,
      documentBytes,
    } = this.state;

    const documentLoadedSuccessfully = !!documentLoadResult && documentLoadResult.success;

    const {actions} = this.props.context;

    return (
      <div className="cytology-results-print-page" style={{margin: '0 auto'}}>

        <DocumentTitle title="Цитологичен резултат - Преглед/Печат"/>

        {model && documentBytes && <Fragment>

          <div className="card">
            <div className="card-header blue white-text">
              Цитологичен резултат - Преглед/Печат
            </div>
            <div className="card-body">
              <div className="button-group">

                <button
                  onClick={() => {
                    if (this.props.onDisplayList) {
                      this.props.onDisplayList();
                    } else {
                      actions.router.routerPush('/doctor/cytology-results');
                    }
                  }}
                  className="btn btn-md btn-info">
                  Картотека
                </button>

                {documentLoadedSuccessfully && <Fragment>
                  <ScreenSizeComponent size="not-phone">
                    <button className="btn btn-md btn-default" onClick={this.printPdf}>Принтирай</button>
                  </ScreenSizeComponent>
                </Fragment>}

                <DropdownButton label="Опции" className="btn btn-md btn-info">

                  {documentLoadedSuccessfully && <Fragment>
                    <button className="dropdown-item" onClick={this.downloadPdf}>Изтегли</button>
                  </Fragment>}
                  <button
                    onClick={() => {
                      if (this.props.onEdit) {
                        this.props.onEdit();
                      } else {
                        actions.router.routerPush(`/doctor/cytology-results/${model.patientRecordID}/edit`);
                      }
                    }}
                    className="dropdown-item">
                    Редакция
                  </button>

                  <button
                    onClick={() => {
                      if (this.props.onDelete) {
                        this.props.onDelete();
                      } else {
                        actions.router.routerPush(`/doctor/cytology-results/${model.patientRecordID}/delete`);
                      }
                    }}
                    className="dropdown-item">
                    Изтриване
                  </button>

                </DropdownButton>

                <BackButton render={(props) =>
                  <a {...props}
                     className="btn btn-md btn-warning">
                    Назад
                  </a>
                }/>
              </div>
            </div>
          </div>

          {!!documentLoadResult && !documentLoadResult.success &&
          <ErrorMessages errors={documentLoadResult.errorMessages}/>}

          <div style={{marginTop: '1rem'}}>
            <PdfDocument data={documentBytes} onLoad={this.onPdfLoad}/>
          </div>
        </Fragment>}

        {loading && <LoadingIndicator className="document-loading" delay={0}/>}
      </div>
    );
  }
}
