import dicomParser from 'dicom-parser';
import cornerstone from 'cornerstone-core';
// @ts-ignore
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
// @ts-ignore
import cornerstoneMath from 'cornerstone-math';
// @ts-ignore
import cornerstoneTools from 'cornerstone-tools';
// @ts-ignore
import Hammer from 'hammerjs';
import {wadoUriMetaDataProvider} from './MetadataProvider';

export default function initCornerstone() {
  cornerstone.metaData.addProvider(wadoUriMetaDataProvider);

  // Cornerstone Tools
  cornerstoneTools.external.cornerstone = cornerstone;
  cornerstoneTools.external.Hammer = Hammer;
  cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
  cornerstoneTools.init({ showSVGCursors: true });

  // Image Loader
  cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
  cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
  cornerstoneWADOImageLoader.webWorkerManager.initialize({
    maxWebWorkers: Math.max(navigator.hardwareConcurrency - 1, 1),
    startWebWorkersOnDemand: true,
    taskConfiguration: {
      decodeTask: {
        initializeCodecsOnStartup: false,
        usePDFJS: false,
        strict: false,
      },
    },
  });
}
