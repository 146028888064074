import dicomParser from 'dicom-parser';
// @ts-ignore
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';

// За да видим dataSet.string('x00080018') какво е
// https://www.dicomlibrary.com/dicom/dicom-tags/#:~:text=a%20tag%20that%20identifies%20the,format%20of%20the%20attribute%20value.
// Kолоната Tag -> слагаме 'х' и сливаме двете стойности (0002,0000) -> х00020000

export const wadoUriMetaDataProvider = (type: string, imageId: string) => {
  const { parseImageId, dataSetCacheManager } = cornerstoneWADOImageLoader.wadouri;
  const parsedImageId = parseImageId(imageId);
  const dataSet = dataSetCacheManager.get(parsedImageId.url);
  if (!dataSet) {
    return;
  }

  if (type === 'generalImageModule') {
    return {
      sopInstanceUid: dataSet.string('x00080018'),
      instanceNumber: dataSet.intString('x00200013'),
      lossyImageCompression: dataSet.string('x00282110'),
      lossyImageCompressionRatio: dataSet.string('x00282112'),
      lossyImageCompressionMethod: dataSet.string('x00282114'),
    };
  }

  if (type === 'patientModule') {
    return {
      patientName: dataSet.string('x00100010'),
      patientId: dataSet.string('x00100020'),
    };
  }

  if (type === 'generalStudyModule') {
    return {
      studyDescription: dataSet.string('x00081030'),
      studyDate: dataSet.string('x00080020'),
      studyTime: dataSet.string('x00080030'),
    };
  }

  if (type === 'cineModule') {
    return {
      frameTime: dataSet.floatString('x00181063'),
    };
  }

  if (dataSet.elements[type] !== undefined) {
    const element = dataSet.elements[type];
    if (!element.vr) {
      return;
    }

    return dicomParser.explicitElementToString(dataSet, element);
  }
};
