import React from 'react';

import { AppContextProps } from '../infrastructure/react-context';
import { BaseComponent } from '../infrastructure/components/BaseComponent';
import LoadingIndicator from '../infrastructure/components/LoadingIndicator';
import { DocumentTitle } from '../infrastructure/DocumentTitle';
import {PatientPrescriptionsResponse} from '../dto';
import {downloadFile, base64ToArray} from '../infrastructure/util';
import {JUserConfirmModal} from '../common/JUserConfirmModal';

interface Props extends AppContextProps {
}

interface State {
  loading: boolean;
  confirmDialogDcDynDocID: number | undefined;
  response: PatientPrescriptionsResponse | undefined;
}

export class ViewPrescriptions extends BaseComponent<Props, State> {

  state: State = {
    loading: false,
    response: undefined,
    confirmDialogDcDynDocID: undefined,
  };

  componentDidMountAsync = async () => {

    await this.reloadItems();
  };

  reloadItems = async () => {

    const {server, actions} = this.props.context;

    await this.setStateAsync({loading: true});

    const response = await server.patientPrescriptions({});

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loading: false, response: undefined});
      return;
    }

    await this.setStateAsync({
      loading: false,
      response: response.payload,
    });
  };

  downloadPdf = async (dcDyndocID: number) => {
    this.setState({confirmDialogDcDynDocID: undefined});
    const {server, actions} = this.props.context;
    const response = await server.patientPrescriptionPdf({dcDyndocID});
    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loading: false, response: undefined});
      return;
    }

    downloadFile(
      base64ToArray(response.payload.fileContainBase64 as string),
      response.payload.fileContainBase64,
      `${response.payload.fileName}`,
    );
  };

  render() {

    const {loading, response} = this.state;

    return (
      <div className="patient-visits-page">

        <DocumentTitle title="Рецепти"/>

        {loading && <LoadingIndicator/>}
        <h3 className="text-center">Рецепти</h3>
        {response && !!response.prescriptionDtos.length && response.prescriptionDtos.map((day) => {
          return (
            <div className="card" key={day.dcDynDocID} style={{marginTop: '1.5rem'}}>
              <div className="card-header blue white-text">
                Издадена на: {day.dcDynDocDateFormated} от {day.doctorFirstLastName}
              </div>
              <div className="card-body">
                <div dangerouslySetInnerHTML={{ __html: day.formatedMedicaments }} />
              </div>
              <div className="text-right">
                <button type="button" className="btn btn-secondary"
                        onClick={() => this.setState({confirmDialogDcDynDocID: day.dcDynDocID})}>
                  Свали като файл
                </button>
              </div>
            </div>
          );
        })}
        {response && !response.prescriptionDtos.length && <div className="card" style={{marginTop: '1.5rem'}}>
            <div className="card-body text-center padded">
                Към момента нямате издадени рецепти
            </div>
        </div>}

        {!!this.state.confirmDialogDcDynDocID && <JUserConfirmModal
            deleteMessage={'Моля, потвърдете сваленето на файла!'}
            onCancel={() => this.setState({confirmDialogDcDynDocID: undefined})}
            onSuccess={() => this.downloadPdf(this.state.confirmDialogDcDynDocID as number)}>
        </JUserConfirmModal>}
      </div>
    );
  }
}
