import { createTabCom } from '../tab-com';

export const distributeDispatch = createTabCom('distribute-dispatch');

export const distributeMiddleware = () => (next: any) => (action: object) => {

  if (isDistributed(action)) {
    distributeDispatch.sendMessage({
      type: 'dispatch',
      payload: action,
    });
  }

  return next(action);
};

export function distributedAction<T>(action: T): T {

  const newAction = {};

  Object.defineProperty(newAction, '__distribute__', {
    configurable: false,
    enumerable: false,
    value: true,
    writable: false,
  });

  Object.assign(newAction, action);

  return newAction as T;
}

export const isDistributed = (action: any) => action.__distribute__;
