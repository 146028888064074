import { RouteTable } from '../infrastructure/components/RenderRouteTable';

import { TextFieldDemo } from './TextFieldDemo';
import { NumberFieldDemo } from './NumberFieldDemo';
import { TextAreaFieldDemo } from './TextAreaFieldDemo';
import { EditorFieldDemo } from './EditorFieldDemo';
import { SelectFieldDemo } from './SelectFieldDemo';
import { MultiSelectFieldDemo } from './MultiSelectFieldDemo';
import { DateFieldDemo } from './DateFieldDemo';
import { CheckboxFieldDemo } from './CheckboxFieldDemo';
import { FileSelectFieldDemo } from './FileSelectFieldDemo';
import { ListMultiSelectFieldDemo } from './ListMultiSelectFieldDemo';
import {SmailDemo} from './SmailDemo';

export const demoRoutes: RouteTable = [
  {location: '/demo/text-field', component: TextFieldDemo, requireLogin: false, frame: 'narrow'},
  {location: '/demo/number-field', component: NumberFieldDemo, requireLogin: false, frame: 'narrow'},
  {location: '/demo/text-area-field', component: TextAreaFieldDemo, requireLogin: false, frame: 'narrow'},
  {location: '/demo/editor-field', component: EditorFieldDemo, requireLogin: false, frame: 'narrow'},
  {location: '/demo/select-field', component: SelectFieldDemo, requireLogin: false, frame: 'narrow'},
  {location: '/demo/multi-select-field', component: MultiSelectFieldDemo, requireLogin: false, frame: 'narrow'},
  {
    location: '/demo/list-multi-select-field',
    component: ListMultiSelectFieldDemo,
    requireLogin: false,
    frame: 'standard',
  },
  {location: '/demo/date-field', component: DateFieldDemo, requireLogin: false, frame: 'narrow'},
  {location: '/demo/checkbox-field', component: CheckboxFieldDemo, requireLogin: false, frame: 'narrow'},
  {location: '/demo/file-select-field', component: FileSelectFieldDemo, requireLogin: false, frame: 'narrow'},
  {location: '/smail', component: SmailDemo, requireLogin: false},
];
