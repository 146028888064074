import { replaceAll, spliceString } from '../infrastructure/util';

export const JString = {
  concat: (...args: string[]) => args.join(''),
  isNullOrWhiteSpace: (text: string) => !(text && text.trim()),
  join: (separator: string, array: string[]) => array.join(separator),
  replace: replaceAll,
  toLower: (text: string) => text.toLowerCase(),
  toUpper: (text: string) => text.toUpperCase(),
  trimStart: (text: string) => text.trimStart(),
  trimEnd: (text: string) => text.trimEnd(),
  split: (text: string, separator: string) => text.split(separator).filter((x) => x),
  substring: (text: string, start: number, length?: number) => text.substr(start, length),
  remove: (text: string, start: number, length?: number) => spliceString(text, start, length),
};
