import React from 'react';

import {AppContextProps} from '../infrastructure/react-context';
import {BaseComponent} from '../infrastructure/components/BaseComponent';
import {TextResource} from '../infrastructure/TextResource';
import {DocumentTitle} from '../infrastructure/DocumentTitle';
import {CustomForm} from '../infrastructure/components/CustomForm';
import LoadingIndicator from '../infrastructure/components/LoadingIndicator';
import {SelectField} from '../infrastructure/fields/SelectInput';
import {SelectItem} from '../infrastructure/fields/select-item';
import {TextField} from '../infrastructure/fields/TextInput';
import {AlertModal} from '../infrastructure/AlertModal';
import {serverValidations} from '../dto';
import {isValidEGN, getSexFromEGN, getBirthdateFromEGN} from '../infrastructure/fields/validations';
import {Link} from 'react-router-dom';
import {DateEditField} from '../infrastructure/dx/DateEdit';

import {GoogleReCaptcha} from 'react-google-recaptcha-v3';
import SessionComponent from '../infrastructure/components/SessionComponent';

interface Props extends AppContextProps {
}

interface State {
  loading: boolean;
  submitLoading: boolean;
  acceptToS: boolean;

  isComplete: boolean;
  isError: boolean;
  isVerified: boolean;
  errorMessage: string | string[];

  identTypes: SelectItem<number>[];
}

interface PatientState {
  identTypeID: number;
  patientIdentNumber: string;
  patientPersonalDocNumber: string;
  patientFirstName: string;
  patientSecondName: string;
  patientLastName: string;
  patientNameEN: string;
  patientBirthDay: Date | null;
  patientSex: number;
  patientState: string;
  patientCity: string;
  patientAddress: string;
  patientMobile: string;
  patientEmail: string;
}

const sexTypes = [{value: 1, label: 'Мъж'}, {value: 2, label: 'Жена'}];

export class PatientRegister extends BaseComponent<Props, State> {

  state: State = {
    loading: true,
    submitLoading: false,
    acceptToS: false,

    isComplete: false,
    isError: false,
    isVerified: false,
    errorMessage: '',

    identTypes: [],
  };

  componentDidMountAsync = async () => {
    const {server, actions} = this.props.context;

    const resIdentTypes = await server.identTypes({});
    if (!resIdentTypes.success) {
      actions.errors.setErrorMessages(resIdentTypes.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    const identTypesArr = [...resIdentTypes.payload.identTypes
      .filter((i) => i.identTypeID === 2 || i.identTypeID === 3)
      .map((i) => ({value: i.identTypeID, label: i.identTypeName}))];

    await this.setStateAsync({
      loading: false,

      identTypes: identTypesArr,
    });
  };

  onSubmitHandler = async (pat: PatientState) => {
    if (!this.state.isVerified) {
      return;
    }

    if (!this.state.acceptToS) {
      this.setState({
        isError: true,
        errorMessage: 'Моля, потвърдете че сте запознати и приемате общите условия на сайта!',
      });
      return;
    }

    const {server} = this.props.context;

    this.setState({submitLoading: true});

    const res = await server.patientRegister({
      acceptToS: this.state.acceptToS,
      patient: {
        ...pat,
        patientBirthDay: pat.patientBirthDay !== null ? pat.patientBirthDay : new Date(1900, 0),
      },
    });

    if (!res.success) {
      this.setState({submitLoading: false, isError: true, errorMessage: res.errorMessages});
      return;
    }

    this.setState({submitLoading: false, isComplete: true});
  };

  render() {
    const validations = serverValidations.patientRegisterDto;
    const {loading, submitLoading, isComplete, isError, errorMessage} = this.state;

    return (
      <div className="patient-register-page">

        <div>
          <GoogleReCaptcha onVerify={() => this.setState({isVerified: true})}/>
        </div>

        <DocumentTitle title="Регистрация на пациент"/>

        {loading && <LoadingIndicator/>}

        {!submitLoading && isComplete &&
        <div className="card" style={{marginTop: '1rem'}}>
            <div className="card-header green white-text">Успешно направихте своята регистрация!</div>
            <div className="card-body">
                <p>На посочения от Вас имейл адрес ще получите потребител и парола за достъп.</p>
                <Link to="/" className="btn btn-md btn-green">Начало</Link>
                <button
                    onClick={() => window.location.href = '/register'}
                    className="btn btn-md btn-deep-orange">ПРОДЪЛЖИ С НОВА РЕГИСТРАЦИЯ
                </button>
            </div>
        </div>}

        {!loading && !isComplete &&
        <div className="row">
            <div className="col-sm-6">
                <CustomForm
                    onSubmit={this.onSubmitHandler}
                    render={({values, setFieldTouched, setFieldValue}) => (
                      <div className="card" style={{display: (submitLoading ? 'none' : 'block')}}>
                        <div className="card-header blue white-text">Данни за регистрацията</div>
                        <div className="card-body">
                          <TextField
                            name="patientEmail"
                            label="Имейл*"
                            validation={validations.patientEmail}
                          />
                          <TextField
                            name="patientMobile"
                            label="Мобилен*"
                            validation={validations.patientMobile}
                          />
                          <SelectField
                            name="identTypeID"
                            label="Идентификация(ЕГН или ЛНЧ)*"
                            items={this.state.identTypes}
                            validation={validations.identTypeID}
                            defaultValueOnSelectedItemRemoval
                            onChange={(selectedIdentTypeID) => {
                              if (selectedIdentTypeID === 2 && isValidEGN(values.patientIdentNumber)) {
                                const birthDate = getBirthdateFromEGN(values.patientIdentNumber);
                                const sex = getSexFromEGN(values.patientIdentNumber);

                                setFieldValue('patientBirthDay', birthDate, false);
                                setFieldTouched('patientBirthDay', true, false);

                                setFieldValue('patientSex', sex, false);
                                setFieldTouched('patientSex', true, false);
                              }
                            }}
                          />
                          <TextField
                            name="patientIdentNumber"
                            label="ЕГН/ЛНЧ*"
                            mask={/\d+/g}
                            validation={validations.patientIdentNumber}
                            onChange={(egn) => {
                              if (values.identTypeID === 2 && isValidEGN(egn)) {
                                const birthDate = getBirthdateFromEGN(egn);
                                const sex = getSexFromEGN(egn);

                                setFieldValue('patientBirthDay', birthDate, false);
                                setFieldTouched('patientBirthDay', true, false);

                                setFieldValue('patientSex', sex, false);
                                setFieldTouched('patientSex', true, false);
                              }
                            }}
                          />
                          <TextField
                            name="patientFirstName"
                            label="Име*"
                            validation={validations.patientFirstName}
                          />
                          <TextField
                            name="patientSecondName"
                            label="Презиме*"
                            validation={validations.patientSecondName}
                          />
                          <TextField
                            name="patientLastName"
                            label="Фамилия*"
                            validation={validations.patientLastName}
                          />
                          <div className="row">
                            <div className="col-8">
                              <DateEditField
                                name="patientBirthDay"
                                label="Дата на раждане*"
                              />
                            </div>
                            <div className="col-4">
                              <SelectField
                                name="patientSex"
                                label="Пол*"
                                items={sexTypes}
                                validation={validations.patientSex}
                                defaultValueOnSelectedItemRemoval
                              />
                            </div>
                          </div>
                          <SessionComponent publicOnly feature="PatientIdCardDetails">
                            <TextField
                                name="patientNameEN"
                                label="Три имена на английски по ЛК"
                                validation={validations.patientNameEN}
                            />
                          </SessionComponent>
                          <div className="row">
                            <div className="col-7">
                              <SessionComponent publicOnly feature="PatientIdCardDetails">
                                <TextField
                                    name="patientPersonalDocNumber"
                                    label="Лична карта №"
                                    validation={validations.patientPersonalDocNumber}
                                />
                              </SessionComponent>
                            </div>
                            <div className="col-5 text-right">
                              <button type="submit" className="btn btn-default btn-block" disabled={submitLoading}>Запис
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                />
            </div>
            <div className="col-sm-6">
              {!loading && !submitLoading && !isComplete &&
              <div className="card">
                  <div className="card-header blue white-text">Общи условия</div>
                  <div className="card-body text-center padded">
                      <div className="patient-register-page__resource-top">
                          <TextResource injectHtml resourceId={23}/>
                      </div>
                      <br/>
                      <p className="text-left">
                          <input type="checkbox"
                                 style={{transform: 'scale(1.5)'}}
                                 checked={this.state.acceptToS}
                                 onChange={(e) => this.setState({acceptToS: e.target.checked})}
                          />&nbsp; &nbsp;Декларирам, че съм запознат и приемам общите условия на сайта.
                          <a href="/public/tos" target="_blank"> ОБЩИ УСЛОВИЯ </a></p>
                  </div>
              </div>}
            </div>
        </div>}

        {!submitLoading && isError &&
        <AlertModal
            message={errorMessage}
            onClose={() => this.setState({isError: false, errorMessage: ''})}
        />
        }

        {submitLoading && <LoadingIndicator/>}
      </div>

    );
  }
}
