import { connect } from 'react-redux';
import { FunctionComponent } from 'react';
import { SessionState } from '../session.state';
import { ChildrenProps } from '../react-helpers';
import { CacheState } from '../cache.state';
import { shouldRender, RenderPermissionOptions } from '../render-permissions';
import { ReduxState } from '../redux/store';

interface Props extends ChildrenProps, RenderPermissionOptions {
}

interface InjectedProps extends Props {
  session: SessionState;
  cache: CacheState;
}

const SessionComponent: FunctionComponent<Props> = (props) => {

  const {children} = props;
  const {session, cache} = props as InjectedProps;

  if (!shouldRender({...props}, session, cache)) {
    return null;
  }

  return children || null;
};

export default connect(({session, cache}: ReduxState) => ({session, cache}))(SessionComponent);
