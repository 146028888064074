import React, { FunctionComponent, memo, ReactNode } from 'react';

import { wrapField, cssClasses, BaseInputProps } from './field-helpers';
import { ErrorMessages } from '../errors';

import './SelectInput.scss';

export interface ListMultiSelectInputProps extends BaseInputProps<any[]> {
  items: any[];
  render: ((props: ListMultiSelectInputRenderProps) => ReactNode);
  getKey: ((x: any) => any);
}

export interface ListMultiSelectItems<T = any> {
  items: (T & { CheckBox: FunctionComponent, onToggle: (() => void) })[];
}

interface ListMultiSelectInputRenderProps {
  items: any[];
}

export const ListMultiSelectInput: FunctionComponent<ListMultiSelectInputProps> =
  memo((props: ListMultiSelectInputProps) => {

    const {readonly, items, style, errorMessages, onChange, setTouched, className, customRef, render, getKey} = props;

    if (customRef) {
      customRef(props);
    }

    let value = props.value;

    // Initialize
    if (value === undefined) {
      value = [];
      setTimeout(() => onChange(value), 0);
    }

    const active = (value && value.length) || !readonly;

    const cssStates = cssClasses({
      readonly,
      invalid: !!errorMessages,
      active,
    });

    const enhancedItems: ListMultiSelectItems[] = items.map((x) => {

      const selected = !!value.filter((y) => getKey(y) === getKey(x)).length;

      const onToggle = () => {
        let newValue;
        if (selected) {
          newValue = value.filter((y) => getKey(y) !== getKey(x));
        } else {
          newValue = [...value, x];
        }
        onChange(newValue);
        if (setTouched) {
          setTouched();
        }
      };

      return {
        ...x,
        CheckBox: () => (
          <input
            type="checkbox"
            checked={selected}
            onChange={onToggle}/>
        ),
        onToggle,
      };
    });

    return (
      <div className={`select-input custom-form-input form-group ${className} ${cssStates}`} style={style}>
        {render({items: enhancedItems})}
        {errorMessages && <ErrorMessages errors={errorMessages}/>}
      </div>
    );
  });

export const ListMultiSelectField = wrapField(ListMultiSelectInput);
