import React from 'react';

import {AppContextFactoryProps, withAppContext} from '../infrastructure/react-context';
import {BaseComponent} from '../infrastructure/components/BaseComponent';
import LoadingIndicator from '../infrastructure/components/LoadingIndicator';
import {PatientObligation} from '../dto';
import {CustomModal} from '../infrastructure/components/CustomModal';
import {CustomForm} from '../infrastructure/components/CustomForm';
import {AlertModal} from '../infrastructure/AlertModal';
import {NumberField} from '../infrastructure/fields/NumberInput';

interface Props extends AppContextFactoryProps {
  onClose: () => void;
  obligationKeys: string[];
}

interface State {
  obligations: PatientObligation[] | undefined;
  loading: boolean;
  showError: boolean;
  errorMessage: string | undefined;
}

class PatientCartModal extends BaseComponent<Props, State> {
  state: State = {
    loading: false,
    obligations: undefined,
    showError: false,
    errorMessage: undefined,
  };

  componentDidMountAsync = async () => {
    const {server, actions} = this.props.getContext();

    const resp = await server.patientObligations({obligationKeys: [...this.props.obligationKeys]});
    if (!resp.success) {
      actions.errors.setErrorMessages(resp.errorMessages);
      await this.setStateAsync({loading: false, obligations: undefined});
      return;
    }

    await this.setStateAsync({loading: false, obligations: resp.payload.obligations});
  };

  submit = async (values: PatientObligation[]) => {
    if (!values) {
      throw new Error('values is falsy');
    }
    if (!values.length) {
      await this.setStateAsync({
        showError: true,
        errorMessage: 'Няма услуги за плащане!',
      });
      return;
    }
    const {server} = this.props.getContext();
    const resp = await server.patientCreateCart({
      obligations: values.map((v) => {
        return {
          obligationKey: v.obligationKey,
          forPayActual: v.forPayActual,
        };
      }),
    });

    if (!resp.success) {
      await this.setStateAsync({
        showError: true,
        errorMessage: resp.errorMessages.join(' '),
      });
      return;
    }

    const cart = resp.payload;
    if (cart.cartType === 1) {
      // Borica APGW
      const div = document.createElement('div');
      div.innerHTML = cart.resultData;
      div.setAttribute('style', 'display: none');
      document.body.appendChild(div);
      // @ts-ignore
      div.querySelector('form').submit();
    } else if (cart.cartType === 2) {
      // Fibank ECOMM
      const div = document.createElement('div');
      div.innerHTML = cart.resultData;
      div.setAttribute('style', 'display: none');
      document.body.appendChild(div);
      // @ts-ignore
      div.querySelector('form').submit();
    } else {
      // голям проблем
      console.error('голям проблем');
    }
  };

  render() {
    const {loading, obligations, showError, errorMessage} = this.state;

    return (
      <CustomModal
        title="Моля, потвърдете избраните услуги за плащане"
        onClose={() => this.props.onClose && this.props.onClose()}>

        {obligations && obligations.length &&
        <CustomForm values={obligations} onSubmit={this.submit} render={({values, isSubmitting}) => (
          <div>
            <p className="text-left font-weight-bold">*За частично плащане въведете желаната сума в посоченото поле.</p>

            {values.map((item: PatientObligation, i: number) => (
              <div key={item.jOrderID} className="card mb-2 border border-info">
                <div className="card-header p-2 border-bottom border-info">{item.obligationInfo}</div>
                <div className="card-body">
                  <div>Име на услугата: {item.serviceName}</div>
                  <div>Сума на задължението: {item.forPay} лв.</div>
                  <NumberField
                    name={i + '.forPayActual'}
                    label="Желана сума за плащане"
                    decimalPlaces={2}
                  />
                </div>
              </div>
            ))}

            <div className="row">
              <div className="col-lg-4">
                <button type="submit" className="btn btn-danger w-100" disabled={isSubmitting}>
                  Направи плащане
                </button>
              </div>
              <div className="col-lg-4">

              </div>
              <div className="col-lg-4">
                <button className="btn btn-warning w-100" onClick={this.props.onClose} disabled={isSubmitting}>
                  Затвори
                </button>
              </div>

            </div>
          </div>
        )}/>}

        {!loading && obligations && !obligations.length &&
          <div style={{margin: '1rem'}}>
                <h3 style={{color: 'black', textAlign: 'center'}}>Не са намерени задължения за плащане.</h3>
            </div>}

        {loading && <LoadingIndicator/>}

        {showError && errorMessage &&
            <AlertModal
                onClose={() => this.setState({showError: false, errorMessage: undefined})}
            message={errorMessage}/>}
      </CustomModal>
    );
  }
}

export default withAppContext(PatientCartModal);
