import React, {CSSProperties} from 'react';
import {BaseComponent} from '../../infrastructure/components/BaseComponent';
import {OptionalChildrenProps} from '../../infrastructure/react-helpers';
import {ConfirmModal2} from './ConfirmModal2';

interface Props extends OptionalChildrenProps {
  onClose?: () => void;
  onConfirm: () => void;
  modalDescription?: string;
  btnClassName?: string;
  btnText: string;
  btnStyle?: CSSProperties;
}

interface State {
}

export class ConfirmModalButton extends BaseComponent<Props, State> {
  render() {
    const {onClose, onConfirm, modalDescription, btnClassName, btnText, btnStyle} = this.props;

    return (
      <div>
        <ConfirmModal2
          onConfirm={onConfirm}
          onClose={onClose}
          descriptionText={modalDescription}
        />

        <button
          type="button"
          className={`btn ${btnClassName || 'btn-info'}`}
          style={btnStyle}
          data-toggle="modal"
          data-target="#confirmationModal">
          {btnText}
        </button>
      </div>
      );
  }
}
