import React from 'react';
import {BaseComponent} from '../../infrastructure/components/BaseComponent';
import {AppContextProps} from '../../infrastructure/react-context';
import {DocumentTitle} from '../../infrastructure/DocumentTitle';
import {CustomForm} from '../../infrastructure/components/CustomForm';
import {TextField} from '../../infrastructure/fields/TextInput';
import LoadingIndicator from '../../infrastructure/components/LoadingIndicator';
import BackButton from '../../infrastructure/components/BackButton';
import {ConfirmModal} from '../../common/ConfirmModal';
import {PatientRestorePasswordDto, serverValidations} from '../../dto';
import {SelectField} from '../../infrastructure/fields/SelectInput';
import {SelectItem} from '../../infrastructure/fields/select-item';
import {ErrorMessages} from '../../infrastructure/errors';
import {DateEditField} from '../../infrastructure/dx/DateEdit';
import {rangeNumber} from '../../infrastructure/fields/validations';
import {InfoModal} from '../../common/InfoModal';
import {texts} from '../../infrastructure/texts';
import {GoogleReCaptcha} from 'react-google-recaptcha-v3';

interface Props extends AppContextProps {
}

interface State {
  loading: boolean;
  showConfirmationModal: boolean;
  showInfoModal: boolean;
  model: PatientRestorePasswordDto | undefined;
  errorMessages: string[];
  isVerified: boolean;
}

const initialState: State = {
  loading: false,
  showConfirmationModal: false,
  showInfoModal: false,
  model: undefined,
  errorMessages: [],
  isVerified: false,
};

const genderTypes: SelectItem[] = [
  {label: '-', value: 0},
  {label: texts.PASS_RECOVERY_GENDER_M, value: 1},
  {label: texts.PASS_RECOVERY_GENDER_F, value: 2}];

export class PasswordRecoveryForm extends BaseComponent<Props, State> {
  state: State = initialState;

  onSubmit = async (model: PatientRestorePasswordDto) => {
    if (!this.state.isVerified) {
      return;
    }

    if (!this.state.showConfirmationModal) {
      await this.setStateAsync({showConfirmationModal: true});
      return;
    }

    const {server} = this.props.context;

    await this.setStateAsync({loading: true});

    const result = await server.patientRestorePassword({patient: model});

    if (!result.success) {
      await this.setStateAsync({errorMessages: result.errorMessages, loading: false, showConfirmationModal: false});
      return;
    }

    await this.setStateAsync({loading: false, showInfoModal: true, showConfirmationModal: false});
  };

  render() {
    const validations = serverValidations.patientRestorePasswordDto;

    const {loading, errorMessages} = this.state;

    return (
      <div className="password-recovery-page">

        <div>
          <GoogleReCaptcha onVerify={() => this.setState({isVerified: true})}/>
        </div>

        <DocumentTitle title="Възстановяване на парола."/>

        {/* форма за възстановяване на парола*/}
        <CustomForm
          onSubmit={this.onSubmit}
          render={({isSubmitting, submitForm}) => (
            <div className="card">
              <div className="card-header blue white-text">
                {texts.PASS_RECOVERY_TITLE}
              </div>

              <span style={{padding: '20px 10px 0px 20px', fontStyle: 'italic', fontWeight: 'bold'}}>
                  Моля, преди да продължите да използвате услугите на сайта, да се
                  запознаете с Общите условия и политиките за поверителност.
                <a href={'/public/tos'} target="_blank"> ОБЩИ УСЛОВИЯ</a>
                </span>

              <span style={{padding: '20px 10px 0px 20px', fontStyle: 'italic', fontWeight: 'bold'}}>
                  Please, before continuing meet with the terms.
                <a href={'/public/tos'} target="_blank"> TERMS</a>
                </span>

              <div className="card-body ">
                <div>
                  <TextField
                    name="patientMobile"
                    label={texts.PASS_RECOVERY_PHNUM}
                    validation={validations.patientMobile}
                  />
                </div>
                <div>
                  <TextField
                    name="patientEmail"
                    label={texts.PASS_RECOVERY_EMAIL}
                    validation={validations.patientEmail}
                  />
                </div>
                <div>
                  <TextField
                    name="patientFirstName"
                    label={texts.PASS_RECOVERY_FNAME}
                    validation={validations.patientFirstName}
                  />

                </div>
                <div>
                  <TextField
                    name="patientSecondName"
                    label={texts.PASS_RECOVERY_SNAME}
                    validation={validations.patientSecondName}
                  />

                </div>
                <div>
                  <TextField
                    name="patientLastName"
                    label={texts.PASS_RECOVERY_LNAME}
                    validation={validations.patientLastName}
                  />
                </div>
                <div>
                  <SelectField
                    items={genderTypes}
                    name="patientSex"
                    label={texts.PASS_RECOVERY_GENDER}
                    validation={rangeNumber(1, 2, 'Моля, изберете пол.')}
                  />
                </div>
                <div>
                  <DateEditField
                    name="patientBirthDay"
                    label={texts.PASS_RECOVERY_DOB}
                    validation={validations.patientBirthDay}
                  />
                </div>

                <div className="text-center">
                  <button type="submit"
                          className="btn btn-md btn-default"
                          disabled={isSubmitting}>
                    {texts.PASS_RECOVERY_RESETBUTTON}
                  </button>

                  <BackButton render={(props) =>
                    <a {...props} className="btn btn-md btn-warning">
                      {texts.PASS_RECOVERY_BACKBUTTON}
                    </a>
                  }/>

                  {loading && <LoadingIndicator/>}

                  {this.state.showConfirmationModal &&
                  <ConfirmModal
                      title="Възстановяване на парола"
                      message="Моля, потвърдете възстановяването на паролата Ви."
                      onClose={async () => {
                        await this.setStateAsync({showConfirmationModal: false});
                      }}
                      onConfirm={submitForm}/>}

                  {this.state.showInfoModal &&
                  <InfoModal
                      message="Проверете вашият имейл/телефон за новата Ви парола"
                      linkTo={'/login'}
                  />}
                </div>

                {errorMessages && !!errorMessages.length && <div className="row">
                    <div className="col-sm-12">
                        <ErrorMessages errors={errorMessages}/>
                        <hr/>
                    </div>
                </div>}

              </div>
            </div>
          )}
        />
      </div>
    );
  }
}
