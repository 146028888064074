import { CacheState } from './cache.state';
import { PublicSettingsModel } from '../dto';

export interface SettingsService {
  getSettings: () => PublicSettingsModel;
}

export const createSettingsService = (cache: CacheState): SettingsService => {

  return {
    getSettings: () => ({...cache.settings} as any),
  };
};

declare module './context' {

  interface AppContext {
    settingsService: SettingsService;
  }
}
