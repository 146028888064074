import React from 'react';
import {RouteComponentProps, NavLink} from 'react-router-dom';

import {AppContextProps} from '../infrastructure/react-context';
import {BaseComponent} from '../infrastructure/components/BaseComponent';
import {PatientObligationsResponse, PatientCartsResponse} from '../dto';
import LoadingIndicator from '../infrastructure/components/LoadingIndicator';
import {format} from '../infrastructure/util';
import {DocumentTitle} from '../infrastructure/DocumentTitle';
import PatientCartModal from './PatientCartModal';
import {AlertModal} from '../infrastructure/AlertModal';
import {groupBy} from '../infrastructure/group-by';
import {ObligationList} from './ObligationList';
import {CheckboxInput} from '../infrastructure/fields/CheckboxInput';
import SessionComponent from '../infrastructure/components/SessionComponent';

interface RouterParams {
  orderID: string;
}

interface Props extends RouteComponentProps<RouterParams>, AppContextProps {
}

interface State {
  loading: boolean;
  showError: boolean;
  errorMessage?: string | string[];
  showModal: boolean;
  obligationsResponse: PatientObligationsResponse | undefined;
  cartsResponse: PatientCartsResponse | undefined;
  newCartObligationKeys?: string[];
  cartStatusMessage?: string;
  cartStatusIsOK: boolean;
  tocAgreed: boolean;
}

export class ViewObligations extends BaseComponent<Props, State> {

  state: State = {
    loading: false,
    showError: false,
    showModal: false,
    obligationsResponse: undefined,
    cartsResponse: undefined,
    cartStatusIsOK: false,
    tocAgreed: false,
  };

  componentDidMountAsync = async () => {
    await this.refreshObligations();
    await this.refreshCarts();

    if (this.state.cartsResponse && this.state.cartsResponse.carts.length > 0) {
      const cartsToRefresh = this.state.cartsResponse.carts.filter((p) => p.isWaiting);
      if (cartsToRefresh.length) {
        for (const cart of cartsToRefresh) {
          await this.onRefreshCartStatus(cart.cartID);
        }
      }
    }

    const {orderID} = this.props.match.params;

    if (orderID) {
      const {server} = this.props.context;
      const resOrderStatus = await server.patientCarts({
        orderIDs: [orderID],
      });

      if (resOrderStatus.success && resOrderStatus.payload.carts.length > 0) {
        const cart = resOrderStatus.payload.carts[0];
        if (cart.isPaid) {
          await this.setStateAsync({
            cartStatusIsOK: true,
            cartStatusMessage: `Поръчка № ${cart.orderID} е успешно платена!`,
          });
        } else if (cart.isRejected) {
          await this.setStateAsync({
            cartStatusIsOK: false,
            cartStatusMessage: `Поръчка № ${cart.orderID} беше отхвърлена!`,
          });
        }
      }
    }

  };

  refreshObligations = async () => {
    const {server, actions} = this.props.context;

    await this.setStateAsync({loading: true});
    const resObligations = await server.patientObligations({});

    if (!resObligations.success) {
      actions.errors.setErrorMessages(resObligations.errorMessages);
      await this.setStateAsync({loading: false, obligationsResponse: undefined});
      return;
    }

    await this.setStateAsync({
      loading: false,
      obligationsResponse: resObligations.payload,
    });
  };

  refreshCarts = async () => {
    const {server, actions} = this.props.context;

    await this.setStateAsync({loading: true});
    const resCarts = await server.patientCarts({});

    if (!resCarts.success) {
      actions.errors.setErrorMessages(resCarts.errorMessages);
      await this.setStateAsync({loading: false, cartsResponse: undefined});
      return;
    }

    await this.setStateAsync({
      loading: false,
      cartsResponse: resCarts.payload,
    });
  };

  onRefreshCartStatus = async (cartID: number) => {
    const {server} = this.props.context;

    await this.setStateAsync({loading: true});

    const resp = await server.patientCheckCartStatus({cartID});
    if (!resp.success) {
      await this.setStateAsync({loading: false, showError: true, errorMessage: resp.errorMessages});
      return;
    }

    await this.setStateAsync((state) => {
      if (!state.cartsResponse) {
        return state;
      }

      return {
        ...state,
        loading: false,
        cartsResponse: {
          ...state.cartsResponse,
          carts: state.cartsResponse.carts.map((c) => {
            if (c.cartID === cartID) {
              return {...resp.payload.cart};
            }

            return c;
          }),
        },
      };
    });
  };

  onCreateCart = async (obligationKeys: string[]) => {
    await this.setStateAsync((state) => {
      if (!obligationKeys.length) {
        return {
          ...state,
          showError: true,
          errorMessage: 'Моля, изберете поне една услуга за плащане!',
        };
      }
      if (!this.state.tocAgreed) {
        return {
          ...state,
          showError: true,
          errorMessage: 'Моля, приемете общите условия на сайта.',
        };
      }

      return {
        ...state,
        showModal: true,
        newCartObligationKeys: obligationKeys,
      };
    });

    await this.setStateAsync({showError: true});
  };

  render() {
    const {
      loading, showError, showModal, newCartObligationKeys, errorMessage,
      cartStatusIsOK, cartStatusMessage,
    } = this.state;
    const cartStatusClass = `card text-white mb-3 ${cartStatusIsOK ? 'bg-success' : 'bg-danger'}`;
    const obligations = this.state.obligationsResponse?.obligations || [];
    const groupedObligations = groupBy(obligations, (x) => x.hospitalInfoID);
    const carts = this.state.cartsResponse?.carts;

    return (
      <div className="patient-obligations-page">

        <DocumentTitle title="Задължения за плащане"/>

        {this.props.match.params.orderID && cartStatusMessage &&
            <div className={cartStatusClass}>
                <div className="card-header">Статус</div>
                <div className="card-body">
                    <p className="card-text text-white">{cartStatusMessage}</p>
                </div>
            </div>
        }

        <div className="card">
          <h4 className="card-header d-flex justify-content-center">Он-лайн плащане с карта</h4>
          <div className="card-body">
            <div className="d-flex align-items-center">
              <CheckboxInput
                label={``}
                name={'tocAgreed'}
                value={this.state.tocAgreed}
                className="pt-0 mt-2"
                onChange={async () => await this.setStateAsync({tocAgreed: !this.state.tocAgreed})}
              />
              <span>Приемам <a href="/public/tos"> ОБЩИТЕ УСЛОВИЯ </a>на сайта.</span>
            </div>
            <p className="font-italic mb-0">
              *Ако желаете можете да платите само избрани услуги от Вас, или да ги платите частично.
            </p>
          </div>
        </div>

        {loading && <LoadingIndicator/>}

        {!loading &&
            <div>
                <div>
                  {groupedObligations.length > 0 ?
                    groupedObligations.map((ob) =>
                      <ObligationList
                        key={ob.key}
                        obligations={ob.items}
                        onCreateCart={this.onCreateCart}/>) :
                    <h3 className="d-flex justify-content-center mt-2">Към момента няма задължения за плащане.</h3>
                  }
                </div>

                <div className="card mt-2">
                    <div className="card-body">
                        <div className="row">
                            <h4 className="col-lg-8 col-md-6 d-flex justify-content-center align-items-center">
                                Извършени плащания и статуси
                            </h4>
                            <div className="col-lg-4 col-md-6">
                                <SessionComponent feature="OnlineConsultationPage">
                                    <NavLink
                                        className="btn btn-default w-100"
                                        to={'/patient/online-consultations'}>
                                        ОНЛАЙН КОНСУЛТАЦИ &gt;&gt;
                                    </NavLink>
                                </SessionComponent>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                  {carts && !!carts.length &&
                      <div className="table-responsive" style={{marginTop: '1rem'}}>
                          <table className="table list-table">
                              <thead className="blue text-white">
                              <tr>
                                  <th>Дата и час</th>
                                  <th>№ на поръчката</th>
                                  <th>Сума</th>
                                  <th>Услуги</th>
                                  <th>Статус</th>
                                  <th/>
                              </tr>
                              </thead>

                            {carts && carts.length > 0 && <tbody>
                            {carts.map((item) => (
                              <tr key={item.cartID}>
                                <td className="no-wrap">{format(item.transactionDatetime, 'dd.MM.yyyy HH:mm')}</td>
                                <td>{item.orderID}</td>
                                <td>{item.amount} лв.</td>
                                <td>{item.servicesNames}</td>
                                <td
                                  className={item.isPaid ? 'bg-success text-white' :
                                    item.isRejected ? 'bg-danger text-white' :
                                      item.isStorniran ? 'bg-warning text-black' : ''}>{item.cartStatusName}</td>
                                <td>
                                  <button className="btn btn-blue btn-sm" onClick={() => this.onRefreshCartStatus(item.cartID)}>
                                    Опресни
                                  </button>
                                </td>
                              </tr>
                            ))}
                            </tbody>}
                          </table>
                      </div>}
                </div>

                <div>
                  {carts && carts.length > 0 &&
                      <h3 className="mt-2 d-flex justify-content-center">Няма други плащания</h3>}
                </div>
            </div>
        }

        {showModal && newCartObligationKeys && newCartObligationKeys.length &&
            <PatientCartModal
                obligationKeys={newCartObligationKeys}
                onClose={() => this.setState({showModal: false})}
            />}

        {showError && errorMessage &&
            <AlertModal
                onClose={() => this.setState({showError: false, errorMessage: undefined})}
                message={errorMessage}/>
        }

      </div>
    );
  }
}
