import React, { ComponentType, lazy, Suspense, FunctionComponent } from 'react';
import LoadingIndicator from './components/LoadingIndicator';

type ImportType<T> = Promise<{ default: T }>;

export function LazyComponent<TComponent extends ComponentType<any>>(
  lazyFactory: () => ImportType<TComponent>, Fallback?: FunctionComponent): TComponent {
  const Impl = lazy(lazyFactory);
  const f = (props: any) => (
    <Suspense fallback={(Fallback && <Fallback/>) || <LoadingIndicator />}>
      <Impl {...props} />
    </Suspense>
  );
  return f as TComponent;
}
