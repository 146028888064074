import React, { useImperativeHandle } from 'react';
import { Formik, FormikProps, FormikConfig, FormikValues } from 'formik';

export const FormikWithRef = <Values extends FormikValues = FormikValues, ExtraProps = {}>(
  props: FormikConfig<Values> & ExtraProps & { forwardedRef: any; }) => {

  let _formikProps: FormikProps<Values>;

  useImperativeHandle(props.forwardedRef, () => (_formikProps));

  return (
    <Formik {...props}>
      {(formikProps) => {
        _formikProps = formikProps;
        if (typeof props.children === 'function') {
          return props.children(formikProps);
        }
        return props.children;
      }}
    </Formik>
  );
};
