import React, { FunctionComponent, Fragment } from 'react';
import { Link } from 'react-router-dom';

import BackButton from '../../infrastructure/components/BackButton';
import { CustomForm } from '../../infrastructure/components/CustomForm';
import { ErrorMessages } from '../../infrastructure/errors';
import { DateField } from '../../infrastructure/fields/DateInput';
import { TextField } from '../../infrastructure/fields/TextInput';
import { SelectItem } from '../../infrastructure/fields/select-item';
import { SelectField } from '../../infrastructure/fields/SelectInput';
import { AttachedResultDto, PatientInformationDto } from '../../dto';
import { FileSelectField } from '../../infrastructure/fields/FileSelectInput';
import LoadingIndicator from '../../infrastructure/components/LoadingIndicator';
import { required } from '../../infrastructure/fields/validations';

interface Props {
  model: AttachedResultDto;
  patient: PatientInformationDto;
  errorMessages: string[];
  onSubmit: (data: AttachedResultDto) => Promise<any>;
  submitLoading: boolean;
}

const patientRecStatuses: SelectItem[] = [
  {value: 1, label: 'Обработва се'},
  {value: 2, label: 'Готово'},
];

export const AttachedResultForm: FunctionComponent<Props> =
  ({model, patient, errorMessages, onSubmit, submitLoading}) => (
    <CustomForm values={model} onSubmit={onSubmit} render={({isSubmitting}) => (
      <Fragment>
        <div className="row" style={{marginTop: '1rem'}}>
          <div className="col-md-8 vertical-center">
            <h4>
              {patient.patientFullName}, ЕГН: {patient.patientIdentNumber} Възраст: {patient.patientAge} год.
            </h4>
          </div>

          <div className="col-md-4">
            <div className="button-group">
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-md btn-info">
                Запази
              </button>

              {!!model.patientRecordID && <Link
                to={`/doctor/attached-results/${model.patientRecordID}/delete`}
                className="btn btn-md btn-danger">
                Изтриване
              </Link>}

              <BackButton render={(props) =>
                <a {...props}
                   className="btn btn-md btn-warning">
                  Назад
                </a>
              }/>
            </div>

          </div>
        </div>

        <hr/>

        {submitLoading && <LoadingIndicator/>}

        {!!errorMessages.length && <div className="row">
          <div className="col-sm-12">
            <hr/>
            <ErrorMessages errors={errorMessages}/>
            <hr/>
          </div>
        </div>}

        <div className="row" style={{marginTop: '1rem'}}>
          <div className="offset-md-3 col-md-6">
            <FileSelectField
              name="pdfFileContent"
              label="Файл:"
              mimeTypes="application/pdf"
              nullable
              validation={(!model.patientRecordID ? [required('Pdf файлът е задължителен.')] : [])}
            />
          </div>
        </div>

        <div className="row">

          <div className="col-md-6">
            <DateField
              name="patientRecDateTime"
              label="Дата:"
            />
          </div>
          <div className="col-md-6">
            <TextField
              name="patientRecShortName"
              label="Описание:"
            />
          </div>
          <div className="col-md-6">
            <SelectField
              name="patientRecStatus"
              label="Статус:"
              items={patientRecStatuses}
            />
          </div>
          <div className="col-md-6">
            <TextField
              name="docID"
              label="DocID:"
            />
          </div>
        </div>
      </Fragment>
    )}/>
  );
