import React, { Fragment } from 'react';
import { BaseComponent } from '../../infrastructure/components/BaseComponent';
import { AppContextProps } from '../../infrastructure/react-context';
import LoadingIndicator from '../../infrastructure/components/LoadingIndicator';
import { DocumentTitle } from '../../infrastructure/DocumentTitle';
import BackButton from '../../infrastructure/components/BackButton';
import { ErrorMessages } from '../../infrastructure/errors';
import { CustomForm } from '../../infrastructure/components/CustomForm';
import { TextAreaField } from '../../infrastructure/fields/TextAreaInput';
import { Link } from 'react-router-dom';
import { SendNotificationResponse, serverValidations } from '../../dto';
import { Result } from '../../infrastructure/api-result';

import './SendNotifications.scss';
import { DebugValue } from '../../infrastructure/components/DebugValue';

interface Props extends AppContextProps {
}

interface State {
  submitLoading: boolean;
  sendResult: Result<SendNotificationResponse> | undefined;
}

const initialState: State = {
  submitLoading: false,
  sendResult: undefined,
};

export class SendNotifications extends BaseComponent<Props, State> {

  state: State = initialState;

  saveItem = async ({content}: { content: string }) => {
    const {server} = this.props.context;
    const result = await server.sendNotification({content});
    await this.setStateAsync({sendResult: result});
  };

  render() {

    const {submitLoading, sendResult} = this.state;

    const validations = serverValidations.sendNotificationRequest;

    return (
      <div className="send-notifications-page">
        <DocumentTitle title="Съобщения - Изпращане"/>

        {sendResult && sendResult.success && <div>
          <div className="card">
            <div className="card-header green white-text">Собщението е изпратено успешно!</div>
            <div className="card-body">
              <DebugValue value={sendResult.payload.oneSignalResponseJson}/>
              <div className="text-center">
                <button className="btn btn-md btn-default" onClick={() => this.setState(initialState)}>
                  Изпрати ново
                </button>
                <Link to="/" className="btn btn-md btn-primary">Начало</Link>
              </div>
            </div>
          </div>
        </div>}

        {(!sendResult || !sendResult.success) && <div className="card">
          <div className="card-header blue white-text">Съобщения - Изпращане</div>
          <div className="card-body">
            {submitLoading && <LoadingIndicator/>}
            <CustomForm values={{}} onSubmit={this.saveItem} render={({isSubmitting}) => (
              <Fragment>
                <div style={{textAlign: 'center'}}>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn btn-md btn-default">
                    Изпрати
                  </button>

                  <BackButton render={(props) =>
                    <a {...props}
                       className="btn btn-md btn-warning">
                      Назад
                    </a>
                  }/>
                </div>

                <div>
                  <TextAreaField
                    label="Съдържание"
                    name="content"
                    inputStyle={{height: '10rem'}}
                    validation={validations.content}
                  />
                </div>

                {submitLoading && <LoadingIndicator/>}

                {sendResult && !sendResult.success && <Fragment>
                  <div className="col-sm-12">
                    <hr/>
                    <ErrorMessages errors={sendResult.errorMessages}/>
                    <hr/>
                  </div>
                </Fragment>}

              </Fragment>
            )}/>
          </div>
        </div>}
      </div>
    );
  }
}
