import {AppContextFactoryProps, withAppContext} from '../infrastructure/react-context';
import {BaseComponent} from '../infrastructure/components/BaseComponent';
import {CustomModal} from '../infrastructure/components/CustomModal';
import React from 'react';
import {Link} from 'react-router-dom';

/* Форма за потвърждване от потребителя */
interface Props extends AppContextFactoryProps {
  onClose?: () => void;
  message?: string;
  linkTo?: string;
}

class InfoModalImpl extends BaseComponent<Props> {
  render() {
    const {message, onClose, linkTo} = this.props;

    return (
      <div className="confirmation-modal">
        <CustomModal title="Информация" onClose={onClose}>
          <div className="row">
            <div className="col-12 p-2 m-2">
              {message && <h3 className="text-center">{message}</h3>}
            </div>
          </div>
          <div className="col-12">
            <div className="text-center">
              {linkTo ?
                <Link className="btn btn-md btn-warning" to={linkTo}>
                  Затвори
                </Link> :
                <button className="btn btn-md btn-warning" type="button" onClick={onClose}>Отказ</button>
              }
            </div>
          </div>
        </CustomModal>
      </div>
    );
  }
}

/* Прозорец за потвърждаване на операция*/
export const InfoModal = withAppContext(InfoModalImpl);
