import React, {Fragment, CSSProperties} from 'react';
import {BaseComponent} from '../../infrastructure/components/BaseComponent';
import {OptionalChildrenProps} from '../../infrastructure/react-helpers';

import './LoadingIndicator.scss';

interface Props extends OptionalChildrenProps {
  centerAndBlurBackground?: boolean;
  style?: CSSProperties;
  showLoadingText?: boolean;
  loadingText?: string;
  className?: string;
}

interface State {
}

export class LoadingIndicator extends BaseComponent<Props, State> {

  render() {
    const {centerAndBlurBackground, style, showLoadingText, loadingText, className} = this.props;

    return (
      <Fragment>
        <div className={`${centerAndBlurBackground ? 'loading-modal-center' : 'loading-modal'} ${className}`}
             style={style}>
          {showLoadingText &&
              <div className="loading-modal-text">{loadingText ? loadingText : 'Зареждаме'}</div>}
          <div className="ring-span"></div>
        </div>

        {centerAndBlurBackground &&
            <div className="overlay hidden"></div>}
      </Fragment>
    );
  }
}
