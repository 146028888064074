import {RouteTable} from '../infrastructure/components/RenderRouteTable';
import {DicomViewer} from './Viewer/DicomViewer';
import {StudiesCatalog} from './Studies/StudiesCatalog';
import React, {memo} from 'react';
import {useParams} from 'react-router-dom';
import {AppContext} from '../infrastructure/context';
import {DownloadStudyArchive} from './DownloadStudyArchive';
import {PatientImaging} from './PatientWorklist/PatientImaging';

export const dicomRoutes: RouteTable = [
  {
    location: '/dicom/studies/catalog',
    component: StudiesCatalog,
    frame: 'standard',
    role: 'doctor',
    feature: 'DicomPage',
  },
  {
    location: '/dicom/viewer/:studyInstanceUID',
    component: memo(({context}: { context: AppContext }) => {
      const params = useParams<{ studyInstanceUID: string }>();

      return (
        <DicomViewer
          studyInstanceUID={params.studyInstanceUID}
          context={context}/>
      );
    }),
    frame: 'without',
    role: 'doctor',
    feature: 'DicomPage',
  },
  {
    location: '/dicom/download-study-archive/:downloadKey/:downloadID',
    component: memo(({context}: { context: AppContext }) => {
      const params = useParams<{ downloadKey: string, downloadID: string }>();
      const downloadID = Number.parseInt(params.downloadID, 10);

      return (
        <DownloadStudyArchive
          downloadID={downloadID}
          downloadKey={params.downloadKey}
          context={context}/>
      );
    }),
    frame: 'wide',
    feature: 'DicomPatientPage',
    requireLogin: false,
  },
  {
    location: '/dicom/patients/imaging-studies',
    component: PatientImaging,
    frame: 'standard',
    role: 'patient',
    feature: 'DicomPatientPage',
  },
];
