import { PureComponent } from 'react';
import { logError } from '../errors';
import { isPromise } from '../util';

export const componentUnmountedKey = '__IS_COMPONENT_UNMOUNTED_ERROR__';

const componentUnmountedError = (): Error => {
  const err = new Error('The operation is being canceled.');
  (err as any)[componentUnmountedKey] = true;
  return err;
};

// tslint:disable-next-line:max-classes-per-file
export class BaseComponent<P = {}, S = {}> extends PureComponent<P, S> {
  _isMounted: boolean = false;

  // tslint:disable-next-line:max-line-length
  setStateAsync<K extends keyof S>(state: ((prevState: Readonly<S>, props: Readonly<P>) => (Pick<S, K> | S | null)) | (Pick<S, K> | S | null)) {
    return new Promise((resolve) => {
      if (!this._isMounted) {
        throw componentUnmountedError();
      }
      this.setState(state, resolve);
    });
  }

  unwrapPromise<T>(cb: (...args: any[]) => Promise<T> | void) {
    return (...args: any[]) => {
      const onError = (error: any) => {
        if (this._isMounted) {
          this.setState(() => {
            throw error;
          });
          return;
        }
        if (error[componentUnmountedKey]) {
          console.warn('There was a continuation that executed after the component was unmounted.');
        } else {
          console.warn('An error occurred in a component that was not mounted.');
          //noinspection JSIgnoredPromiseFromCall
          logError(error);
        }
      };

      try {
        const value = cb(...args);
        if (isPromise(value)) {
          return value.catch(onError);
        }
      } catch (e) {
        onError(e);
      }
    };
  }

  async componentDidMountAsync() {
  }

  async componentWillUnmountAsync() {
  }

  async componentDidUpdateAsync(_prevProps: P) {
  }

  componentDidMount() {
    this._isMounted = true;
    this.unwrapPromise(this.componentDidMountAsync.bind(this))();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.unwrapPromise(this.componentWillUnmountAsync.bind(this))();
  }

  componentDidUpdate(prevProps: P) {
    this.unwrapPromise(this.componentDidUpdateAsync.bind(this))(prevProps);
  }
}
