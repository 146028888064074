import {RouteTable} from '../infrastructure/components/RenderRouteTable';
import {MakeAppointment} from '../shared/MakeAppointment';
import {RenderPdf} from './RenderPdf';
import {TermsOfService} from './TermsOfService';
import {Colors} from './Colors';
import {PatientRegister} from './PatientRegister';
import {PromotionList} from '../promotions/PromotionsList';
import {ViberPublicQr} from './ViberPublicQr';

export const publicRoutes: RouteTable = [
  {
    location: '/public/appointment',
    component: MakeAppointment,
    publicOnly: true,
    feature: 'AppointmentPage',
    frame: 'narrow',
  },
  {
    location: '/public/render-pdf',
    component: RenderPdf,
    requireLogin: false,
  },
  {
    location: '/public/tos',
    component: TermsOfService,
    requireLogin: false,
  },
  {
    location: '/public/colors',
    component: Colors,
    requireLogin: false,
  },
  {
    location: '/register',
    component: PatientRegister,
    requireLogin: false,
  },
  {
    location: '/promo/:promotionGuid',
    component: PromotionList,
    requireLogin: false,
    frame: 'legacy',
  },
  {
    location: '/vib/:identification',
    component: ViberPublicQr,
    frame: 'legacy',
    requireLogin: false,
  },
];
