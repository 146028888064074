const shallowEqual = (a: readonly unknown[], b: readonly unknown[]): boolean => {
  if (a.length !== b.length) {
    return false;
  }
  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) {
      return false;
    }
  }
  return true;
};

type KeyedFn<T> = (key: object[]) => T;
type ValueFactory<T> = () => T;

export const customMemo = <T>(fn: ValueFactory<T>): KeyedFn<T> => {

  let currentKey: object[];
  let value: T;

  return (key) => {

    if (!currentKey || !shallowEqual(key, currentKey)) {
      value = fn();
      currentKey = key;
    }

    return value;
  };
};
