import React, {Fragment} from 'react';
import {RouteComponentProps, Link} from 'react-router-dom';

import {ErrorMessages} from '../infrastructure/errors';
import {AppContextProps} from '../infrastructure/react-context';
import {BaseComponent} from '../infrastructure/components/BaseComponent';
import {CustomForm} from '../infrastructure/components/CustomForm';
import {TextField} from '../infrastructure/fields/TextInput';
import LoadingIndicator from '../infrastructure/components/LoadingIndicator';
import {serverValidations, LoginRequest} from '../dto';
import {DocumentTitle} from '../infrastructure/DocumentTitle';
import {TextResource} from '../infrastructure/TextResource';
import {CheckboxField} from '../infrastructure/fields/CheckboxInput';
import {texts} from '../infrastructure/texts';
import SessionComponent from '../infrastructure/components/SessionComponent';
import {GoogleReCaptcha} from 'react-google-recaptcha-v3';

interface RouteParams {
  token: string | undefined;
}

interface Props extends RouteComponentProps<RouteParams>, AppContextProps {
}

interface State {
  errorMessages: string[];
  loading: boolean;
  isVerified: boolean;
  loadingTokenLogin: boolean;
}

export class Login extends BaseComponent<Props, State> {
  state: State = {
    errorMessages: [],
    loading: false,
    isVerified: false,
    loadingTokenLogin: false,
  };

  async componentDidMountAsync() {
    const {server, actions} = this.props.context;

    const token = this.props.match.params.token;
    if (token) {
      await this.setStateAsync({loadingTokenLogin: true});
      const resp = await server.loginWithToken({tokenValue: token});
      if (!resp.success) {
        await this.setStateAsync({errorMessages: resp.errorMessages, loadingTokenLogin: false});
        return;
      }

      actions.session.login(resp.payload);
      actions.router.routerPush('/');

      await this.setStateAsync({loadingTokenLogin: false});
    }
  }

  login = async (data: LoginRequest) => {
    if (!this.state.isVerified) {
      return;
    }

    const {server, actions} = this.props.context;

    await this.setStateAsync({loading: true});

    const loginResponse = await server.login(data);

    if (!loginResponse.success) {
      await this.setStateAsync({errorMessages: loginResponse.errorMessages, loading: false});
      return;
    }

    actions.session.login(loginResponse.payload);

    actions.router.routerPush('/');

    await this.setStateAsync({loading: false});
  };

  render() {
    const {loading, loadingTokenLogin, errorMessages} = this.state;

    const validations = serverValidations.loginRequest;

    return (
      <div className="login-page" style={{marginTop: '1rem'}}>

        <div>
          <GoogleReCaptcha onVerify={() => this.setState({isVerified: true})}/>
        </div>

        <DocumentTitle title={texts.LOGIN_PAGE_TITLE}/>

        <div className="home-page__resource-top">
          <TextResource injectHtml resourceId={6}/>
        </div>

        <CustomForm onSubmit={this.login} render={(_) => (
          <div>
            <div className="card login-form">
              <div className="card-body">
                <div>
                  <h4 className="text-center mt-2">{texts.LOGIN_FORM_TITLE}</h4>
                </div>
                {loadingTokenLogin && <LoadingIndicator delay={0}/>}
                {!loadingTokenLogin &&
                    <Fragment>
                        <div>
                            <TextField
                                name="username"
                                icon="user"
                                label={texts.LOGIN_USERNAME_LABEL}
                                validation={validations.username}
                                inputProps={{autoComplete: 'username'}}
                            />

                            <TextField
                                password
                                name="password"
                                icon="lock"
                                label={texts.LOGIN_PASSWORD_LABEL}
                                validation={validations.password}
                                inputProps={{autoComplete: 'current-password'}}
                            />
                        </div>

                      {errorMessages && !!errorMessages.length && <Fragment>
                          <hr/>
                          <ErrorMessages errors={errorMessages}/>
                          <hr/>
                      </Fragment>}

                      {loading && <LoadingIndicator delay={0}/>}

                        <div className="row">
                            <div className="col-6 text-center">
                                <CheckboxField
                                    name="rememberMe"
                                    label={texts.LOGIN_REMEMBER_ME_LABEL}
                                />
                            </div>
                            <div className="col-6 my-2">
                                <button className="btn btn-success btn-block" disabled={loading} type="submit">
                                  {texts.LOGIN_BUTTON_TEXT}
                                </button>
                            </div>
                        </div>
                    </Fragment>
                }
              </div>
            </div>
            <div>
              <h6 className="text-center mt-1">Декларирам, че съм запознат и съгласен с
                <a href="/public/tos" target="_blank"> ОБЩИТЕ УСЛОВИЯ / TERMS</a>.</h6>
            </div>
            <div>
              <h6 className="text-center mt-1">
                <SessionComponent publicOnly feature="PasswordRecoveryPage">
                  <Link
                    to={'/patient/password-recovery'} className="nav-link">
                    {texts.LOGIN_FORGOT_PASS}
                  </Link>
                </SessionComponent>
              </h6>
            </div>
          </div>
        )}/>
      </div>
    );
  }
}
