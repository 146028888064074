import {toast} from 'react-toastify';
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faExclamation, faTimes, faInfoCircle} from '@fortawesome/free-solid-svg-icons';

export const notify = (type: string, message: string, autoClose?: number | false) => {
  switch (type) {
    case 'warning':
      return toast.warn(<div><span className="mr-2"><FontAwesomeIcon icon={faExclamation} size="lg"/></span>{message}
      </div>, {autoClose, position: 'top-right'});
    case 'error':
      return toast.error(<div><span className="mr-2"><FontAwesomeIcon icon={faTimes} size="lg"/></span>{message}
      </div>, {autoClose, position: 'top-right'});
    case 'success':
      return toast.success(<div><span className="mr-2"><FontAwesomeIcon icon={faCheck} size="lg"/></span>{message}
      </div>, {autoClose, position: 'top-right'});
    case 'info':
      return toast.info(<div><span className="mr-2"><FontAwesomeIcon icon={faInfoCircle} size="lg"/></span>{message}
      </div>, {autoClose, position: 'top-right'});
    default:
      return toast(message);
  }
};
