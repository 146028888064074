import * as historyImport from 'history';
import { History, LocationState } from 'history';

import { configureStore, ReduxStore, StoreOptions } from './infrastructure/redux/store';
import { AppContextFactory, ConfigureContext, getContextFactory } from './infrastructure/context';
import { sessionReducer, sessionActionCreators } from './infrastructure/session.state';
import { settingsReducer } from './infrastructure/settings.state';
import { errorsReducer, errorsSagas, errorsActionCreators } from './infrastructure/errors.state';
import { cacheReducer, cacheSagas, cacheActionCreators } from './infrastructure/cache.state';
import {
  toastSagas,
  routerSagas,
  routerActionCreators,
  toastActionCreators,
  modalsActionCreators,
  modalsReducer,
} from './infrastructure/global.state';
import { freezeMiddleware } from './infrastructure/redux/freeze-middleware';
import { distributeMiddleware } from './infrastructure/redux/distribute-dispatch';
import { jCoreClient } from './infrastructure/jcore-client';
import { createSessionService } from './infrastructure/session-service';
import { createSettingsService } from './infrastructure/settings-service';
import { serverApi } from './dto';
import { createCommonService } from './common/common-service';

interface Global {
  store: ReduxStore;
  getContext: AppContextFactory;
  history: History<LocationState>;
}

interface GlobalOptions {
  storeOptions: StoreOptions;
  configureContext: ConfigureContext;
}

export const createGlobal = (options: GlobalOptions): Global => {

  let getContext: AppContextFactory;
  const getContextWrapper: AppContextFactory = () => getContext();

  const store = configureStore(options.storeOptions, getContextWrapper);
  const history = historyImport.createBrowserHistory();

  getContext = getContextFactory(store, (x) => options.configureContext(x, getContextWrapper), history);

  return {
    store,
    getContext,
    history,
  };
};

export const app: Global = {} as Global;

export const initializeGlobal = () => {
  const newGlobal = createGlobal({
    storeOptions: {
      reducers: {
        session: sessionReducer,
        settings: settingsReducer,
        errors: errorsReducer,
        cache: cacheReducer,
        modals: modalsReducer,
      },
      sagas: {
        ...errorsSagas,
        ...toastSagas,
        ...cacheSagas,
        ...routerSagas,
      },
      persistentFields: [
        'session',
        'cache',
      ],
      middleware: [
        freezeMiddleware,
        distributeMiddleware,
      ],
    },
    configureContext: (store, getContext) => {

      if (!getContext) {
        throw new Error('No context self-reference.');
      }

      const {session, cache} = store.getState();

      const client = jCoreClient(session.token, getContext);
      const sessionService = createSessionService(session, cache);
      const settingsService = createSettingsService(cache);
      const commonService = createCommonService(session, cache);

      return {
        server: serverApi(client),
        sessionService,
        settingsService,
        commonService,
        actions: {
          session: sessionActionCreators,
          router: routerActionCreators,
          modals: modalsActionCreators,
          errors: errorsActionCreators,
          toast: toastActionCreators,
          cache: cacheActionCreators,
        },
      };
    },
  });

  Object.assign(app, newGlobal);
};
