import React, { FunctionComponent, ComponentType } from 'react';

import { ChildrenProps } from './react-helpers';
import { AppContextFactory, AppContext } from './context';

export interface AppContextFactoryProps {
  getContext: AppContextFactory;
}

export interface AppContextProps {
  context: AppContext;
}

export const ReactAppContext = React.createContext<AppContextFactory>(() => (undefined as any));

interface AppContextProviderProps extends ChildrenProps, AppContextFactoryProps {
}

export const AppContextProvider: FunctionComponent<AppContextProviderProps> = ({getContext, children}) => (
  <ReactAppContext.Provider value={getContext}>
    {children}
  </ReactAppContext.Provider>
);

export const withAppContext = <P extends AppContextFactoryProps>(Component: ComponentType<P>):
  FunctionComponent<Omit<P, keyof AppContextFactoryProps>> => (props) => (
  <ReactAppContext.Consumer>
    {(getContext) => <Component {...props as P} getContext={getContext}/>}
  </ReactAppContext.Consumer>
);
