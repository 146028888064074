import React, { FunctionComponent } from 'react';

interface Props {
  value: any;
}

export const DebugValue: FunctionComponent<Props> = ({value}) => (
  <pre style={{backgroundColor: '#c5ff78'}}>
    {(typeof value === 'string' ? value : JSON.stringify(value, null, 2))}
  </pre>
);
