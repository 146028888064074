import React, { FunctionComponent, useState, memo } from 'react';

import dateFnsParse from 'date-fns/parse';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DateUtils } from 'react-day-picker';
import { format, newDateWithoutTime, trimDate } from '../util';

import 'react-day-picker/lib/style.css';

import './DateEdit.scss';

import { wrapField, cssClasses, BaseInputProps } from '../fields/field-helpers';
import { ErrorMessages } from '../errors';

type InputType = Date | undefined;
type StoredType = Date | null;

interface Props extends BaseInputProps<StoredType> {
  label: string;
  defaultValue?: StoredType;
  nullable?: boolean;
  disabledDays?: (date: Date) => boolean;
}

const MONTHS = [
  'Януари', 'Февруари', 'Март', 'Април', 'Май', 'Юни',
  'Юли', 'Август', 'Септември', 'Октомври', 'Ноември', 'Декември',
];
const WEEKDAYS_LONG = ['Неделя', 'Понеделник', 'Вторник', 'Сряда', 'Четвъртък', 'Петък', 'Събота'];
const WEEKDAYS_SHORT = ['Нед', 'Пон', 'Вт', 'Ср', 'Чет', 'Пет', 'Съб'];

const getDefaultValue = ({nullable}: Props): StoredType => {
  if (nullable) {
    return null;
  } else {
    return newDateWithoutTime();
  }
};

const getInitialValue = (props: Props): StoredType => {
  if (props.defaultValue !== undefined) {
    return props.defaultValue;
  } else {
    return getDefaultValue(props);
  }
};

const toInputValue = (value: StoredType): InputType => value === null ? undefined : value;
const fromInputValue = (inputValue: InputType): StoredType => {
  return inputValue === undefined ? null : trimDate(inputValue);
};

export const DateEdit: FunctionComponent<Props> = memo((props: Props) => {

  const {label, readonly, nullable, style, disabledDays, errorMessages, setTouched, onChange, className} = props;

  let value = props.value;

  // Initialize
  if (value === undefined) {
    value = getInitialValue(props);
    setTimeout(() => onChange(value), 0);
  }

  const [focused, setFocused] = useState(false);

  const active = value !== null || (!readonly && focused);

  const cssStates = cssClasses({
    readonly,
    invalid: !!errorMessages && !focused,
    active,
    focused,
  });

  const onClear = () => {
    onChange(null);

    setFocused(false);
    if (setTouched) {
      setTouched();
    }
  };

  const FORMAT = 'dd.MM.yyyy';

  const parseDate = (str: string, fmt: string) => {
    const parsed = dateFnsParse(str, fmt, new Date());
    if (parsed.getFullYear() < 1905) {
      return undefined;
    }
    if (DateUtils.isDate(parsed)) {
      return parsed;
    }
    return undefined;
  };

  return (
    <div className={`date-input custom-form-input form-group ${className} ${cssStates}`} style={style}>

      <div className="datepicker-wrapper">
        <p className="label-style">{label}</p>
        <DayPickerInput
          dayPickerProps={{
            locale: 'bg',
            months: MONTHS,
            weekdaysLong: WEEKDAYS_LONG,
            weekdaysShort: WEEKDAYS_SHORT,
            firstDayOfWeek: 1,
            disabledDays,
          }}
          format={FORMAT}
          formatDate={format}
          parseDate={parseDate}
          value={toInputValue(value)}
          placeholder={FORMAT}
          onDayChange={(val) => {
            const newValue = fromInputValue(val);
            onChange(newValue);

            if (setTouched) {
              setTouched();
            }

            setFocused(false);
          }}
        />

        {nullable && !readonly && <span className="btn btn-default clear-button" onClick={onClear}>✘</span>}
      </div>

      {errorMessages && !focused && <ErrorMessages errors={errorMessages}/>}

    </div>
  );
});

export const DateEditField = wrapField(DateEdit);
