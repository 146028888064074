import React from 'react';
import {Link} from 'react-router-dom';
import {format, newDateWithoutTime} from '../../infrastructure/util';

import {AppContextProps} from '../../infrastructure/react-context';
import {BaseComponent} from '../../infrastructure/components/BaseComponent';
import {TextField} from '../../infrastructure/fields/TextInput';
import {CustomForm} from '../../infrastructure/components/CustomForm';
import {SenderDoctorResultListRequest, PatientRecordDto} from '../../dto';
import {DateField} from '../../infrastructure/fields/DateInput';
import LoadingIndicator from '../../infrastructure/components/LoadingIndicator';
import {DocumentTitle} from '../../infrastructure/DocumentTitle';

import './sender-doctor-results.scss';
import {subDays} from 'date-fns';
import {SenderDoctorDicomResultRow} from '../../Dicom/SenderDoctorDicomResultRow';

interface Props extends AppContextProps {
}

interface State {
  filter: SenderDoctorResultListRequest | undefined;
  listItems: PatientRecordDto[] | undefined;
  loading: boolean;
  firstQuery: boolean;
}

const defaultFilterState: SenderDoctorResultListRequest = {
  fromDate: subDays(newDateWithoutTime(), 5),
  toDate: subDays(newDateWithoutTime(), 0),
  patientIdentNumber: undefined,
  patientFirstName: undefined,
  patientLastName: undefined,
};

export class SenderDoctorResultsList extends BaseComponent<Props, State> {

  state: State = {
    loading: false,
    filter: undefined,
    listItems: undefined,
    firstQuery: false,
  };

  async searchItems() {

    const {server, actions} = this.props.context;

    await this.setStateAsync({
      loading: true,
      listItems: [],
    });

    if (!this.state.filter) {
      throw new Error('The filter is falsy.');
    }

    const response = await server.senderDoctorResultList(this.state.filter);

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    await this.setStateAsync({
      loading: false,
      listItems: response.payload.items,
    });
  }

  async getCachedFilter() {
    const {server, actions} = this.props.context;

    await this.setStateAsync({loading: true});

    const response = await server.getSessionInfo({});

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    const filter = response.payload.state.senderDoctorResultListRequest || defaultFilterState;
    filter.fromDate = subDays(newDateWithoutTime(), 5);
    filter.toDate = subDays(newDateWithoutTime(), 0);

    await this.setStateAsync({loading: false, filter});
  }

  async saveFilterCache() {
    const {server, actions} = this.props.context;

    await this.setStateAsync({loading: true});

    const response = await server.saveSessionInfo({state: {senderDoctorResultListRequest: this.state.filter}});

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    await this.setStateAsync({loading: false});
  }

  componentDidMountAsync = async () => {
    await this.getCachedFilter();
    await this.searchItems();
  };

  submit = async (filter: SenderDoctorResultListRequest) => {
    await this.setStateAsync({filter});
    await this.searchItems();
    await this.saveFilterCache();
    await this.setStateAsync({
      firstQuery: true,
    });
  };

  render() {
    const {listItems, loading, firstQuery} = this.state;

    return (
      <div className="sender-doctor-results-list-page">

        <DocumentTitle title="Резултати на пациенти - Картотека"/>

        {this.state.filter && <CustomForm values={this.state.filter} onSubmit={this.submit} render={(_) => (
          <div className="card">
            <div className="card-header blue white-text">
              Резултати на пациенти - Картотека
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-sm-6 col-md-6">
                  <DateField
                    name="fromDate"
                    label="От дата:"
                  />
                </div>
                <div className="col-sm-6 col-md-6">
                  <DateField
                    name="toDate"
                    label="До дата:"
                  />
                </div>
                <div className="col-sm-3 col-md-3">
                  <TextField
                    name="patientIdentNumber"
                    label="ЕГН:"
                  />
                </div>
                <div className="col-sm-3 col-md-3">
                  <TextField
                    name="patientFirstName"
                    label="Име:"
                  />
                </div>
                <div className="col-sm-3 col-md-3">
                  <TextField
                    name="patientLastName"
                    label="Фамилия:"
                  />
                </div>
                <div className="col-sm-3 col-md-3 vertical-center">
                  <button className="btn btn-md btn-success" style={{width: '100%'}} type="submit">
                    Обнови
                  </button>
                </div>
              </div>

              {(firstQuery || (listItems && !!listItems.length)) &&
                  <div className="table-responsive" style={{marginTop: '1rem'}}>
                      <table className="table list-table">
                          <thead className="blue text-white">
                          <tr>
                              <th>Статус</th>
                              <th>Дата на регистрация</th>
                              <th>Дата на резултата</th>
                              <th>ЕГН</th>
                              <th>Пациент</th>
                              <th>Насочен от</th>
                              <th>Пояснение</th>
                              <th>Локация</th>
                          </tr>
                          </thead>
                        {listItems && listItems.length > 0 && <tbody>
                        {listItems.map((item) => (
                          <>
                            <tr key={item.patientRecID}>
                              <td>
                                {item.customReadyStatus === 2 && <Link
                                    to={`/doctor/sender-doctor-results/${item.patientRecID}`}
                                    className="btn btn-sm btn-success">
                                    &nbsp;&nbsp;&nbsp;&nbsp;ГОТОВ&nbsp;</Link>}
                                {item.customReadyStatus === 3 && <Link
                                    to={`/doctor/sender-doctor-results/${item.patientRecID}`}
                                    className="btn btn-sm btn-primary">
                                    НЕГОТОВ</Link>} {item.customReadyStatus === 4 &&
                                  <button type="button" className="btn btn-sm btn-warning">&nbsp;ПРОЦЕС</button>}
                                {/* tslint:disable-next-line:max-line-length */}
                                {item.customReadyStatus === 1 && <button type="button"
                                                                         className="btn btn-sm btn-info">&nbsp;&nbsp;ПРИЕТ&nbsp;&nbsp;</button>}{item.customReadyStatus === 0 &&
                                  <button type="button"
                                          className="btn btn-sm btn-light">&nbsp;&nbsp;&nbsp;ЧАКА&nbsp;&nbsp;&nbsp;</button>}
                              </td>
                              <td
                                className="no-wrap">{item.dateJRequestDateTime ? format(item.dateJRequestDateTime, 'dd.MM.yyyy') : '-'}</td>
                              <td
                                className="no-wrap">{item.datePatientRecDateTime ? format(item.datePatientRecDateTime, 'dd.MM.yyyy') : '-'}</td>
                              <td className="no-wrap">{item.patientIdentNumber}</td>
                              <td className="no-wrap">{item.patientFullName}</td>
                              <td className="no-wrap">{item.senderDoctorName}</td>
                              <td>{item.groupConcatServiceNames}</td>
                              <td>{item.locationName}</td>
                            </tr>
                            {item.dicomImages.length > 0 &&
                              item.dicomImages.map((x) => (
                                <SenderDoctorDicomResultRow model={x} context={this.props.context}/>
                              ))
                            }
                          </>
                        ))}
                        </tbody>}
                      </table>
                  </div>}

              {firstQuery && !loading && listItems && !listItems.length &&
                  <div style={{margin: '1rem'}}>
                      <h3 style={{color: 'black', textAlign: 'center'}}>Не са намерени резултати за това търсене.</h3>
                  </div>}

              {loading && <LoadingIndicator/>}

            </div>
          </div>
        )}/>}
      </div>
    );
  }
}
