import React, { FunctionComponent, useContext, Fragment, memo } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { SessionState } from '../session.state';
import { CacheState } from '../cache.state';
import { RenderPermissionOptions, shouldRender } from '../render-permissions';
import { ReactAppContext } from '../react-context';
import { ReduxState } from '../redux/store';

type Frame = 'standard' | 'wide' | 'narrow' | 'legacy' | 'without';

interface RouteTableEntry extends RenderPermissionOptions {
  location: string;
  component: any;
  frame?: Frame;
}

export type RouteTable = RouteTableEntry[];

interface Props {
  routeTable: RouteTable;
  session: SessionState;
  cache: CacheState;
}

const RenderRouteTable: FunctionComponent<Props> = memo(({routeTable, session, cache}) => {

  const getContext = useContext(ReactAppContext);

  return (
    <Switch>
      {routeTable.map((x, i) => {
        const show = shouldRender(x, session, cache);
        const TopLevelComponent = x.component;
        const frame = x.frame || 'standard';

        return (
          <Route exact key={i} path={x.location} render={(componentProps) =>
            <Fragment>
              {show && <div className={`${frame}-frame`}>
                <TopLevelComponent {...componentProps} context={getContext()}/>
              </div>}
              {!show && <Redirect to={{pathname: '/'}}/>}
            </Fragment>
          }/>
        );
      })}

      <Route render={() => <Redirect to="/"/>}/>
    </Switch>
  );
});

export default connect(({session, cache}: ReduxState) => ({session, cache}))(RenderRouteTable);
