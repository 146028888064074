import {
  Dispatch,
  SetStateAction,
  useState,
  DependencyList,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { isFunction } from './util';
import { ReactAppContext } from './react-context';
import { ServerApiClient } from '../dto';
import { Result } from './api-result';
import { AppContextFactory } from './context';

export function useMergedState<S>(initialState: S | (() => S)): [S, Dispatch<SetStateAction<Partial<S>>>];

export function useMergedState<S = undefined>(): [S | undefined, Dispatch<SetStateAction<Partial<S> | undefined>>];

export function useMergedState(arg?: any) {

  // tslint:disable-next-line:prefer-const
  let [value, setValue] = useState(arg);

  const mySetValue = (newValue: any) => {

    let newState;

    if (isFunction(newValue)) {
      newState = newValue(value);
    } else {
      newState = newValue;
    }

    value = {
      ...value,
      ...newState,
    };

    setValue(value);
  };

  return [value, mySetValue];
}

type AsyncCallback = (...args: any[]) => Promise<any>;

export const useAsyncCallback = (callback: AsyncCallback, deps: DependencyList): AsyncCallback =>
  useCallback((...args: any[]) => callback(...args).catch((err: Error) => {
    const getContext = useContext(ReactAppContext);
    getContext().actions.errors.setError(err);
  }), deps);

type AsyncEffectCallback = (isUnmounted: () => boolean) => Promise<void>;

export const useAsyncEffect = (effect: AsyncEffectCallback, deps?: DependencyList): void => {

  const getContext = useContext(ReactAppContext);

  useEffect(() => {
    let unmounted = false;

    const isUnmounted = () => unmounted;

    effect(isUnmounted).catch((err: Error) => {
      getContext().actions.errors.setError(err);
    });

    return () => {
      unmounted = true;
    };
  }, deps);
};

// tslint:disable-next-line:only-arrow-functions
export const useServer = function <T>(
  serverCall: (server: ServerApiClient) => Promise<Result<T>>,
  deps?: DependencyList,
): [false, T] | [true, undefined] {

  const getContext = useContext(ReactAppContext);

  const {server, actions} = getContext();

  const [result, setResult] = useState<T | undefined>(undefined);

  useEffect(() => {
    let unmounted = false;
    serverCall(server).then((x) => {
      if (!unmounted) {
        if (x.success) {
          setResult(x.payload);
        } else {
          actions.errors.setErrorMessages(x.errorMessages);
        }
      }
    });

    return () => {
      unmounted = true;
    };
  }, deps);

  if (result === undefined) {
    return [true, undefined];
  }

  return [false, result];
};

export const useUnmounted: () => { current: boolean } = () => {
  const ref = useRef<boolean>(false);
  useEffect(() => () => {
    ref.current = true;
  }, []);
  return ref;
};

export const useAppContext: AppContextFactory = () => useContext(ReactAppContext)();
