import React from 'react';

import {BaseComponent} from '../../infrastructure/components/BaseComponent';
import {AppContextProps} from '../../infrastructure/react-context';
import {serverValidations, GrOperationDto} from '../../dto';
import * as validations from '../../infrastructure/fields/validations';
import LoadingIndicator from '../../infrastructure/components/LoadingIndicator';
import {CustomModal} from '../../infrastructure/components/CustomModal';
import {TextField} from '../../infrastructure/fields/TextInput';
import {SelectField} from '../../infrastructure/fields/SelectInput';
import {SelectItem} from '../../infrastructure/fields/select-item';
import {TextAreaField} from '../../infrastructure/fields/TextAreaInput';
import {CustomForm} from '../../infrastructure/components/CustomForm';
import {DateField} from '../../infrastructure/fields/DateInput';
import dateFnsFormat from 'date-fns/format';
import {AlertModal} from '../../infrastructure/AlertModal';
import {trimDate} from '../../infrastructure/util';

interface Props extends AppContextProps {
  operationID?: number;
  onClose: (success: boolean) => void;
}

interface State {
  loading: boolean;
  isSubmitting: boolean;
  showError: boolean;
  errorMessage: string | string[];

  operationCabinets: SelectItem[] | undefined;
  operationTypes: SelectItem[] | undefined;
  startEndTimes: SelectItem[] | undefined;

  cabinetID: number | undefined;
  operationDate: Date | undefined;
  startTime: string | undefined;
  endTime: string | undefined;
  patientIdentNumber: string | undefined;
  patientName: string | undefined;
  patientMobile: string | undefined;
  operationType: number | undefined;
  note: string | undefined;
}

interface OperationFields {
  cabinetID: number;
  operationDate: Date;
  startTime: string;
  endTime: string;
  patientIdentNumber: string;
  patientName: string;
  patientMobile: string;
  operationType: number;
  note: string;
}

// Оперативно планиране нов/редакция
export class OperationPlanningNewEditModal extends BaseComponent<Props, State> {

  state: State = {
    loading: true,
    isSubmitting: false,
    showError: false,
    errorMessage: '',

    operationCabinets: undefined,
    operationTypes: undefined,
    startEndTimes: undefined,

    cabinetID: undefined,
    operationDate: undefined,
    startTime: undefined,
    endTime: undefined,
    patientIdentNumber: undefined,
    patientName: undefined,
    patientMobile: undefined,
    operationType: undefined,
    note: undefined,
  };

  componentDidMountAsync = async () => {
    const {server, actions} = this.props.context;

    this.generateStartEndTimes();

    const resCabs = await server.getOperationCabinets({});
    if (!resCabs.success) {
      actions.errors.setErrorMessages(resCabs.errorMessages);
      this.setState({loading: false});
      return;
    }

    const resOperTypes = await server.getOperationTypes({});
    if (!resOperTypes.success) {
      actions.errors.setErrorMessages(resOperTypes.errorMessages);
      this.setState({loading: false});
      return;
    }

    // ако има подадена операция трябва да я заредим
    if (!!this.props.operationID) {
      const resOp = await server.getOperations({grOperationID: this.props.operationID});
      if (!resOp.success) {
        actions.errors.setErrorMessages(resOp.errorMessages);
        this.setState({loading: false});
        return;
      }

      if (resOp.payload.operations.length > 0) {
        const op = resOp.payload.operations[0];

        const opDate = trimDate(op.grStartDateTime);
        const stTime = dateFnsFormat(op.grStartDateTime, 'HH:mm');
        const edTime = dateFnsFormat(op.grEndDateTime, 'HH:mm');

        this.setState({
          cabinetID: op.grCabinetID,
          operationDate: opDate,
          startTime: stTime,
          endTime: edTime,
          patientIdentNumber: op.grOperationPatientIdentNumber,
          patientName: op.grOperationPatientName,
          patientMobile: op.grOperationPatientMobile,
          operationType: op.grOperationTypeID,
          note: op.grDescription,
        });
      }
    }

    this.setState({
      loading: false,
      operationCabinets: resCabs.payload.operationCabinets
        .map((c) => ({value: c.grCabinetID, label: c.grCabinetName})),
      operationTypes: resOperTypes.payload.operationTypes
        .map((t) => ({value: t.grOperationTypeID, label: t.grOperationTypeName})),
    });
  };

  showErrorMessage = (msg: string | string[]) => {
    this.setState({showError: true, errorMessage: msg});
  };

  generateStartEndTimes = () => {
    const times = [];
    const time = new Date(1, 0, 1, 8);
    while (true) {
      const format = dateFnsFormat(time, 'HH:mm');
      times.push({value: format, label: format});
      const min = time.getMinutes();
      time.setMinutes(min + 15);

      if (time.getHours() === 21 && time.getMinutes() === 15) {
        break;
      }
    }

    this.setState({startEndTimes: times});
  };

  saveOperation = async (op: OperationFields) => {
    const {server} = this.props.context;
    await this.setStateAsync({isSubmitting: true});

    try {
      const startHour = parseInt(op.startTime.substr(0, 2), 10);
      const startMinute = parseInt(op.startTime.substr(3, 2), 10);
      const endHour = parseInt(op.endTime.substr(0, 2), 10);
      const endMinute = parseInt(op.endTime.substr(3, 2), 10);

      const startDateTime = new Date(op.operationDate);
      startDateTime.setHours(startHour, startMinute);
      const endDateTime = new Date(op.operationDate);
      endDateTime.setHours(endHour, endMinute);

      if (startDateTime >= endDateTime) {
        this.showErrorMessage('Началото на операцията не може да бъде след края!');
        return;
      }

      const dto: GrOperationDto = {
        grOperationID: this.props.operationID ?? 0,
        grCabinetID: op.cabinetID,
        grStartDateTime: startDateTime,
        grEndDateTime: endDateTime,
        grOperationTypeID: op.operationType,
        grDescription: op.note,
        grOperationPatientIdentNumber: op.patientIdentNumber,
        grOperationPatientName: op.patientName,
        grOperationPatientMobile: op.patientMobile,

        // не ни трябва реално, но за да не правим цял нов модел трябва да го попълним
        operatorDoctorName: '',
      };

      const res = await server.saveOperation({operation: dto});
      if (!res.success) {
        this.showErrorMessage(res.errorMessages);
        return;
      }

      this.props.onClose(true);
    } catch (err) {
      this.showErrorMessage(err.toString());
    } finally {
      this.setState({isSubmitting: false});
    }
  };

  deleteOperation = async () => {
    if (this.props.operationID === undefined) {
      return;
    }

    const {server} = this.props.context;
    await this.setStateAsync({isSubmitting: true});

    try {
      const res = await server.deleteOperation({grOperationID: this.props.operationID});
      if (!res.success) {
        this.showErrorMessage(res.errorMessages);
        return;
      }

      this.props.onClose(true);
    } catch (err) {
      this.showErrorMessage(err.toString());
    } finally {
      this.setState({isSubmitting: false});
    }
  };

  render() {
    const {
      loading,
      isSubmitting,
      showError,
      errorMessage,
      operationCabinets,
      operationTypes,
      startEndTimes,
    } = this.state;

    return (
      <div className="operation-planning-new-edit">
        {!loading && <CustomModal title="Планиране на операция" onClose={() => this.props.onClose(false)}>

            <CustomForm onSubmit={this.saveOperation} render={() => (
              <div>
                <SelectField name="cabinetID" label="Операционна" items={operationCabinets}
                             defaultValue={this.state.cabinetID}
                             validation={validations.rangeNumber(1, 9999999, 'Моля, изберете операционна.')}/>

                <div className="row">
                  <DateField name="operationDate" label="Дата" defaultValue={this.state.operationDate}
                             className="col-sm-12 col-md-6"
                             style={{display: 'inline-block'}}/>
                  <SelectField name="startTime" label="начало" items={startEndTimes}
                               className="col-sm-6 col-md-3"
                               defaultValue={this.state.startTime}/>
                  <SelectField name="endTime" label="край" items={startEndTimes}
                               className="col-sm-6 col-md-3"
                               defaultValue={this.state.endTime}/>
                </div>
                <TextField name="patientIdentNumber" label="ЕГН/ЛНЧ" defaultValue={this.state.patientIdentNumber}/>
                <TextField name="patientName" label="Пациент"
                           defaultValue={this.state.patientName}
                           validation={serverValidations.grOperationDto.grOperationPatientName}/>
                <TextField name="patientMobile" label="Телефон" defaultValue={this.state.patientMobile}/>
                <SelectField name="operationType" label="Вид операция" items={operationTypes}
                             defaultValue={this.state.operationType}
                             validation={validations.rangeNumber(1, 9999999, 'Моля, изберете вид оепрация.')}/>
                <TextAreaField name="note" label="Бележка" defaultValue={this.state.note}/>

                <div>
                  <button className="btn btn-danger float-left" onClick={this.deleteOperation}
                          disabled={isSubmitting || this.props.operationID === undefined}>Изтрий
                  </button>
                  <button className="btn btn-secondary float-right" onClick={() => this.props.onClose(false)}>Отказ
                  </button>
                  <button className="btn btn-primary float-right" type="submit" disabled={isSubmitting}>Запис
                  </button>
                </div>
              </div>
            )}/>

          {showError && <AlertModal onClose={() => this.setState({showError: false})} message={errorMessage}/>}
        </CustomModal>}

        {loading && <LoadingIndicator/>}
      </div>
    );
  }
}
