import React, { Fragment } from 'react';

import { AppContextProps } from '../../infrastructure/react-context';
import { BaseComponent } from '../../infrastructure/components/BaseComponent';
import { ScreenSizeComponent } from '../../infrastructure/media-type';
import { Result } from '../../infrastructure/api-result';
import { ErrorMessages } from '../../infrastructure/errors';
import { PatientRecordDto } from '../../dto';
import { PdfDocument } from '../../infrastructure/components/PdfDocument';
import LoadingIndicator from '../../infrastructure/components/LoadingIndicator';
import { downloadFile, base64ToArray, printPdf } from '../../infrastructure/util';
import { DocumentTitle } from '../../infrastructure/DocumentTitle';

import './sender-doctor-results.scss';

interface Props extends AppContextProps {
  patientRecID?: number;
  patientID?: number;
  onSelectedResult: (selectedPatientRecID: number) => void;
}

interface State {
  loading: boolean;

  items: PatientRecordDto[] | undefined;
  documentContent: string | undefined;
  documentBytes: Uint8Array | undefined;
  documentTitle: string | undefined;

  documentLoadResult: Result | undefined;
}

export class SenderDoctorResultsView extends BaseComponent<Props, State> {

  state: State = {
    loading: false,
    items: undefined,

    documentContent: undefined,
    documentBytes: undefined,
    documentTitle: undefined,
    documentLoadResult: undefined,
  };

  async fetchData() {

    const {server, actions} = this.props.context;

    await this.setStateAsync({
      loading: true,
      documentContent: undefined,
      documentBytes: undefined,
    });

    const response = await server.senderDoctorResultView({
      patientID: this.props.patientID,
      patientRecID: this.props.patientRecID,
    });

    if (!response.success) {

      actions.errors.setErrorMessages(response.errorMessages);

      await this.setStateAsync({
        loading: false,
        documentContent: undefined,
        documentBytes: undefined,
      });

      return;
    }

    if (response.payload.pdfContent) {
      await this.setStateAsync({
        loading: false,
        items: response.payload.items,

        documentLoadResult: undefined,
        documentContent: response.payload.pdfContent,
        documentBytes: base64ToArray(response.payload.pdfContent),
        documentTitle: response.payload.title,
      });
    } else {
      await this.setStateAsync({
        loading: false,
        items: response.payload.items,
      });
    }
  }

  componentDidMountAsync = async () => {
    await this.fetchData();
  };

  componentDidUpdateAsync = async (prevProps: Props) => {

    if (prevProps.patientRecID !== this.props.patientRecID ||
      prevProps.patientID !== this.props.patientID) {
      await this.fetchData();
    }
  };

  downloadPdf = () => {
    if (!this.state.documentContent) {
      throw new Error('Trying to print while documentContent is falsy.');
    }

    downloadFile(
      this.state.documentBytes,
      this.state.documentContent,
      `${this.state.documentTitle}.pdf`,
    );
  };

  printPdf = () => {
    if (!this.state.documentContent) {
      throw new Error('Trying to print with falsy documentContent.');
    }

    printPdf(
      this.state.documentBytes,
      this.state.documentContent,
      `${this.state.documentTitle}.pdf`,
    );
  };

  onPdfLoad = (pdfLoadResult: Result) => {
    setTimeout(() => {
      this.setState({
        documentLoadResult: pdfLoadResult,
        loading: false,
      });
    }, 0);
  };

  render() {

    const {
      items,
      documentContent,
      documentBytes,
      loading,
      documentTitle,
      documentLoadResult,
    } = this.state;

    return (
      <div className="sender-doctor-lab-results-page">

        <DocumentTitle title="Резултати на пациенти - Пациент"/>

        <div className="card">
          <div className="card-header blue white-text">
            Резултати на пациенти - Пациент
          </div>
          <div className="card-body">
            {items && <div className="row" style={{margin: '0', marginTop: '.6rem'}}>
              <div className="col-sm-12">
                <div className="card resource-box">
                  <div className="card-body text-center padded">
                    <h2>{items[0] && items[0].patientFullName}</h2>
                    {(!items[0]) && <h2>Към този момент няма изследвания за този пациент.</h2>}
                  </div>
                </div>
              </div>
            </div>}

            <div className="row" style={{margin: '0'}}>

              <div className="col-xl-8">

                {documentContent && documentBytes && <Fragment>

                  <div className="card resource-box">
                    <div className="card-body">
                      <div>
                        <h2 className="text-center document-title">{documentTitle}</h2>
                      </div>

                      {!!documentLoadResult && documentLoadResult.success && <Fragment>
                        <hr/>

                        <div className="button-group">
                          <button className="btn btn-md btn-success" onClick={this.downloadPdf}>Изтегли</button>
                          <ScreenSizeComponent size="not-phone">
                            <button className="btn btn-md btn-default" onClick={this.printPdf}>Принтирай</button>
                          </ScreenSizeComponent>
                        </div>
                      </Fragment>}
                    </div>
                  </div>

                  {!!documentLoadResult && !documentLoadResult.success &&
                  <ErrorMessages errors={documentLoadResult.errorMessages}/>}

                  <PdfDocument data={documentBytes} onLoad={this.onPdfLoad}/>

                </Fragment>}

                {loading && <LoadingIndicator className="document-loading" delay={0}/>}
              </div>

              <div className="col-xl-4">

                {items && !!items.length && <Fragment>
                  <div className="card result-list-box">
                    <div className="card-body">
                      {items && items.map((x) =>
                        <div key={x.patientRecID}
                             onClick={async () => {

                               if (!x.hasPatientRecord) {
                                 return;
                               }

                               if (x.patientRecID === this.props.patientRecID) {
                                 await this.fetchData();
                                 return;
                               }

                               this.props.onSelectedResult(x.patientRecID || 0);

                               window.scrollTo(0, 0);
                             }}
                             className={`list-group-item active result-item-link ${(!x.hasPatientRecord ? 'not-ready' : '')}`}>
                          {x.onlineDisplayName}
                        </div>)}
                    </div>
                  </div>
                </Fragment>}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
