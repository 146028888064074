import { AppContextFactoryProps, withAppContext } from '../infrastructure/react-context';
import { BaseComponent } from '../infrastructure/components/BaseComponent';
import { CustomModal } from '../infrastructure/components/CustomModal';
import React from 'react';

/* Форма за потвърждване от потребителя */
interface Props extends AppContextFactoryProps {
  onCancel: () => void;
  onSuccess: () => void;
  deleteMessage: string;
}

class JUserConfirmModalImp extends BaseComponent<Props, {}> {

  onSuccess = () => {
    this.props.onSuccess();
  };

  onCancel = () => {
    this.props.onCancel();
  };

  render() {
    return (
      <div className="delete_ask">
        {<CustomModal title="Потвърждаване на операцията!" onClose={this.onCancel}>
          <div className="card">
            <div className="row">
              <div className="col-12 p-2 m-2">
                <h3>{this.props.deleteMessage}</h3>
              </div>
            </div>
            <div className="col-12">
              <div className="float-right">
                <button className="btn btn-danger" onClick={this.onSuccess}>Потвърждавам</button>
                <button className="btn bg-info" type="button" onClick={this.onCancel}> не сега</button>
              </div>
            </div>
          </div>
        </CustomModal>
        }
      </div>
    );
  }
}
/* Прозорец за потвърждаване на операция*/
export const JUserConfirmModal = withAppContext(JUserConfirmModalImp);
