import { FunctionComponent, useEffect } from 'react';
import { texts } from './texts';

const DefaultTitle = texts.APP_NAME;

interface Props {
  title: string;
}

export const DocumentTitle: FunctionComponent<Props> = ({title}) => {
  useEffect(() => {
    document.title = `${DefaultTitle} | ${title}`;
    return () => {
      document.title = DefaultTitle;
    };
  }, []);
  return null;
};
