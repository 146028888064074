import {AppContextFactoryProps, withAppContext} from '../infrastructure/react-context';
import {BaseComponent} from '../infrastructure/components/BaseComponent';
import {CustomModal} from '../infrastructure/components/CustomModal';
import React from 'react';
import {TextInput} from '../infrastructure/fields/TextInput';
import {ErrorMessages} from '../infrastructure/errors';
import {isOnlyMatch} from '../infrastructure/util';
import {emailRegex} from '../infrastructure/fields/validations';

interface Props extends AppContextFactoryProps {
  onClose: () => void;
  onConfirm: (downloadEmail: string) => void;
}

interface State {
  downloadEmail: string;
  errorMessages: string[];
}

class DicomJStudyArchiveEmailModalImpl extends BaseComponent<Props, State> {
  state: State = {
    downloadEmail: '',
    errorMessages: [],
  };

  onSubmit = async () => {
    const val = isOnlyMatch(emailRegex, this.state.downloadEmail);
    if (!val) {
      await this.setStateAsync({errorMessages: ['Моля, въведете правилен имейл.']});
      return;
    }

    this.props.onConfirm(this.state.downloadEmail);
  };

  render() {
    const {onClose} = this.props;
    const {errorMessages} = this.state;

    return (
      <div className="confirmation-modal">
        {<CustomModal title={'Имейл за получаване на линк с архива'} onClose={onClose}>
            <div className="d-flex flex-column flex-wrap">
              <div className="mb-2">Подготовката на архива може да отнеме до 30 минути. Моля, след като изчакате необходимото време, обновете тази страница. Благодарим ви за търпението!</div>
              <div className="font-weight-bold"> Ако въведете своя имейл и го запазите, ще получите линк, чрез който ще можете да изтеглите създадения архив по всяко време.
              </div>
              <TextInput
                label={'имейл за получаване на линк'}
                value={this.state.downloadEmail}
                name={'downloadEmail'}
                onChange={async (value) => await this.setStateAsync({downloadEmail: value || ''})}
              />
              {errorMessages && <ErrorMessages errors={errorMessages}/>}
              <div className="flex-row ml-auto">
                <button type="submit" className="btn btn-md btn-success" onClick={this.onSubmit}>
                  Запази
                </button>
                <button type="button" className="btn btn-md btn-warning" onClick={() => onClose()}>
                  Отказ
                </button>
              </div>
            </div>
        </CustomModal>}
      </div>
    );
  }
}

export const EmailForStudyArchiveModal = withAppContext(DicomJStudyArchiveEmailModalImpl);
