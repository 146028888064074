import React from 'react';

import {AppContextProps} from '../infrastructure/react-context';
import {BaseComponent} from '../infrastructure/components/BaseComponent';
import {SignDocCM} from '../dto';
import {Link} from 'react-router-dom';
import {JDateTime} from '../public/JDateTime';
import {RsaSignDocumentModal} from './RsaSignDocumentModal';

interface Props extends AppContextProps {
  signDocument: SignDocCM;
  onSigned: () => void;
  hasCertificate: boolean;
}

interface State {
  errorMessages: string[];
  showPinModal: boolean;
}

export class RsaDocument extends BaseComponent<Props, State> {
  state: State = {
    errorMessages: [],
    showPinModal: false,
  };

  showSignModal = async () => {
    await this.setStateAsync({showPinModal: true});
  };

  closeSignModal = async () => {
    await this.setStateAsync({showPinModal: false});
  };

  render() {
    const {showPinModal} = this.state;
    const {signDocument, hasCertificate} = this.props;

    return (
      <div>
        <div className={`card mb-2 border-${signDocument.signStatus === 1 ? 'warning' : 'info'}`}>
          <div className="card-header p-2">
            <div className="row">
              <div className="col-md-8 align-self-center">
                {signDocument.signDocID} | {JDateTime.toJoystickDateFormat(signDocument.requestDatetime)} г.
                | {signDocument.docName || '-'}
              </div>
              {signDocument.signStatus === 1 &&
                  <>
                      <div className="col-6 col-md-2">
                          <Link className="btn btn-sm btn-info m-0 mt-2 mt-md-0 w-100"
                                to={`/patient/rsa/${signDocument.signDocID}`}>ПРЕГЛЕД</Link>
                      </div>
                      <div className="col-6 col-md-2">
                          <button
                              disabled={!hasCertificate}
                              type="submit" className="btn btn-sm btn-success m-0 mt-2 mt-md-0 w-100"
                              onClick={this.showSignModal}>
                              подпиши
                          </button>
                      </div>
                  </>
              }
              {signDocument.signStatus === 2 &&
                  <>
                      <div className="col-6 col-md-2">
                          <Link className="btn btn-sm btn-info m-0 mt-2 mt-md-0 w-100"
                                to={`/patient/rsa/${signDocument.signDocID}`}>ПРЕГЛЕД</Link>
                      </div>
                      <div className="col-6 col-md-2">
                          <button type="submit" className="btn btn-sm btn-warning m-0 mt-2 mt-md-0 w-100">
                              сподели
                          </button>
                      </div>
                  </>
              }
            </div>
          </div>
        </div>

        {showPinModal &&
            <RsaSignDocumentModal
                signDocID={this.props.signDocument.signDocID}
                onClose={this.closeSignModal}
                onSigned={async () => {
                  this.props.onSigned();
                  await this.closeSignModal();
                }}
                context={this.props.context}
            />
        }
      </div>
    );
  }
}
