import React, { CSSProperties, memo, useState } from 'react';
import { ChildrenProps } from '../react-helpers';
import { OutsideAlerter } from './OutsideAlerter';

import './DropdownButton.scss';

interface Props extends ChildrenProps {
  label: string;
  style?: CSSProperties;
  className?: string;
}

export const DropdownButton = memo((props: Props) => {

  const {label, className, children, style} = props;
  const [open, setOpen] = useState<boolean>(false);

  return (
    <OutsideAlerter registered={open} onOutsideClick={() => setOpen(false)}>
      {(ref: any) => (
        <div ref={ref} className={`btn-group ${(open ? 'show' : '')}`}>
          <button
            type="button"
            className={`${className || ''} dropdown-toggle`}
            style={style}
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            onClick={() => setOpen((x) => !x)}>
            {label}
          </button>
          <div className={`dropdown-menu ${(open ? 'show' : '')}`}>
            {children}
          </div>
        </div>
      )}
    </OutsideAlerter>
  );
});
