import React, { FunctionComponent, useState, useCallback, CSSProperties, memo, Fragment } from 'react';

import { useServer, useUnmounted, useAppContext } from '../infrastructure/custom-hooks';
import LoadingIndicator from '../infrastructure/components/LoadingIndicator';
import { ErrorMessages } from '../infrastructure/errors';
import { TextField } from '../infrastructure/fields/TextInput';
import { CustomForm } from '../infrastructure/components/CustomForm';
import { TextAreaField } from '../infrastructure/fields/TextAreaInput';
import { CustomModal } from '../infrastructure/components/CustomModal';
import { DoctorTemplateDto } from '../dto';

import './TemplatesButton.scss';

interface EditTemplateModalProps {
  templateID: number;
  templateTypeID: number;
  onClose: () => void;
}

const EditTemplateModal: FunctionComponent<EditTemplateModalProps> = memo(({onClose, templateID, templateTypeID}) => {

  const [loading, response] = useServer((x) => {
    if (templateID === 0) {
      return x.newDoctorTemplate({templateTypeID});
    }
    return x.getDoctorTemplate({templateID});
  }, [templateID]);

  const [errorMessages, setErrorMessages] = useState<string[] | undefined>(undefined);

  const unmounted = useUnmounted();
  const {server} = useAppContext();

  const onSubmit = async (data: DoctorTemplateDto) => {

    const saveResponse = await server.saveDoctorTemplates({item: data});

    if (unmounted.current) {
      return;
    }

    if (!saveResponse.success) {
      setErrorMessages(saveResponse.errorMessages);
      return;
    }

    onClose();
  };

  return (
    <CustomModal className="select-templates-button__edit-modal" onClose={onClose}
                 title={`Шаблони - ${(templateID === 0 ? 'Нов' : 'Редакция')}`}>
      {loading && <LoadingIndicator/>}

      {response && <CustomForm values={response.item} onSubmit={onSubmit} render={({isSubmitting}) => (
        <Fragment>

          {isSubmitting && <LoadingIndicator/>}

          <div className="row" style={{marginTop: '1rem'}}>

            <div className="col-sm-6">
              <TextField
                name="templateShortName"
                label="Име:"
              />
            </div>

            <div className="col-sm-6">
              <TextField
                name="templateTypeName"
                label="Тип:"
                readonly
              />
            </div>

            <div className="col-sm-12">
              <TextAreaField
                name="templateDescription"
                label="Съдържание:"
              />
            </div>
          </div>

          {errorMessages && !!errorMessages.length && <div className="row">
            <div className="col-sm-12">
              <ErrorMessages errors={errorMessages}/>
              <hr/>
            </div>
          </div>}

          <div className="text-center">
            <button
              type="submit"
              disabled={isSubmitting}
              className="btn btn-md btn-default">
              Запази
            </button>

            <button type="button" onClick={onClose} className="btn btn-md btn-warning">Назад</button>
          </div>
        </Fragment>
      )}/>}
    </CustomModal>
  );
});

interface DeleteTemplateModalProps {
  templateID: number;
  onClose: () => void;
}

const DeleteTemplateModal: FunctionComponent<DeleteTemplateModalProps> = memo(({onClose, templateID}) => {

  const [loading, response] = useServer((x) => x.getDoctorTemplate({templateID}), [templateID]);

  const [errorMessages, setErrorMessages] = useState<string[] | undefined>(undefined);

  const unmounted = useUnmounted();
  const {server} = useAppContext();

  const deleteClick = async () => {

    const deleteResponse = await server.deleteDoctorTemplate({templateID});

    if (unmounted.current) {
      return;
    }

    if (!deleteResponse.success) {
      setErrorMessages(deleteResponse.errorMessages);
      return;
    }

    onClose();
  };

  return (
    <CustomModal className="select-templates-button__delete-modal" onClose={onClose} title="Шаблони - Изтриване">
      {loading && <LoadingIndicator/>}

      {response && <div className="card">
        <div className="card-body text-center">
          <h4 style={{marginTop: '2rem'}}>Име: {response.item.templateShortName}</h4>
          <hr/>
          <h4 style={{marginTop: '2rem'}}>№: {response.item.templateID}</h4>
          <hr/>
          <h3 style={{marginTop: '2rem'}}>Сигурни ли сте, че искате да изтриете този шаблон?</h3>

          {errorMessages && <Fragment>
            <hr/>
            <ErrorMessages errors={errorMessages}/>
          </Fragment>}

          <div style={{marginTop: '2rem'}}>
            <button type="button" onClick={onClose} className="btn btn-md btn-warning">Назад</button>
            <button className="btn btn-md btn-danger" type="button" onClick={deleteClick}>Изтриване</button>
          </div>
        </div>
      </div>}
    </CustomModal>
  );
});

interface TemplateListModalProps {
  templateTypeID: number;
  onClose: () => void;
  onSelect: (item: DoctorTemplateDto) => void;
}

const TemplateListModal: FunctionComponent<TemplateListModalProps> = memo(({onClose, onSelect, templateTypeID}) => {

  const [deleteItem, setDeleteItem] = useState<DoctorTemplateDto | undefined>(undefined);
  const [editItem, setEditItem] = useState<DoctorTemplateDto | undefined>(undefined);

  const [loading, response] = useServer((x) => x.listDoctorTemplates({templateTypeID}),
    [templateTypeID, deleteItem, editItem]);

  return (
    <Fragment>
      <CustomModal className="select-templates-button__select-modal"
                   title={`Шаблони - ${((response && response.templateTypeName) || '')}`}
                   onClose={onClose}>
        {loading && <LoadingIndicator/>}
        {response && <div className="table-responsive">
          <table className="table list-table">
            <thead className="blue text-white">
              <tr>
                <th>Шаблон</th>
                <th/>
                <th/>
                <th/>
              </tr>
            </thead>

            {response && response.items.length > 0 && <tbody>
              {response.items.map((item) => (
                <tr key={item.templateID}>
                  <td>{item.templateShortName}</td>
                  <td>
                    <button type="button" className="btn btn-sm btn-default"
                            onClick={() => onSelect(item)}>
                      Избери
                    </button>
                  </td>
                  <td>
                    <button type="button" className="btn btn-sm btn-warning"
                            onClick={() => setEditItem(item)}>
                      Редакция
                    </button>
                  </td>
                  <td>
                    <button type="button" className="btn btn-sm btn-danger"
                            onClick={() => setDeleteItem(item)}>
                      Изтриване
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>}
          </table>

          <hr/>

          <div className="text-center" style={{display: 'flex'}}>
            <button type="button" className="btn btn-md btn-success full-width"
                    onClick={() => {
                      setEditItem(({
                        templateID: 0,
                        templateTypeID: response.templateTypeID,
                      }) as DoctorTemplateDto);
                    }}>
              Нов шаблон
            </button>
            <button type="button" onClick={onClose} className="btn btn-md btn-warning full-width">Назад</button>
          </div>
        </div>}
      </CustomModal>

      {deleteItem && <DeleteTemplateModal
        templateID={deleteItem.templateID}
        onClose={() => setDeleteItem(undefined)}
      />}

      {editItem && <EditTemplateModal
        templateID={editItem.templateID}
        templateTypeID={editItem.templateTypeID}
        onClose={() => setEditItem(undefined)}
      />}
    </Fragment>
  );
});

interface TemplatesButtonProps {
  templateTypeID: number;
  onTemplateSelected: (content: string) => void;
  style?: CSSProperties;
  className?: string;
}

export const TemplatesButton: FunctionComponent<TemplatesButtonProps> =
  ({onTemplateSelected, templateTypeID, style, className}) => {

    const [open, setOpen] = useState<boolean>(false);

    const openModal = useCallback(() => setOpen(true), [templateTypeID]);
    const closeModal = useCallback(() => setOpen(false), [templateTypeID]);

    const selectTemplate = (item: DoctorTemplateDto) => {
      onTemplateSelected(item.templateDescription);
      setOpen(false);
    };

    return (
      <div className={`select-templates-button ${className || ''}`} style={style}>
        <button type="button" onClick={openModal} className="select-templates-button btn btn-sm btn-success">
          Шаблони
        </button>
        {open && <TemplateListModal
          onClose={closeModal}
          onSelect={selectTemplate}
          templateTypeID={templateTypeID}
        />}
      </div>
    );
  };
