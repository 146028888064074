import { ReduxStore, StoreOptions } from './store';

const localStorageKey = '6cbf0b25-aa8e-49ff-8394-d1830288325b';

// Subscribes to the store and persists some of the state's fields to localStorage.
export const configureStatePersistence = (store: ReduxStore, {persistentFields}: StoreOptions) => {

  store.subscribe(() => {
    const state = store.getState();
    const subState = Object.fromEntries(
      Object.entries(state || {})
        .filter((x) => persistentFields.includes(x[0])),
    );

    try {
      localStorage.setItem(localStorageKey, JSON.stringify(subState));
    } catch (e) {
      // ignore
    }
  });
};

// Returns the persisted fields from localStorage.
export const getPersistedState = () => {

  try {
    const json = localStorage.getItem(localStorageKey) || '{}';
    return JSON.parse(json);
  } catch (e) {
    return {};
  }
};
