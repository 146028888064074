import React, { FunctionComponent, useEffect, useState, memo } from 'react';

import { AppContextFactoryProps, withAppContext } from '../infrastructure/react-context';
import { SelectField } from '../infrastructure/fields/SelectInput';
import { DoctorCatalogDto } from '../dto';
import { ValidationFunction } from '../infrastructure/fields/field-helpers';

interface Props extends AppContextFactoryProps {
  name: string;
  label: string;
  doctorCategoryIDs?: number[];
  readonly?: boolean;
  validation?: ValidationFunction | ValidationFunction[];
}

const LegacyDoctorSelectFieldImpl: FunctionComponent<Props> = memo((
  {name, label, doctorCategoryIDs, getContext, readonly, validation}) => {

  const [doctors, setDoctors] = useState<DoctorCatalogDto[] | undefined>(undefined);

  useEffect(() => {
    (async () => {
      const {server, actions} = getContext();

      const result = await server.doctorCatalog({doctorCategoryID: (doctorCategoryIDs || [])});

      if (result.success) {
        setDoctors(result.payload.items);
      } else {
        actions.errors.setErrors(result.errorMessages);
      }
    })();
  }, [(doctorCategoryIDs || []).join(', ')]);

  const isLoading = !doctors;

  let items;
  if (!doctors) {
    items = undefined;
  } else {
    items = doctors.map((x) => ({value: x.doctorID, label: x.doctorFirstLastName}));
  }

  return (
    <SelectField
      name={name}
      label={label}
      defaultValueOnSelectedItemRemoval
      items={items}
      isLoading={isLoading}
      readonly={readonly}
      validation={validation}
    />
  );
});

export const LegacyDoctorSelectField = withAppContext(LegacyDoctorSelectFieldImpl);
